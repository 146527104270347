import { SelectChangeEvent } from '@mui/material';
import { IbssComponent } from '../../Core/BaseComponent/IbssComponent';
import { appContext } from '../../../AppContext';
import { DateTime } from 'luxon';
import Helper from '../../../Common/Helper';
import apis from '../../../Providers.Api/apis';
import { Constants } from '../../../Common/Constants';
import IbssDialog from '../BaseDialog/IbssDialog';
import IbssFormControl from '../../Forms/FormControl/IbssFormControl';
import LoadingOverlay from '../../Navigation/LoadingOverlay/LoadingOverlay';
import IbssTextField from '../../Inputs/TextField/IbssTextField';
import IbssInputDropDown from '../../Inputs/SelectList/IbssInputDropDown';
import UserPicker, * as UserPickerModule from '../../../Components/Inputs/UserPicker/UserPicker';
import { IbssLuxonDateTimePicker } from '../../Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker';
import IbssButton from '../../Buttons/Button/IbssButton';
import Notes, { INote } from '../../Lists/Notes/Notes';
import { EventType } from '../../../Providers.Api/EventTypes';
import { PagedResponse } from '../../../Providers.Api/Models';
import { ISpace } from '../../../Providers.Api/Spaces/UpdateV2SpaceByIdEndpoint';
import IbssTimePicker from '../../Inputs/TimePicker/IbssTimePicker';
import IbssDatePicker from '../../Inputs/DatePicker/IbssDatePicker';
import { IOnBehalfOfVisitor, ISelectedDates } from '../../../Pages/Flex/Bookings/CreateRecurring/DataModels';

class CreateVisitorDialog extends IbssComponent<IProps, IState>
{
    private get visitsService() { return appContext().visitsService; }
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get apiCache() { return appContext().apiCache; }

    constructor(props: IProps)
    {
        super(props);
        this.state ={
            isLoading: false,
            fullname: '',
            company: '',
            email: '',
            invalidVisitorEmail: false,
            hostName: '',
            hostEmail: '',
            pacs: '',
            arrival: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.appState.buildingId),
            departure: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.appState.buildingId),
            date: DateTime.now().offsetTimeByNode(this.appState.buildingId),
            buildingId: this.appState.buildingId,
            creatingVisitor: false,
            arrivalError: "",
            departureError: "",
            spaceName: "",
            spaceId: "",
            notes: [],
            arrivalSpaces: [],
            visitorArrivalLocation: "",
            isFlex: false,
            buildingOption: [],
        }
    }

    private get defaultFlexState(): IFlexInterface
    {
        return {
            isLoading: false,
            fullname: '',
            company: '',
            email: '',
            invalidVisitorEmail: false,
            hostName: '',
            hostEmail: '',
            pacs: '',
            arrival: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.appState.buildingId),
            departure: DateTime.now().plus({ minutes: 10 }).offsetTimeByNode(this.appState.buildingId),
            date: DateTime.now().offsetTimeByNode(this.appState.buildingId),
            buildingId: this.appState.buildingId,
            creatingVisitor: false,
            arrivalError: "",
            departureError: "",
            spaceName: "",
            spaceId: "",
            notes: [],
            arrivalSpaces: [],
            visitorArrivalLocation: "",
        };
    }

    public async componentDidMount(): Promise<void>
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ value: i.Node_Id, label: i.Name }) as IBuildingOption);
        this.setState({ buildingOption: options });
        if (this.props.buildingId)
        {
            await this.setState({ buildingId: this.props.buildingId});
        }
        if (this.props.dates)
        {
            const { Start_Time, End_Time } = this.props.dates;
            await this.setState({ arrival: Start_Time, departure: End_Time });
        }
        if (this.props.hostDetail)
        {
           await this.setState({ hostName: this.props.hostDetail });
        }
        await this.getSpaceDetails(this.state.buildingId);
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevProps.showCreateVisitorDialog !== this.props.showCreateVisitorDialog)
        {
            const url = window.location.href;
            const path = new URL(url).pathname;
            this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
            if (path.includes('flex'))
            {
                this.setState({ isFlex: true });
            }
            this.setState(this.defaultFlexState);
            this.getLocationDetails(this.state.buildingId);
            if (this.props.dates)
            {
                const { Start_Time, End_Time } = this.props.dates;
                await this.setState({ arrival: Start_Time, departure: End_Time });
            }
            if (this.props.hostDetail)
            {
                await this.setState({ hostName: this.props.hostDetail });
            }
        }

        if (prevState.buildingId !== this.state.buildingId)
        {
            this.setState({
                arrival: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.state.buildingId),
                departure: DateTime.now().plus({ minutes: 10 }).offsetTimeByNode(this.state.buildingId),
            })
        }
    }

    private async getLocationDetails(selectedBuildingId: number): Promise<void>
    {
        try 
        {
            const  arrivalLocation: ILocationSelector[] = [];
            const spaces = await appContext().ibssApiClientV2.v2.byNodeid.spaces.get<PagedResponse<ISpace[]>>({ nodeId: selectedBuildingId, filter: "Space_Arrival_Loc eq 1" });
            spaces.value.forEach((item) => {
                 arrivalLocation.push({
                    label: item.Space_Name,
                    value: item.Space_Id,
                })
            })
            
            this.setState({ arrivalSpaces:  arrivalLocation });
        }
        catch (error)
        {
        }
    }

    private async selectedUserChanged(user: UserPickerModule.IUser | null): Promise<void>
    {
        this.setState({ hostEmail: user?.email ?? "" })
    }

    private dateChanged(value: DateTime | null): void
    {
        if(value != null)
        {
            this.setState({
                date: value,
                arrival: this.state.arrival.set({ year: value.year, month: value.month, day: value.day }),
                departure: this.state.departure.set({ year: value.year, month: value.month, day: value.day })
            })
        }
    }

    private async arrivalChanged(value: DateTime | null): Promise<void>
    {
        if (value != null)
        {
            await this.setStateAsync({ arrival: value });
            await this.validateArrival();
        }
    }

    private async departureChanged(value: DateTime | null): Promise<void>
    {
        if (value != null)
        {
            await this.setStateAsync({ departure: value });
            await this.validateDeparture();
        }
    }

    private async validateArrival(): Promise<boolean>
    {
        const fromGreaterThanTo = (this.state.arrival.isValid && this.state.departure.isValid && this.state.arrival >= this.state.departure);

        if (this.state.arrival.isNull())
        {
            await this.setStateAsync({ arrivalError: this.labels.funcCateringMenuEditRequired_S });
        }
        else if (!this.state.arrival.isValid)
        {
            await this.setStateAsync({ arrivalError: this.labels.funcCateringMenuEditMustBeAValidDateAndTime_S });
        }
        else if (fromGreaterThanTo)
        {
            await this.setStateAsync({ arrivalError: this.labels.funcAddVisitorEditMustBeBeforeDeparture_S });
        }
        else
        {
            await this.setStateAsync({ arrivalError: "" });
        }

        if (this.state.departure.isValid)
        {
            if (fromGreaterThanTo)
            {
                await this.setStateAsync({ departureError: this.labels.funcAddVisitorMustBeAfterArrival_S });
            }
            else
            {
                await this.setStateAsync({ departureError: "" });
            }
        }

        return (this.state.arrivalError == "");
    }

    private async validateDeparture(): Promise<boolean>
    {
        const fromGreaterThanTo = (this.state.arrival.isValid && this.state.departure.isValid && this.state.arrival >= this.state.departure);

        if (this.state.departure.isNull())
        {
            await this.setStateAsync({ departureError: this.labels.funcCateringMenuEditRequired_S });
        }
        else if (!this.state.departure.isValid)
        {
            await this.setStateAsync({ departureError: this.labels.funcCateringMenuEditMustBeAValidDateAndTime_S });
        }
        else if (fromGreaterThanTo)
        {
            await this.setStateAsync({ departureError: this.labels.funcAddVisitorMustBeAfterArrival_S });
        }
        else
        {
            await this.setStateAsync({ departureError: "" });
        }

        if (this.state.arrival.isValid)
        {
            if (fromGreaterThanTo)
            {
                await this.setStateAsync({ arrivalError: this.labels.funcAddVisitorEditMustBeBeforeDeparture_S });
            }
            else
            {
                await this.setStateAsync({ arrivalError: "" });
            }
        }

        return (this.state.departureError == "");
    }

    private async getSpaceDetails(selectedBuildingId: number): Promise<void>
    {
        try 
        {
            const visitorArrivalLocation = await apis.getParametersByName(selectedBuildingId);
            const spaceId = visitorArrivalLocation.data.Parameter_Value;
            if (spaceId)
            {
                const spaceList = await this.apiCache.getSpacesByBuilding(selectedBuildingId);
                const space = spaceList.find(x => x.Space_Id === spaceId);
                const spaceName = space ? space.Space_Name : '';
                this.setState({
                    spaceName: spaceName,
                    spaceId: spaceId,
                });
            }
        }
        catch (error)
        {
        }
    }
    private async createVisitorRecord(): Promise<void>
    {
        try
        {
            this.setState({ creatingVisitor: true });
            const visitorFullName = this.state.fullname.split(' ');

            const payload = {
                Space_Id: this.state.visitorArrivalLocation ? this.state.visitorArrivalLocation : this.state.spaceId,
                Space_Name: this.state.spaceName,
                Visitor_First_Name: visitorFullName[0],
                Visitor_Last_Name: visitorFullName.length > 1 ? visitorFullName[visitorFullName.length - 1] : '',
                Visitor_Email: this.state.email,
                Visitor_Company: this.state.company,
                Visit_Host_Name: this.state.hostName,
                Visit_Host_Email: this.state.hostEmail,
                Visit_Start_Date: this.state.arrival.setZoneByNode(this.state.buildingId).toUTC().toISO(),
                Visit_End_Date: this.state.departure.setZoneByNode(this.state.buildingId).toUTC().toISO(),
                Visit_Approval_Comments: "",
                DisableExtUpdate: false,
                Visit_Pacs_Id: this.state.pacs,
                Booking_Id: "",
                Booking_Start: "",
                Booking_End: "",
                Visitor_Dietary_Pref: 0,
                Visit_Save_Info: 0,
                Disable_Ext_Update: false
            };

            if(this.props.getPayload)
            {
                const visitorPayload: IOnBehalfOfVisitor = {
                    Email: payload.Visit_Host_Email,
                    First_Name: payload.Visitor_First_Name,
                    Last_Name: payload.Visitor_Last_Name,
                    Display_Name:null,
                    Company: payload.Visitor_Company,
                    Visit_Id: null,
                    IsVisitor: true
                };
                this.props.getPayload(JSON.stringify(visitorPayload));
                this.props.closeClicked(true);
                return;
            }
            const visitResponse = await this.visitsService.create(this.state.buildingId, payload);

            for (const note of this.state.notes)
            {
                try
                {
                    await appContext().ibssApiClientV2.v2.byNodeid.notes.post({
                        nodeId: this.state.buildingId,
                        body: {
                            Node_Id: this.state.buildingId,
                            Event_Id: visitResponse.Visit_Id,
                            EventType_Id: EventType.visits.id,
                            EventType_Name: EventType.visits.name,
                            Note: note.value,
                            IsDeleted: 0,
                        },
                    });
                }
                catch { }
            }

            const buildingData = this.local.getNodeData().Regions.flatMap(i => i.Buildings).find(x => x.Node_Id == this.state.buildingId);
            const visitArrivalWindow = buildingData?.Vis_Arvl_Wndw_Mins;
            const visitReqsApproval = buildingData?.Vis_Req_Approval;

            if (visitReqsApproval == 1 && this.local.hasRight('API.Visits.Approve') && visitResponse.Visit_IsApproved == 0)
            {
                try
                {
                    await this.visitsService.approve(this.state.buildingId, [visitResponse.Visit_Id], '');
                }
                catch { }
            }

            if (this.local.hasRight('API.Visits.CheckIn') && !this.state.isFlex && this.state.arrival.minus({ minutes: visitArrivalWindow }) <= DateTime.now().offsetTimeByNode(this.state.buildingId))
            {
                try
                {
                    await this.visitsService.checkin(this.state.buildingId, [visitResponse.Visit_Id]);
                }
                catch { }
            }

            this.props.closeClicked(true);
        }
        finally
        {
            this.setState({ creatingVisitor: false });
        }
    }

    private visitorEmailChanged(value: string): void
    {
        this.setState({ invalidVisitorEmail: !Constants.emailExpression?.test(value), email: value });
    }

    private async buildingChanged(buildingId: string): Promise<void>
    {
        this.setState({ buildingId: parseInt(buildingId) });
        await this.getSpaceDetails(parseInt(buildingId));
        await this.getLocationDetails(parseInt(buildingId));
    }

    private isHost(): boolean
    {
        return !!this.props.hostDetail ?? false;
    }

    public render(): JSX.Element
    {
        const allFieldsValid = this.state.fullname.length > 0 && this.state.company.length > 0 && this.state.email.length > 0 && this.state.hostName.length > 0 && !this.state.invalidVisitorEmail

        return (
            <IbssDialog
                open={this.props.showCreateVisitorDialog}
                onClose={() => this.props.closeClicked()}
                dialogContent=
                {
                    <>
                        {this.state.creatingVisitor && <LoadingOverlay />}
                        <div style={{ fontSize: '14px' }} className='mb-3'>{this.labels.HubLabelAddAdhocVisitorText}</div>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssTextField
                                value={this.state.fullname}
                                fullWidth
                                variant='outlined'
                                onChange={e => this.setState({ fullname: e.target.value })}
                                label={this.labels.HubLabelFullName}
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssTextField
                                value={this.state.company}
                                fullWidth
                                variant='outlined'
                                onChange={e => this.setState({ company: e.target.value })}
                                label={this.labels.HubLabelCompany}
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssTextField
                                value={this.state.email}
                                error={this.state.invalidVisitorEmail}
                                helperText={this.state.invalidVisitorEmail ? this.labels.HubLabelInvalidEmailAddress : ''}
                                fullWidth
                                variant='outlined'
                                onChange={e => this.visitorEmailChanged(e.target.value)}
                                label={this.labels.HubLabelEmail}
                            />
                        </IbssFormControl>
                        {this.state.isFlex && !this.props.buildingId &&
                            <IbssFormControl fullWidth className='mb-3'>
                                <IbssInputDropDown
                                    inputLabel={this.labels.HubLabelBuilding}
                                    options={this.state.buildingOption}
                                    value={this.state.buildingId}
                                    id={"buildingSelection"}
                                    onChange={(e: SelectChangeEvent<string>) => this.buildingChanged(e.target.value)}
                                    fullWidth
                                />
                            </IbssFormControl>
                        }
                        <IbssFormControl fullWidth className='mb-4'>
                            <UserPicker
                                disabled={this.isHost()}
                                searchText={this.state.hostName}
                                placeholder={this.labels.HubLabelHostName}
                                onChange={async text => this.setState({ hostName: text })}
                                onUserChange={user => this.selectedUserChanged(user)}
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssTextField
                                disabled
                                value={this.state.hostEmail}
                                fullWidth
                                variant='outlined'
                                onChange={e => this.setState({ hostEmail: e.target.value })}
                                label={this.labels.HubLabelHostNamePlaceholder}
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssInputDropDown
                                disabled={this.isHost()}
                                inputLabel={this.labels.HubLabelArrivalLocationId}
                                options={this.state.arrivalSpaces}
                                value={this.state.visitorArrivalLocation}
                                id={"arrivalLocationSelection"}
                                onChange={(e: SelectChangeEvent<string>) => this.setState({visitorArrivalLocation: e.target.value})}
                                fullWidth
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssTextField
                                disabled={this.isHost()}
                                value={this.state.pacs}
                                fullWidth
                                variant='outlined'
                                onChange={e => this.setState({ pacs: e.target.value })}
                                label={this.labels.HubLabelPacs}
                            />
                        </IbssFormControl>
                        <IbssDatePicker
                            disabled={this.isHost()}
                            minDate={DateTime.now()}
                            value={this.state.date}
                            onChange={(e) => this.dateChanged(e)}
                            slotProps={{ textField: { label: this.labels.HubLabelDate, fullWidth: true } }}
                            className='mb-1'
                        />
                        <div style={{ display: 'flex' }}>
                            <div className='mb-3' style={{ width: '49%', flexWrap: 'wrap', display: 'flex', marginRight: '2%' }}>
                                <IbssTimePicker
                                    disabled={this.isHost()}
                                    label={this.labels.HubLabelArrival}
                                    value={this.state.arrival}
                                    onChange={e => this.arrivalChanged(e)}
                                    slotProps={{ textField: { error: this.state.arrivalError != "", helperText: this.state.arrivalError } }}
                                />
                            </div>
                            <div style={{ width: '49%' }}>
                                <IbssTimePicker
                                    disabled={this.isHost()}
                                    label={this.labels.HubLabelDeparture}
                                    value={this.state.departure}
                                    onChange={e => this.departureChanged(e)}
                                    slotProps={{ textField: { error: this.state.departureError != "", helperText: this.state.departureError } }}
                                />
                            </div>
                        </div>
                        <IbssFormControl fullWidth className='mb-3'>
                            <Notes
                                buildingId={this.state.buildingId}
                                notes={this.state.notes}
                                onChange={notes => this.setState({ notes: notes })}
                            />
                        </IbssFormControl>
                    </>
                }
                header={this.labels.HubLabelAddVisitor}
                footer=
                {
                    <>
                        <IbssButton disabled={!allFieldsValid || this.state.creatingVisitor} onClick={() => this.createVisitorRecord()} variant="contained" sx={{ width: '100px' }}>
                            {this.labels.HubLabelOk}
                        </IbssButton>
                    </>
                }
                fullWidth
            />
        )
    }
}

export default CreateVisitorDialog;

export interface IProps
{
    closeClicked: (submitted?: boolean) => void;
    showCreateVisitorDialog: boolean;
    buildingId?: number | null;
    dates?: ISelectedDates | null;
    hostDetail?: string;
    getPayload?:  (payload: string) => void;
}

export interface IState
{
    isLoading: boolean,
    fullname: string;
    company: string;
    email: string;
    invalidVisitorEmail: boolean;
    hostName: string;
    hostEmail: string;
    pacs: string;
    date: DateTime;
    arrival: DateTime;
    departure: DateTime;
    buildingId: number;
    creatingVisitor: boolean;
    arrivalError: string;
    departureError: string;
    spaceName: string;
    spaceId: string;
    notes: INote[];
    arrivalSpaces: ILocationSelector[];
    visitorArrivalLocation: string;
    isFlex: boolean,
    buildingOption: IBuildingOption[],
}

export interface IFlexInterface extends Omit<IState, "isFlex" | "buildingOption">
{

}

interface IBuildingOption
{
    label: string,
    value: number,
}


interface ILocationSelector
{
    label: string;
    value: string;
}