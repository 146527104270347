import { IRootNode } from "../Providers.Api/Models";
import { IAdminPortalRights, IApiRights, IDataModelRights, IFlexRights, IIBSSRights, IOneLens360Rights, IRoamerRights, IUserRights } from "../Providers.Api/Models.UserApi";
import { Space } from "../Providers.Api/Spaces/SpaceRepository";
import { IUserPreferences } from "../Providers.Api/UserPreferences/UserPreferenceRepository";
import { IUserDetails } from "./Models";

export class LocalStorageProvider implements ILocalStorageProvider
{
    public setUserPreferences(value: IUserPreferences): void
    {
        localStorage.setItem("userPreferences", JSON.stringify(value));
    }

    public getUserPreferences(): IUserPreferences
    {
        return JSON.parse(localStorage.getItem("userPreferences") || "{}")
    }

    public setUserDetails(value: IUserDetails): void
    {
        const email = value.email ? value.email.toLowerCase() : '';
        const userDetails = { ...value, email };
        localStorage["user"] = JSON.stringify(userDetails);
    }

    public getUserDetails(): IUserDetails
    {
        return JSON.parse(localStorage.getItem("user") || "{}");
    }

    public setToken(value: string): void
    {
        localStorage.setItem("token", value);
    }

    public getToken(): string
    {
        return localStorage.getItem("token") ?? ""
    }

    public setVersion(value: number): void
    {
        localStorage["version"] = value;
    }

    public getVersion(): number
    {
        return localStorage["version"] ?? 0;
    }

    public setNodeData(value: IRootNode): void
    {
        localStorage["nodeData"] = JSON.stringify(value);
    }

    public getNodeData(): IRootNode
    {
        return JSON.parse(localStorage["nodeData"] || "{}");
    };

    public setIbssRights(value: IUserRights): void
    {
        localStorage.setItem("ibssRightList", JSON.stringify(value));
    }

    public getIbssRightList(): IUserRights
    {
        return JSON.parse(localStorage.getItem("ibssRightList") || "{}");
    }

    public clear():void
    {
        localStorage.clear();
    }

    public setUserPolicy(value: boolean): void
    {
        localStorage.setItem("userPolicyAccepted", JSON.stringify(value));
    }

    public getUserPolicy(): boolean
    {
        return JSON.parse(localStorage.getItem("userPolicyAccepted") || "false")
    }

    public hasRight(name: string): boolean
    {
        const parts = name.split(".");
        if (parts.length != 3)
        {
            throw new Error("Right must be in the format {module}.{entity}.{action}");
        }
        try
        {
            const module = parts[0] as keyof IUserRights;
            const entity = parts[1] as keyof (IAdminPortalRights | IApiRights | IDataModelRights | IFlexRights | IIBSSRights | IOneLens360Rights | IRoamerRights);
            const action = parts[2];

            const ibssRights = this.getIbssRightList();
            const userDetail = this.getUserDetails();
            const allRights = [ userDetail.userRights, ibssRights];

            for (let rights of allRights)
            {
                const hasRight = ((rights[module][entity] as string[]).find((i: string) => i === action) !== undefined);
                if (hasRight)
                {
                    return true;
                }
            }
            return false;
        }
        catch
        {
            return false;
        }
    }

    public permissionAppliesToBuilding(right: string, buildingId: number): boolean
    {
        // check APPLIESTO part of IbssRightList contains the permission AND the permission is applied to the building (buildingId can be found in the list of buildingIds stored for each permission key)
        const userAppliesToRights = this.getIbssRightList().APPLIESTO;

        if(userAppliesToRights[right] && userAppliesToRights[right].length > 0)
        {
            if(userAppliesToRights[right].includes(buildingId.toString()))
            {
                return true;
            }
        }

        return false;
    }

}
export interface ILocalStorageProvider
{
    setUserPreferences(value: IUserPreferences): void;
    getUserPreferences(): IUserPreferences;
    setUserDetails(value: IUserDetails): void;
    getUserDetails(): IUserDetails;
    setToken(value: string): void;
    getToken(): string;
    setVersion(value: number): void;
    getVersion(): number;
    setNodeData(value: IRootNode): void;
    getNodeData(): IRootNode;
    setIbssRights(value: IUserRights): void;
    getIbssRightList(): IUserRights;
    clear():void;
    setUserPolicy(value: boolean): void;
    getUserPolicy(): boolean;
    hasRight(name: string): boolean;
    permissionAppliesToBuilding(right: string, buildingId: number): boolean;
}
