import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDataGrid, { IIbssGridColDef } from '../../../../Components/Data/DataGrid/IbssDataGrid';
import { appContext } from '../../../../AppContext';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { ICustomOrderDetails } from './ListCateringOrders';
import CloseIcon from '@mui/icons-material/Close';
import IbssIconButton from '../../../../Components/Buttons/IconButton/IbssIconButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { INode } from '../../../../Providers.Api/Models';
import { DateTime } from 'luxon';
import { DateHelper } from '../../../../Common/DateHelper';
import IbssDrawer from '../../../../Components/Drawer/IbssDrawer';

class CateringOrderDrawer extends IbssComponent<IProps, IState> 
{
  private get labels() { return appContext().labels; }
  private get local() { return appContext().localStorageProvider; }
  private get appState() { return appContext().state; }
  
  public getSelectedBuildingData(): INode | null
  {
    const availableSpaces = this.local.getNodeData();
    for (const region of availableSpaces.Regions) {
      const building = region.Buildings.find(b => b.Node_Id === this.appState.buildingId);
      if (building) 
      {
        return building;
      }
    }
    return null;
  }

  public calculateCutOffTime(selectedBuilding: INode): DateTime | null
  {
    const [cutOffDays, timeOfDay] = selectedBuilding.Pre_Book_Cat_Time.split('.');
    const cutOffTimeOfDay = timeOfDay ? timeOfDay.split(':') : null;

    if (!cutOffTimeOfDay) return null;

    return DateHelper.fromIsoByNode(this.props.orderDetails?.bookingStart,this.appState.buildingId)
      .minus({ days: parseInt(cutOffDays, 10) })
      .set({ hour: parseInt(cutOffTimeOfDay[0], 10), minute: parseInt(cutOffTimeOfDay[1], 10) });
  }

  public calculatePastCutOffPeriod(cutOffTime: DateTime | null): boolean
  {
    if (!cutOffTime) return false;

    const cutOffDuration = cutOffTime.diff(DateTime.now(), ['days', 'hours', 'minutes']).toObject();
    return (
      (cutOffDuration.days ?? 1) < 1 &&
      (cutOffDuration.hours ?? 1) < 1 &&
      (cutOffDuration.minutes ?? 1) < 1
    );
  }

  public renderStatusButtons(status: string): React.ReactNode 
  {
    const selectedBuilding = this.getSelectedBuildingData();
    if (!selectedBuilding) 
    {
      return null;
    }

    const cutOffTime = this.calculateCutOffTime(selectedBuilding);
    const pastCutOffPeriod = this.calculatePastCutOffPeriod(cutOffTime);

    switch (status) {
      case "PendingApproval":
        return (
          <>
          <Box display={'flex'} justifyContent={'center'} mt={3}>
            <IbssButton variant="contained" size='large' onClick={() => this.props.statusChangedClicked("RequestApproved")}>{this.labels.HubButtonApprove}</IbssButton>
            <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("NotApproved")}>{this.labels.HubButtonDeny}</IbssButton>
          </Box>
          </>
        );
      case "RequestApproved":
        return (
        <>
          <Box display={'flex'} justifyContent={'center'} mt={3}>
            <IbssButton variant="contained" color="error" size='large' onClick={() => this.props.statusChangedClicked("CancelledCharged")}>{this.labels.funcCateringOrderCancelOrderCharge_S}</IbssButton>
            <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledNoCharge")}>{this.labels.funcCateringOrderCancelOrderNoCharge_S}</IbssButton>
          </Box>
          {!pastCutOffPeriod && 
            <Box mt={1} display={'flex'} justifyContent={'center'}>
              <IbssButton variant="contained" onClick={() => this.props.statusChangedClicked("ReadyForPrep")}>{this.labels.funcCateringOrderReadyForPrep_S}</IbssButton>
            </Box>}
        </>
        ); 
        case "ReadyForPrep":
          return (
          <>
            <Box display={'flex'} justifyContent={'center'} mt={3}>
              <IbssButton variant="contained" color="primary" size='large' onClick={() => this.props.statusChangedClicked("PrepareForService")}>{this.labels.funcCateringOrderPrepareForService_S}</IbssButton>
              <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledCharged")}>{this.labels.funcCateringOrderCancelOrderCharge_S}</IbssButton>
              <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledNoCharge")}>{this.labels.funcCateringOrderCancelOrderNoCharge_S}</IbssButton>
            </Box>
          </>
          ); 
        case "ReadyForDespatchOutOfTime":
          return (
            <>
              <Box display={'flex'} justifyContent={'center'} mt={3}>
                <IbssButton variant="contained" color="primary" size='large' onClick={() => this.props.statusChangedClicked("Delivered")}>{this.labels.funcCateringOrderDelivered_S}</IbssButton>
                <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledCharged")}>{this.labels.funcCateringOrderCancelOrderCharge_S}</IbssButton>
                <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledNoCharge")}>{this.labels.funcCateringOrderCancelOrderNoCharge_S}</IbssButton>
              </Box>
            </>
            ); 
        case "ReadyForDespatchInTime":
          return (
            <>
              <Box display={'flex'} justifyContent={'center'} mt={3}>
                <IbssButton variant="contained" color="primary" size='large' onClick={() => this.props.statusChangedClicked("Delivered")}>{this.labels.funcCateringOrderDelivered_S}</IbssButton>
                <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledCharged")}>{this.labels.funcCateringOrderCancelOrderCharge_S}</IbssButton>
                <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledNoCharge")}>{this.labels.funcCateringOrderCancelOrderNoCharge_S}</IbssButton>
              </Box>
            </>
            ); 
        case "PrepareForService":
          return (
            <>
              <Box display={'flex'} justifyContent={'center'} mt={3}>
                <IbssButton variant="contained" color="primary" size='large' onClick={() => this.props.statusChangedClicked("ReadyForDespatchInTime")}>{this.labels.funcCateringOrderReadyInTime_S}</IbssButton>
                <IbssButton variant="contained" color="primary" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("ReadyForDespatchOutOfTime")}>{this.labels.funcCateringOrderReadyLate_S}</IbssButton>
                <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledCharged")}>{this.labels.funcCateringOrderCancelOrderCharge_S}</IbssButton>
                <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledNoCharge")}>{this.labels.funcCateringOrderCancelOrderNoCharge_S}</IbssButton>
              </Box>
            </>
            ); 
        case "InPreparation":
        return (
        <>
          <Box display={'flex'} justifyContent={'center'} mt={3}>
            <IbssButton variant="contained" color="error" size='large' onClick={() => this.props.statusChangedClicked("CancelledCharged")}>{this.labels.funcCateringOrderCancelOrderCharge_S}</IbssButton>
            <IbssButton variant="contained" color="error" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("CancelledNoCharge")}>{this.labels.funcCateringOrderCancelOrderNoCharge_S}</IbssButton>
          </Box>
          {!pastCutOffPeriod && 
            <Box mt={1} display={'flex'} justifyContent={'center'}>
              <IbssButton variant="contained" onClick={() => this.props.statusChangedClicked("Delivered")}>{this.labels.funcReadyforDelivery_S}</IbssButton>
            </Box>}
        </>
        ); 
      case "Delivered":
        return (
        <Box display={'flex'} justifyContent={'center'} mt={3}>
          <IbssButton variant="contained" size='large' onClick={() => this.props.statusChangedClicked("DeliveryIssue")}>{this.labels.funcCateringOrderDeliveryIssue_S}</IbssButton>
          <IbssButton variant="contained" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("ClearUpScheduled")}>{this.labels.funcCateringOrderClearedUpInTime_S}</IbssButton>
          <IbssButton variant="contained" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("ClearUpExcess")}>{this.labels.funcCateringOrderClearedUpLate_S}</IbssButton>
        </Box>
        );
      case "ClearUpScheduled":
        return (
        <Box display={'flex'} justifyContent={'center'} mt={3}>
          <IbssButton variant="contained" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("Cleared")}>{this.labels.funcCateringOrderCleared_S}</IbssButton>
        </Box>
        );
      case "ClearUpExcess":
        return (
        <Box display={'flex'} justifyContent={'center'} mt={3}>
          <IbssButton variant="contained" className='ml-2' size='large' onClick={() => this.props.statusChangedClicked("Cleared")}>{this.labels.funcCateringOrderCleared_S}</IbssButton>
        </Box>
        );
      default:
        return null;
    }
  }

  private getClassificationLabel(classification: string): string
  {
      if (classification == 'MenuItemClassificationFood')
      {
          return this.labels.HubLabelFood
      }
      if (classification == 'MenuItemClassificationSnack')
      {
          return this.labels.HubLabelSnack
      }
      if (classification == 'MenuItemClassificationBeverage')
      {
          return this.labels.HubLabelBeverage
      }
      return '';
  }


  render(): JSX.Element 
  {
    const columns: IIbssGridColDef[] = 
    [
      {
          headerName: this.labels.HubLabelName,
          field: "name",
          flex: 1
      },
      {
          headerName: this.labels.HubLabelClassification,
          field: "classification",
          flex: 1,
          renderCell: (params) => this.getClassificationLabel(params.row.classification)
      },
      {
          headerName: this.labels.HubLabelQty,
          field: "quantity",
          flex: 1
      },
  ]

    const { isDrawerOpen, activeStep, stepperInfo,orderStatus ,onClose, orderDetails,editCateringOrder } = this.props;
    

    return (
          <IbssDrawer
            anchor="right"
            open={isDrawerOpen}
            onClose={onClose}
            sx={{width:'250px'}}
          >
            <Box px={3} py={4}>
              <Box mb={3} display={'flex'} justifyContent={'space-between'}>
                <Box>
                    <Typography variant='h5' className='ibss-modal-title'>{this.labels.funcCateringOrder_S}</Typography> 
                </Box>
                <Box>
                  <IbssIconButton aria-label="close" onClick={onClose}>
                      <CloseIcon />
                    </IbssIconButton>
                </Box>
              </Box>
                <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                  <Box width={'70%'}>
                    <Typography variant='h5' className='ibss-modal-title'>{this.labels.funcOrderNumber_S}</Typography>
                    <Typography variant='body1'>{orderDetails.orderId}</Typography>
                  </Box>
                  {orderStatus}
                </Box>
                <Grid container spacing={2} mb={1.5} mt={0.5}>
                    <Grid xs={6}>
                        <Box pl={2} display={'flex'} alignItems={'center'}>
                            <Box>
                              <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='small'>
                                  {Icons.UserIcon}
                              </IbssSvgIcon>
                            </Box>
                            <Box ml={3} mt={1}>
                            <Typography variant='body1' fontWeight={600}>{this.labels.funcDeliveryTo_S}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <Typography variant='body1' mt={1}>{orderDetails.OwnedBy}</Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} mb={1.5} mt={0.5}>
                    <Grid xs={6}>
                        <Box pl={2} display={'flex'} alignItems={'center'}>
                          <Box className='mt-12'>
                            <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                                {Icons.MapPinIcon}
                            </IbssSvgIcon>
                          </Box>
                          <Box ml={3} mt={1}>
                            <Typography variant='body1' fontWeight={600}>{this.labels.funcDeliveryLocation_S}</Typography>
                          </Box>
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <Typography variant='body1' mt={1}>{orderDetails.spaceName}</Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} mb={1.5} mt={0.5}>
                    <Grid xs={6}>
                        <Box pl={2} display={'flex'} alignItems={'center'}>
                        <Box className='mt-12'>
                            <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                                {Icons.TimeIcon}
                            </IbssSvgIcon>
                        </Box>
                          <Box ml={3} mt={1}>
                            <Typography variant='body1' fontWeight={600}>{this.labels.funcDropoffTime_S}</Typography>
                          </Box>
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <Typography variant='body1' mt={1}>{orderDetails.timeForDelivery}</Typography>
                    </Grid>
                </Grid>
                <Box my={3} sx={{ width: "100%" }}>
                    <Stepper 
                        alternativeLabel
                        activeStep={activeStep}>
                        {stepperInfo.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                </Box>
                {this.local.hasRight("DATAMODEL.CateringOrders.Update") && this.renderStatusButtons(orderDetails.cateringStatus)}
                <Box display={'flex'} justifyContent={'space-between'} my={2}>
                  <Typography variant='h5' className='ibss-modal-title'>{this.labels.funcOrderedItems_S}</Typography>
                  {this.local.hasRight("DATAMODEL.CateringOrders.Update") &&
                    <IbssButton onClick={() => editCateringOrder()} variant='contained'>{this.labels.funcEditOrder_S}</IbssButton>
                  }
                </Box>
                <IbssDataGrid className="data-grid-clickable-row" height="calc(100vh - 450px)" columns={columns} rows={orderDetails.menuItems} />
            </Box>
          </IbssDrawer>
    );
  }
}

export default CateringOrderDrawer;

interface IState {}

interface IProps {
    isDrawerOpen: boolean;
    onClose: () => void;
    activeStep: number;
    stepperInfo: string[];
    orderStatus: React.ReactNode;
    statusChangedClicked: (type: string) => Promise<void>; 
    editCateringOrder: () => void; 
    orderDetails: ICustomOrderDetails;
    bookingStart?: string;
  }
