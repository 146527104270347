import {
        TimelineViews,
        ScheduleComponent,
        ViewsDirective,
        ViewDirective,
        ResourcesDirective,
        ResourceDirective,
        Inject,
        EventRenderedArgs,
        PopupOpenEventArgs,
    } from "@syncfusion/ej2-react-schedule";
import "./ScheduleView.scss";
import { appContext } from "../../../../AppContext";
import "./ScheduleStyles.scss";
import { DateTime } from "luxon";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { EntityType, IFilter, IScheduleBookings, IScheduleCateringOrders } from "../List/ListPantryTimeline";
import { CateringOrderView, ICustomOrderDetails, IMenu_Items } from "../../CateringOrders/List/ListCateringOrders";
import CateringOrderDrawer from "../../CateringOrders/List/CateringOrderDrawer";
import { GetCateringStatus } from "../../CateringOrders/List/CateringStatus";

export default class ScheduleCateringOrder extends IbssComponent<IProps, IState> 
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isDrawerOpen: false,
            orderDetails:
            {
                nodeId: 0,
                orderId: "",
                OwnedBy: "",
                spaceName: "",
                cateringStatus: "",
                timeForDelivery: "",
                bookingStart: "",
                menuItems: [],
                bookingId: ""
            },
            stepperInfo:
                [
                    this.labels.funcInApproval_S,
                    this.labels.funcInPrep_S,
                    this.labels.funcReadyforDelivery_S,
                    this.labels.HubLabelDelivered,
                    this.labels.HubLabelCleanUp,
                    this.labels.HubLabelCancelled
                ],
            activeStep: -1,
        };
    }


    public processBookingColor(type: EntityType, status: string): string
    {
        if(type === EntityType.Booking)
        {
            return 'var(--ui-success)';
        }
        switch (status)
        {
            case 'RequestApproved':
            case 'InPreparation':
            case 'ReadyForDespatchInTime':
            case 'ReadyForDespatchOutOfTime':
            case 'Delivered':
            case 'Cleared':
            case 'ReadyForPrep':
                return 'green';
            case 'DeliveryIssue':
            case 'ClearUpExcess':
            case 'ClearUpScheduled':
            default:
                return 'black';
        }
    }

    private getEventBackgroundColor(args: EventRenderedArgs): string
    {
        const currentTime = DateTime.now().setZoneByNode(args.data.NodeId).toJSDate();
        const startTime = args.data.StartTime;
        const endTime = args.data.EndTime;
        
        if(args.data.Type === EntityType.Booking)
        {
            return 'var(--ui-success-pastel)';
        }
        else if (currentTime >= startTime && currentTime <= endTime)
        {
            return '#80ff99'; // preparation
        } else if (startTime < currentTime && args.data.Status === 'InPreparation')
        {
            return '#cccccc'; // waiting
        } else if (args.data.Status === 'ReadyForDespatchInTime' || args.data.Status === 'ReadyForDespatchOutOfTime' || args.data.Status === 'PrepareForService')
        {
            return '#c8ff80'; // yellow fill
        } else if (args.data.Status === 'Delivered' || args.data.Status === 'Cleared')
        {
            return '#80ff99';
        } else if (args.data.Status === 'DeliveryIssue')
        {
            return '#e64545';
        } else if (args.data.Status === 'ClearUpExcess')
        {
            return '#cccccc';
        }
        else
        {
            return 'white';
        }
    }

    private getActiveStep(status: string): void
    {
        const statusMap: Record<string, number> =
        {
            "RequestApproved": 0,
            "Approved": 0,
            "InPreparation": 1,
            "ReadyForPrep": 1,
            "PrepareForService": 1,
            "ReadyForDespatchInTime": 2,
            "ReadyForDespatchOutOfTime": 2,
            "Delivered": 3,
            "ClearUpScheduled": 4,
            "ClearUpExcess": 4,
            "Cleared": 4,
            "CancelledNoCharge": 5,
            "CancelledCharged": 5
        };

        const activeStep = statusMap[status] !== undefined ? statusMap[status] : -1;

        this.setState({
            activeStep: activeStep
        });
    }

    private onEventRendered(args: EventRenderedArgs): void 
    {
        (args.element as HTMLElement).style.color = '#263238';
        (args.element as HTMLElement).style.backgroundColor = this.getEventBackgroundColor(args);
        (args.element as HTMLElement).style.borderColor = this.processBookingColor(args.data.Type, args.data.Status);
        (args.element as HTMLElement).style.borderRadius = '5px';
        (args.element as HTMLElement).style.borderLeftWidth = 'thick';
    }

    private async cateringMenuItems(Id: number): Promise<void>
    {
        const cateringOrders = this.props.scheduleOrders.filter((item) => item.id === Id)[0];
        const menuItems: IMenu_Items[] = [];
        const menuId = cateringOrders.menuItem.map((item) => item.Menu_Id)[0];
        const cateringMenu = await this.apiClient.cateringMenus.getById(cateringOrders.nodeId, menuId, true, true);
        const cateringMenuItems = cateringMenu.Menu_MenuItems.map((item) => item.MenuItem);

        cateringMenuItems.forEach(catering =>
        {
            const orderItem = cateringOrders.menuItem.find((order) => order.Id === catering.MenuItem_Id);
            if (orderItem)
            {
                menuItems.push({
                    id: catering.MenuItem_Id,
                    name: catering.Name,
                    classification: catering.Classification,
                    quantity: orderItem.QuantityOfItems
                });
            }
        });
        this.getActiveStep(cateringOrders.status);
        this.setState({
            isDrawerOpen: true,
            orderDetails:
            {
                nodeId: cateringOrders.nodeId,
                orderId: cateringOrders.orderId,
                OwnedBy: cateringOrders.CreatedBy,
                spaceName: cateringOrders.spaceName,
                timeForDelivery: DateTime.fromISO(cateringOrders.cateringServiceTime).toLocaleDateTimeString(),
                cateringStatus: cateringOrders.status,
                menuItems: menuItems,
                bookingStart: cateringOrders.bookingStart.toString(),
                bookingId: cateringOrders.bookingId
            }
        })
    }

    private async statusChangedClicked(type: string): Promise<void>   
    {
        const currentStatus =
        {
            Catering_Status: type
        }
        try 
        {
            this.setState({
                isDrawerOpen: false,
            })
            const payload: IFilter =
            {
                buildingId: this.props.buildingId,
                startDate: DateTime.fromJSDate(this.props.scheduleDate).setZoneByNode(this.props.buildingId).startOf('day').toString(),
                endDate: DateTime.fromJSDate(this.props.scheduleDate).setZoneByNode(this.props.buildingId).plus({ days: 1 }).startOf('day').toString(),
            };
            await this.apiClient.cateringOrders.update(this.state.orderDetails.nodeId, this.state.orderDetails.orderId, currentStatus);
            this.props.loadScheduleTasks(payload);
        } catch (error) 
        {
        }
    }

    private editCateringOrder(): void
    {
        this.props.redirectPath(this.state.orderDetails.bookingId, this.state.orderDetails.orderId);
    }

    private onPopupOpen(args: PopupOpenEventArgs): void
    {
        if (args.data)
        {
            if(args.data.Status)
            {
                this.cateringMenuItems(args.data.Id);
            }
        }
        args.cancel = true;
    }

    render(): JSX.Element 
    {
        const cateringStatus = new GetCateringStatus({});
        const combinedEvents = [...this.props.bookingDetails, ...this.props.cateringOrders];

        const fieldsData =
        {
            id: 'Id',
            subject: { title: 'Summary', name: 'Subject' },
            location: { title: 'Location', name: 'Location' },
            description: { title: 'Comments', name: 'Description' },
            startTime: { title: 'From', name: 'StartTime' },
            endTime: { title: 'To', name: 'EndTime' },
            type: { title: 'Type', name: 'Type'},
            nodeId: { title: 'NodeId', name: 'NodeId'},
        }
        const eventSettings = { dataSource: this.props.listViewType === "allSchedule" ? combinedEvents : this.props.cateringOrders , fields: fieldsData };
        const group = { enableCompactView: false, resources: ['MeetingRoom'] };

        return (
            <>
                <CateringOrderDrawer
                    isDrawerOpen={this.state.isDrawerOpen}
                    onClose={() => this.setState({ isDrawerOpen: false })}
                    orderDetails={this.state.orderDetails}
                    activeStep={this.state.activeStep}
                    stepperInfo={this.state.stepperInfo}
                    statusChangedClicked={(type) => this.statusChangedClicked(type)}
                    editCateringOrder={() => this.editCateringOrder()}
                    orderStatus={cateringStatus.renderTaskOverAllStatus(this.state.orderDetails.cateringStatus)}
                />
                <ScheduleComponent
                    width='100%'
                    className='schedule-calender'
                    selectedDate={this.props.scheduleDate}
                    startHour={`${this.props.buildingStartHrs.split(':')[0]}:00`} //ensure the start hour is a whole hour rather than 07:15.
                    endHour={this.props.buildingEndHrs}
                    workHours={{ highlight: true, start: this.props.buildingStartHrs, end: this.props.buildingEndHrs }}
                    showHeaderBar={false}
                    rowAutoHeight={true}
                    eventSettings={eventSettings}
                    group={group}
                    timeScale={{enable: true, interval: 60, slotCount: 2}}
                    popupOpen={this.onPopupOpen.bind(this)}
                    eventRendered={this.onEventRendered.bind(this)}
                >
                    <ResourcesDirective>
                        <ResourceDirective
                            field='SpaceId'
                            title='Room Type'
                            name='MeetingRoom'
                            allowMultiple={true}
                            dataSource={this.props.spaceData}
                            textField='text'
                            idField='id'
                            colorField='color'
                        >
                        </ResourceDirective>
                    </ResourcesDirective>
                    <ViewsDirective>
                        <ViewDirective option='TimelineDay' />
                    </ViewsDirective>
                    <Inject services={[TimelineViews]} />
                </ScheduleComponent>
            </>
        );
    }
}

interface IProps
{
    buildingId: number;
    spaceData: {
        text: string,
        id: string,
    }[];
    cateringOrders: IScheduleCateringOrders[];
    scheduleOrders: CateringOrderView[];
    scheduleDate: Date;
    loadScheduleTasks: (data: IFilter) => Promise<void>;
    redirectPath: (bookingId: string, orderId: string) => void;
    bookingDetails: IScheduleBookings[];
    listViewType: string;
    buildingStartHrs: string;
    buildingEndHrs: string;
}

interface IState
{
    stepperInfo: string[];
    activeStep: number;
    orderDetails: ICustomOrderDetails;
    isDrawerOpen: boolean;
}
