import { DateTime } from "luxon";

export class BaseQueryParams
{
    [key: string]: QueryParamValue;
    //public buildingId = 1;
}

export type QueryParamValue = string | number | boolean | DateTime;
export type BuildingChangeReason = "BuildingSelectorChanged" | "UrlChanged";
