import { UnregisterCallback } from "history";
import { IbssComponent } from "../BaseComponent/IbssComponent";
import { match, matchPath, RouteComponentProps } from "react-router";
import { appContext } from "../../../AppContext";
import { BaseQueryParams, BuildingChangeReason, QueryParamValue } from "./Types";
import { RouteHelper } from "../../../Common/RouteHelper";
import { DateTime } from "luxon";

export class IbssPage<TProps, TState, TQueryParams extends BaseQueryParams = {}> extends IbssComponent<TProps, TState>
{
    private queryParamsType?: new () => TQueryParams;
    private get _appState() { return appContext().state; }
    private unregisterHistoryChanged: (UnregisterCallback | null) = null;
    private unregisterHistoryChanged2: (UnregisterCallback | null) = null;
    private route = new RouteHelper();

    constructor(props: TProps, queryParamsType?: new () => TQueryParams)
    {
        super(props);
        this.queryParamsType = queryParamsType;
    }

    public get pageTitle(): string
    {
        return this._appState.pageTitle;
    }

    public set pageTitle(value: string)
    {
        this._appState.set({ pageTitle: value });
    }

    public get area(): '' | 'admin' | 'onelens' | 'flex'
    {
        const path = window.location.pathname;
        if (path.startsWith('/admin') || path.startsWith('/security-') || path.startsWith('/portfolio-setup-') || path.startsWith('/filemgmt/'))
        {
            return 'admin';
        }
        else if (path.startsWith('/onelens') || path.startsWith('/operational-') || path.startsWith('/sustainability-analytics-') || path.startsWith('/space-analytics-'))
        {
            return 'onelens';
        }
        else if (path.startsWith('/flex/') || path.startsWith('/flex-'))
        {
            return 'flex';
        }
        else
        {
            return '';
        }
    }

    public componentDidMount(): void
    {
        const props = this.props as unknown as RouteComponentProps;
        this.unregisterHistoryChanged2 = props.history.listen(async () =>
        {
            this.handleQueryParamChange(this.queryParams);
        });
    }

    public componentWillUnmount(): void
    {
        this.pageTitle = "";
        this.unregisterHistoryChanged?.();
        this.unregisterHistoryChanged2?.();
    }

    public onBuildingIdChanged<TMatchParams>(getBuildingIdMatchParam: (matchParams: TMatchParams) => string, buildingIdChanged: (buildingId: number, changeReason: BuildingChangeReason) => Promise<void>): void
    {
        const props = this.props as unknown as RouteComponentProps;

        const getBuildingIdMatch = (): (number | null) =>
        {
            const match = matchPath(window.location.pathname, { path: props.match.path });
            if (match == null)
            {
                return null;
            }
            const buildingId = parseInt(getBuildingIdMatchParam(match.params as TMatchParams));
            return (isNaN(buildingId) ? null : buildingId);
        }

        // handle building selector change
        const ref = this._appState.subscribe(this, async i =>
        {
            const buildingIdMatch = getBuildingIdMatch();
            if (i.buildingId == null || i.buildingId == buildingIdMatch)
            {
                return;
            }
            await buildingIdChanged(i.buildingId, "BuildingSelectorChanged");
        }, false);

        // update building drop-down when URL changed, then invoke event handler
        if (props.history != null)
        {
            this.unregisterHistoryChanged = props.history.listen(async () =>
            {
                const buildingIdMatch = getBuildingIdMatch();
                if (buildingIdMatch == null || this._appState.buildingId == buildingIdMatch)
                {
                    return;
                }

                await this._appState.set({ buildingId: buildingIdMatch });
                await buildingIdChanged(buildingIdMatch, "UrlChanged");
            });
        }
    }

    public get queryParams(): TQueryParams
    {
        if (!this.queryParamsType)
        {
            throw new Error('The query parameters for this page are not defined.');
        }
        const params = new this.queryParamsType();
        let key: string;

        for (key in params)
        {
            const lowerKey = key.toLocaleLowerCase();
            const defaultValue = params[key];
            let value: QueryParamValue | null = null;

            switch (typeof (defaultValue))
            {
                case 'string':
                    value = this.route.getQueryParamAsString(lowerKey);
                    break;
                case 'number':
                    value = this.route.getQueryParamAsNumber(lowerKey);
                    break;
                case 'boolean':
                    value = this.route.getQueryParamAsBoolean(lowerKey);
                    break;
                default:
                    if (DateTime.isDateTime(defaultValue))
                    {
                        value = this.route.getQueryParamAsDateTime(lowerKey);
                    }
            }
            if (value != null)
            {
                params[key as keyof TQueryParams] = value as TQueryParams[keyof TQueryParams];
            }
        }

        return params;
    }

    public pushQueryParams(value: TQueryParams): void
    {
        const props = this.props as unknown as RouteComponentProps;
        const url = window.location.href;
        const urlObject = new URL(url);
        const queryParams = urlObject.searchParams;
        const newQueryParams = value;

        for (const queryParamName in newQueryParams)
        {
            const queryParamValue = newQueryParams[queryParamName];
            let queryParamValueAsString: string;

            switch (typeof (queryParamValue))
            {
                case 'string':
                    queryParamValueAsString = queryParamValue;
                    break;
                case 'number':
                    queryParamValueAsString = queryParamValue.toString();
                    break;
                case 'boolean':
                    queryParamValueAsString = queryParamValue ? '1' : '0';
                    break;
                default:
                    if (DateTime.isDateTime(queryParamValue))
                    {
                        queryParamValueAsString = queryParamValue.toUrl() ?? '';
                    }
                    else
                    {
                        throw new Error('Not supported');
                    }
            }

            if (queryParamValueAsString)
            {
                queryParams.set(queryParamName, queryParamValueAsString);
            }
            else
            {
                queryParams.delete(queryParamName);
            }
        }
        const newUrl = `${urlObject.pathname}?${queryParams.toString()}`;
        props.history.push(newUrl);
    }

    public async handleQueryParamChange(queryParams: TQueryParams): Promise<void>
    {
    }
}
