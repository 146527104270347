/* eslint-disable no-loop-func */
import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import AddIcon from '@mui/icons-material/Add';
import
Helper, {
    getBuildingTimeZoneByNodeId,
    getBuildingNameUsingFloorNodeId,
    getBuildingNodeIdUsingFloorNodeId,
    truncateString,
    dateConverter
} from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import moment from "moment";
import { appContext } from "../../../../AppContext";
import { RouterProps, RouteComponentProps, generatePath } from "react-router";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import { SelectChangeEvent } from "@mui/material/Select";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormControl, Grid, Typography } from "@mui/material";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssPageHeader, { StartOrEnd } from "../../../../Components/Forms/DateRange/IbssPageHeader";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import { BookingFilter, BookingFilter2 } from "../../../../Providers.Api/Bookings/BookingRepository";
import RefreshIcon from '@mui/icons-material/Refresh';
import customTheme from "../../../../customTheme";
import { ODataQuery } from "../../../../Providers.Api/ODataQuery";
import * as GetV2BookingsEndpoint from "../../../../Providers.Api/Bookings/GetV2BookingsEndpoint";
import React from "react";
import * as GetMyV2BookingsForOthersEndpoint from "../../../../Providers.Api/Bookings/GetMyV2BookingsForOthersEndpoint";
import { KeyValue } from "../../../../Common/KeyValue";
import { PagedResponse } from "../../../../Providers.Api/Models";
import BookingStatusesChips from "./BookingStatusesChips";
import UserPicker, * as UserPickerModule from "../../../../Components/Inputs/UserPicker/UserPicker";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { BuildingChangeReason } from "../../../../Components/Core/BasePage/Types";
import { InputOption } from "../../../../Components/Inputs/SelectList2/InputSelectBox";
import { Booking, MyBooking, MyPrimaries, SpaceZones, SystemFilter, UsersRoles } from "./DataModels";
import ServerDataGrid, { DataGridQueryResult, IDataQuery, Column } from "../../../../Components/Data/ServerDataGrid/ServerDataGrid";
import { toggleButtonListObject } from "../../../../Components/Inputs/ToggleButtons/IbssToggleButtons";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { ISpaceZone } from "../../../../Providers.Api/SpaceZones/GetSpaceZones";
import CancelBookingsDialog from "../../../../Components/Dialogs/ChangeBookingDetailsDialog/CancelBookingsDialog";


class ListBookings extends IbssPage<IProps, IState>
{
    private dataGridRef = React.createRef<ServerDataGrid<BookingView>>();
    private get dataGrid() { return this.dataGridRef.current; }
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() { return appContext().apiCache; }
    private buildingTimeZone: string = getBuildingTimeZoneByNodeId(this.appState.buildingId);
    private hasV2Rights = this.local.hasRight("API.Bookings.V2");
    private hasApproveRights = this.local.hasRight("API.Bookings.Approve");
    private hasRejectRights = this.local.hasRight("API.Bookings.Deny");
    private hasFilterReadRights = this.local.hasRight("DATAMODEL.Filters.Read")

    private enableRefreshButtonAfterTimeOut(): void
    {
        setTimeout(() =>
        {
            this.setState({ isRefreshButtonDisabled: false });
        }, 60000);
    }

    private async handleRefreshButtonClick(): Promise<void>
    {
        this.setState({ isRefreshButtonDisabled: true });
        await this.dataGrid?.refresh();
        this.enableRefreshButtonAfterTimeOut();
    }

    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            getRightApiResponseErrorLoading: false,
            isFilter: false,
            disableExport: false,
            isRefreshButtonDisabled: false,
            showCancelModal: false,
            showRejectModal: false,
            isLoading: false,
            isLoadingToGetFilteredData: false,
            activePage: "0",
            show: false,
            buildingid: this.appState.buildingId,
            canExport: false,
            isCreateRight: false,
            isUpdateRight: false,
            isApproveRight: false,
            isDenyRight: false,
            selectedBookingToday: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingNext: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingYesterdayAuto: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingNext7Day: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedCustomFilterBooking: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            isCancel: false,
            isCheckIn: false,
            isCheckOut: false,
            isV2Rights: false,
            isCheckInOnBehalfOf: false,
            bookingStatuses: [],
            relationshipStatuses: [],
            userList: [{ label: "", email: "" }],
            bookedForSearchText: "",
            selectedUserBookedForObject: { label: "", email: "" },
            bookedBySearchText: "",
            selectedUserBookedByObject: { label: "", email: "" },
            spaceOptions: [],
            selectedSpaceOption: null,
            filterMode: false,
            confirmModalshow: false,
            isCompleteLoading: false,
            isToday: false,
            daysFilter: "",
            bookingStartDate: DateHelper.now().toJSDate(),
            bookingEndDate: DateHelper.now().toJSDate(),
            dateDisabled: true,
            rowSelectionModel: [],
            cancelButtonDisabled: true,
            approveButtonEnabled: true,
            rejectButtonEnabled: true,
            checkInButtonEnabled: true,
            checkOutButtonEnabled: true,
            tableMessage: "",
            updates: false,
            cancelAllRowsEnable: true,
            modalStatus: "",
            isMyBookings: false,
            isMyBookingsForOthers: false,
            searchTerm: "",
            isOperationalServicesBookings: false,
            page: {
                value: [],
                skipToken: "",
            },
            searchTermChanged: false,
            filterOptions: [],
            selectedFilterId: '',
            filterQuery: '',
            showViewBookingSeriesModal: false,
            selectedBookingSeries: { seriesId: '', nodeId: 0, bookingId: '', spaceId: '' },
            relationStatusList: [
                { label: this.labels.dataBookingMain_S, value: '0' },
                { label: this.labels.dataBookingCreateSetup_S, value: '1' },
                { label: this.labels.dataBookingCreateReset_S, value: '2' }
            ],
            currentUsersRoleIds: [],
            selectedBookingZone:'',
            zoneOptions: [],
            isBookingsPrimaries: false,
            primariesList: [],
            selectedPrimary: '-1'
        };
    }

    public async componentDidMount(): Promise<void>
    {
        const { history } = this.props;
        const query = new URLSearchParams(this.props.location.search);

        this.onBuildingIdChanged<IMatchParams>(params => params.buildingid, (buildingId, reason) => this.buildingIdChanged(buildingId, reason, this.props.location.search));
        this.setState({ isLoading: true });
        const url = window.location.href;
        const path = new URL(url).pathname;
        await this.setStateAsync({ isMyBookings: path === "/flex-my-bookings", isMyBookingsForOthers: path === "/flex-my-bookings-for-others", isBookingsPrimaries:  path.includes("/flex/bookings/primaries"), isOperationalServicesBookings: path.includes("/operational-services-bookings/")});
        if (this.state.isMyBookings || this.state.isMyBookingsForOthers)
        {
            this.setState({
                daysFilter: this.labels.HubLabelThisWeek,
                bookingStartDate: DateHelper.now().startOf('week').toJSDate(),
                bookingEndDate: DateHelper.now().endOf('week').toJSDate(),
            });
        }
        else
        {
            if(this.state.isBookingsPrimaries)
            {
                    const primariesList = await appContext().userApiClientV1.api.user.v1.delegates.myPrimaries.get<PagedResponse<MyPrimaries[]>>({
                        select: MyPrimaries
                    })
                    const primariesOption = await [
                        new InputOption(this.labels.HubLabelAny, "-1"),
                        ...primariesList.value.map(i => new InputOption(i.PrimaryDisplayName ?? '', i.PrimaryEmail ?? ''))
                    ];
                    this.setState({primariesList: primariesOption});
            }
            const bookingStatus = query.get('Status'); // FacilitySpaces use query string to set state bookingStatus, which are used to filter the bookings loaded. Only singular BookingStatus are used in url query on FacilitySpaces.
            this.setState({
                bookingStartDate: DateHelper.now().toJSDate(),
                bookingEndDate: DateHelper.now().toJSDate(),
                daysFilter: this.labels.HubLabelToday,
                bookingStatuses: bookingStatus !== null ? [bookingStatus] : [],
            });
        }
        this.setState({ isToday: true });

        const onelensBookingsSearchCriteria = this.session.getOnelensBookingSearchCriteria();

        if (onelensBookingsSearchCriteria.startTime.isValid && onelensBookingsSearchCriteria.endTime.isValid && onelensBookingsSearchCriteria.filterId)
        {
            // Set startTime and endTime set in bookings dashboard, stored in sessionStorage
            this.setState({ bookingStartDate: onelensBookingsSearchCriteria.startTime.toJSDate(), bookingEndDate: onelensBookingsSearchCriteria.endTime.toJSDate() });
            await this.dateRangeDropdownChanged(this.labels.HubLabelCustom);

            // Set selected filterId from sessionStorage
            await this.filterChanged(onelensBookingsSearchCriteria.filterId);

            // Reset session onelensBookingsSearchCriteria
            this.session.setOnelensBookingSearchCriteria(DateHelper.null(), DateHelper.null(), '');
        }

        let buildingId = this.props.match.params.buildingid;
        if (buildingId == '0')
        {
            const buildings = await apis.getSpacesByFilterEnabledBuilding('Building', 1)
            buildingId = buildings.data[0].id
        }
        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: parseInt(buildingId) });
            if (this.props.match.params.tab)
            {
                this.setState({
                    activePage: this.props.match.params.tab,
                });
            }
            const { buildingid, activePage } = this.state;
            const { match, location } = this.props;
            
            let url = `/operational-services-bookings/`;
            
            if (buildingid) 
            {
                const search = location.search ? `/${location.search}` : '';
                const newBuildingId = parseInt(match.params.buildingid);
            
                if (newBuildingId !== buildingid || !match.params.filter) 
                {
                    url += `${buildingid}/${activePage}${search}`;
                } 
                else if (match.params.filter) 
                {
                    url += `${buildingid}/${match.params.tab}/${match.params.filter}`;
                }
            } 
            else 
            {
                url += `0`;
            }
            
            history.push(url);

            this.setRights();
            await this.setBuilding(parseInt(buildingId));
        }
        else
        {
            this.setRights();
            await this.setBuilding(this.appState.buildingId);
        }
        await this.loadFilters();
        const zones = await appContext().ibssApiClientV1.v1.byNodeid.spaceZones.get<SpaceZones[]>({
            nodeId: this.state.buildingid,
            select: SpaceZones
        });
        
        const options = await zones.map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone })).filter((item, index, self) => index === self.findIndex((t) => t.label === item.label && t.value === item.value));
        await options.unshift({ label: "Any", value: "Any" });
        this.setState({ zoneOptions: options, selectedBookingZone: 'Any' });
        this.setState({ isLoading: false });
        await this.getCurrentUserRoles();
    }

    private async getCurrentUserRoles(): Promise<void> 
    {
        try 
        {
            const currentUsersRoles = await appContext().userApiClientV1.api.user.v1.users.userroles2.get<UsersRoles[]>({
                select: UsersRoles  
            })
            const currentUsersRoleIds = currentUsersRoles?.map(i => i.id) ?? null;

            this.setState({
                currentUsersRoleIds: currentUsersRoleIds,
            })
        }
        catch 
        {
        }
    }

    public async loadFilters(): Promise<void>
    {
        if (this.hasFilterReadRights && this.state.isOperationalServicesBookings)
        {
            try
            {
                const filters = await appContext().ibssApiClientV2.v2.byNodeid.filters.get<PagedResponse<SystemFilter[]>>({
                    nodeId: this.state.buildingid,
                    select: SystemFilter,
                });
                const updatedfilterOptions = [
                    new InputOption(this.labels.HubLabelNone, "-1"),
                    ...filters.value.map(i => new InputOption(i.Name, i.Filter_Id ?? ''))
                ];
                await this.setStateAsync({ filterOptions: updatedfilterOptions });

            } catch (error)
            {
                this.setState({ filterOptions: [] });
            }
        }
    }


    public async buildingIdChanged(buildingId: number, reason: BuildingChangeReason, query?: string): Promise<void>
    {
        if (reason == "BuildingSelectorChanged")
        {
            const path = generatePath(this.props.match.path, { buildingid: buildingId, tab: this.props.match.params.tab });
            const url = `${path}${query ?? ''}` // react router dom path cannot take a query.             
            this.props.history.push(url);
        }
        await this.setStateAsync({ selectedFilterId: '', filterQuery: '' })
        await this.setBuilding(buildingId);
        await this.loadFilters();
    }

    private async setBuilding(buildingId: number): Promise<void>
    {
        // update state
        const building = this.local.getNodeData().Regions.flatMap(i => i.Buildings).find(i => i.Node_Id == buildingId);
        this.pageTitle = this.getPageTitle(building?.Name ?? "");
        await this.setStateAsync({ buildingid: buildingId });

        // load bookings
        await this.dataGrid?.refresh();
    }

    private getPageTitle(buildingName: string): string
    {
        if (this.state.isMyBookings)
        {
            return this.labels.HubMenumySchedule;
        }
        else if (this.state.isMyBookingsForOthers)
        {
            return this.labels.HubMenuBookingsForOthers;
        }
        else
        {
            return `${this.labels.HubLabelFacilityManagementText} ${buildingName}`;
        }
    }

    private setRights(): void
    {
        const res = this.local.getIbssRightList();
        this.setState({ canExport: this.local.hasRight("API.Bookings.Export") });
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Create") > -1
        )
        {
            this.setState({
                isCreateRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Update") > -1
        )
        {
            this.setState({
                isUpdateRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Delete") > -1
        )
        {
            this.setState({
                isCancel: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("V2") > -1
        )
        {
            this.setState({
                isV2Rights: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Approve") > -1 &&
            this.local.permissionAppliesToBuilding('API.Bookings.Approve', this.state.buildingid)
        )
        {
            this.setState({
                isApproveRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Deny") > -1 &&
            this.local.permissionAppliesToBuilding('API.Bookings.Deny', this.state.buildingid)
        )
        {
            this.setState({
                isDenyRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Spaces &&
            res.ADMINPORTAL.Spaces.indexOf("CheckIn") > -1
        )
        {
            this.setState({
                isCheckIn: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Spaces &&
            res.ADMINPORTAL.Spaces.indexOf("CheckOut") > -1
        )
        {
            this.setState({
                isCheckOut: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Spaces &&
            res.ADMINPORTAL.Spaces.indexOf("CheckOut") > -1 &&
            res.ADMINPORTAL.Spaces.indexOf("CheckInOnBehalfOf") > -1
        )
        {
            this.setState({
                isCheckInOnBehalfOf: true,
            });
        }
    }

    private async dataQueryChanged(gridQuery: IDataQuery): Promise<DataGridQueryResult<BookingView>>
    {
        if (this.state.searchTermChanged) 
        {
            const bookingsView = this.state.page.value.map((i: any) => BookingView.fromBookingsResponse(i));
            const pageOfRows = bookingsView as BookingView[];
            const list = pageOfRows?.filter(booking => 
            {
                let key: keyof BookingView;
                for (key in booking) 
                {
                    if (booking[key]?.toString().toLowerCase().includes(this.state.searchTerm.toLowerCase())) 
                    {
                        return true;
                    }
                }
                return false;
            });
            this.setState({ searchTermChanged: false });
            return new DataGridQueryResult(list, this.state.page.skipToken);
        }

        const startDate = DateTime.fromJSDate(this.state.bookingStartDate).setZoneByNode(this.state.buildingid).startOf('day');
        const endDate = DateTime.fromJSDate(this.state.bookingEndDate).setZoneByNode(this.state.buildingid).plus({ days: 1 }).startOf('day');
        const odataQuery = new ODataQuery({ top: gridQuery.pageSize, skipToken: gridQuery.skipToken, orderBy: Helper.nameOf<GetV2BookingsEndpoint.Booking>("Booking_Start") });
        let bookingsView: BookingView[];

        if (this.state.isMyBookings || this.state.isBookingsPrimaries)
        {
            const bookingStatus = this.state.bookingStatuses[0] ? this.state.bookingStatuses[0] : ''; // in flex, users can filter by only one bookingStatus

            odataQuery.filter = new BookingFilter({
                baseFilter: odataQuery.filter,
                bookingStatus: (bookingStatus === "" || bookingStatus === "Any" ? "" : bookingStatus),
                bookingStatusNot: (bookingStatus === "" ? ['Cancelled', 'No Show', 'Auto Cancelled'] : []),
                createdBy: this.state.selectedUserBookedByObject.email,
                spaceName: this.state.selectedSpaceOption?.value ?? "",
            });

            let pagedBookings: PagedResponse<MyBooking[]>;
            if (this.state.isBookingsPrimaries)
            {
                pagedBookings = await appContext().ibssApiClientV2.v2.byNodeid.bookings.myPrimariesBookings.get<PagedResponse<MyBooking[]>>({
                    nodeId: 1,
                    top: gridQuery.pageSize,
                    skipToken: gridQuery.skipToken,
                    select: MyBooking,
                    filter: odataQuery.toODataFilter(' and ', `Booking_Start ge datetime'${startDate.toUTC().toISO()}' and Booking_End lt datetime'${endDate.toUTC().toISO()}'`),
                    orderBy: Helper.nameOf<MyBooking>("Booking_Start"),
                    primary: this.state.selectedPrimary === '-1' ? undefined : this.state.selectedPrimary
                });
            }
            else
            {
                pagedBookings = await appContext().ibssApiClientV2.v2.byNodeid.bookings.myBookings.get<PagedResponse<MyBooking[]>>({
                    nodeId: 1,
                    select: MyBooking,
                    filter: odataQuery.toODataFilter(' and ', `Booking_Start ge datetime'${startDate.toUTC().toISO()}' and Booking_End lt datetime'${endDate.toUTC().toISO()}'`),
                    top: gridQuery.pageSize,
                    skipToken: gridQuery.skipToken,
                    orderBy: Helper.nameOf<MyBooking>("Booking_Start"),
                });
            }

            this.setState({ page: pagedBookings });
            const email = this.local.getUserDetails().email.toLowerCase();
            const displayName = this.local.getUserDetails().displayName;
            bookingsView = pagedBookings.value.map(i => BookingView.fromMyBookingsResponse(i, email, displayName));
            return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
        }
        else if (this.state.isMyBookingsForOthers) 
        {
            const bookingStatus = this.state.bookingStatuses[0] ? this.state.bookingStatuses[0] : ''; // in flex, users can filter by only one bookingStatus
            odataQuery.filter = new BookingFilter
                ({
                    baseFilter: odataQuery.filter,
                    bookingStatus: (bookingStatus === "" || bookingStatus === "Any" ? "" : bookingStatus),
                    bookingOwnerEmail: this.state.selectedUserBookedForObject.email,
                    spaceName: this.state.selectedSpaceOption?.value ?? "",
                });

            const email = this.local.getUserDetails().email.toLowerCase();
            const pagedBookings = await this.bookingService.getBookingsForOthers(odataQuery, email, startDate, endDate);
            this.setState({ page: pagedBookings })
            bookingsView = pagedBookings.value.map(i => BookingView.fromMyBookingsForOthersResponse(i, email));
            return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
        }
        else 
        {
            odataQuery.nodeId = this.state.buildingid;
            
            odataQuery.filter = new BookingFilter2
                ({
                    baseFilter: odataQuery.filter,
                    // statuses: (this.state.bookingStatus == "" || this.state.bookingStatus == "Any" ? undefined : [ this.state.bookingStatus ]), // - todo remove redundant code.
                    statuses: (this.state.bookingStatuses.length === 0 || this.state.bookingStatuses.includes("Any") ? undefined : this.state.bookingStatuses),
                    relationships: (this.state.relationshipStatuses.length === 0 ? undefined : this.state.relationshipStatuses),
                    bookingOwnerEmail: this.state.selectedUserBookedForObject.email,
                    createdBy: this.state.selectedUserBookedByObject.email,
                    spaceName: this.state.selectedSpaceOption?.value ?? "",
                    startDate: startDate?.toUtcByNode(this.state.buildingid) ?? "",
                    endDate: endDate?.toUtcByNode(this.state.buildingid) ?? ""
                });
            const filterQueryStr = this.state.filterQuery && this.state.selectedFilterId !== '-1' ? `(${this.state.filterQuery})`: '';
            let filters =  [ filterQueryStr !== '' ? filterQueryStr : odataQuery.filter.toODataString()].filter(filterQuery => filterQuery !=='').join(' and ');
            if(this.state.selectedBookingZone !== 'Any')
            {
                filters = `${filters} and Meta_Loc_Zone eq '${this.state.selectedBookingZone}'`
            }
            const response = await appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<Booking[]>>({
                nodeId: this.state.buildingid,
                top: gridQuery.pageSize,
                skipToken: gridQuery.skipToken,
                select: Booking,
                filter: filters,
                orderBy: Helper.nameOf<MyBooking>("Booking_Start"),
            });

            bookingsView = response.value.map(i => BookingView.fromBookingsResponse(i));
            return new DataGridQueryResult(bookingsView, response.skipToken);
        }
    }

    private fromDateToString(date: Date, type: "start" | "end"): string
    {
        if (type === "start")
        {
            return DateTime.fromISO(dateConverter(date)).setZone(getBuildingTimeZoneByNodeId(this.state.buildingid)).startOf('day').toUTC().toISO();
        }
        else
        {
            return DateTime.fromISO(dateConverter(date)).setZone(getBuildingTimeZoneByNodeId(this.state.buildingid)).endOf('day').toUTC().toISO();
        }
    }

    private filterButtonClicked(): void
    {
        this.setState({
            isFilter: !this.state.isFilter,
            filterMode: true,
        });
    }

    private async bookedForChanged(newValue: UserPickerModule.IUser | null): Promise<void>
    {
        if (newValue)
        {
            this.setState({
                selectedUserBookedForObject: {
                    label: newValue.label,
                    email: newValue.email,
                },
            });
        } else
        {
            this.setState({ selectedUserBookedForObject: { label: "", email: "" } });
        }
    }

    private async bookedByChanged(newValue: UserPickerModule.IUser | null): Promise<void>
    {
        if (newValue)
        {
            this.setState({
                selectedUserBookedByObject: {
                    label: newValue.label,
                    email: newValue.email,
                },
            });
        } else
        {
            this.setState({ selectedUserBookedByObject: { label: "", email: "" } });
        }
    }

    private spaceOptionChanged(e: React.SyntheticEvent<Element, Event>, newValue: KeyValue<string, string> | null): void
    {
        this.setState({ selectedSpaceOption: newValue });
    }

    private relationShipstatusChanged(e: SelectChangeEvent<string[]>): void
    {
        const status = e.target.value;
        const statusesArray = Array.isArray(status) ? status : [status];
        this.setState({ relationshipStatuses: statusesArray });
    }

    private async spaceOptionTextChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void>
    {
        const searchTerm = e.target.value;
        if (searchTerm.length < 3)
        {
            this.setState({ spaceOptions: [] });
            return;
        }

        const spaces = await this.apiCache.getSpacesByBuilding(this.state.buildingid);
        const filteredSpaces = spaces.filter(i => i.Space_Name.includes(searchTerm));
        const spaceOptions = filteredSpaces.map(i => ({ key: i.Space_Id, value: i.Space_Name, displayName: i.Space_Name }));
        this.setState({ spaceOptions: spaceOptions });
    }

    private statusChanged(e: SelectChangeEvent<string[]>): void
    {
        if (e.target.value.includes('Any') && e.target.value[0] !== 'Any') //Remove other selected status options when 'Any' is selected
        {
            this.setState({ bookingStatuses: ['Any'] });
        }
        else
        {
            const status = e.target.value;
            let statusesArray = typeof status === 'string' ? [status] : status;
            if (statusesArray.length > 1) // Remove 'Any' when another status option is selected
            {
                statusesArray = statusesArray.filter(x => x != 'Any')
            }
            this.setState({ bookingStatuses: statusesArray });
        }
    }

    private zoneChanged(e: SelectChangeEvent<string>): void
    {
        this.setState({ selectedBookingZone: e.target.value });
    }
    

    private filterModalClosed(): void
    {
        this.setState({
            isFilter: false,
        });
    }

    private async filterModalOkayed(): Promise<void>
    {
        await this.setState({ filterQuery: null, selectedFilterId: '-1' });
        await this.dataGrid?.refresh();
        this.filterModalClosed();
        return;
    }

    private async dateRangeDropdownChanged(value: string): Promise<void>
    {
        this.setState({ daysFilter: value });

        if (value === this.labels.HubLabelToday)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().toJSDate(),
                    bookingEndDate: DateHelper.now().toJSDate(),
                    dateDisabled: true,
                });
        }
        if (value === this.labels.HubLabelTomorrow)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    bookingEndDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (value === this.labels.HubLabelThisWeek)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('week').toJSDate(),
                    bookingEndDate: DateHelper.now().endOf('week').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (value === this.labels.HubLabelNextWeek)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('week').plus({ days: 7 }).toJSDate(),
                    bookingEndDate: DateHelper.now().startOf('week').plus({ days: 13 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (value === this.labels.HubLabelThisMonth)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('month').toJSDate(),
                    bookingEndDate: DateHelper.now().endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (value === this.labels.HubLabelNextMonth)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('month').plus({ months: 1 }).toJSDate(),
                    bookingEndDate: DateHelper.now().plus({ months: 1 }).endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (value === this.labels.HubLabelCustom)
        {
            await this.setState({ dateDisabled: false });
        }

        await this.dataGrid?.refresh();
    }

    private async dateChanged(e: Date, type: StartOrEnd): Promise<void>
    {
        if (isNaN(e.valueOf()))
        {
            return;
        }
        if (type === StartOrEnd.Start)
        {
            await this.setState({
                bookingStartDate: new Date(e),
                isCompleteLoading: true
            });
        }
        else
        {
            await this.setState({
                bookingEndDate: new Date(e),
                isCompleteLoading: true
            });
        }
        await this.dataGrid?.refresh();
        await this.setState({ isCompleteLoading: false });
    }

    private async exportClicked(): Promise<void>
    {
        this.setState({ disableExport: true });
        setTimeout(() => this.setState({ disableExport: false }), 60000);

        const bookingFilter = new BookingFilter2
            ({
                statuses: this.state.bookingStatuses,
                bookingOwnerEmail: this.state.selectedUserBookedForObject.email,
                createdBy: this.state.selectedUserBookedByObject.email,
                startDate: DateTime.fromISO(this.fromDateToString(this.state.bookingStartDate, 'start')),
                endDate: DateTime.fromISO(this.fromDateToString(this.state.bookingEndDate, 'end')),
            });

        this.bookingService.download(this.state.buildingid, bookingFilter).then(blob => Helper.downloadFile(blob, 'Booking'));
    }

    private async checkOutOneClicked(data: BookingView): Promise<void>  
    {
        const bookingOwner = this.state.isMyBookingsForOthers ? data.Booking_Owner_Name : data.Booking_Owner_Email;
        try
        {
            this.setState({ isCompleteLoading: true });
            const spaceId = data.Space_Id.split(';')[0];
            let body = {
                BookingOwnerEmail: bookingOwner
            }
            await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkout.patch({
                nodeId: data.Node_Id,
                id: spaceId,
                body: body,
            })
            this.setState({ isCompleteLoading: false });
            this.alert.show("", this.labels.HubLabelBookingCheckOutSuccessfully);
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private checkOutManyClicked(): void
    {
        this.setState({ isCompleteLoading: true });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<void>((resolve, reject) =>
            {
                try
                {
                    const spaceId = booking.Space_Id.split(';')[0];
                    let body = {
                        BookingOwnerEmail: booking.Booking_Owner_Email
                    }
                    appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkout.patch({
                        nodeId: booking.Node_Id,
                        id: spaceId,
                        body: body,
                    })
                    .then(() => resolve());
                }
                catch
                {
                    return reject(new Error(`Failed to approve the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show("", this.labels.HubLabelBookingCheckOutSuccessfully);
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage();
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private async checkInOneClicked(): Promise<void>
    {
        const booking = this.state.rowSelectionModel[0];
        try
        {
            this.setState({ isCompleteLoading: true });
            const spaceId = booking.Space_Id.split(';')[0];
            let body = {
                BookingOwnerEmail: booking.Booking_Owner_Email
            }
            await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkin.patch({
                nodeId: booking.Node_Id,
                id: spaceId,
                body: body,
            })
            this.setState({ isCompleteLoading: false });
            this.alert.show("", this.labels.HubLabelBookingCheckInSuccessfully);
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private async approveOneClicked(data: BookingView): Promise<void>  
    {
        try
        {
            this.setState({ isCompleteLoading: true });
            await appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.approve.patch<void>({
                nodeId: data.Node_Id!,
                bookingid: data.Booking_Id
            })
            this.setState({ isCompleteLoading: false });
            await this.dataGrid?.refreshPage();
        }
        catch (error)
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private approveManyClicked(): void
    {
        this.setState({ isCompleteLoading: true });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<string>((resolve, reject) =>
            {
                try
                {
                    appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.approve.patch<string>({
                        nodeId: booking.Node_Id!,
                        bookingid: booking.id
                    })
                        .then(i => resolve(i));
                }
                catch
                {
                    return reject(new Error(`Failed to approve the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelBookingsApprovedSuccesfullyMsg, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage();
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private async rejectOneClicked(data: BookingView): Promise<void>  
    {
        try
        {
            this.setState({ isCompleteLoading: true });
            appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.deny.patch({
                nodeId: data.Node_Id,
                bookingid: data.Booking_Id
            })
            this.alert.show("", this.labels.HubLabelBookingsRejectedSuccesfullyMsg);
            this.setState({ isCompleteLoading: false });
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private rejectManyClicked(): void
    {
        this.setState({
            showRejectModal: false,
            isCompleteLoading: true,
        });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<string>((resolve, reject) =>
            {
                try
                {
                    appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.deny.patch<string>({
                        nodeId: booking.Node_Id,
                        bookingid: booking.id
                    })
                        .then(i => resolve(i));
                    this.setState({ isCompleteLoading: false });

                }
                catch
                {
                    this.setState({ isCompleteLoading: false });
                    return reject(new Error(`Failed to reject the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(messages =>
            {
                this.alert.show("", this.labels.HubLabelBookingsRejectedSuccesfullyMsg);
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage();
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private async cancelOneClicked(data: BookingView): Promise<void>  
    {
        this.setState({showCancelModal: true});
    }

    private async confirmCancelManyClicked(): Promise<void>
    {
        this.setState({ showCancelModal: false, isCompleteLoading: true });
        const numOfBookings = this.state.rowSelectionModel.length;
        let numOfFailedDeletes = 0;

        for (const booking of this.state.rowSelectionModel)
        {
            try
            {
                const response = await this.bookingService.delete(booking.Node_Id, booking.id || "");
            }
            catch
            {
                numOfFailedDeletes++;
            }
        }

        if (numOfFailedDeletes > 0)
        {
            this.alert.show(this.labels.HubLabelError, this.labels.funcManyBookingsNotCancelled_D.replace("{0}", numOfFailedDeletes.toString()).replace("{1}", numOfBookings.toString()));
        }
        else
        {
            this.alert.show(this.labels.HubLabelSuccess, this.labels.funcManyBookingsCancelled_D.replace("{0}", numOfBookings.toString()));
        }

        this.setState({ isCompleteLoading: false, cancelButtonDisabled: true });
        setTimeout(() => this.dataGrid?.refresh(), 500); // bookings takes time to update in the API
    }

    private selectionChanged(selection: GridRowSelectionModel): void
    {
        const pageOfRows = this.dataGrid?.state.rows as BookingView[];
        const selectedRows = pageOfRows.filter(row => selection.includes(row.Booking_Id))
        this.setState({ rowSelectionModel: selectedRows });

        let isUserInApprovalGroup : boolean;
        if (this.state.isBookingsPrimaries)
        {
            isUserInApprovalGroup = true;
        }
        else
        {
            isUserInApprovalGroup = selectedRows.every(row => {
                    const approvalGroups = JSON.parse(row.approvalGroups);
                    if (!approvalGroups || approvalGroups.length === 0) {
                        return false;
                    }
                    return approvalGroups.some((group: string) => this.state.currentUsersRoleIds.includes(group));
                });
        }


        const cancelRows: BookingView[] = [];
        const approveRows: BookingView[] = [];
        const rejectRows: BookingView[] = [];
        const checkInRows: BookingView[] = [];
        const checkOutRows: BookingView[] = [];
        const selectAllRows: BookingView[] = [];

        for (let i = 0; i <= selectedRows.length - 1; i++)
        {
            const rowStatus = selectedRows[i]["Booking_Status"];
            const rowApproved = selectedRows[i]["Booking_IsApproved"];
            if (this.state.isMyBookingsForOthers)
            {
                if (rowStatus === "New" || rowStatus === "Amended") 
                {
                    selectAllRows.push(selectedRows[i])
                }
            } else
            {
                if ((rowStatus === "New" && rowApproved === 0) ||
                    (rowStatus === "Amended" && rowApproved === 0) || //Awaiting Approval
                    (rowStatus === "New" && rowApproved === 3) ||
                    (rowStatus === "New" && rowApproved === 4) || //Approved
                    (rowStatus === "Late Checkin" && rowApproved === 3) ||
                    (rowStatus === "Late Checkin" && rowApproved === 4) || //Late Check in
                    (rowStatus === "Amended" && rowApproved === 3) ||
                    (rowStatus === "Amended" && rowApproved === 4) //Amended
                )
                {

                    cancelRows.push(selectedRows[i])
                }
            }

        }
        for (let j = 0; j <= selectedRows.length - 1; j++)
        {
            const rowStatus = selectedRows[j]["Booking_Status"];
            const rowApproved = selectedRows[j]["Booking_IsApproved"];
            const rowType = selectedRows[j]["Parent_Booking_Relation_Type"];
            if (((rowStatus === "New" && rowApproved === 0) ||
                (rowStatus === "Amended" && rowApproved === 0)) && rowType === "BookingRelationTypeMain")
            {
                approveRows.push(selectedRows[j])
            }
        }
        for (let k = 0; k <= selectedRows.length - 1; k++)
        {
            const rowStatus = selectedRows[k]["Booking_Status"];
            const rowApproved = selectedRows[k]["Booking_IsApproved"];
            const rowType = selectedRows[k]["Parent_Booking_Relation_Type"];
            if (((rowStatus === "New" && rowApproved === 0) ||
                (rowStatus === "Amended" && rowApproved === 0)) && rowType === "BookingRelationTypeMain")
            {
                rejectRows.push(selectedRows[k])
            }
        }
        for (let l = 0; l <= selectedRows.length - 1; l++)
        {
            const rowStatus = selectedRows[l]["Booking_Status"];
            const rowApproved = selectedRows[l]["Booking_IsApproved"];
            const rowEarlyCheckin = selectedRows[l]["Booking_Early_Checkin"];
            if ((((rowStatus === "New" || rowStatus === "Amended" || rowStatus === "Late Checkin") && rowApproved === 3) && DateTime.fromISO(rowEarlyCheckin) < DateTime.utc()) ||
                ((rowStatus === "New" || rowStatus === "Amended" || rowStatus === "Late Checkin") && rowApproved === 4 && DateTime.fromISO(rowEarlyCheckin) < DateTime.utc()))
            {
                checkInRows.push(selectedRows[l])
            }
        }
        for (let m = 0; m <= selectedRows.length - 1; m++)
        {
            const rowStatus = selectedRows[m]["Booking_Status"];
            const rowApproved = selectedRows[m]["Booking_IsApproved"];

            if ((rowStatus === "In Progress" && rowApproved === 3) ||
                (rowStatus === "In Progress" && rowApproved === 4) ||
                (rowStatus === "Amended" && selectedRows[m]["Booking_IsCheckedIn"] === 1) ||
                (rowStatus === "Early Check In"))
            {
                checkOutRows.push(selectedRows[m]);
            }
        }
        if (selectedRows.length <= 0 && cancelRows.length <= 0)
        {
            this.setState({
                cancelButtonDisabled: true,
            })
        }
        else if (selectedRows.length === cancelRows.length && this.state.isCancel)
            {
                const otherRecordsAvailable = selectedRows.find((row) => row.Parent_Booking_Relation_Type !== 'BookingRelationTypeMain');
                if(otherRecordsAvailable && this.state.isCancel && !(this.state.isMyBookings || this.state.isMyBookingsForOthers))
                {
                this.setState({
                        cancelButtonDisabled: true,
                })
            }
            else
            {
                this.setState({
                        cancelButtonDisabled: false,
                    })
                }
            }
            else
            {
                this.setState({
                cancelButtonDisabled: true
            })
        }
        if (selectedRows.length <= 0 && approveRows.length <= 0)
        {
            this.setState({
                approveButtonEnabled: true,
            })
        } else if (selectedRows.length === approveRows.length && this.state.isApproveRight && isUserInApprovalGroup)
        {
            this.setState({
                approveButtonEnabled: false
            })
        } else
        {
            this.setState({
                approveButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && rejectRows.length <= 0 && this.state.isDenyRight)
        {
            this.setState({
                rejectButtonEnabled: true,
            })
        } else if (selectedRows.length === rejectRows.length && this.state.isDenyRight && isUserInApprovalGroup)
        {
            this.setState({
                rejectButtonEnabled: false
            })
        } else
        {
            this.setState({
                rejectButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && checkInRows.length <= 0)
        {
            this.setState({
                checkInButtonEnabled: true,
            })
        } else if (selectedRows.length === checkInRows.length && this.state.isCheckIn)
        {
            this.setState({
                checkInButtonEnabled: false
            })
        } else
        {
            this.setState({
                checkInButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && checkOutRows.length <= 0)
        {
            this.setState({
                checkOutButtonEnabled: true,
            })
        } else if (selectedRows.length === checkOutRows.length && this.state.isCheckOut)
        {
            this.setState({
                checkOutButtonEnabled: false
            })
        } else
        {
            this.setState({
                checkOutButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && selectAllRows.length <= 0)
        {
            this.setState({
                cancelAllRowsEnable: true,
            })
        } else if (selectedRows.length === selectAllRows.length && this.state.isCancel)
        {
            this.setState({
                cancelAllRowsEnable: false
            })
        } else
        {
            this.setState({
                cancelAllRowsEnable: true
            })
        }
    }

    public createBookingClicked(): void
    {
        const { history } = this.props;
        if ( this.state.isBookingsPrimaries )
        {
            history.push(`/flex-find-a-space`);
        }
        else
        {
            history.push(`/operational-services/buildings/${this.state.buildingid}/spaces/search`);
        }
    }

    public updateBookingClicked(bookingId: string, spaceId: string): void
    {
        const { history } = this.props;
        let bookingSpaceId: string | undefined = "";
        if (spaceId.includes(";"))
        {
            const space_id = spaceId.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = spaceId;
        }
        if ( this.state.isBookingsPrimaries )
        {
            history.push(`/flex-my-bookings/${getBuildingNodeIdUsingFloorNodeId(this.state.selectedBookingSeries.nodeId)}/mybooking/${bookingId}/${bookingSpaceId}`);
        }
        else
        {
            history.push(`/operational-services-bookings/${this.state.buildingid}/bookings/${bookingId}/${spaceId}`);
        }
    }

    private createBookingForMeClicked(bookingId: string, spaceId: string, nodeId: number): void
    {
        let bookingSpaceId: string | undefined = "";
        if (spaceId.includes(";"))
        {
            const space_id = spaceId.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = spaceId

        }
        const { history } = this.props;
        history.push(`/flex-my-bookings/${getBuildingNodeIdUsingFloorNodeId(nodeId)}/mybooking/${bookingId}/${bookingSpaceId}`);
    }

    private createBookingForOthersClicked(bookingId: string, spaceId: string, nodeId: number): void
    {
        let bookingSpaceId: string | undefined = "";
        if (spaceId.includes(";"))
        {
            const space_id = spaceId.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = spaceId

        }
        const { history } = this.props;
        history.push(`/flex-my-bookings-for-others/${getBuildingNodeIdUsingFloorNodeId(nodeId)}/mybookingother/${bookingId}/${bookingSpaceId}`);
    }

    private updateBookingRoute(): void
    {
        const { selectedBookingSeries } = this.state;

        if (this.state.isOperationalServicesBookings)
        {
            this.updateBookingClicked(selectedBookingSeries.bookingId, selectedBookingSeries.spaceId);
        }

        if (this.state.isMyBookings || this.state.isBookingsPrimaries)
        {
            this.createBookingForMeClicked(selectedBookingSeries.bookingId, selectedBookingSeries.spaceId, selectedBookingSeries.nodeId);
        }

        if (this.state.isMyBookingsForOthers)
        {
            this.createBookingForOthersClicked(selectedBookingSeries.bookingId, selectedBookingSeries.spaceId, selectedBookingSeries.nodeId);
        }

        
    }

    private seriesBookingSelected(row: BookingView): void
    {
        this.setState({ showViewBookingSeriesModal: true, selectedBookingSeries: { seriesId: row.BookingSeriesId, nodeId: row.Node_Id, bookingId: row.Booking_Id, spaceId: row.Space_Id } })
    }

    private viewSeriesRoute(): void
    {
        if (this.state.isOperationalServicesBookings)
        {
            this.props.history.push(`/oneLens/${this.state.selectedBookingSeries.nodeId}/booking-series/view/${this.state.selectedBookingSeries.seriesId}`);
        }
        else if (this.state.isMyBookings)
        {
            this.props.history.push(`/flex/bookings/${this.state.selectedBookingSeries.nodeId}/booking-series/view/${this.state.selectedBookingSeries.seriesId}`);
        }
        else if (this.state.isMyBookingsForOthers)
        {
            this.props.history.push(`/flex/bookings-for-others/${this.state.selectedBookingSeries.nodeId}/booking-series/view/${this.state.selectedBookingSeries.seriesId}`);
        }
    }

    private getDateAndTime(row: BookingView, type: string): string
    {
        const buildingNodeId = getBuildingNodeIdUsingFloorNodeId(row.Node_Id);
        const getBuilding = getBuildingTimeZoneByNodeId(buildingNodeId);
        const localNow = DateTime.local();
        const currentZone = localNow.zoneName;
        if (type === 'start')
        {
            return DateTime.fromISO(row.Booking_Start, { zone: getBuilding }).toLocaleDateTimeString();
        }
        else if (type === "end")
        {
            return DateTime.fromISO(row.Booking_End, { zone: getBuilding }).toLocaleDateTimeString();
        } else
        {
            if (currentZone === getBuilding) 
            {
                return this.labels.HubLabelLocalTime
            }
            else 
            {
                // Get the current time in the specified timezone
                const dateTime = DateTime.local().setZone(getBuilding);

                const utcOffsetMinutes = dateTime.offset;

                const utcOffsetHours = utcOffsetMinutes / 60;

                return `UTC ${utcOffsetHours > 0 ? "+" + utcOffsetHours : utcOffsetHours}`
            }
        }
    }

    private async filterTextChanged(searchTerm: string): Promise<void> 
    {
        await this.setStateAsync({
            searchTermChanged: true,
            searchTerm: searchTerm,
        });
        await this.dataGrid?.refreshPage();
    }

    private async primaryChanged(primary: string): Promise<void> 
    {
        await this.setState({ selectedPrimary : primary });
        await this.dataGrid?.refresh();
    }
    

    private async filterChanged(selectedId: string): Promise<void> 
    {
        if(selectedId === '-1')
        {
            await this.setState({ selectedFilterId: selectedId });
        }
        else
        {
            const selectedFilter = await appContext().ibssApiClientV2.v2.byNodeid.filters.byFilterid.get<SystemFilter>({
                nodeId: this.state.buildingid,
                filterid: selectedId,
                select: SystemFilter
            });
            await this.setState({ selectedFilterId: selectedId, filterQuery: selectedFilter.Filter });
        }
        await this.dataGrid?.refreshPage();
    }

    private getRelationshipType(type: string): string
    {
        if (type === "BookingRelationTypeMain")
        {
            return this.labels.dataBookingMain_S;
        }
        else if (type === "BookingRelationTypeSetup")
        {
            return this.labels.dataBookingCreateSetup_S;
        }
        else if (type === "BookingRelationTypeTearDown")
        {
            return this.labels.dataBookingCreateReset_S;
        }
        else
        {
            return "";
        }
    }


    public changeLayout(layout: string | null): void 
    {
        if(layout !== null)
        {
            this.props.history.push(`/buildings/${this.state.buildingid}/bookings/dashboard`);
        }
    }

    private getStatusLabel(statusList: string[]): string[]
    {   
        const statusArray: string[] = statusList?.map(value => {
            const statusObj = this.state.relationStatusList?.find(item => item.value === value);
            return statusObj ? statusObj.label : '';
        }).filter(label => label !== '');
        return statusArray;
    }

    private async deleteStatus(relationshipStatuses: string): Promise<void>
    {
        const status = await this.state.relationStatusList.find(item => item.label === relationshipStatuses);
        this.setState((prevState) => ({ relationshipStatuses: prevState.relationshipStatuses.filter(i => i !== status?.value) }));
    }


    public render(): JSX.Element
    {
        const columnWidth = 130;

        // for the server field names, there is an assumption that all 3 endpoints (my bookings, my bookings for others and one lens bookins) use the same field names
        const dataGridColumns: Column<BookingView>[] =
        this.state.isBookingsPrimaries ? [
            {
                headerName: this.labels.HubLabelBookedBy,
                field: "_CreatedBy",
                minWidth: columnWidth,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelBookingStart,
                field: "Booking_Start",
                minWidth: columnWidth,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'start'),
            },
            {
                headerName: this.labels.HubLabelBookingEnd,
                field: "Booking_End",
                minWidth: columnWidth,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'end'),
            },
            {
                headerName: this.labels.HubLabelBookingName,
                field: "Booking_Name",
                minWidth: columnWidth,
                flex: 1,
                filterable: false,
            },
            {
                headerName: this.labels.HubLabelSpace,
                field: "Space_Name",
                minWidth: columnWidth,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelAction,
                minWidth: columnWidth,
                field: "",
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params) =>
                    <>
                        {this.renderActionButtonFromBooking(params.row)}
                    </>
            },
            {
                headerName: "Status",
                minWidth: columnWidth,
                flex: 1,
                field: "status",
                renderCell: (params) => (this.state.isMyBookingsForOthers || this.state.isMyBookings) ? this.flexBookingStatus(params.row) : this.renderBookingOverAllStatus(params.row),
                valueGetter: (params) => params.row.Booking_Status,
                filterable: false,
                sortable: false
            }
        ] :
        [
            ...(this.state.isMyBookings || this.state.isMyBookingsForOthers ? [{
                headerName: this.labels.HubLabelBuilding,
                field: "Building_Name",
                minWidth: columnWidth,
                flex: 1,
            }] : []),
            {
                headerName: this.labels.HubLabelBookingName,
                field: "Booking_Name",
                minWidth: columnWidth,
                flex: 1,
                filterable: false,
            },
            ...(!(this.state.isMyBookings || this.state.isMyBookingsForOthers) ? [{
                headerName: this.labels.funcType_S,
                field: "Parent_Booking_Relation_Type",
                valueGetter: (params: { row: BookingView }) => this.getRelationshipType(params.row.Parent_Booking_Relation_Type),
                minWidth: columnWidth,
                flex: 1,
                filterable: false,
            }] : []),
            {
                headerName: this.labels.HubLabelBookingStart,
                field: "Booking_Start",
                minWidth: columnWidth,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'start'),
            },
            {
                headerName: this.labels.HubLabelBookingEnd,
                field: "Booking_End",
                minWidth: columnWidth,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'end'),
            },
            {
                headerName: this.labels.HubLabelTimeZone,
                field: "timeZone",
                minWidth: columnWidth,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'timezone'),
            },
            ...(!this.state.isMyBookings && !this.state.isMyBookingsForOthers ? [{
                headerName: this.labels.HubLabelZone,
                field: Helper.nameOf<BookingView>('locationZone'),
                minWidth: columnWidth,
                flex: 1,
            },
            ] : []),
            {
                headerName: this.labels.HubLabelSpace,
                field: "Space_Name",
                minWidth: columnWidth,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelBookedFor,
                field: "Booking_Owner_Name",
                minWidth: columnWidth,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelBookedBy,
                field: "_CreatedBy",
                minWidth: columnWidth,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelAction,
                minWidth: columnWidth,
                field: "",
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params) =>
                    <>
                        {this.state.isMyBookings && this.renderCheckInCheckOutActionButton(params.row)}
                        {this.state.isMyBookingsForOthers && this.renderCancelAllActionButton(params.row)}
                        {!this.state.isMyBookings && !this.state.isMyBookingsForOthers && !this.state.isBookingsPrimaries && this.renderActionButtonFromBooking(params.row)}
                    </>
            },
            {
                headerName: "Status",
                minWidth: columnWidth,
                flex: 1,
                field: "status",
                renderCell: (params) => (this.state.isMyBookingsForOthers || this.state.isMyBookings) ? this.flexBookingStatus(params.row) : this.renderBookingOverAllStatus(params.row),
                valueGetter: (params) => params.row.Booking_Status,
                filterable: false,
                sortable: false
            }];

        let filterStatusOption = [
            { label: this.labels.HubLabelApproved, value: "Approved" },
            { label: this.labels.HubTabAwaitingApproval, value: "Awaiting Approval" },
            {
                label: this.labels.HubLabelAutoCancelledStatus,
                value: "Auto Cancelled",
            },
            { label: this.labels.HubLabelCancelled, value: "Cancelled" },
            { label: this.labels.HubLabelCompleted, value: "Completed" },
            { label: this.labels.HubLabelamended, value: "Amended" },
            { label: this.labels.HubLabelNew, value: "New" },
            { label: this.labels.HublabelEarlyCheckIn, value: "Early Check In" },
            { label: this.labels.HubLabelInProgress, value: "In Progress" },
            { label: this.labels.HublabelLateCheckIn, value: "Late Checkin" },
            { label: this.labels.HubLabelNoShow, value: "No Show" },
        ].sort((a, b) =>
        {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })

        filterStatusOption = [{ label: this.labels.HubLabelAny, value: "Any" }, ...filterStatusOption]


        const actions: IActionButton[] = [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {
                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelButtonDisabled,
                onClick: () => this.setState({
                    showCancelModal: true,
                    modalStatus: "cancel"
                })
            },
            {
                label: this.labels.HubLabelReject,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.rejectButtonEnabled || !(this.hasV2Rights && this.hasRejectRights),
                onClick: () => this.setState({
                    showRejectModal: true,
                    modalStatus: "reject"
                })
            },
            {
                label: this.labels.HubLabelApprove,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.approveButtonEnabled || !(this.hasV2Rights && this.hasApproveRights),
                onClick: () => this.approveManyClicked()
            },
            {
                label: this.labels.HubButtonAdd,
                className: "addIcon",
                icon: (
                    <IbssSvgIcon>
                        <AddIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: this.state.isCreateRight ? false : true,
                onClick: () => this.createBookingClicked(),
            }
        ]

        const checkInActions: IActionButton[] = this.state.isBookingsPrimaries ? [
            {
                label: this.labels.HubButtonCheckOut,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.checkOutButtonEnabled,
                onClick: () => this.checkOutManyClicked()
            },
            {
                label: this.labels.HubButtonCheckIn,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.checkInButtonEnabled,
                onClick: () => this.checkInOneClicked()
            },
            {
                label: this.labels.HubButtonAdd,
                className: "addIcon",
                icon: (
                    <IbssSvgIcon>
                        <AddIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: this.state.isCreateRight ? false : true,
                onClick: () => this.createBookingClicked(),
            }
            ] : 
            [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {

                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelButtonDisabled,
                onClick: () => this.setState({
                    showCancelModal: true,
                    modalStatus: "cancel"
                })
            },
            {
                label: this.labels.HubButtonCheckOut,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.checkOutButtonEnabled,
                onClick: () => this.checkOutManyClicked()
            },
        ]

        const otherActions: IActionButton[] = [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {
                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelAllRowsEnable,
                onClick: () => this.setState({
                    showCancelModal: true,
                    modalStatus: "cancel"
                })
            }
        ]


        const layoutOptions: toggleButtonListObject[] = 
        [
            {  value: 'allList', icon: <FormatListBulletedIcon /> },
            {  value: 'scheduleView', icon: <CalendarTodayIcon /> },
        ];

        return (
            <>
                <div className="">
                    <IbssDialog
                        aria-modal="true"
                        aria-label="view series modal"
                        onClose={() => this.setState({
                            showViewBookingSeriesModal: false,
                            selectedBookingSeries: { seriesId: '', nodeId: 0, bookingId: '', spaceId: '' }
                        })}
                        open={this.state.showViewBookingSeriesModal}
                        header={this.labels.funcConfirmViewBookingSeries_Message}
                        fullWidth
                        dialogContent={
                            <>
                                <div className="mb-3">{this.labels.funcSeriesBookingSelected_Message}</div>
                            </>
                        }
                        footer={
                            <>
                                <div className="d-flex justify-content-between w-90">
                                    <IbssButtonRedo color="secondary" variant="contained"
                                        className="mr-2"
                                        disabled={!this.state.isUpdateRight}
                                        onClick={() => this.updateBookingRoute()}
                                    >
                                        {this.labels.funcViewBooking_S}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo color="primary" variant="contained"
                                        className="ml-2"
                                        onClick={() => this.viewSeriesRoute()}
                                    >
                                        {this.labels.funcViewSeries_S}
                                    </IbssButtonRedo>
                                </div>
                            </>
                        }
                    />
                    <IbssDialog
                        aria-modal="true"
                        aria-label="booking filter modal"
                        onClose={() => this.filterModalClosed()}
                        open={this.state.isFilter}
                        header={this.labels.HubLabelFilter}
                        fullWidth
                        dialogContent={
                            <>
                                {this.state.isOperationalServicesBookings && <Box className="my-1">
                                    {this.labels.HubSubTitleCustomFilter}
                                </Box>}
                                <Box>
                                    <IbssInputDropDown
                                        inputLabel={this.labels.HubLabelStatus}
                                        options={filterStatusOption}
                                        value={this.state.bookingStatuses}
                                        id={"statusSelection"}
                                        isMultiple={this.state.isOperationalServicesBookings}
                                        onChange={(e: SelectChangeEvent<string[]>) => this.statusChanged(e)}
                                        fullWidth
                                    />
                                </Box>
                                {this.state.bookingStatuses.length > 1 &&
                                    <Box width={'100%'} maxWidth={'100%'}>
                                        <BookingStatusesChips
                                            bookingStatuses={this.state.bookingStatuses}
                                            onDelete={(bookingStatus: string) => this.setState((prevState) => ({ bookingStatuses: prevState.bookingStatuses.filter(i => i !== bookingStatus) }))}
                                        />
                                    </Box>
                                }
                                {this.state.isOperationalServicesBookings &&
                                    <Box mt={1}>
                                        <IbssInputDropDown
                                            inputLabel={this.labels.funcBookingRelationshipType_S}
                                            options={this.state.relationStatusList}
                                            value={this.state.relationshipStatuses}
                                            id={"relationshipstatusSelection"}
                                            isMultiple={this.state.isOperationalServicesBookings}
                                            onChange={(e: SelectChangeEvent<string[]>) => this.relationShipstatusChanged(e)}
                                            fullWidth
                                        />
                                    </Box>
                                }
                                 {this.state.relationshipStatuses.length > 1 &&
                                    <Box width={'100%'} maxWidth={'100%'}>
                                        <BookingStatusesChips
                                            bookingStatuses={this.getStatusLabel(this.state.relationshipStatuses)}
                                            onDelete={(relationshipStatuses: string) => this.deleteStatus(relationshipStatuses)}
                                        />
                                    </Box>
                                }
                                   <Box mt={1}>
                                    <IbssInputDropDown
                                        inputLabel={this.labels.HubLabelZones}
                                        options={this.state.zoneOptions}
                                        value={this.state.selectedBookingZone}
                                        id={"zoneSelection"}
                                        isMultiple={false}
                                        onChange={(e: SelectChangeEvent<string>) => this.zoneChanged(e)}
                                        fullWidth
                                    />
                                </Box>

                                {!this.state.isMyBookings &&
                                    <div className="row">
                                        <div className="form-input-box">
                                            <label className="form-input-box-label visit-filter-label">
                                                {this.labels.HubLabelBookedFor}
                                            </label>
                                            <UserPicker
                                                width="calc(100% - 110px)"
                                                searchText={this.state.bookedForSearchText}
                                                label=""
                                                placeholder="&#8230;"
                                                alternateStyle={true}
                                                onChange={async text => this.setState({ bookedForSearchText: text, updates: true })}
                                                onUserChange={user => this.bookedForChanged(user)}
                                            />
                                        </div>
                                    </div>
                                }

                                {!this.state.isMyBookingsForOthers &&
                                    <div className="row">
                                        <div className="form-input-box">
                                            <label className="form-input-box-label visit-filter-label">
                                                {this.labels.HubLabelBookedBy}
                                            </label>
                                            <UserPicker
                                                width="calc(100% - 110px)"
                                                searchText={this.state.bookedBySearchText}
                                                label=""
                                                placeholder="&#8230;"
                                                alternateStyle={true}
                                                onChange={async text => this.setState({ bookedBySearchText: text, updates: true })}
                                                onUserChange={user => this.bookedByChanged(user)}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="form-input-box">
                                        <label className="form-input-box-label visit-filter-label">
                                            {this.labels.HubLabelSpace}
                                        </label>
                                        <Autocomplete
                                            freeSolo
                                            className="form-input-box-autocomplete auto-text"
                                            options={this.state.spaceOptions}
                                            getOptionLabel={i => (i as KeyValue<string, string>).value}
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Source Sans Pro",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            value={this.state.selectedSpaceOption}
                                            onChange={(e, newValue) => this.spaceOptionChanged(e, newValue as KeyValue<string, string>)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="input-box model-textbox select-box-border"
                                                    name="selectedSpaceOption"
                                                    id="selectedSpaceOption"
                                                    placeholder="&#8230;"
                                                    value={this.state.selectedSpaceOption}
                                                    onBlur={(e) =>
                                                    {
                                                        this.spaceOptionTextChanged(e);
                                                    }}
                                                    onChange={(e) =>
                                                    {
                                                        this.spaceOptionTextChanged(e);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.filterModalClosed()}
                                    color="secondary"
                                    variant="outlined"
                                    className="mr-2"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    className="ml-2"
                                    onClick={() => this.filterModalOkayed()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    
                    {/** start of cancel booking modal **/}

                    <CancelBookingsDialog
                        show={this.state.showCancelModal}
                        onClose={()=> this.setState({showCancelModal: false})}
                        updateBookings={() => this.confirmCancelManyClicked()}
                    />
                        
                    {/** end of cancel booking modal */}

                    {/** start of reject booking modal **/}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="reject booking modal"
                        onClose={() => this.setState({
                            showRejectModal: false,
                        })}
                        open={this.state.showRejectModal}
                        header={this.labels.HubLabelModalConfirmTitle}
                        fullWidth
                        dialogContent={
                            <>
                                {
                                    <p>{this.labels.HubLabelRejectAllMessage}</p>
                                }
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo color="secondary" variant="contained"
                                    className="mr-2"
                                    onClick={() => this.setState({
                                        showRejectModal: !this.state.showRejectModal,
                                    })}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo color="primary" variant="contained"
                                    className="ml-2"
                                    onClick={() => this.rejectManyClicked()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {/** end of cancel reject modal */}

                    {this.state.isLoading ? <Spinner /> :
                        <div className="page-height-exct-header">
                            <div className="rightPanel-main-content">
                                <div className="table-panel">
                                    <IbssPageHeader
                                        title={this.state.isMyBookingsForOthers ? this.labels.HubMenuBookingsForOthers : this.state.isBookingsPrimaries ? this.labels.HubLabelBookingsForPrimaries  : this.labels.HubLabelBookingsOverview}
                                        selectedPreset={this.state.daysFilter}
                                        presetChanged={(e) => this.dateRangeDropdownChanged(e.target.value)}
                                        startDate={this.state.bookingStartDate}
                                        onLayoutChange={(newLayout) => this.changeLayout(newLayout)}
                                        layout={"allList"}
                                        toggleButtonList={this.state.isOperationalServicesBookings ? layoutOptions : []}
                                        endDate={this.state.bookingEndDate}
                                        datesDisabled={this.state.dateDisabled}
                                        DEPRECATED_onDateChange={(event, value) => this.dateChanged(event, value)}
                                        presets={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                    />
                                    <Grid container rowSpacing={1} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                                        <Grid item md={7} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                                                sx={{
                                                    '& > :not(style)': { mr: 2, my: 1, alignItems: "center" },
                                                }}
                                            >
                                                {this.state.isOperationalServicesBookings && (
                                                    <IbssFormControl variant="outlined">
                                                        <IbssTextField
                                                            type="text"
                                                            size="medium"
                                                            placeholder={this.labels.HubLabelSearch}
                                                            value={this.state.searchTerm}
                                                            onChange={(event) => this.filterTextChanged(event.target.value)}
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        />
                                                    </IbssFormControl>
                                                )}
                                                {this.state.isOperationalServicesBookings &&
                                                    <>
                                                        <IbssFormControl sx={{ width: 250 }} size="small">
                                                            <IbssInputDropDown
                                                                className="py-0"
                                                                inputLabel={this.labels.HubLabelPredefinedFilter}
                                                                options={this.state.filterOptions}
                                                                value={this.state.selectedFilterId}
                                                                id={"filterSelection"}
                                                                onChange={(e: SelectChangeEvent<string>) => this.filterChanged(e.target.value)}
                                                            />
                                                        </IbssFormControl>
                                                    </>
                                                }
                                                {this.state.isBookingsPrimaries ? <>
                                                    <Box width="calc(100% + 110px)">
                                                        <IbssInputDropDown
                                                            options={this.state.primariesList}
                                                            value={this.state.selectedPrimary}
                                                            id={"ownerSelection"}
                                                            isMultiple={false}
                                                            onChange={(e: SelectChangeEvent<string>) => this.primaryChanged(e.target.value)}
                                                            fullWidth
                                                        />
                                                    </Box>
                                                </> 
                                                : <IbssButtonRedo className="mt-1" variant="contained" onClick={() => this.filterButtonClicked()}>{this.state.isOperationalServicesBookings ? this.labels.HubTabCustomFilter : this.labels.HubLabelFilter}</IbssButtonRedo>}
                                            </Box>
                                        </Grid>
                                        <Grid item md={5} sx={{ display: 'flex', justifyContent: 'right', my: 1 }}>
                                            {this.state.canExport && !(this.state.isMyBookings || this.state.isMyBookingsForOthers || this.state.isBookingsPrimaries) &&
                                                <IbssButtonRedo variant="contained" color="secondary" onClick={() => this.exportClicked()} disabled={this.state.disableExport}>{this.labels.HubButtonExport}</IbssButtonRedo>
                                            }
                                        </Grid>
                                        <Grid sm={12}>
                                            {this.state.isOperationalServicesBookings && (<Typography className="table-panel-description">{this.labels.HubLabelBookingFilterText}</Typography>)}
                                        </Grid>
                                        <Grid sm={12}>
                                           {this.state.isBookingsPrimaries || <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                                {this.state.isMyBookingsForOthers ?
                                                    <IbssActionButton buttons={otherActions} />
                                                    :
                                                    <IbssActionButton buttons={this.state.isMyBookings || this.state.isBookingsPrimaries ? checkInActions : actions} />
                                                }
                                            </Box>}
                                        </Grid>
                                    </Grid>

                                    {this.state.isCompleteLoading &&
                                        <Spinner />
                                    }
                                    <Box sx={{ mt: 1 }}>
                                        <ServerDataGrid
                                            ref={this.dataGridRef}
                                            checkboxSelection
                                            onDataQueryChange={i => this.dataQueryChanged(i)}
                                            columns={dataGridColumns}
                                            onRowSelectionModelChange={e => this.selectionChanged(e)}
                                            disableRowSelectionOnClick
                                            pageSizeOptions={[100]}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>}
                </div>
            </>
        );
    }

    private renderBookingOverAllStatus(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;
        if ((bookingStatus === "New" && bookingApproval === 0) ||
            (bookingStatus === "Amended" && bookingApproval === 0))
        {
            return <IbssChip label={this.labels.HubTabAwaitingApproval} sx={{
                backgroundColor: 'var(--ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "New" && bookingApproval === 3) ||
            (bookingStatus === "New" && bookingApproval === 4))
        {
            return <IbssChip label={this.labels.HubLabelApproved} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Early Check In" && bookingApproval === 3) ||
            (bookingStatus === "Early Check In" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "Early Check In"))
        {
            return <IbssChip label={this.labels.HublabelEarlyCheckInText} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Late Checkin" && bookingApproval === 3) ||
            (bookingStatus === "Late Checkin" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "Late Checkin"))
        {
            return <IbssChip label={this.labels.HublabelLateCheckIn} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Amended" && bookingApproval === 3) ||
            (bookingStatus === "Amended" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "Amended"))
        {
            return <IbssChip label={this.labels.HubLabelamended} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "In Progress" && bookingApproval === 3) ||
            (bookingStatus === "In Progress" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "In Progress"))
        {
            return <IbssChip label={this.labels.HubLabelInProgress} sx={{
                backgroundColor: 'var(--ui-mid-tone)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelCancelled} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (bookingStatus === "Auto Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelAutoCancelledStatus} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (bookingStatus === "Completed")
        {
            return <IbssChip label={this.labels.HubLabelCompleted} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "No Show")
        {
            return <IbssChip label={this.labels.HubLabelNoShow} sx={{
                backgroundColor: 'var(ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (this.state.isMyBookings && bookingStatus === "New")
        {
            return <IbssChip label={this.labels.HubLabelNew} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }

    private flexBookingStatus(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        if (bookingStatus === "New")
        {
            return <IbssChip label={this.labels.HubLabelNew} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Amended")
        {
            return <IbssChip label={this.labels.HubLabelamended} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Early Check In")
        {
            return <IbssChip label={this.labels.HublabelEarlyCheckInText} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Late Checkin")
        {
            return <IbssChip label={this.labels.HublabelLateCheckIn} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "In Progress")
        {
            return <IbssChip label={this.labels.HubLabelInProgress} sx={{
                backgroundColor: 'var(--ui-mid-tone)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "No Show")
        {
            return <IbssChip label={this.labels.HubLabelNoShow} sx={{
                backgroundColor: 'var(ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Auto Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelAutoCancelledStatus} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Completed")
        {
            return <IbssChip label={this.labels.HubLabelCompleted} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }

    private renderActionButton(row: BookingView, disabled: boolean, hanldeFunction: () => Promise<void>, label: string): JSX.Element
    {
        return (
            <>
                <IbssButtonRedo tabIndex={0} disabled={!disabled} onClick={() => hanldeFunction()} className="text-link">{label}</IbssButtonRedo>
                {this.state.isUpdateRight ?
                    <IbssSvgIcon tabIndex={0} className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.state.isMyBookings || this.state.isBookingsPrimaries ? this.createBookingForMeClicked(row.Booking_Id, row.Space_Id, row.Node_Id) : this.updateBookingClicked(row.Booking_Id, row.Space_Id)}>
                        <CreateIcon />
                    </IbssSvgIcon> : null
                }
            </>
        )
    }

    private renderActionButtonForOthers(row: BookingView, disabled: boolean, hanldeFunction: () => Promise<void>, label: string): JSX.Element
    {
        return (
            <>
                <IbssButtonRedo disabled={!disabled} onClick={() => hanldeFunction()} className="text-link">{label}</IbssButtonRedo>
                {this.state.isUpdateRight ?
                    <IbssSvgIcon className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.createBookingForOthersClicked(row.Booking_Id, row.Space_Id, row.Node_Id)}>
                        <CreateIcon />
                    </IbssSvgIcon> : <p>-</p>
                }
            </>
        )
    }

    private renderActionButtonFromBooking(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;
        const bookingCheckedIn = row.Booking_IsCheckedIn == 1;
        const bookingIsActive = row.Booking_IsActive == 1;
        const bookingIsCancelled = row.Booking_IsCancelled == 1;
        const bookingIsComplete = row.Booking_CompletedTime?.isValid;
        const bookingTypeIsMain = row.Parent_Booking_Relation_Type === "BookingRelationTypeMain"

        let isUserInApprovalGroup : boolean;
        if (this.state.isBookingsPrimaries)
        {
            isUserInApprovalGroup = true;
        }
        else
        {
            const approvalGroups = JSON.parse(row.approvalGroups);
            isUserInApprovalGroup = approvalGroups.some((group: string) => this.state.currentUsersRoleIds.includes(group)); 
        }
        
        if (bookingCheckedIn && bookingIsActive && !bookingIsCancelled && !bookingIsComplete)
        {
            return this.renderActionButton(row, this.state.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        } else if (bookingTypeIsMain && (bookingStatus === 'New' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 0)) 
        {
            if (this.state.isApproveRight && isUserInApprovalGroup)
            {
                return this.renderActionButton(row, this.state.isApproveRight, () => this.approveOneClicked(row), this.labels.HubLabelApprove)
            } else if (this.state.isDenyRight && isUserInApprovalGroup) 
            {
                return this.renderActionButton(row, this.state.isDenyRight, () => this.rejectOneClicked(row), this.labels.HubLabelReject)
            }
        }
        else if ((bookingStatus === 'Amended' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 3) ||
            (bookingStatus === "New" && bookingApproval === 3) || (bookingStatus === "New" && bookingApproval === 4))
        {
            return this.renderActionButton(row, this.state.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        else 
        {
            return (
                <>
                    {/* <IbssButtonRedo className="text-link">-</IbssButtonRedo> */}
                    {this.state.isUpdateRight ?
                        <IbssSvgIcon className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.updateBookingClicked(row.Booking_Id, row.Space_Id)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssSvgIcon> : null
                    }
                </>
            )
        }
    }

    private renderCheckInCheckOutActionButton(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;
        const bookingCheckedIn = row.Booking_IsCheckedIn == 1;

        if (bookingCheckedIn && (bookingStatus == "Amended" || bookingStatus == "Checked In"))
        {
            return this.renderActionButton(row, this.state.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        } else if ((bookingStatus === 'Amended' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 3))
        {
            return this.renderActionButton(row, this.state.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        else 
        {
            return (
                <>
                    {this.state.isUpdateRight ?
                        <IbssSvgIcon className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.createBookingForMeClicked(row.Booking_Id, row.Space_Id, row.Node_Id)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssSvgIcon> : null
                    }
                </>
            )
        }
    }

    private renderCancelAllActionButton(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        if ((bookingStatus === 'New') || (bookingStatus === "Amended"))
        {
            return this.renderActionButtonForOthers(row, this.state.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        if ((bookingStatus === 'In Progress') || (bookingStatus === "Early Check In"))
        {
            return this.renderActionButtonForOthers(row, this.state.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        }
        else 
        {
            return (
                <>
                    {this.state.isUpdateRight ?
                        <IbssSvgIcon className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.createBookingForOthersClicked(row.Booking_Id, row.Space_Id, row.Node_Id)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssSvgIcon> : null
                    }
                </>
            )
        }
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
    tab: string;
    filter: string;
}

interface IState
{
    getRightApiResponseErrorLoading: boolean;
    isFilter: boolean;
    isLoading: boolean;
    disableExport: boolean;
    isRefreshButtonDisabled: boolean;
    isLoadingToGetFilteredData: boolean;
    activePage: string;
    show: boolean;
    buildingid: number;
    canExport: boolean;
    isCreateRight: boolean;
    isUpdateRight: boolean;
    selectedBookingToday: IBooking[];
    selectedBookingNext: IBooking[];
    selectedBookingYesterdayAuto: IBooking[];
    selectedBookingNext7Day: IBooking[];
    selectedCustomFilterBooking: IBooking[];
    isCancel: boolean;
    isCheckIn: boolean;
    isCheckOut: boolean;
    isV2Rights: boolean;
    isApproveRight: boolean;
    isDenyRight: boolean;
    isCheckInOnBehalfOf: boolean;
    relationshipStatuses: string[];
    bookingStatuses: string[];
    userList: ILabelEmail[];
    bookedForSearchText: string;
    selectedUserBookedForObject: ILabelEmail;
    bookedBySearchText: string;
    selectedUserBookedByObject: ILabelEmail;
    spaceOptions: KeyValue<string, string>[];
    selectedSpaceOption: (KeyValue<string, string> | null);
    filterMode: boolean;
    confirmModalshow: boolean;
    isCompleteLoading: boolean;
    isToday: boolean;
    daysFilter: string;
    bookingStartDate: Date;
    bookingEndDate: Date;
    dateDisabled: boolean;
    rowSelectionModel: BookingView[];
    cancelButtonDisabled: boolean;
    approveButtonEnabled: boolean;
    rejectButtonEnabled: boolean;
    tableMessage: string;
    updates: boolean;
    checkInButtonEnabled: boolean;
    checkOutButtonEnabled: boolean;
    cancelAllRowsEnable: boolean;
    showCancelModal: boolean;
    showRejectModal: boolean;
    modalStatus: string;
    isMyBookings: boolean;
    isMyBookingsForOthers: boolean;
    searchTerm: string;
    isOperationalServicesBookings: boolean;
    page: PagedResponse<GetV2BookingsEndpoint.Booking[]> | PagedResponse<GetMyV2BookingsForOthersEndpoint.Booking[]> | PagedResponse<MyBooking[]>;
    searchTermChanged: boolean;
    filterOptions: InputOption[],
    selectedFilterId: string;
    filterQuery: string | null;
    showViewBookingSeriesModal: boolean;
    selectedBookingSeries: { seriesId: string, nodeId: number, bookingId: string, spaceId: string };
    relationStatusList : InputOption[];
    currentUsersRoleIds: string[];
    selectedBookingZone: string;
    zoneOptions: InputOption[];
    isBookingsPrimaries: boolean;
    primariesList: InputOption[];
    selectedPrimary: string;
}

interface IBooking
{
    id: number;
    status: string;
    spaceId: string;
    ownerEmail: string;
}

interface ILabelEmail
{
    label?: string;
    email: string;
}

interface IFilterObject
{
    buildingId: number;
    statusValue: string;
    bookedFor: string;
    bookedBy: string;
}

interface IUserDetails
{
    label?: string;
    companyName: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    oid: string;
    pin: string;
    userName: string;
}

export interface CustomRouteState 
{
    status: string;
}

class BookingView
{
    public id = "";
    public Building_Name = "";
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Start = "";
    public Booking_Early_Checkin = "";
    public Booking_End = "";
    public Booking_Status = "";
    public Booking_IsActive = 0;
    public Booking_IsApproved = 0;
    public Booking_IsCheckedIn?= 0;
    public Booking_IsCancelled?= 0;
    public Booking_CompletedTime?= DateHelper.null();
    public Booking_Owner_Email = "";
    public Booking_Owner_Name = "";
    public _CreatedAt = "";
    public _CreatedBy = "";
    public BookingSeriesId = "";
    public Parent_Booking_Relation_Type = "";
    public locationZone = "";
    public approvalGroups = "";

    public static fromMyBookingsResponse(booking: MyBooking, bookingOwnerEmail: string, defaultBookingOwnerName: string): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: booking.Booking_Early_Checkin.toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: 1,
            Booking_IsApproved: 4,
            Booking_IsCheckedIn: booking.Booking_Participant_CheckedIn,
            Booking_Owner_Email: bookingOwnerEmail,
            Booking_Owner_Name: (booking.Booking_Owner_Name ? booking.Booking_Owner_Name : defaultBookingOwnerName),
            _CreatedAt: booking._CreatedAt.toISO(),
            _CreatedBy: booking.CreatedBy,
            Parent_Booking_Relation_Type: booking.Parent_Booking_Relation_Type,
            BookingSeriesId: booking.Booking_Series_Id,
            locationZone: '',
            approvalGroups:'',
        };
    }

    public static fromMyBookingsForOthersResponse(booking: GetMyV2BookingsForOthersEndpoint.Booking, bookingOwnerEmail: string): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: booking.Booking_Early_Checkin.toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: 1,
            Booking_IsApproved: 4,
            Booking_IsCheckedIn: booking.Booking_IsCheckedIn,
            Booking_Owner_Email: bookingOwnerEmail,
            Booking_Owner_Name: booking.Booking_Owner_Name,
            _CreatedAt: booking._CreatedAt.toISO(),
            _CreatedBy: booking._CreatedBy,
            Parent_Booking_Relation_Type: booking.Parent_Booking_Relation_Type,
            BookingSeriesId: booking.Booking_Series_Id,
            locationZone: '',
            approvalGroups:'',
        };
    }

    public static fromBookingsResponse(booking: Booking): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: DateHelper.fromIsoByNode(booking.Booking_Early_Checkin as unknown as string, booking.Node_Id).toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: booking.Booking_IsActive,
            Booking_IsCancelled: booking.Booking_IsCancelled,
            Booking_CompletedTime: DateHelper.fromIsoByNode(booking.Booking_Completed_Time as unknown as string, booking.Node_Id),
            Booking_IsApproved: booking.Booking_IsApproved,
            Booking_IsCheckedIn: booking.Booking_IsCheckedIn,
            Booking_Owner_Email: booking.Booking_Owner_Email,
            Booking_Owner_Name: booking.Booking_Owner_Name,
            _CreatedAt: DateHelper.fromIsoByNode(booking._CreatedAt as unknown as string, booking.Node_Id).toISO(),
            _CreatedBy: booking._CreatedBy,
            Parent_Booking_Relation_Type: booking.Parent_Booking_Relation_Type,
            BookingSeriesId: booking.Booking_Series_Id,
            locationZone: booking.Meta_Loc_Zone,
            approvalGroups: booking.Approval_Groups,
        };
    }
}

export default connect(mapStateToProps)(ListBookings);
