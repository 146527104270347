import { DateTime } from "luxon";
import { IPartialAppState, appContext } from "../../../../AppContext";
import { DateHelper } from "../../../../Common/DateHelper";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { BuildingChangeReason } from "../../../../Components/Core/BasePage/Types";
import { VisitsDailySummary } from "./DataModels";
import IbssPageHeader from "../../../../Components/Forms/DateRange/IbssPageHeader";
import { CardContent, Grid, SelectChangeEvent } from "@mui/material";
import { RouteHelper } from "../../../../Common/RouteHelper";
import { RouteComponentProps } from "react-router";
import { KpiDataCard } from "./KpiDataCard";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay";
import { WeekdayTrendForPeriod } from "./WeekdayTrendForPeriod";
import { KpiDataForPeriod } from "./KpiDataForPeriod";
import { VisitorBreakdownByDay } from "./VisitorBreakdownByDay";
import { VisitorBreakdownByTime } from "./VisitorBreakdownByTime";
import { VisitAnalyiticsForPeriod } from "./VisitAnalyiticsForPeriod";
import { Card } from "./Card";
import { ApiConstants } from "../../../../Providers.Api/ApiConstants";

export class ViewAnalytics extends IbssPage<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private routeHelper = new RouteHelper();
    private customDateRangePreset = this.labels.HubLabelCustom;
    private defaultDateRangePreset = this.labels.funcLastMonth_S;

    private get defaultFrom(): DateTime
    {
        return DateHelper.today(this.buildingId).startOf('month').minus({ months: 1 });
    }

    private get defaultTo(): DateTime
    {
        return DateHelper.today(this.buildingId).startOf('month').minus({ days: 1 });
    }

    private get buildingId(): number
    {
        return this.routeHelper.getQueryParamAsNumber("node") || 1;
    }

    private get dateRange(): string
    {
        return this.routeHelper.getQueryParamAsString("daterange") || this.defaultDateRangePreset;
    }

    private get from(): DateTime
    {
        const value = this.routeHelper.getQueryParamAsDateTime("from");
        const preset = this.state.dateRanges.find(i => this.normaliseString(i.name) == this.normaliseString(this.dateRange)) ?? null;
        const isCustom = this.dateRange == this.normaliseString(this.customDateRangePreset);

        if (isCustom && value && !value.isNull())
        {
            return value;
        }
        else if (!isCustom && preset)
        {
            return preset.from;
        }
        else
        {
            return this.defaultFrom;
        }
    }

    private get to(): DateTime
    {
        const value = this.routeHelper.getQueryParamAsDateTime("to");
        const preset = this.state.dateRanges.find(i => this.normaliseString(i.name) == this.normaliseString(this.dateRange)) ?? null;
        const isCustom = this.dateRange == this.normaliseString(this.customDateRangePreset);

        if (isCustom && value && !value.isNull())
        {
            return value;
        }
        else if (!isCustom && preset)
        {
            return preset.to;
        }
        else
        {
            return this.defaultTo;
        }
    }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            isLoading: true,
            dateRanges: [],
            summaries: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.HubMenuAnalytics;
        this.appState.subscribe(this, e => this.appStateChanged(e), false);
        this.props.history.listen(() => this.historyChanged());
        await this.init();
    }

    private normaliseString(value: string): string
    {
        return value.toLocaleLowerCase().replace(/\W/g, "");
    }

    private pushToHistory(buildingId: number, dateRange: string, from: DateTime, to: DateTime): void
    {
        const normalisedDateRange = this.normaliseString(dateRange);
        let url = `/onelens/visits/analytics?node=${buildingId}&daterange=${normalisedDateRange}`;

        if (normalisedDateRange == this.normaliseString(this.customDateRangePreset))
        {
            url += `&from=${from.toUrl()}&to=${to.toUrl()}`;
        }
        this.props.history.push(url);
    }

    private async appStateChanged(appState: IPartialAppState): Promise<void>
    {
        if (appState.buildingId && appState.buildingId != this.buildingId)
        {
            this.pushToHistory(appState.buildingId, this.dateRange, this.from, this.to);
        }
    }

    private async historyChanged(): Promise<void>
    {
        await this.init();
    }

    private async init(): Promise<void>
    {
        await this.loadDateRanges();
        await this.loadSummaries();
        await this.appState.set({ buildingId: this.buildingId });
    }

    private async loadDateRanges(): Promise<void>
    {
        const today = DateHelper.today(this.buildingId);

        await this.setStateAsync({
            dateRanges: [
                { name: this.labels.funcWeekToDate_S, from: today.startOf('week'), to: today },
                { name: this.labels.funcMonthToDate_S, from: today.startOf('month'), to: today },
                { name: this.labels.funcQuarterToDate_S, from: today.startOf('quarter'), to: today },
                { name: this.labels.funcYearToDate_S, from: today.startOf('year'), to: today },
                { name: this.labels.funcLastWeek_S, from: today.startOf('week').minus({ weeks: 1 }), to: today.startOf('week').minus({ days: 1 }) },
                { name: this.labels.funcLastMonth_S, from: today.startOf('month').minus({ months: 1 }), to: today.startOf('month').minus({ days: 1 }) },
                { name: this.labels.funcLastQuarter_S, from: today.startOf('quarter').minus({ quarters: 1 }), to: today.startOf('quarter').minus({ days: 1 }) },
            ],
        });
    }

    private async loadSummaries(): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            const from = this.from.minus({ month: 3 }).toUtcByNode(this.buildingId).toISO(); // get previous 3 months for rolling average
            const to = this.to.plus({ days: 1 }).toUtcByNode(this.buildingId).toISO();

            const summaries = await appContext().ibssApiClientV1.v1.byNodeid.visitsDailySummary.get<VisitsDailySummary[]>({
                nodeId: this.buildingId,
                select: VisitsDailySummary,
                filter: `Node_Id eq ${this.buildingId} and Summary_Date ge datetime'${from}' and Summary_Date lt datetime'${to}'`,
                top: ApiConstants.largeODataTop,
            });

            await this.setStateAsync({ summaries: summaries });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async dateRangeChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        const dateRange = this.state.dateRanges.find(i => i.name == e.target.value);
        if (!dateRange)
        {
            this.pushToHistory(this.buildingId, this.customDateRangePreset, this.from, this.to);
            return;
        }
        this.pushToHistory(this.buildingId, dateRange.name, dateRange.from, dateRange.to);
    }

    private async startDateChanged(value: DateTime): Promise<void>
    {
        this.pushToHistory(this.buildingId, this.customDateRangePreset, value, this.to);
    }

    private async endDateChanged(value: DateTime): Promise<void>
    {
        this.pushToHistory(this.buildingId, this.customDateRangePreset, this.from, value);
    }

    public render(): JSX.Element
    {
        const state = this.state;
        const dateRangePresets = state.dateRanges.map(i => i.name);
        const isCustomDateRange = (this.normaliseString(this.dateRange) == this.normaliseString(this.customDateRangePreset));
        const selectedDateRangePreset = state.dateRanges.find(i => this.normaliseString(i.name) == this.normaliseString(this.dateRange)) ?? null;
        const selectedDateRangePresetName = (selectedDateRangePreset ? selectedDateRangePreset.name : (isCustomDateRange ? this.customDateRangePreset : this.defaultDateRangePreset));
        const summariesForPeriod = state.summaries.filter(i => i.Summary_Date >= this.from && i.Summary_Date < this.to.plus({ days: 1 }));

        return (
            <>
                {state.isLoading && <LoadingOverlay />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <Grid container direction="column" spacing={2}>
                            <Grid item height={110}>
                                <div className="table-panel">
                                    <IbssPageHeader
                                        title={this.labels.funcVisitorData_S}
                                        excludeToday={true}
                                        presetsLabel={this.labels.funcDateRange_S}
                                        presets={dateRangePresets}
                                        selectedPreset={selectedDateRangePresetName}
                                        presetChanged={e => this.dateRangeChanged(e)}
                                        startDate={this.from.toJSDate()}
                                        onStartDateChange={e => this.startDateChanged(e)}
                                        endDate={this.to.toJSDate()}
                                        onEndDateChange={e => this.endDateChanged(e)}
                                        datesDisabled={!isCustomDateRange}
                                    />
                                </div>
                            </Grid>
                            <Grid item>
                                <Card title={this.labels.funcKpiDataForPeriod_S}>
                                    <KpiDataForPeriod summaries={state.summaries} from={this.from} to={this.to} />
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card title={this.labels.funcWeekdayTrendForPeriod_S}>
                                    <WeekdayTrendForPeriod summaries={summariesForPeriod} from={this.from} to={this.to} />
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card title={this.labels.funcVisitorBreakdownByDay_S}>
                                    <VisitorBreakdownByDay summaries={summariesForPeriod} from={this.from} to={this.to} />
                                </Card>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Card title={this.labels.funcVisitorBreakdownByArrivalTimeVsDepartureTime_S}>*/}
                            {/*        <VisitorBreakdownByTime timeType='arrival' summaries={summariesForPeriod} from={this.from} to={this.to} />*/}
                            {/*        <VisitorBreakdownByTime timeType='departure' summaries={summariesForPeriod} from={this.from} to={this.to} />*/}
                            {/*    </Card>*/}
                            {/*</Grid>*/}
                            <Grid item>
                                <Card title={this.labels.funcVisitAnalyticsForPeriod_S}>
                                    <VisitAnalyiticsForPeriod summaries={summariesForPeriod} from={this.from} to={this.to} />
                                </Card>
                            </Grid>
                        </Grid >
                    </div >
                </div >
            </>
        );
    }
}

export interface IProps extends RouteComponentProps
{
}

export interface IState
{
    isLoading: boolean;
    dateRanges: IDateRange[];
    summaries: VisitsDailySummary[];
}

export interface IDateRange
{
    name: string;
    from: DateTime;
    to: DateTime;
}
