import React, { Component } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, DialogTitleProps, DialogContentProps, DialogActionsProps, Breakpoint, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import IbssIconButton from '../../Buttons/IconButton/IbssIconButton';
import './Styles.scss';
import ErrorImage from './Error.svg';

export default class IbssDialog extends Component<IProps>
{
    public render(): JSX.Element
    {
        const props = this.props;
        const styleName = props.styleName ?? 'default';

        return (
            <Dialog maxWidth={props.maxWidth} {...props} className={`ibss-dialog--${styleName}`}>
                <Grid container direction="row">
                    <Grid
                        item
                        className="ibss-dialog__icon"
                    >
                        <img src={ErrorImage} />
                    </Grid>
                    <Grid
                        item
                        className="ibss-dialog__main"
                        flexGrow={1}
                    >
                        {
                            props.header &&
                            <Box className="ibss-dialog__header">
                                {props.header}
                                {props.onClose &&
                                    <IbssIconButton aria-label="close" onClick={props.onClose}>
                                        <CloseIcon />
                                    </IbssIconButton>
                                }
                            </Box>
                        }
                        <Box className="ibss-dialog__content">
                            {props.dialogContent}
                        </Box>
                        {
                            props.footer &&
                            <Box className="ibss-dialog__footer">
                                {props.footer}
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

export interface IProps extends DialogProps
{
    styleName?: DialogStyle;
    header?: React.ReactNode;
    dialogContent: React.ReactNode;
    footer?: React.ReactNode;
    maxWidth?: Breakpoint;
    onClose?: () => void;
}

export type DialogStyle = 'default' | 'error';
