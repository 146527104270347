import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { IGetV2BookingResponse, IGetV2BookingEndpoint, GetV2BookingEndpoint } from "./GetV2BookingEndpoint";
import { IGetV1BookingResponse, IGetV1BookingEndpoint } from "./GetV1BookingEndpoint";
import * as GetBookings from "./GetBookingsEndpoint";
import * as GetV2Bookings from "./GetV2BookingsEndpoint";
import * as GetV1BookingsByEmail from "./GetV1BookingsByEmailEndpoint";
import * as GetMyBookingsForOthers from "./GetMyBookingsForOthersEndpoint";
import * as GetMyV2BookingsForOthers from "./GetMyV2BookingsForOthersEndpoint";
import { IApproveBookingEndpoint } from "./ApproveBookingEndpoint";
import { IRejectBookingEndpoint } from "./RejectBookingEndpoint";
import { ICreateBookingResponse, ICreateV1BookingEndpoint, ICreateV1BookingRequest } from "./CreateV1BookingEndpoint";
import { ICreateV2Booking_BookingParty, ICreateV2Booking_CostCodeAllocation, ICreateV2BookingEndpoint, ICreateV2BookingRequest, ICreateV2BookingResponse } from "./CreateV2BookingEndpoint";
import { IDeleteBookingResponse, IDeleteV1BookingEndpoint } from "./DeleteV1BookingEndpoint";
import { IDeleteV2BookingEndpoint } from "./DeleteV2BookingEndpoint";
import { IUpdateBookingCostCodesEndpoint, IUpdateCostCodeAllocation } from "./UpdateBookingCostCodesEndpoint";
import { IUpdateBookingResponse, IUpdateV1BookingEndpoint, IUpdateV1BookingRequest } from "./UpdateV1BookingEndpoint";
import { IUpdateV2BookingEndpoint, IUpdateV2BookingRequest } from "./UpdateV2BookingEndpoint";
import { IPatchV2BookingEndpoint, IPatchV2BookingRequest, IPatchV2BookingResponse } from "./PatchV2BookingEndpoint";
import { IDownloadV1BookingEndpoint } from "./DownloadV1BookingEndpoint";
import { IDownloadV2BookingEndpoint } from "./DownloadV2BookingEndpoint";
import { PagedResponse } from "../Models";
import { ODataQuery } from "../ODataQuery";
import { IFilter } from "../ODataFilter";

export class BookingRepository implements IBookingRepository
{
    private getV2BookingEndpoint: IGetV2BookingEndpoint;
    private getV1BookingEndpoint: IGetV1BookingEndpoint;
    private getBookingsEndpoint: GetBookings.IGetBookingsEndpoint;
    private getV2BookingsEndpoint: GetV2Bookings.IGetV2BookingsEndpoint;
    private getV1BookingsByEmailEndpoint: GetV1BookingsByEmail.IGetV1BookingsByEmailEndpoint;
    private getMyBookingsForOthersEndpoint: GetMyBookingsForOthers.IGetMyBookingsForOthersEndpoint;
    private getMyBookingsForOthersV2Endpoint: GetMyV2BookingsForOthers.IGetMyV2BookingsForOthersEndpoint;
    private approveBookingEndpoint: IApproveBookingEndpoint;
    private rejectBookingEndpoint: IRejectBookingEndpoint;
    private createV1BookingEndpoint: ICreateV1BookingEndpoint;
    private createV2BookingEndpoint: ICreateV2BookingEndpoint;
    private deleteV1BookingEndpoint: IDeleteV1BookingEndpoint;
    private deleteV2BookingEndpoint: IDeleteV2BookingEndpoint;
    private updateBookingCostCodesEndpoint: IUpdateBookingCostCodesEndpoint;
    private updateV1BookingEndpoint: IUpdateV1BookingEndpoint;
    private updateV2BookingEndpoint: IUpdateV2BookingEndpoint;
    private patchV2BookingEndpoint: IPatchV2BookingEndpoint; // no patch for v1
    private downloadV1BookingEndpoint: IDownloadV1BookingEndpoint;
    private downloadV2BookingEndpoint: IDownloadV2BookingEndpoint;

    constructor(
        getV2BookingEndpoint: IGetV2BookingEndpoint,
        getV1BookingEndpoint: IGetV1BookingEndpoint,
        getBookingsEndpoint: GetBookings.IGetBookingsEndpoint,
        getV2BookingsEndpoint: GetV2Bookings.IGetV2BookingsEndpoint,
        getV1BookingsByEmailEndpoint: GetV1BookingsByEmail.IGetV1BookingsByEmailEndpoint,
        getMyBookingsForOthersEndpoint: GetMyBookingsForOthers.IGetMyBookingsForOthersEndpoint,
        getMyBookingsForOthersV2Endpoint: GetMyV2BookingsForOthers.IGetMyV2BookingsForOthersEndpoint,
        approveBookingEndpoint: IApproveBookingEndpoint,
        rejectBookingEndpoint: IRejectBookingEndpoint,
        createV1BookingEndpoint: ICreateV1BookingEndpoint,
        createV2BookingEndpoint: ICreateV2BookingEndpoint,
        deleteV1BookingEndpoint: IDeleteV1BookingEndpoint,
        deleteV2BookingEndpoint: IDeleteV2BookingEndpoint,
        updateBookingCostCodesEndpoint: IUpdateBookingCostCodesEndpoint,
        updateV1BookingEndpoint: IUpdateV1BookingEndpoint,
        updateV2BookingEndpoint: IUpdateV2BookingEndpoint,
        patchV2BookingEndpoint: IPatchV2BookingEndpoint,
        downloadV1BookingEndpoint: IDownloadV1BookingEndpoint,
        downloadV2BookingEndpoint: IDownloadV2BookingEndpoint
    )
    {
        this.getV2BookingEndpoint = getV2BookingEndpoint;
        this.getV1BookingEndpoint = getV1BookingEndpoint;
        this.getBookingsEndpoint = getBookingsEndpoint;
        this.getV2BookingsEndpoint = getV2BookingsEndpoint;
        this.getV1BookingsByEmailEndpoint = getV1BookingsByEmailEndpoint;
        this.getMyBookingsForOthersEndpoint = getMyBookingsForOthersEndpoint;
        this.getMyBookingsForOthersV2Endpoint = getMyBookingsForOthersV2Endpoint;
        this.approveBookingEndpoint = approveBookingEndpoint;
        this.rejectBookingEndpoint = rejectBookingEndpoint;
        this.createV1BookingEndpoint = createV1BookingEndpoint;
        this.createV2BookingEndpoint = createV2BookingEndpoint;
        this.deleteV1BookingEndpoint = deleteV1BookingEndpoint;
        this.deleteV2BookingEndpoint = deleteV2BookingEndpoint;
        this.updateBookingCostCodesEndpoint = updateBookingCostCodesEndpoint;
        this.updateV1BookingEndpoint = updateV1BookingEndpoint;
        this.updateV2BookingEndpoint = updateV2BookingEndpoint;
        this.patchV2BookingEndpoint = patchV2BookingEndpoint;
        this.downloadV1BookingEndpoint = downloadV1BookingEndpoint;
        this.downloadV2BookingEndpoint = downloadV2BookingEndpoint;
    }

    public getV1Booking(nodeId: number, bookingId: string): Promise<IGetV1BookingResponse>
    {
        return this.getV1BookingEndpoint.execute(nodeId, bookingId);
    }

    public getV2Booking(nodeId: number, bookingId: string): Promise<IGetV2BookingResponse>
    {
        return this.getV2BookingEndpoint.execute(nodeId, bookingId);
    }

    public getBookings(nodeId: number, top: number, startTime?: DateTime, endTime?: DateTime, filter?: { spaceId?: string, statusNot?: string[] }): Promise<GetBookings.IBooking[]>
    {
        return this.getBookingsEndpoint.execute(nodeId, top, startTime, endTime, filter);
    }

    public getV2Bookings(query: ODataQuery, startTime?: DateTime, endTime?: DateTime, spaceIds?: Array<string>): Promise<PagedResponse<GetV2Bookings.Booking[]>>
    {
        return this.getV2BookingsEndpoint.execute(query,startTime, endTime, spaceIds);
    }

    public getV1BookingsByEmail(email: string, start: DateTime, end: DateTime, filter: BookingFilter): Promise<GetV1BookingsByEmail.IBooking[]>
    {
        return this.getV1BookingsByEmailEndpoint.execute(email, start, end, filter);
    }

    public getMyBookingsForOthers(email: string, start: DateTime, end: DateTime): Promise<GetMyBookingsForOthers.IBooking[]>
    {
        return this.getMyBookingsForOthersEndpoint.execute(email, start, end);
    }
    public getMyV2BookingsForOthers(query: ODataQuery, email: string, start: DateTime, end: DateTime): Promise<PagedResponse<GetMyV2BookingsForOthers.Booking[]>>
    {
        return this.getMyBookingsForOthersV2Endpoint.execute(query, email, start, end);
    }

    public approveBooking(nodeId: number, bookingId: string): Promise<string>
    {
        return this.approveBookingEndpoint.execute(nodeId, bookingId);
    }

    public rejectBooking(nodeId: number, bookingId: string): Promise<string>
    {
        return this.rejectBookingEndpoint.execute(nodeId, bookingId);
    }

    public createV1Booking(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>
    {
        return this.createV1BookingEndpoint.execute(nodeId, booking, suppressErrorPopup);
    }

    public createV2Booking(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup: boolean): Promise<ICreateV2BookingResponse>
    {
        return this.createV2BookingEndpoint.execute(nodeId, booking, suppressErrorPopup);
    }

    public deleteV1Booking(buildingId: number, bookingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>
    {
        return this.deleteV1BookingEndpoint.execute(buildingId, bookingId, suppressErrorPopup);
    }

    public deleteV2Booking(buildingId: number, bookingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>
    {
        return this.deleteV2BookingEndpoint.execute(buildingId, bookingId, suppressErrorPopup);
    }

    public updateBookingCostCodes(nodeId: number, bookingId: string, data: IUpdateCostCodeAllocation): Promise<string>
    {
        return this.updateBookingCostCodesEndpoint.execute(nodeId, bookingId, data);
    }

    public updateV1Booking(nodeId: number, bookingId: string, booking: IUpdateV1BookingRequest): Promise<IUpdateBookingResponse>
    {
        return this.updateV1BookingEndpoint.execute(nodeId, bookingId, booking);
    }

    public updateV2Booking(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateBookingResponse>
    {
        return this.updateV2BookingEndpoint.execute(nodeId, bookingId, booking);
    }

    public patchV2Booking(nodeId: number, bookingId: string, booking: IPatchV2BookingRequest): Promise<IPatchV2BookingResponse>
    {
        return this.patchV2BookingEndpoint.execute(nodeId, bookingId, booking);
    }

    public downloadV1Booking(buildingId: number, filter: BookingFilter2): Promise<Blob>
    {
        return this.downloadV1BookingEndpoint.execute(buildingId, filter);
    }

    public downloadV2Booking(buildingId: number, filter: BookingFilter2): Promise<Blob>
    {
        return this.downloadV2BookingEndpoint.execute(buildingId, filter);
    }

}

export interface IBookingRepository
{
    getV1Booking(nodeId: number, bookingId: string): Promise<IGetV1BookingResponse>;
    getV2Booking(nodeId: number, bookingId: string): Promise<IGetV2BookingResponse>;
    getBookings(nodeId: number, top: number, startTime?: DateTime, endTime?: DateTime, filter?: { spaceId?: string, statusNot?: string[] }): Promise<GetBookings.IBooking[]>;
    getV2Bookings(query: ODataQuery, startTime?: DateTime, endTime?: DateTime, spaceIds?: Array<string>): Promise<PagedResponse<GetV2Bookings.Booking[]>>;
    getV1BookingsByEmail(email: string, start: DateTime, end: DateTime, filter: BookingFilter): Promise<GetV1BookingsByEmail.IBooking[]>
    getMyBookingsForOthers(email: string, start: DateTime, end: DateTime): Promise<GetMyBookingsForOthers.IBooking[]>;
    getMyV2BookingsForOthers(query: ODataQuery, email: string, start: DateTime, end: DateTime): Promise<PagedResponse<GetMyV2BookingsForOthers.Booking[]>>;
    approveBooking(nodeId: number, bookingId: string): Promise<string>;
    rejectBooking(nodeId: number, bookingId: string): Promise<string>;
    createV1Booking(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>;
    createV2Booking(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup: boolean): Promise<ICreateV2BookingResponse>;
    deleteV1Booking(nodeId: number, buildingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>;
    deleteV2Booking(nodeId: number, buildingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>;
    updateBookingCostCodes(nodeId: number, bookingId: string, data: IUpdateCostCodeAllocation): Promise<string>;
    updateV1Booking(nodeId: number, bookingId: string, booking: IUpdateV1BookingRequest): Promise<IUpdateBookingResponse>;
    updateV2Booking(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateBookingResponse>;
    patchV2Booking(nodeId: number, bookingId: string, booking: IPatchV2BookingRequest): Promise<IPatchV2BookingResponse>;
    downloadV1Booking(buildingId: number, filter: BookingFilter2): Promise<Blob>;
    downloadV2Booking(buildingId: number, filter: BookingFilter2): Promise<Blob>;
}

export class BookingFilter implements IFilter
{
    public baseFilter: (IFilter | null) = null;
    public startDate = DateHelper.null();
    public endDate = DateHelper.null();
    public bookingStatus = "";
    public bookingStatusNot = new Array<string>();
    public bookingOwnerEmail = "";
    public createdBy = "";
    public spaceName = "";

    constructor(value: Partial<BookingFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string>();
        filterParts.push(this.baseFilter ? this.baseFilter.toODataString() : "");
        filterParts.push(this.startDate.isValid ? `Booking_Start ge datetime'${this.startDate.toUTC().toISO()}'` : "");
        filterParts.push(this.endDate.isValid ? `Booking_End lt datetime'${this.endDate.toUTC().toISO()}'` : "");
        filterParts.push(this.bookingStatus ? `Booking_Status eq '${this.bookingStatus}'` : "");
        this.bookingStatusNot.forEach(i => filterParts.push(`Booking_Status ne '${i}'`));
        filterParts.push(this.bookingOwnerEmail ? `Booking_Owner_Email eq '${this.bookingOwnerEmail.toLocaleLowerCase()}'` : "");
        filterParts.push(this.createdBy ? `CreatedBy eq '${this.createdBy.toLocaleLowerCase()}'` : "");
        filterParts.push(this.spaceName ? `Space_Name eq '${this.spaceName}'` : "");
        return filterParts.filter(i => i !== "").join(" and ");
    }

}

export class BookingFilter2 implements IFilter
{
    public baseFilter: (IFilter | null) = null;
    public startDate = DateHelper.null();
    public endDate = DateHelper.null();
    public statuses?: string[];
    public relationships?: string[];
    public bookingOwnerEmail?: string;
    public createdBy?: string;
    public spaceName = "";

    constructor(value: Partial<BookingFilter2>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts: string[] = [];

		let statusFilter: string[] = [];
		let relationFilter: string[] = [];
		this.statuses?.forEach(value =>
        {
            switch (value)
            {
                case 'Approved':
                    statusFilter.push(`((Booking_Status eq 'New' and (Booking_IsApproved eq 3 or Booking_IsApproved eq 4)) or (Booking_Status eq 'Amended' and (Booking_IsApproved eq 3 or Booking_IsApproved eq 4)))`);
                    break;
                case 'Awaiting Approval':
                    statusFilter.push(`(Booking_Status eq 'New' and Booking_IsApproved eq 0) or (Booking_Status eq 'Amended' and Booking_IsApproved eq 0)`);
                    break;
                default:
                    statusFilter.push(`(Booking_Status eq '${value}')`);
            }
		});

        this.relationships?.forEach(value =>
        {
            relationFilter.push(`(Parent_Booking_Relation_Type eq '${value}')`);
        })

        const filterValue = statusFilter.join(" or ");
        const relationValue = relationFilter.join(" or ");
        filterParts.push(this.baseFilter ? this.baseFilter.toODataString() : "");
        filterParts.push(this.startDate.isValid ? `Booking_Start ge datetime'${this.startDate.toUTC().toISO()}'` : "");
        filterParts.push(this.endDate.isValid ? `Booking_End lt datetime'${this.endDate.toUTC().toISO()}'` : "");
        filterParts.push(this.statuses && this.statuses.length > 0 ? `(${filterValue})` : "");
        filterParts.push(this.relationships && this.relationships.length > 0 ? `(${relationValue})` : "");
        filterParts.push(this.bookingOwnerEmail == null || this.bookingOwnerEmail == '' ? "" : `Booking_Owner_Email eq '${this.bookingOwnerEmail.toLocaleLowerCase()}'`);
        filterParts.push(this.createdBy == null || this.createdBy == '' ? "" : `CreatedBy eq '${this.createdBy.toLocaleLowerCase()}'`);
        filterParts.push(this.spaceName ? `Space_Name eq '${this.spaceName}'` : "");
        return filterParts.filter(i => i != "").join(" and ");
    }

}

export type BookingStatus = ("" | "Cancelled" | "No Show" | "Auto Cancelled" | "Completed" | "Amended" | "New" | "Checked In"  | "Late Checkin" | "Early Checkin" | "In Progress" | "Active");
