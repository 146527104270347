import React, { Component } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, InputLabel, Typography } from '@mui/material';
import IbssDatePicker from '../../Inputs/DatePicker/IbssDatePicker';
import { appContext } from "../../../AppContext";
import { DateTime } from 'luxon';
import { DateHelper } from '../../../Common/DateHelper';
import IbssToggleButton, { toggleButtonListObject } from '../../Inputs/ToggleButtons/IbssToggleButtons';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';


class IbssPageHeader extends Component<IPageHeaderProps>
{
    private get labels() { return appContext().labels; }

    private startDateChanged(date: Date | null): void
    {
        const dateTime = (date == null ? DateHelper.null() : DateTime.fromJSDate(new Date(date as Date)));
        this.props.DEPRECATED_onDateChange?.(date as Date, StartOrEnd.Start);
        this.props.onStartDateChange?.(dateTime);
    }

    private endDateChanged(date: Date | null): void
    {
        const dateTime = (date == null ? DateHelper.null() : DateTime.fromJSDate(new Date(date as Date)));
        this.props.DEPRECATED_onDateChange?.(date as Date, StartOrEnd.End);
        this.props.onEndDateChange?.(dateTime);
    }

    private singleDateFilterValueChanged(date: Date | null): void
    {
        const dateTime = (date == null ? DateHelper.null() : DateTime.fromJSDate(new Date(date as Date)));
        this.props.onSingleDateChange?.(dateTime);
    }

    public render(): JSX.Element
    {
        const hasShowFilterFields = this.props.toggleButtonList?.some(item => item.value === this.props.layout && item.showFilterFields);
        const endDate = this.props.endDate ? new Date(this.props.endDate) : null;
        const startDate = this.props.startDate ? new Date(this.props.startDate) : null;
        const toDateFieldError = endDate && startDate ? endDate < startDate : false;
        return (
            <>
                <Grid container rowSpacing={0} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                    <Grid item lg={4} sx={{ pt: 0 }} >
                        <Box className="table-panel-header" component="div" sx={{ ml: '0', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "100%" }}>
                            {hasShowFilterFields ?
                                <Box sx={{ display: "flex", gap: "20px" }}>
                                    <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                                        {Icons.restaurant}
                                    </IbssSvgIcon>
                                    <Typography variant='h5'>{this.props.title}</Typography>
                                </Box>
                                :
                                <Typography variant='h5'>{this.props.title}</Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item lg={8} sx={{ display: 'flex', justifyContent: 'right', pt: 0 }}>
                        <Box
                            sx={{
                                '& > :not(style)': { ml: 1, my: 1, width: '20ch' },
                            }}
                        >
                            {!hasShowFilterFields && !this.props.showSingleDate &&
                                <>
                                    <FormControl>
                                        {
                                            this.props.presetsLabel &&
                                            <InputLabel id="demo-simple-select-label">{this.props.presetsLabel}</InputLabel>
                                        }
                                        <Select
                                            id="demo-simple-select"
                                            value={this.props.selectedPreset}
                                            size="small"
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            onChange={(e: SelectChangeEvent<string>) => this.props.presetChanged ? this.props.presetChanged(e) : null}
                                            label={this.props.presetsLabel}
                                            sx={this.props.presetsLabel ? null : { '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        >
                                            {
                                                (this.props.excludeToday == undefined || this.props.excludeToday == false) &&
                                                <MenuItem value={this.labels.HubLabelToday}>{this.labels.HubLabelToday}</MenuItem>
                                            }
                                            {
                                                this.props.presets && this.props.presets?.length > 0 &&
                                                this.props.presets.map(label => { return (<MenuItem value={label}>{label}</MenuItem>) })
                                            }
                                            {
                                                (this.props.excludeCustom == undefined || this.props.excludeCustom == false) &&
                                                <MenuItem value={this.labels.HubLabelCustom}>{this.labels.HubLabelCustom}</MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                    <IbssDatePicker
                                        value={this.props.startDate ? DateTime.fromJSDate(this.props.startDate) : DateTime.now()}
                                        onChange={event => event ? this.startDateChanged(event?.toJSDate()) : {}}
                                        label={this.labels.HubLabelFromDate}
                                        slotProps={{ textField: { size: 'small' } }}
                                        disabled={this.props.datesDisabled}
                                    />
                                    <IbssDatePicker
                                        value={this.props.endDate ? DateTime.fromJSDate(this.props.endDate) : DateTime.now()}
                                        onChange={event => event ? this.endDateChanged(event?.toJSDate()) : {}}
                                        label={this.labels.HubLabelToDate}
                                        slotProps={{ textField: { error: toDateFieldError, size: 'small' } }}
                                        disabled={this.props.datesDisabled}
                                        minDate={this.props.startDate ? DateTime.fromJSDate(this.props.startDate) : undefined}
                                    />
                                </>
                            }
                            {this.props.showSingleDate && this.props.singleDate &&
                                <IbssDatePicker
                                    value={this.props.singleDate}
                                    onChange={event => event ? this.singleDateFilterValueChanged(event?.toJSDate()) : {}}
                                    label={this.labels.HubLabelDate}
                                    slotProps={{ textField: { size: 'small' } }}
                                />
                            }
                        </Box>
                        <Box mt={1} ml={2}>
                            {this.props.toggleButtonList && this.props.toggleButtonList?.length > 0 &&
                                <IbssToggleButton
                                    onChange={(event, newLayout) => this.props.onLayoutChange?.(newLayout)}
                                    exclusive
                                    size='small'
                                    value={this.props.layout}
                                    buttonList={this.props.toggleButtonList}
                                />
                            }
                        </Box>
                    </Grid>
                    {
                        this.props.endDate && this.props.startDate && new Date(this.props.endDate) < new Date(this.props.startDate) &&
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right', pt: 0 }}>
                            <Box className='text-danger' style={{ margin: 0 }} >{this.labels.HubLabelStartDateMustBeBeforeTheEndDate}</Box>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }
};

export interface IPageHeaderProps
{
    title: string;

    presets?: string[],
    presetsLabel?: string;
    selectedPreset?: string;
    presetChanged?: (e: SelectChangeEvent<string>) => void;

    startDate?: Date;
    onStartDateChange?: (e: DateTime) => void;
    
    endDate?: Date;
    onEndDateChange?: (e: DateTime) => void;

    datesDisabled?: boolean,
    excludeToday?: boolean;
    excludeCustom?: boolean;

    showSingleDate?: boolean;
    singleDate?: DateTime;
    onSingleDateChange?: (e: DateTime) => void;

    layout?: string;
    onLayoutChange?: (newLayout: string | null) => void;
    toggleButtonList?: toggleButtonListObject[];

    /** @deprecated */
    DEPRECATED_onDateChange?: (e: Date, value: StartOrEnd) => void;
}

export enum StartOrEnd
{
    Start,
    End,
}

export default IbssPageHeader;
