import React, { Component } from 'react'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Autocomplete, Box, Divider, Drawer, Grid, Paper, SelectChangeEvent, Step, StepLabel, Stepper, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { RouterProps } from 'react-router';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DateTime } from 'luxon';
import { IPartialAppState, appContext } from '../../../../AppContext';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { IDispatch, IPropsFromState } from '../../../../redux/Interfaces';
import Helper, { dateConverter, getBuildingTimeZoneByNodeId } from '../../../../Common/Helper';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import IbssPageHeader, { StartOrEnd } from '../../../../Components/Forms/DateRange/IbssPageHeader';
import IbssFilter from '../../../../Components/Forms/Filter/IbssFilter';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import { DateHelper } from '../../../../Common/DateHelper';
import { ComponentHelper } from '../../../../Common/ComponentHelper';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import CreateIcon from '@mui/icons-material/Create';
import { CateringOrdersFilter, ICateringOrders } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { toggleButtonListObject } from '../../../../Components/Inputs/ToggleButtons/IbssToggleButtons';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IMenuItem } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import CateringOrderDrawer from './CateringOrderDrawer';
import UserPicker, * as UserPickerModule from '../../../../Components/Inputs/UserPicker/UserPicker';
import { GetCateringStatus } from './CateringStatus';

export default class ListCateringOrders extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    
    private component = new ComponentHelper(this);
    private setStateAsync = this.component.setStateAsync.bind(this.component);
    private buildingTimeZone: string;

    constructor(props: IProps)
    {
        super(props);
        this.buildingTimeZone = getBuildingTimeZoneByNodeId(this.session.getBuildingId())

        this.state =
        {
            loading: false,
            cateringOrders: [],
            cateringCompletedOrders: [],
            upcomingCateringOrdersToday: [],
            isCreateRight: false,
            isAssignRight: false,
            isCancelRight: false,
            isUpdateRight: false,
            isSetInProgressRight: false,
            buildingId: this.appState.buildingId,
            startDate: DateHelper.now().toJSDate(),
            endDate: DateHelper.now().toJSDate(),
            openUserModal: false,
            openMessageModal: false,
            message: "",
            selectedTaskIds: [],
            selectedOrderIds: [],
            actionType: "",
            daysFilter: this.labels.HubLabelToday,
            dateDisabled: true,
            searchTerm: "",
            openFilterModal: false,
            statusValue: this.labels.HubLabelAny,
            disableCancel: true,
            disableAssign: true,
            disableApprove: true,
            disableDecline: true,
            approveCateringRightInBuilding: false, 
            selectedUser: { label: "", userName: "" },
            selectedTaskOwnerObject: { label: "", email: "" },
            listViewType: "allList",
            isDrawerOpen: false,   
            stepperInfo: 
            [
                this.labels.funcInApproval_S, 
                this.labels.funcInPrep_S,
                this.labels.funcReadyforDelivery_S, 
                this.labels.HubLabelDelivered, 
                this.labels.HubLabelCleanUp, 
                this.labels.HubLabelCancelled
            ],
            activeStep: -1,
            orderDetails: 
            {
                nodeId: 0,
                orderId:"",
                OwnedBy: "",
                spaceName: "",
                cateringStatus: "",
                timeForDelivery: "",
                bookingStart: "",
                menuItems: [],
                bookingId: ""
            },     
            selectedTaskOwnerSearchText: "",
        }
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.subscribe(this, i => this.appStateChanged(i));
        const today = this.state.startDate;
        const payload: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.fromDateToString(today, this.labels.HubLabelStart),
            endDate: this.fromDateToString(today, this.labels.HubLabelEnd),
        };
        this.setRights();
        this.loadTasks(payload);

        const approveCateringRightInBuilding = this.local.permissionAppliesToBuilding('API.Catering.Approve', this.state.buildingId);
        await this.setStateAsync({approveCateringRightInBuilding: approveCateringRightInBuilding });
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): Promise<void> {
        if(prevState.buildingId != this.state.buildingId)
        {
            const today = this.state.startDate;
            const payload: IFilter =
            {
                buildingId: this.state.buildingId,
                startDate: this.state.startDate.toISOString(),
                endDate: this.state.endDate.toISOString(),
            };
            this.loadTasks(payload);
            const approveCateringRightInBuilding = this.local.permissionAppliesToBuilding('API.Catering.Approve', this.state.buildingId);
            await this.setStateAsync({approveCateringRightInBuilding: approveCateringRightInBuilding});
        }
      }

    private async appStateChanged(state: IPartialAppState): Promise<void>
    {
      const { history, match }: any = this.props;
        if (state.buildingId != undefined)
        {
            this.setState({ buildingId: state.buildingId });
            history.push(match.path.replace(":buildingid", state.buildingId))
        }
    }

    private fromDateToString(date: Date, type: string): string
    {
        if (type === this.labels.HubLabelStart)
        {
            return DateTime.fromISO(dateConverter(date)).setZone(this.buildingTimeZone).startOf('day').toUTC().toISO();
        }
        else
        {
            return DateTime.fromISO(dateConverter(date)).setZone(this.buildingTimeZone).endOf('day').toUTC().toISO();
        }
    }

    private getCateringApprovalRight()
    {
        return this.local.hasRight("API.Catering.Approve") && this.state.approveCateringRightInBuilding;
    }

    private setRights(): void
    {
        this.setState({
            isCreateRight: this.local.hasRight("DATAMODEL.CateringOrders.Create"),
            isUpdateRight: this.local.hasRight("DATAMODEL.CateringOrders.Update"),
            isCancelRight: this.local.hasRight("DATAMODEL.CateringOrders.Delete"),
        });
    }

    private async loadTasks(payload: IFilter): Promise<void>
    {
        try
        {
            this.setState({ loading: true });

            const tasksFilter = new CateringOrdersFilter(
            {
                status: payload.statusValue,
                owner: payload.owner,
                taskDueFrom: DateTime.fromISO(payload.startDate),
                taskDueTo: DateTime.fromISO(payload.endDate),
            });
            const cateringOrders = await this.apiClient.cateringOrders.getMany(payload.buildingId, tasksFilter);

            if (!cateringOrders)
            {
                return;
            }

            const cateringOrdersList = cateringOrders.map(item => new CateringOrderView(item));

            this.setState({ 
                cateringOrders: cateringOrdersList, 
                loading: false 
            });
            if(this.state.listViewType === "todayList")
            {
                const completedCateringList = cateringOrdersList.filter((item) => item.status === "Cleared")
                const restCateringOrderList = cateringOrdersList.filter((item) => item.status !== "Cleared")
                this.setState({ 
                    cateringCompletedOrders:completedCateringList, 
                    upcomingCateringOrdersToday:restCateringOrderList, 
                    loading: false 
                });
            }
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    private filterTasks(searchTerm: string): CateringOrderView[]
    {
        const filteredTasks = this.state.cateringOrders.filter(task =>
        {
            let key: keyof CateringOrderView;
            for (key in task)
            {
                if (task[key]?.toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }

    private async dateRangeDropdownChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ daysFilter: e.target.value });

        if (e.target.value === this.labels.HubLabelToday)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().toJSDate(),
                    endDate: DateHelper.now().toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelTomorrow)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    endDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisWeek)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('week').toJSDate(),
                    endDate: DateHelper.now().endOf('week').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextWeek)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('week').plus({ days: 7 }).toJSDate(),
                    endDate: DateHelper.now().startOf('week').plus({ days: 13 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('month').toJSDate(),
                    endDate: DateHelper.now().endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextMonth)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('month').plus({ months: 1 }).toJSDate(),
                    endDate: DateHelper.now().plus({ months: 1 }).endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelCustom)
        {
            await this.setStateAsync({ dateDisabled: false });
        }

        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };

        this.loadTasks(filterObject);
    }

    private dateChanged(e: Date, type: StartOrEnd): void
    {
        if (type === StartOrEnd.Start)
        {
            this.setState({ startDate: e });
        }
        else
        {
            this.setState({ endDate: e });
        }

        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: type === StartOrEnd.Start ? this.fromDateToString(e, this.labels.HubLabelStart) : this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: type === StartOrEnd.End ? this.fromDateToString(e, this.labels.HubLabelEnd) : this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };

        this.loadTasks(filterObject);
    }

    private async approveOrDeclineClicked(type:string): Promise<void>
    {
        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };

        try 
        {
            const payload = 
            {
                Catering_Status: type === "approve" ? "RequestApproved" : "NotApproved"
            }
            for (const id of this.state.selectedOrderIds) 
            {
                const orderNodeId = this.state.cateringOrders.filter(x => x.orderId == id)[0].nodeId;
                await this.apiClient.cateringOrders.update(orderNodeId, String(id), payload);
            }
            this.loadTasks(filterObject);
        } catch (error) 
        {
        }
    }

    private filterModalCancelled(): void
    {
        this.setState({
            openFilterModal: !this.state.openFilterModal,
            statusValue: this.labels.HubLabelAssigned,
        });
        this.setState({ loading: false });
    }

    private async filterModalSubmitted(): Promise<void>
    {
        this.setState({ loading: true });
        
        let payload: IFilter =
        {
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
            buildingId: this.state.buildingId,
            statusValue: this.state.statusValue == 'Any' ? '' : this.state.statusValue,
            owner: this.state.selectedTaskOwnerObject.email,
        };

        this.setState({ openFilterModal: false });
        await this.loadTasks(payload);
    }

    private tasksSelected(selection: GridRowSelectionModel): void
    {
        this.setState({ disableDecline: true, disableApprove: true });
        
        const selectedOrders: CateringOrderView[] = [];
        selection.forEach((id) =>
        {
            const orderData = this.filterTasks(this.state.searchTerm).find(i => i.id === id);
            if(orderData)
            {
                selectedOrders.push(orderData)
            }
        });
        if(selectedOrders) 
        {
            const orderId = selectedOrders.map((item) => item?.orderId).filter((orderId) => orderId != null);
            this.setState({
                selectedOrderIds: orderId
            })
        }
 
        const selectedOrderStatuses = selectedOrders.map(x => x?.status)

        // enable the Approval button
        if (selectedOrderStatuses.length > 0 && selectedOrderStatuses.every(status => status == "PendingApproval") && this.state.isUpdateRight && this.getCateringApprovalRight())
        {
            this.setState({ disableApprove: false });
        }
        // enable the In Deny button
        if (selectedOrderStatuses.length > 0 && selectedOrderStatuses.every(status => status == "PendingApproval") && this.state.isUpdateRight && this.getCateringApprovalRight())
        {
            this.setState({ disableDecline: false });
        }
    }


    private async taskOwnerChanged(newValue: UserPickerModule.IUser | null): Promise<void>
    {
        if (newValue)
        {
            this.setState({
                selectedTaskOwnerObject: {
                    label: newValue.label,
                    email: newValue.email.toLowerCase(),
                },
            });
        } else
        {
            this.setState({ selectedTaskOwnerObject: { label: "", email: "" } });
        }
    };

    private async exportTaskList(): Promise<void>
    {
        
        const filter = new CateringOrdersFilter({
            status: this.state.statusValue == 'Any' ? '' : this.state.statusValue,
            owner: this.state.selectedTaskOwnerObject.email,
            taskDueFrom: DateTime.fromJSDate(this.state.startDate).setZone(this.buildingTimeZone).startOf('day'),
            taskDueTo: DateTime.fromJSDate(this.state.endDate).setZone(this.buildingTimeZone).plus({day : 1}).startOf('day'),
        });
        const blob = await this.apiClient.cateringOrders.getManyAsBlob(this.state.buildingId, filter);
        Helper.downloadFile(blob, 'Orders');
    }

    private getActiveStep(status: string): void
    {
        const statusMap: Record<string, number> = 
        {
            "RequestApproved": 0,
            "Approved": 0,
            "InPreparation": 1,
            "ReadyForPrep": 1,
            "PrepareForService": 1,
            "ReadyForDespatchInTime": 2,
            "ReadyForDespatchOutOfTime": 2,
            "Delivered": 3,
            "ClearUpScheduled": 4,
            "ClearUpExcess": 4,
            "Cleared": 4,
            "CancelledNoCharge": 5,
            "CancelledCharged": 5
        };
    
        const activeStep = statusMap[status] !== undefined ? statusMap[status] : -1;
    
        this.setState({
            activeStep: activeStep
        });
    }


    private async cateringMenuItems(cateringOrders: CateringOrderView,buildingId: number,menuId: string, value: boolean): Promise<void>
    {
        const menuItems: IMenu_Items[] = [];
        const cateringMenu = await this.apiClient.cateringMenus.getById(buildingId, menuId, true, true);
        const cateringMenuItems = cateringMenu.Menu_MenuItems.map((item) => item.MenuItem);

        cateringMenuItems.forEach(catering => {
            const orderItem = cateringOrders.menuItem.find(order => order.Id === catering.MenuItem_Id);
            if (orderItem) {
                menuItems.push({
                    id: catering.MenuItem_Id,
                    name: catering.Name,
                    classification: catering.Classification,
                    quantity: orderItem.QuantityOfItems
                });
            }
        });

        this.getActiveStep(cateringOrders.status);
        this.setState({
                isDrawerOpen: value,
                orderDetails:
                {
                    nodeId:cateringOrders.nodeId,
                    orderId: cateringOrders.orderId,
                    OwnedBy: cateringOrders.CreatedBy,
                    spaceName: cateringOrders.spaceName,
                    timeForDelivery: DateHelper.fromIsoByNode(cateringOrders.cateringServiceTime,this.state.buildingId).toLocaleDateTimeString(),
                    cateringStatus: cateringOrders.status,
                    menuItems: menuItems,
                    bookingStart: cateringOrders.bookingStart.toString(),
                    bookingId: cateringOrders.bookingId
                }
        })
    }

    private openCateringDrawer(cateringOrders: CateringOrderView,value: boolean): React.ReactNode
    {
        const menuId = cateringOrders.menuItem.map((item) => item.Menu_Id)[0];
        if(this.state.isUpdateRight && this.getCateringApprovalRight())
        {
            return (
                <IbssSvgIcon onClick={() => {
                    this.cateringMenuItems(cateringOrders,cateringOrders.nodeId,menuId,value)
                }}>
                    <CreateIcon />
                 </IbssSvgIcon>
            )
        } 
        else 
        {
            return <p>-</p>
        }
    }

    private async deleteClicked(): Promise<void> 
    {
        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };
        try 
        {
            for (const id of this.state.selectedOrderIds) 
            {
                await this.apiClient.cateringOrders.delete(this.state.buildingId, String(id));
            }
            this.loadTasks(filterObject);
        } 
        catch (error) 
        {
        }
    }

    public checkLayout(layout: string): void
    {
        if(layout === "todayList")
        {
            const filterObject: IFilter =
            {
                buildingId: this.state.buildingId,
                startDate: this.fromDateToString(DateHelper.now().toJSDate(), this.labels.HubLabelStart),
                endDate: this.fromDateToString(DateHelper.now().toJSDate(),this.labels.HubLabelEnd)
            };
            this.loadTasks(filterObject);
        } 
        else if(layout === "allList")
        {
            const filterObject: IFilter =
            {
                buildingId: this.state.buildingId,
                startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
                endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
            };
            this.loadTasks(filterObject);
        }
    }

    public changeLayout(layout: string | null): void 
    { 
        if(layout !== null)
        {
            this.setState({
                listViewType: layout,
            });
            this.checkLayout(layout);
        }
    }

    private async statusChangedClicked (type:string): Promise<void>   
    {
        const payload = 
        {
            Catering_Status: type
        }
        try 
        {
            this.setState({
                isDrawerOpen: false,
            })
            await this.apiClient.cateringOrders.update(this.state.orderDetails.nodeId,this.state.orderDetails.orderId, payload);
            this.checkLayout(this.state.listViewType);
        } catch (error) 
        {
        }
    }

    private editCateringOrder(): void
    {
            this.props.history.push(`/operational-services-catering/${this.state.buildingId}/catering/${this.state.orderDetails.bookingId}/${this.state.orderDetails.orderId}/edit`)
    }

    public render(): JSX.Element
    {

        const cateringStatus = new GetCateringStatus({});
        const dataGridHeader: GridColDef<CateringOrderView>[] =

            [
                {
                    headerName: this.labels.HubLabelBookingName,
                    field: Helper.nameOf<CateringOrderView>("bookingName"),
                    minWidth: 250,
                },
                {
                    headerName: this.labels.HubLabelDeliverHost,
                    field: Helper.nameOf<CateringOrderView>("CreatedBy"),
                    minWidth: 250,
                },
                {
                    headerName: this.labels.HubLabelLocation,
                    field: Helper.nameOf<CateringOrderView>("spaceName"),
                    minWidth: 250,
                },
                {
                    headerName: this.labels.HubLabelDateTimeDelivery,
                    field: Helper.nameOf<CateringOrderView>("cateringServiceTime"),
                    valueGetter: (params) => DateHelper.fromIsoByNode(params.row.cateringServiceTime,this.state.buildingId).toLocaleDateTimeString(),
                    minWidth: 250,
                },
                {
                    headerName: this.labels.funcTimeForCleanUp_S,
                    field: Helper.nameOf<CateringOrderView>("cateringClearingTime"),
                    valueGetter: (params) => DateHelper.fromIsoByNode(params.row.cateringClearingTime,this.state.buildingId).toLocaleDateTimeString(),
                    minWidth: 250,
                },
                {
                    headerName: this.labels.HubLabelStatus,
                    field: Helper.nameOf<CateringOrderView>("status"),
                    minWidth: 250,
                    renderCell: (params) =>  cateringStatus.renderTaskOverAllStatus(params.row.status)
                },
                {
                    headerName: this.labels.HubLabelAction,
                    minWidth: 120,
                    field: "",
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) => <>{this.openCateringDrawer(params.row,true)}</>
                },
            ];

 

        // filter status options
        let filterStatusOption =
            [
                { label: this.labels.HubLabelPendingApproval, value: "PendingApproval" },
                { label: this.labels.HubLabelNotApproved, value: "NotApproved" },
                { label: this.labels.HubLabelRequestApproved, value: "RequestApproved" },
                { label: this.labels.HubLabelReadyForPrep, value: "ReadyForPrep" },
                { label: this.labels.HubLabelPrepareForService, value: "PrepareForService" },
                { label: this.labels.HubLabelInPreparation, value: "InPreparation" },
                { label: this.labels.HubLabelCancelledCharged, value: "CancelledCharged" },
                { label: this.labels.HubLabelCancelledNoCharge, value: "CancelledNoCharge" },
                { label: this.labels.HubLabelDeliveryIssue, value: "DeliveryIssue" },
                { label: this.labels.HubLabelDelivered, value: "Delivered" },
                { label: this.labels.HubLabelClearUpScheduled, value: "HubLabelClearUpScheduled" },
                { label: this.labels.HubLabelClearUpExcess, value: "ClearUpExcess" },
                { label: this.labels.HubLabelCleared, value: "Cleared" },
            ].sort((a, b) => {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            filterStatusOption = [{ label: this.labels.HubLabelAny, value: "Any" }, ...filterStatusOption]

        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.deleteClicked(),
                    disabled: this.state.selectedOrderIds.length > 0 && this.state.isCancelRight ? false : true
                },
                {
                    label: this.labels.HubButtonDeny,
                    icon: (
                        <IbssSvgIcon>
                            <CancelOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.approveOrDeclineClicked("decline"),
                    disabled: this.state.disableDecline,
                },
                {
                    label: this.labels.HubButtonApprove,
                    icon: (
                        <IbssSvgIcon>
                            <CheckCircleOutlineTwoToneIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.approveOrDeclineClicked("approve"),
                    disabled: this.state.disableApprove
                },
            ];

        const filteredTasks = this.filterTasks(this.state.searchTerm);

        const layoutOptions: toggleButtonListObject[] = 
        [
            {  value: 'allList',icon: <FormatListBulletedIcon />, showFilterFields: false },
            {  value: 'todayList',icon:  <CalendarTodayIcon />, showFilterFields: true },
        ];

        return (
            <>
                <div className="page-container">
                    {/* Filter Modal */}
                    <IbssDialog
                        open={this.state.openFilterModal}
                        onClose={() => this.filterModalCancelled()}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        dialogContent=
                        {
                            <>
                                <div className="row">
                                    <IbssInputDropDown
                                        options={filterStatusOption}
                                        id="statusSelection"
                                        value={this.state.statusValue}
                                        inputLabel={this.labels.HubLabelStatus}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<string>) => { this.setState({ statusValue: event.target.value }) }}
                                    />
                                </div>
                                <div className="row">
                                    <UserPicker
                                        width="100%"
                                        label={this.labels.HubLabelTaskOwner}
                                        searchText={this.state.selectedTaskOwnerSearchText}
                                        onChange={async text => this.setState({ selectedTaskOwnerSearchText: text })}
                                        onUserChange={user => this.taskOwnerChanged(user)}
                                    />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.filterModalCancelled()}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.filterModalSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    <CateringOrderDrawer
                        isDrawerOpen={this.state.isDrawerOpen}
                        onClose={() => this.setState({ isDrawerOpen: false })}
                        orderDetails={this.state.orderDetails}
                        activeStep={this.state.activeStep}
                        stepperInfo={this.state.stepperInfo}
                        statusChangedClicked={(type) => this.statusChangedClicked(type)}
                        editCateringOrder={() => this.editCateringOrder()}
                        orderStatus={cateringStatus.renderTaskOverAllStatus(this.state.orderDetails.cateringStatus)}
                    />
                    <div className="rightPanel">
                    <div className="page-height-exct-header">
                        {this.state.loading && <Spinner />}
                        <div className="rightPanel-main-content">
                                <IbssPageHeader
                                    title={this.state.listViewType === "allList" ? this.labels.HubMenuCateringOrders : this.labels.HubMenuCateringOrdersForToday}
                                    selectedPreset={this.state.daysFilter}
                                    presetChanged={(e) => this.dateRangeDropdownChanged(e)}
                                    onLayoutChange={(newLayout) => this.changeLayout(newLayout)}
                                    layout={this.state.listViewType}
                                    toggleButtonList= {layoutOptions}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    datesDisabled={this.state.dateDisabled}
                                    DEPRECATED_onDateChange={(event, value) => this.dateChanged(event, value)}
                                    presets={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                />
                            {this.state.listViewType === "allList" &&
                            <>
                                <IbssFilter
                                    showExport={true}
                                    searchTerm={this.state.searchTerm}
                                    searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                    filterButtonClicked={() => this.setState({ openFilterModal: true })}
                                    exportButtonClicked={() => { this.exportTaskList() }}
                                />
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                <IbssActionButton
                                    buttons={actionButtons}
                                />
                                </Box>
                            </>
                            }
                            {this.state.listViewType === "allList" &&
                                <Box sx={{mt:1}} className="table-panel">
                                    <IbssDataGrid
                                        checkboxSelection
                                        columns={dataGridHeader}
                                        disableRowSelectionOnClick
                                        rows={filteredTasks}
                                        onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.tasksSelected(rowSelectionModel)}
                                        initialState={{
                                            pagination: { paginationModel: {pageSize: 25} },
                                        }}
                                        pageSizeOptions={[25,50,100]}
                                        />
                                </Box>
                            }   
                            {this.state.listViewType === "todayList" &&
                                <Box mt={2}>
                                    <Box className="table-panel">
                                    <Typography variant='h6'>{this.labels.HubMenuTodaysUpcomingOrders}</Typography>
                                        <Box sx={{mt:2}}>
                                            <IbssDataGrid
                                                className='clickable-data-grid-row'
                                                rows={this.state.upcomingCateringOrdersToday}
                                                columns={dataGridHeader}
                                                disableRowSelectionOnClick
                                                hideFooter
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{mt:3}} className="table-panel">
                                    <Typography variant='h6'>{this.labels.HubMenuTodaysCompleteOrders}</Typography>
                                        <Box sx={{mt:2}}>
                                            <IbssDataGrid
                                                className='clickable-data-grid-row'
                                                rows={this.state.cateringCompletedOrders}
                                                columns={dataGridHeader}
                                                disableRowSelectionOnClick
                                                hideFooter
                                            />
                                        </Box>
                                    </Box>
                                </Box>    
                            }
                    </div>
                </div>
                </div>
                </div>
            </>
        )
    }

}

interface IProps extends RouterProps, IPropsFromState, IDispatch
{
}

export interface ICustomOrderDetails
{
    nodeId: number,
    orderId: string,
    OwnedBy: string,
    spaceName: string,
    cateringStatus: string,
    menuItems: IMenu_Items[],
    timeForDelivery: string,
    bookingStart: string,
    bookingId: string
}


interface IState
{
    loading: boolean;
    cateringOrders: CateringOrderView[];
    cateringCompletedOrders: CateringOrderView[];
    upcomingCateringOrdersToday: CateringOrderView[];
    isCreateRight: boolean;
    isAssignRight: boolean;
    isUpdateRight: boolean;
    isCancelRight: boolean;
    isSetInProgressRight: boolean;
    buildingId: number;
    startDate: Date;
    endDate: Date;
    openUserModal: boolean;
    openMessageModal: boolean;
    message: string;
    selectedTaskIds: GridRowSelectionModel;
    selectedOrderIds: string[];
    actionType: string;
    daysFilter: string;
    dateDisabled: boolean;
    searchTerm: string;
    openFilterModal: boolean;
    statusValue: string;
    disableCancel: boolean;
    disableAssign: boolean;
    disableApprove: boolean;
    disableDecline: boolean;
    approveCateringRightInBuilding: boolean;
    selectedUser: { label?: string, userName: string },
    selectedTaskOwnerObject: ILabelEmail,
    listViewType: string;
    isDrawerOpen: boolean;
    stepperInfo: string[];
    activeStep: number;
    orderDetails: ICustomOrderDetails;
    selectedTaskOwnerSearchText: string;
}

export interface IFilter
{
    buildingId: number;
    startDate: string;
    endDate: string;
    statusValue?: string;
    host?: string;
    owner?: string;
}

export interface IMenu_Items
{
    id: string;
    name: string;
    classification: string;
    quantity: number;
}

interface ILabelEmail
{
    label?: string;
    email: string;
}

interface IUserDetails
{
    label?: string;
    companyName: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    oid: string;
    pin: string;
    userName: string;
}


export class CateringOrderView
{
    public nodeId: number;
    public id: number;
    public bookingName: string;
    public CreatedBy: string;
    public status: string;
    public spaceName: string;
    public spaceId: string;
    public cateringServiceTime: string;
    public cateringClearingTime: string;
    public bookingStart: string;
    public bookingEnd: string;
    public orderId: string;
    public menuItem: IMenuItem[];
    public bookingId: string;
    public ownerEmail: string;
    public ownerId: string;

    constructor(value: ICateringOrders)
    {   
        this.nodeId = value.Node_Id;
        this.id = value.ID;
        this.bookingName = value.Booking_Name;
        this.CreatedBy = value.CreatedBy;
        this.spaceName = value.Space_Name;
        this.spaceId = value.Space_Id;
        this.cateringServiceTime =  value.Catering_Service_Time.toISO();
        this.cateringClearingTime =  value.Catering_Clearing_Time.toISO();
        this.bookingStart = value.Booking_Start.toISO();
        this.bookingEnd = value.Booking_End.toISO();
        this.status = value.Catering_Status;
        this.orderId = value.Order_Id;
        this.menuItem = value.Menu_Items;
        this.bookingId = value.Booking_Id;
        this.ownerEmail = value.OwnedBy;
        this.ownerId = value.OwnedById;
    }
}