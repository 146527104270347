import { DateTime } from "luxon";
import { IGetNewCateringOrdersEndpoint } from "./GetNewCateringOrdersEndpoint";
import { IGetCateringOrdersEditEndpoint } from "./GetCateringOrdersEditEndpoint";
import { IGetCateringOrdersEndpoint } from "./GetCateringOrdersEndpoint";
import { IUpdateCateringOrdersEndpoint } from "./UpdateCateringOrdersEndpoint";
import { IGetManyEndpoint } from './GetManyEndPoint';
import { ICateringStatus, IUpdateEndpoint } from './UpdateEndPoint';
import { ICreateCateringOrder, ICreateCateringOrderEndpoint } from "./CreateCateringOrderEndpoint";
import { ICateringOrder as ICateringOrderNew, IGetCateringOrderEndpoint } from "./GetCateringOrderEndpoint";
import { IEditCateringOrder, IEditCateringOrderEndpoint } from "./EditCateringOrderEndpoint";
import { IDeleteEndpoint } from "./DeleteEndPoint";
import { IPatchCateringOrder, IPatchCateringOrderEndpoint } from "./PatchCateringOrderEndpoint";
import { IGetManyAsBlobEndpoint } from "./GetManyAsBlobEndpoint";
import { ITasksFilter } from "../Tasks/TaskRepository";

export class CateringOrderRepository implements ICateringOrderRepository
{
    private getNewCateringOrdersEndpoint: IGetNewCateringOrdersEndpoint;
    private getCateringOrdersEditEndpoint: IGetCateringOrdersEditEndpoint;
    private getCateringOrdersEndpoint: IGetCateringOrdersEndpoint;
    private updateCateringOrdersEndpoint: IUpdateCateringOrdersEndpoint;
    private createCateringOrderEndpoint: ICreateCateringOrderEndpoint;
    private getCateringOrderEndpoint: IGetCateringOrderEndpoint;
    private editCateringOrderEndpoint: IEditCateringOrderEndpoint;
    private patchCateringOrderEndpoint: IPatchCateringOrderEndpoint;
    private getManyEndpoint: IGetManyEndpoint;
    private updateCateringOrdersById: IUpdateEndpoint;
    private deleteManyEndpoint: IDeleteEndpoint;
    private getManyAsBlobEndpoint: IGetManyAsBlobEndpoint;
    
    constructor(
        getNewCateringOrdersEndpoint: IGetNewCateringOrdersEndpoint,
        getCateringOrdersEditEndpoint: IGetCateringOrdersEditEndpoint,
        getCateringOrdersEndpoint: IGetCateringOrdersEndpoint,
        updateCateringOrdersEndpoint: IUpdateCateringOrdersEndpoint,
        createCateringOrderEndpoint: ICreateCateringOrderEndpoint,
        getCateringOrderEndpoint: IGetCateringOrderEndpoint,
        editCateringOrderEndpoint: IEditCateringOrderEndpoint,
        patchCateringOrderEndpoint: IPatchCateringOrderEndpoint,
        getManyEndpoint: IGetManyEndpoint,
        updateCateringOrdersById: IUpdateEndpoint,
        deleteManyEndpoint: IDeleteEndpoint,
        getManyAsBlobEndpoint: IGetManyAsBlobEndpoint,
    )
    {
        this.getNewCateringOrdersEndpoint = getNewCateringOrdersEndpoint;
        this.getCateringOrdersEditEndpoint = getCateringOrdersEditEndpoint;
        this.getCateringOrdersEndpoint = getCateringOrdersEndpoint;
        this.updateCateringOrdersEndpoint = updateCateringOrdersEndpoint;
        this.createCateringOrderEndpoint = createCateringOrderEndpoint;
        this.getCateringOrderEndpoint = getCateringOrderEndpoint;
        this.editCateringOrderEndpoint = editCateringOrderEndpoint;
        this.patchCateringOrderEndpoint = patchCateringOrderEndpoint;
        this.getManyEndpoint = getManyEndpoint;
        this.updateCateringOrdersById = updateCateringOrdersById;
        this.deleteManyEndpoint = deleteManyEndpoint;
        this.getManyAsBlobEndpoint = getManyAsBlobEndpoint;
    }

    public getNewCateringOrders(nodeId: number, spaceId: string): Promise<ICateringOrder[]>
    {
        return this.getNewCateringOrdersEndpoint.execute(nodeId, spaceId);
    }

    public getCateringOrdersEdit(nodeId: number, taskId: string): Promise<ICateringOrder[]>
    {
        return this.getCateringOrdersEditEndpoint.execute(nodeId, taskId);
    }

    public getTaskOrderItems(nodeId: number, taskId: string): Promise<ICateringOrder[]>
    {
        return this.getCateringOrdersEndpoint.execute(nodeId, taskId);
    }

    public updateCateringOrders(nodeId: number, taskId: number, payload: ICateringOrder[]): Promise<string>
    {
        return this.updateCateringOrdersEndpoint.execute(nodeId, taskId, payload);
    }
    
    public createCateringOrder(nodeId: number, payload: ICreateCateringOrder): Promise<string>
    {
        return this.createCateringOrderEndpoint.execute(nodeId, payload);
    }

    public getCateringOrder(nodeId: number, orderId: string): Promise<ICateringOrderNew>
    {
        return this.getCateringOrderEndpoint.execute(nodeId, orderId);
    }

    public editCateringOrder(nodeId: number, orderId: string, payload: IEditCateringOrder): Promise<string>
    {
        return this.editCateringOrderEndpoint.execute(nodeId, orderId, payload);
    }

    public patchCateringOrder(nodeId: number, orderId: string, payload: IPatchCateringOrder): Promise<string>
    {
        return this.patchCateringOrderEndpoint.execute(nodeId, orderId, payload);
    }

    public getMany(buildingId: number, filter: CateringOrdersFilter): Promise<ICateringOrders[]>
    {
        return this.getManyEndpoint.execute(buildingId, filter);
    }

    public update(nodeId: number, taskId: string, payload: ICateringStatus): Promise<string>
    {
        return this.updateCateringOrdersById.execute(nodeId, taskId, payload);
    }

    public delete(nodeId: number, taskId: string): Promise<string>
    {
        return this.deleteManyEndpoint.execute(nodeId,taskId)
    }

    public getManyAsBlob(buildingId: number, filter: CateringOrdersFilter): Promise<Blob>
    {
        return this.getManyAsBlobEndpoint.execute(buildingId, filter);
    }
}

export interface ICateringOrderRepository
{
    getNewCateringOrders(nodeId: number, spaceId: string): Promise<ICateringOrder[]>;
    getCateringOrdersEdit(nodeId: number, taskId: string): Promise<ICateringOrder[]>;
    getTaskOrderItems(nodeId: number, taskId: string): Promise<ICateringOrder[]>;
    updateCateringOrders(nodeId: number, taskId: number, payload: ICateringOrder[]): Promise<string>;
    createCateringOrder(nodeId: number, payload: ICreateCateringOrder): Promise<string>;
    getCateringOrder(nodeId: number, orderId: string): Promise<ICateringOrderNew>;
    editCateringOrder(nodeId: number, orderId: string, payload: IEditCateringOrder): Promise<string>;
    patchCateringOrder(nodeId: number, orderId: string, payload: IPatchCateringOrder): Promise<string>;
    getMany(buildingId: number, tasksFilter: CateringOrdersFilter): Promise<ICateringOrders[]>;
    update(nodeId: number, taskId: string, payload: ICateringStatus): Promise<string>;
    delete(nodeId: number, taskId: string): Promise<string>;
    getManyAsBlob(buildingId: number, filter: CateringOrdersFilter): Promise<Blob>;
}

export interface ICateringOrder 
{
    ImageURI: string;
    Menu_Item_Id: number;
    Menu_Item_Title: string;
    Menu_Item_Description: string;
    Menu_Item_Section: string;
    Menu_Item_Price: number;
    Node_Id: number;
    Catering_Order_Id: number;
    Space_Id: string;
    Catering_Order_Item_Id: string;
    Catering_Order_Item_Quantity: string;
}


export interface ICostAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}

export type CateringStatus =
  | "PendingApproval"
  | "NotApproved"
  | "RequestApproved"
  | "ReadyForPrep"
  | "PrepareForService"
  | "InPreparation"
  | "ReadyForDespatchInTime"
  | "ReadyForDespatchOutOfTime"
  | "CancelledCharged"
  | "CancelledNoCharge"
  | "DeliveryIssue"
  | "Delivered"
  | "ClearUpScheduled"
  | "ClearUpExcess"
  | "Cleared";

export interface ICateringOrders
{
    Node_Id: number;
    ID: number;
    Catering_Status: CateringStatus;
    Booking_Name: string;
    CreatedBy: string;
    Space_Name: string;
    Space_Id: string;
    Booking_Id: string;
    Catering_Service_Time: DateTime;
    Catering_Clearing_Time: DateTime;
    Booking_Start: DateTime;
    Booking_End: DateTime;
    Order_Id: string;
    Menu_Items: IMenuItem[];
    OwnedBy: string;
    OwnedById: string;
}

export interface IMenuItem
{
    Id: string;
    Menu_Id: string;
    Description?: string;
    QuantityOfItems: number;
}


export class CateringOrdersFilter
{
    public status: (string | null) = null;
    public statusNot = new Array<string>();
    public owner: (string | null) = null;
    public createdBy: (string | null) = null;
    public createdFrom: (DateTime | null) = null;
    public createdTo: (DateTime | null) = null;
    public taskDueFrom: (DateTime | null) = null;
    public taskDueTo: (DateTime | null) = null;
    public bookingId: (string | null) = null;


    constructor(value: Partial<CateringOrdersFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
            let filterParts = new Array<string>();
            filterParts.push(this.status ? `Catering_Status eq '${this.status}'` : "");
            this.statusNot.forEach(i => filterParts.push(`Catering_Status ne '${i}'`));
            filterParts.push(this.owner ? `OwnedBy eq '${this.owner}'` : "");
            filterParts.push(this.taskDueFrom ? `Catering_Service_Time ge datetime'${this.taskDueFrom.toUTC().toISO()}'` : "");
            filterParts.push(this.taskDueTo ? `Catering_Service_Time le datetime'${this.taskDueTo.toUTC().toISO()}'` : "");
            filterParts.push(this.bookingId ? `Booking_Id eq '${this.bookingId}'` : "");
            return filterParts.filter(i => i != "").join(" and ");
    }
}