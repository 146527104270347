import { AxiosError } from 'axios';
import moment from 'moment';
import { appContext } from '../AppContext';
import ApiMessages from '../Providers.Text/ApiMessages';
import IResponse from './IResponse';
import { DateTime } from 'luxon';

export default class ApiMessageBuilder
{
    public getTitleAndMessage(error: (AxiosError<IResponse> | null), nodeId: number): { title: string, message: string, isMessageHtml: boolean }
    {
        const labels = appContext().labels;
        const data = error?.response?.data ?? null;

        if (error?.message == "Network Error")
        {
            return { title: labels.HubLabelnetworkFailure, message: labels.HubLabelPleaseTryAgain, isMessageHtml: false };
        }
        else if (error?.response?.status === 401)
        {
            // the global interceptor redirects to the login page when a 401 is encountered
            // an empty message prevents the popup from briefly showing
            return { title: "", message: "", isMessageHtml: false };
        }
        else if (data?.errors && data?.errors.length > 0)
        {
            const title = data.title ?? data.Title ?? labels.HubLabelError;
            const manyErrors = data.errors.length > 1;
            const message = (manyErrors ? ('<ul>' + data.errors.map(i => `<li>${i.detail}</li>`).join('') + '</ul>') : data.errors[0].detail);
            return { title: title, message: message, isMessageHtml: manyErrors };
        }
        else if (data?.Warnings && data.Warnings.length > 0)
        {
            const title = this.getTitle(data.Warnings[0]);
            const message = this.getMessage(data.Warnings[0], nodeId);
            return { title: title, message: message, isMessageHtml: false };
        }
        else if (error?.response)
        {
            const title = this.getTitle(data);
            const message = this.getMessage(data, nodeId);
            return { title: title, message: message, isMessageHtml: false };
        }
        else
        {
            return { title: labels.HubLabelError, message: labels.HubLabelSomethingWentWrong, isMessageHtml: false };
        }
    }

    public getTitle(response: IResponse | string | null): string
    {
        let labels = appContext().labels;
        let responseType = typeof response;

        if (!response)
        {
            return labels.HubLabelError;
        }
        else if (responseType === "object")
        {
            return this.getTitleFromResponse(response as IResponse);
        }
        else
        {
            return labels.HubLabelError;
        }
    }

    private getTitleFromResponse(response: IResponse): string
    {
        let labels = appContext().labels;
        let apiMessages = appContext().apiMessages;

        if (response.ResponseId)
        {
            let localisedTitle = apiMessages[response.ResponseId + "_Title" as keyof ApiMessages];
            if (localisedTitle)
            {
                return localisedTitle;
            }
        }
        return (response.Title ?? response.title ?? labels.HubLabelError);
    }

    public getMessage(response: IResponse | string | null, nodeId: number): string
    {
        let labels = appContext().labels;
        let responseType = typeof response;

        if (!response)
        {
            return labels.HubLabelSomethingWentWrong;
        }
        else if (responseType === "string")
        {
            return (response as string);
        }
        else if (responseType === "object")
        {
            return this.getMessageFromResponse(response as IResponse, nodeId);
        }
        else
        {
            return labels.HubLabelSomethingWentWrong;
        }
    }

    private static readonly argExpression = /\{\d+(:\w+)?\}/gi;
    private static readonly dateTimeExpression = /^datetime'(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)'$/i;
    private static readonly badArg = "-";

    private getMessageFromResponse(response: IResponse, nodeId: number): string
    {
        let labels = appContext().labels;
        let apiMessages = appContext().apiMessages;

        if (response.ResponseId)
        {
            let localisedMessage = apiMessages[response.ResponseId + "_Message" as keyof ApiMessages];
            if (localisedMessage)
            {
                let args = localisedMessage.match(ApiMessageBuilder.argExpression);
                if (args?.length ?? 0) // ensure there are enough details to honour the args
                {
                    let localisedMessageWithArgs = localisedMessage.replace(ApiMessageBuilder.argExpression, arg =>
                    {
                        const argMatch = arg.match(/\{(\d+)(:\w+)?\}/i) as RegExpMatchArray;
                        const argIndex = parseInt(argMatch[1] as string);
                        const detail = response.Details?.[argIndex] ?? null;

                        if (detail == null)
                        {
                            return ApiMessageBuilder.badArg;
                        }

                        const format = argMatch[2]?.substring(1);
                        if (format == null)
                        {
                            return this.formatString(detail);
                        }

                        switch (format[0])
                        {
                            case 't':
                                return this.formatTime(detail, nodeId);

                            case 'd':
                                return this.formatDate(detail, nodeId);

                            case 'g':
                                return this.formatDateTime(detail, nodeId);

                            case 'N':
                            case 'F':
                                return this.formatNumber(detail, format);

                            default:
                                return ApiMessageBuilder.badArg;
                        }
                    });

                    return localisedMessageWithArgs;
                }
            }
        }
        return (response.Message ?? response.message ?? labels.HubLabelSomethingWentWrong);
    }

    private formatTime(value: string | number, nodeId: number): string
    {
        if (typeof (value) != "string")
        {
            return ApiMessageBuilder.badArg;
        }

        const dateTimeMatch = value.match(ApiMessageBuilder.dateTimeExpression);
        const dateTimeString = dateTimeMatch?.[1];

        if (dateTimeString == null)
        {
            return ApiMessageBuilder.badArg;
        }

        const formattedValue = DateTime.fromISO(dateTimeString).offsetTimeByNode(nodeId, true).toLocaleTimeString();
        return formattedValue;
    }

    private formatDate(value: string | number, nodeId: number): string
    {
        if (typeof (value) != "string")
        {
            return ApiMessageBuilder.badArg;
        }

        const dateTimeMatch = value.match(ApiMessageBuilder.dateTimeExpression);
        const dateTimeString = dateTimeMatch?.[1];

        if (dateTimeString == null)
        {
            return ApiMessageBuilder.badArg;
        }

        const formattedValue = DateTime.fromISO(dateTimeString).offsetTimeByNode(nodeId, true).toLocaleDateString();
        return formattedValue;
    }

    private formatDateTime(value: string | number, nodeId: number): string
    {
        if (typeof (value) != "string")
        {
            return ApiMessageBuilder.badArg;
        }

        const dateTimeMatch = value.match(ApiMessageBuilder.dateTimeExpression);
        const dateTimeString = dateTimeMatch?.[1];

        if (dateTimeString == null)
        {
            return ApiMessageBuilder.badArg;
        }

        const formattedValue = DateTime.fromISO(dateTimeString).offsetTimeByNode(nodeId, true).toLocaleDateTimeString();
        return formattedValue;
    }

    private formatNumber(value: string | number, format: string): string
    {
        if (typeof (value) != "number")
        {
            return ApiMessageBuilder.badArg;
        }

        let decimalPlaces = parseInt(format.substring(1));
        if (isNaN(decimalPlaces))
        {
            decimalPlaces = 2;
        }

        const formattedValue = value.toFixed(decimalPlaces);
        return formattedValue;
    }

    private formatString(value: string | number): string
    {
        const formattedValue = (typeof (value) == "string" ? value : ApiMessageBuilder.badArg);
        return formattedValue;
    }
}