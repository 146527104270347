import { ILabels } from "./ILabels";
import { PoEditorParser } from "./PoEditorParser";

export class Labels implements ILabels
{
    private parser = new PoEditorParser();
    private simple(term: string) { return this.parser.simple(term); }
    private plural(term: string, size: number) { return this.parser.plural(term, size); }

    private constructor() { }

    public static async create(): Promise<ILabels>
    {
        const labels = new Labels();
        await labels.parser.read("Labels.json");
        return labels;
    }

    public get HubLabelWelcomeText() { return this.simple("HubLabelWelcomeText"); }
    public get HubLabelforgotPassword() { return this.simple("HubLabelforgotPassword"); }
    public get HubLabelSignInText() { return this.simple("HubLabelSignInText"); }
    public get HubLabelemailPlaceholderText() { return this.simple("HubLabelemailPlaceholderText"); }
    public get HubLabelPasswordPlaceholderText() { return this.simple("HubLabelPasswordPlaceholderText"); }
    public get HubButtonContinue() { return this.simple("HubButtonContinue"); }
    public get HubButtonSubmit() { return this.simple("HubButtonSubmit"); }
    public get HubMenuportfolioText() { return this.simple("HubMenuportfolioText"); }
    public get HubLabelSpaces() { return this.simple("HubLabelSpaces"); }
    public get HubMenuSpaces() { return this.simple("HubMenuSpaces"); }
    public get HubMenuSecurity() { return this.simple("HubMenuSecurity"); }
    public get HubLabelIdentityproviders() { return this.simple("HubLabelIdentityproviders"); }
    public get HubMenuIdentityproviders() { return this.simple("HubMenuIdentityproviders"); }
    public get HubLabelIdentityprovider() { return this.simple("HubLabelIdentityprovider"); }
    public get HubLabelRoles() { return this.simple("HubLabelRoles"); }
    public get HubMenuRoles() { return this.simple("HubMenuRoles"); }
    public get HubLabelDevices() { return this.simple("HubLabelDevices"); }
    public get HubTabTaskTypes() { return this.simple("HubTabTaskTypes"); }
    public get HubLabelNotifications() { return this.simple("HubLabelNotifications"); }
    public get HubMenuNotifications() { return this.simple("HubMenuNotifications"); }
    public get HubLabelUsers() { return this.simple("HubLabelUsers"); }
    public get HubMenuUsers() { return this.simple("HubMenuUsers"); }
    public get HubLabelName() { return this.simple("HubLabelName"); }
    public get HublabelcreatedAt() { return this.simple("HublabelcreatedAt"); }
    public get HubLabelNamePlaceholderText() { return this.simple("HubLabelNamePlaceholderText"); }
    public get HubLabelIdentityProviderType() { return this.simple("HubLabelIdentityProviderType"); }
    public get HubLabelIdentityProviderPlaceholderText() { return this.simple("HubLabelIdentityProviderPlaceholderText"); }
    public get HubLabelClientID() { return this.simple("HubLabelClientID"); }
    public get HubLabelClientIDPlaceholderText() { return this.simple("HubLabelClientIDPlaceholderText"); }
    public get HubLabelDirectoryClientID() { return this.simple("HubLabelDirectoryClientID"); }
    public get HubLabelTenantDomain() { return this.simple("HubLabelTenantDomain"); }
    public get HubLabelAdditionalAudiences() { return this.simple("HubLabelAdditionalAudiences"); }
    public get HubLabelTenantDomainText() { return this.simple("HubLabelTenantDomainText"); }
    public get HubLabelAudiences() { return this.simple("HubLabelAudiences"); }
    public get HubLabelProviderClientIDPlaceholderText() { return this.simple("HubLabelProviderClientIDPlaceholderText"); }
    public get HubLabelClientSecret() { return this.simple("HubLabelClientSecret"); }
    public get HubLabelClaimType() { return this.simple("HubLabelClaimType"); }
    public get HubLabelClientSecretPLR() { return this.simple("HubLabelClientSecretPLR"); }
    public get HubLabelClaimTypePLR() { return this.simple("HubLabelClaimTypePLR"); }
    public get HubLabelMinimumPasswordLength() { return this.simple("HubLabelMinimumPasswordLength"); }
    public get HubLabelMinPasswordLength() { return this.simple("HubLabelMinPasswordLength"); }
    public get HubLabelMaxPasswordLength() { return this.simple("HubLabelMaxPasswordLength"); }
    public get HubLabelPasswordComplexity() { return this.simple("HubLabelPasswordComplexity"); }
    public get HubLabelMaxPasswordAge() { return this.simple("HubLabelMaxPasswordAge"); }
    public get HubLabelLoadingText() { return this.simple("HubLabelLoadingText"); }
    public get HubLabelReason() { return this.simple("HubLabelReason"); }
    public get HubLabelStepOne() { return this.simple("HubLabelStepOne"); }
    public get HubLabelStepTwo() { return this.simple("HubLabelStepTwo"); }
    public get HubLabelCommonProperties() { return this.simple("HubLabelCommonProperties"); }
    public get HubTabProperties() { return this.simple("HubTabProperties"); }
    public get HubLabelVisitorDetails() { return this.simple("HubLabelVisitorDetails"); }
    public get HubLabelflexFilterSearchCritera() { return this.simple("HubLabelflexFilterSearchCritera"); }
    public get HubLabelAssociatedDetails() { return this.simple("HubLabelAssociatedDetails"); }
    public get HubLabelStatus() { return this.simple("HubLabelStatus"); }
    public get HubLabelDisabled() { return this.simple("HubLabelDisabled"); }
    public get HubLabelEnabled() { return this.simple("HubLabelEnabled"); }
    public get HubLabelBookingPolicyDescriptionError() { return this.simple("HubLabelBookingPolicyDescriptionError"); }
    public get HubLabelBookingPolicyDescriptionNotAvailable() { return this.simple("HubLabelBookingPolicyDescriptionNotAvailable"); }
    public get HubButtonCancel() { return this.simple("HubButtonCancel"); }
    public get HubButtonDelete() { return this.simple("HubButtonDelete"); }
    public get HubButtonDownload() { return this.simple("HubButtonDownload"); }
    public get HubButtonNext() { return this.simple("HubButtonNext"); }
    public get HubButtonPrevious() { return this.simple("HubButtonPrevious"); }
    public get HubButtonSave() { return this.simple("HubButtonSave"); }
    public get HubLabelNativeIdentityProvider() { return this.simple("HubLabelNativeIdentityProvider"); }
    public get HubButtonNewUser() { return this.simple("HubButtonNewUser"); }
    public get HubButtonNewRole() { return this.simple("HubButtonNewRole"); }
    public get HubLabelManageRoles() { return this.simple("HubLabelManageRoles"); }
    public get HubTabOpservSpacesFree() { return this.simple("HubTabOpservSpacesFree"); }
    public get HubLabelIdentityProviderGroupID() { return this.simple("HubLabelIdentityProviderGroupID"); }
    public get HubLabelApplicationActions() { return this.simple("HubLabelApplicationActions"); }
    public get HubLabelIsOwner() { return this.simple("HubLabelIsOwner"); }
    public get HublabelIsActive() { return this.simple("HublabelIsActive"); }
    public get HubLabelEmailAddress() { return this.simple("HubLabelEmailAddress"); }
    public get HubLabelFirstName() { return this.simple("HubLabelFirstName"); }
    public get HubLabelLastName() { return this.simple("HubLabelLastName"); }
    public get HubButtonConfirmBooking() { return this.simple("HubButtonConfirmBooking"); }
    public get HubLabelDisplayName() { return this.simple("HubLabelDisplayName"); }
    public get HubLabelPhoneNumber() { return this.simple("HubLabelPhoneNumber"); }
    public get HubLabelDeletedAt() { return this.simple("HubLabelDeletedAt"); }
    public get HubLabelFirstNamePlaceholder() { return this.simple("HubLabelFirstNamePlaceholder"); }
    public get HubLabelProvideLastName() { return this.simple("HubLabelProvideLastName"); }
    public get HubLabelEmailAddressPlaceholder() { return this.simple("HubLabelEmailAddressPlaceholder"); }
    public get HubLabelDisplayNamePlaceholder() { return this.simple("HubLabelDisplayNamePlaceholder"); }
    public get HubLabelLocationPlaceholder() { return this.simple("HubLabelLocationPlaceholder"); }
    public get HubLabelDeletedAtPlaceholder() { return this.simple("HubLabelDeletedAtPlaceholder"); }
    public get HubLabelPhoneNumberPlaceholder() { return this.simple("HubLabelPhoneNumberPlaceholder"); }
    public get HubTabUserDetails() { return this.simple("HubTabUserDetails"); }
    public get HubTabUserPreferecnces() { return this.simple("HubTabUserPreferecnces"); }
    public get HubLabelActiveRoles() { return this.simple("HubLabelActiveRoles"); }
    public get HubLabeleventTypeCol() { return this.simple("HubLabeleventTypeCol"); }
    public get HubLabelCreateCol() { return this.simple("HubLabelCreateCol"); }
    public get HubLabelReadCol() { return this.simple("HubLabelReadCol"); }
    public get HubLabelUpdateCol() { return this.simple("HubLabelUpdateCol"); }
    public get HubLabelDeleteCol() { return this.simple("HubLabelDeleteCol"); }
    public get HubLabelLockCol() { return this.simple("HubLabelLockCol"); }
    public get HubLabelUnlockCol() { return this.simple("HubLabelUnlockCol"); }
    public get HubLabelShareCol() { return this.simple("HubLabelShareCol"); }
    public get HubMenuOrganisation() { return this.simple("HubMenuOrganisation"); }
    public get HubMenuRegions() { return this.simple("HubMenuRegions"); }
    public get HubLabelBuildings() { return this.simple("HubLabelBuildings"); }
    public get HubMenuBuildings() { return this.simple("HubMenuBuildings"); }
    public get HubMenuFloor() { return this.simple("HubMenuFloor"); }
    public get HubLabelFloors() { return this.simple("HubLabelFloors"); }
    public get HubMenuFloorLabel() { return this.simple("HubMenuFloorLabel"); }
    public get HubMenuZones() { return this.simple("HubMenuZones"); }
    public get HubLabelZones() { return this.simple("HubLabelZones"); }
    public get HubLabelSensorDetail() { return this.simple("HubLabelSensorDetail"); }
    public get HubLabelCode() { return this.simple("HubLabelCode"); }
    public get HubLabelType() { return this.simple("HubLabelType"); }
    public get HubLabelCapacity() { return this.simple("HubLabelCapacity"); }
    public get HubLabelSize() { return this.simple("HubLabelSize"); }
    public get HubLabelActive() { return this.simple("HubLabelActive"); }
    public get HubLabelaccessDeniedLabel() { return this.simple("HubLabelaccessDeniedLabel"); }
    public get HubLabelaccessDenied() { return this.simple("HubLabelaccessDenied"); }
    public get HubLabelUnauthorisedMessage() { return this.simple("HubLabelUnauthorisedMessage"); }
    public get HubLabelSearchable() { return this.simple("HubLabelSearchable"); }
    public get HubButtonAdd() { return this.simple("HubButtonAdd"); }
    public get HubButtonExport() { return this.simple("HubButtonExport"); }
    public get HubButtonEdit() { return this.simple("HubButtonEdit"); }
    public get HubLabelSpaceConfig() { return this.simple("HubLabelSpaceConfig"); }
    public get HubTabSpaceProperties() { return this.simple("HubTabSpaceProperties"); }
    public get HubTabSpaceParameters() { return this.simple("HubTabSpaceParameters"); }
    public get HubTabSpaceParts() { return this.simple("HubTabSpaceParts"); }
    public get HubTabSpaceIsPartOfTab() { return this.simple("HubTabSpaceIsPartOfTab"); }
    public get HubLabelLabel() { return this.simple("HubLabelLabel"); }
    public get HubLabelSection() { return this.simple("HubLabelSection"); }
    public get HubLabelItemCode() { return this.simple("HubLabelItemCode"); }
    public get HubLabelPreBookable() { return this.simple("HubLabelPreBookable"); }
    public get HubLabelCateringTable() { return this.simple("HubLabelCateringTable"); }
    public get HubLabelRoomService() { return this.simple("HubLabelRoomService"); }
    public get HubLabelCost() { return this.simple("HubLabelCost"); }
    public get HubLabelValue() { return this.simple("HubLabelValue"); }
    public get HubLabelSpace() { return this.simple("HubLabelSpace"); }
    public get HubLabelDeviceType() { return this.simple("HubLabelDeviceType"); }
    public get HubLabelDeviceCode() { return this.simple("HubLabelDeviceCode"); }
    public get HubLabelDeviceMode() { return this.simple("HubLabelDeviceMode"); }
    public get HubTaskStates() { return this.simple("HubTaskStates"); }
    public get HubLabelSpaceStatesl() { return this.simple("HubLabelSpaceStatesl"); }
    public get HubLabelTaskTypes() { return this.simple("HubLabelTaskTypes"); }
    public get HubLabelTaskDetails() { return this.simple("HubLabelTaskDetails"); }
    public get HubLabelTaskDetailsAdd() { return this.simple("HubLabelTaskDetailsAdd"); }
    public get HubLabelOrderPoliciesAdd() { return this.simple("HubLabelOrderPoliciesAdd"); }
    public get HubLabelCateringMenu() { return this.simple("HubLabelCateringMenu"); }
    public get HubLabelTaskCateory() { return this.simple("HubLabelTaskCateory"); }
    public get HubLabelSpaceType() { return this.simple("HubLabelSpaceType"); }
    public get HubLabelBySpaceType() { return this.simple("HubLabelBySpaceType"); }
    public get HubLabelworkType() { return this.simple("HubLabelworkType"); }
    public get HubLabelSelectStart() { return this.simple("HubLabelSelectStart"); }
    public get HubLabelSelectEnd() { return this.simple("HubLabelSelectEnd"); }
    public get HubLabelCountry() { return this.simple("HubLabelCountry"); }
    public get HubLabelCity() { return this.simple("HubLabelCity"); }
    public get HubLabelAddress() { return this.simple("HubLabelAddress"); }
    public get HubLabelLongitude() { return this.simple("HubLabelLongitude"); }
    public get HubLabelLatitude() { return this.simple("HubLabelLatitude"); }
    public get HubLabelLevelNumber() { return this.simple("HubLabelLevelNumber"); }
    public get HubLabelNewSpaces() { return this.simple("HubLabelNewSpaces"); }
    public get HubLabelSpaceConfiguration() { return this.simple("HubLabelSpaceConfiguration"); }
    public get HubLabelstateId() { return this.simple("HubLabelstateId"); }
    public get HubLabelStateName() { return this.simple("HubLabelStateName"); }
    public get HubLabelHeaderText() { return this.simple("HubLabelHeaderText"); }
    public get HubLabelBodyText() { return this.simple("HubLabelBodyText"); }
    public get HubLabelBookingCancelledBy() { return this.simple("HubLabelBookingCancelledBy"); }
    public get HubLabelIcon() { return this.simple("HubLabelIcon"); }
    public get HubLabelAlternate() { return this.simple("HubLabelAlternate"); }
    public get HubLabelPrimary() { return this.simple("HubLabelPrimary"); }
    public get HubLabelPrimaryCommand() { return this.simple("HubLabelPrimaryCommand"); }
    public get HubLabelSecondary() { return this.simple("HubLabelSecondary"); }
    public get HubLabelSecondaryCommand() { return this.simple("HubLabelSecondaryCommand"); }
    public get HubLabelTertiaryLabel() { return this.simple("HubLabelTertiaryLabel"); }
    public get HubLabelTertiaryCommand() { return this.simple("HubLabelTertiaryCommand"); }
    public get HubLabelvalue0Label() { return this.simple("HubLabelvalue0Label"); }
    public get HubLabelvalue1Label() { return this.simple("HubLabelvalue1Label"); }
    public get HubLabelShowSpace() { return this.simple("HubLabelShowSpace"); }
    public get HubLabelShowNotes() { return this.simple("HubLabelShowNotes"); }
    public get HubTabCallToAction() { return this.simple("HubTabCallToAction"); }
    public get HubTabVisualSettings() { return this.simple("HubTabVisualSettings"); }
    public get HubLabelPreHeaderText() { return this.simple("HubLabelPreHeaderText"); }
    public get HubLabelLabelText() { return this.simple("HubLabelLabelText"); }
    public get HubLabelColour() { return this.simple("HubLabelColour"); }
    public get HubLabelShowFault() { return this.simple("HubLabelShowFault"); }
    public get HubLabelShowControls() { return this.simple("HubLabelShowControls"); }
    public get HubLabelShowInfo() { return this.simple("HubLabelShowInfo"); }
    public get HubLabelShowSchedule() { return this.simple("HubLabelShowSchedule"); }
    public get HubLabelShowBooking() { return this.simple("HubLabelShowBooking"); }
    public get HubLabelShowCustom() { return this.simple("HubLabelShowCustom"); }
    public get HubLabelValidationField() { return this.simple("HubLabelValidationField"); }
    public get HubLabelHeaderTextPlaceholder() { return this.simple("HubLabelHeaderTextPlaceholder"); }
    public get HubLabelIconLabelPlaceHolder() { return this.simple("HubLabelIconLabelPlaceHolder"); }
    public get HubLabelSpaceLabelPlaceholder() { return this.simple("HubLabelSpaceLabelPlaceholder"); }
    public get HubLabelprimaryPlaceholder() { return this.simple("HubLabelprimaryPlaceholder"); }
    public get HubLabelPrimaryCommandPlaceholder() { return this.simple("HubLabelPrimaryCommandPlaceholder"); }
    public get HubLabelSecondaryPlaceholder() { return this.simple("HubLabelSecondaryPlaceholder"); }
    public get HubLabelSecondaryCommandPlaceholder() { return this.simple("HubLabelSecondaryCommandPlaceholder"); }
    public get HubLabelTertiaryPlaceholder() { return this.simple("HubLabelTertiaryPlaceholder"); }
    public get HubLabelTertiaryCommandPlaceholder() { return this.simple("HubLabelTertiaryCommandPlaceholder"); }
    public get HubLabelValue0Placeholder() { return this.simple("HubLabelValue0Placeholder"); }
    public get HubLabelValue1Placeholder() { return this.simple("HubLabelValue1Placeholder"); }
    public get HubLabelStateIdPlaceholder() { return this.simple("HubLabelStateIdPlaceholder"); }
    public get HubLabelStateNamePlaceholder() { return this.simple("HubLabelStateNamePlaceholder"); }
    public get HubLabelBodyTextPlaceholder() { return this.simple("HubLabelBodyTextPlaceholder"); }
    public get HubLabelPreHeaderTextPlaceholder() { return this.simple("HubLabelPreHeaderTextPlaceholder"); }
    public get HubLabelLabelPlaceholderText() { return this.simple("HubLabelLabelPlaceholderText"); }
    public get HubLabelColourPlaceholder() { return this.simple("HubLabelColourPlaceholder"); }
    public get HubLabelAlternatePlaceholder() { return this.simple("HubLabelAlternatePlaceholder"); }
    public get HubLabelShowFaultLabelPlaceholder() { return this.simple("HubLabelShowFaultLabelPlaceholder"); }
    public get HubLabelShowControlsLabelPlaceholder() { return this.simple("HubLabelShowControlsLabelPlaceholder"); }
    public get HubLabelShowInfoLabelPlaceholder() { return this.simple("HubLabelShowInfoLabelPlaceholder"); }
    public get HubLabelSchedulePlaceholder() { return this.simple("HubLabelSchedulePlaceholder"); }
    public get HubLabelShowBookingPlaceholder() { return this.simple("HubLabelShowBookingPlaceholder"); }
    public get HubLabelShowCustomPlaceholder() { return this.simple("HubLabelShowCustomPlaceholder"); }
    public get HubLabelValidationFieldPlaceholder() { return this.simple("HubLabelValidationFieldPlaceholder"); }
    public get HubLabelRoleLabel() { return this.simple("HubLabelRoleLabel"); }
    public get HubLabelTaskCategoryNamePlaceholder() { return this.simple("HubLabelTaskCategoryNamePlaceholder"); }
    public get HubLabelImageURI() { return this.simple("HubLabelImageURI"); }
    public get HubLabelImageURIPlaceholder() { return this.simple("HubLabelImageURIPlaceholder"); }
    public get HubLabelDeviceId() { return this.simple("HubLabelDeviceId"); }
    public get HubLabelDeviceIdPlaceholder() { return this.simple("HubLabelDeviceIdPlaceholder"); }
    public get HubLabelDeviceTypePlaceholder() { return this.simple("HubLabelDeviceTypePlaceholder"); }
    public get HubLabelDeviceCodePlaceholder() { return this.simple("HubLabelDeviceCodePlaceholder"); }
    public get HubLabelDeviceModePlaceholder() { return this.simple("HubLabelDeviceModePlaceholder"); }
    public get HubLabelSpaceIdPlaceholder() { return this.simple("HubLabelSpaceIdPlaceholder"); }
    public get HubLabelSignageId() { return this.simple("HubLabelSignageId"); }
    public get HubLabelSignageIdPlaceholder() { return this.simple("HubLabelSignageIdPlaceholder"); }
    public get HubLabelOEMMode() { return this.simple("HubLabelOEMMode"); }
    public get HubLabelOEMModePlaceholder() { return this.simple("HubLabelOEMModePlaceholder"); }
    public get HubLabelOEMClientImageURI() { return this.simple("HubLabelOEMClientImageURI"); }
    public get HubLabelOEMClientImageURIPlaceholder() { return this.simple("HubLabelOEMClientImageURIPlaceholder"); }
    public get HubLabelDeviceOEMBrandImageURI() { return this.simple("HubLabelDeviceOEMBrandImageURI"); }
    public get HubLabelOEMBrandImageURIPlaceholder() { return this.simple("HubLabelOEMBrandImageURIPlaceholder"); }
    public get HubLabelGeneralErrorMessage() { return this.simple("HubLabelGeneralErrorMessage"); }
    public get HubLabelGeneralErrorMessagePlaceholder() { return this.simple("HubLabelGeneralErrorMessagePlaceholder"); }
    public get HubLabelAuthenticationError() { return this.simple("HubLabelAuthenticationError"); }
    public get HubLabelAuthenticationErrorPlaceholder() { return this.simple("HubLabelAuthenticationErrorPlaceholder"); }
    public get HubLabelTypeLabelPlaceholder() { return this.simple("HubLabelTypeLabelPlaceholder"); }
    public get HubLabelLabelPlaceholder() { return this.simple("HubLabelLabelPlaceholder"); }
    public get HubLabelSectionPlaceholder() { return this.simple("HubLabelSectionPlaceholder"); }
    public get HubLabelDescription() { return this.simple("HubLabelDescription"); }
    public get HubLabelDescriptionPlaceholder() { return this.simple("HubLabelDescriptionPlaceholder"); }
    public get HubLabelToggleValues() { return this.simple("HubLabelToggleValues"); }
    public get HubLabelToggleValuesPlaceholder() { return this.simple("HubLabelToggleValuesPlaceholder"); }
    public get HubLabelToggleValueCount() { return this.simple("HubLabelToggleValueCount"); }
    public get HubLabelToggleValueCountPlaceholder() { return this.simple("HubLabelToggleValueCountPlaceholder"); }
    public get HubLabelInitialValue() { return this.simple("HubLabelInitialValue"); }
    public get HubLabelInitialValuePlaceholder() { return this.simple("HubLabelInitialValuePlaceholder"); }
    public get HubLabelLowLimit() { return this.simple("HubLabelLowLimit"); }
    public get HubLabelLowLimitPlaceholder() { return this.simple("HubLabelLowLimitPlaceholder"); }
    public get HubLabelHighLimitLabel() { return this.simple("HubLabelHighLimitLabel"); }
    public get HubLabelHighLimitPlaceholder() { return this.simple("HubLabelHighLimitPlaceholder"); }
    public get HubLabelUnits() { return this.simple("HubLabelUnits"); }
    public get HubLabelUnitsPlaceholder() { return this.simple("HubLabelUnitsPlaceholder"); }
    public get HubTabBuildingKPIs() { return this.simple("HubTabBuildingKPIs"); }
    public get HubLabelKPIType() { return this.simple("HubLabelKPIType"); }
    public get HubLabelText0() { return this.simple("HubLabelText0"); }
    public get HubLabelText0PlaceHolder() { return this.simple("HubLabelText0PlaceHolder"); }
    public get HubLabelText1() { return this.simple("HubLabelText1"); }
    public get HubLabelText1PlaceHolder() { return this.simple("HubLabelText1PlaceHolder"); }
    public get HubLabelText2() { return this.simple("HubLabelText2"); }
    public get HubLabelText2PlaceHolder() { return this.simple("HubLabelText2PlaceHolder"); }
    public get HubLabelValueURI() { return this.simple("HubLabelValueURI"); }
    public get HubLabelValueURIPlaceHolder() { return this.simple("HubLabelValueURIPlaceHolder"); }
    public get HubLabelMaximumValue() { return this.simple("HubLabelMaximumValue"); }
    public get HubLabelMaximumValuePlaceholder() { return this.simple("HubLabelMaximumValuePlaceholder"); }
    public get HubLabelLimit1() { return this.simple("HubLabelLimit1"); }
    public get HubLabellimit1Placeholder() { return this.simple("HubLabellimit1Placeholder"); }
    public get HubLabelLimit2() { return this.simple("HubLabelLimit2"); }
    public get HubLabelLimit2Placeholder() { return this.simple("HubLabelLimit2Placeholder"); }
    public get HubLabelMode() { return this.simple("HubLabelMode"); }
    public get HubLabelOrientation() { return this.simple("HubLabelOrientation"); }
    public get HubLabellimit1PlaceholderText() { return this.simple("HubLabellimit1PlaceholderText"); }
    public get HubLabelSignage() { return this.simple("HubLabelSignage"); }
    public get HubLabelsignageMode() { return this.simple("HubLabelsignageMode"); }
    public get HubLabelsignagePlaceholder() { return this.simple("HubLabelsignagePlaceholder"); }
    public get HubLabelShowBuildingName() { return this.simple("HubLabelShowBuildingName"); }
    public get HubLabelBuildingNamePlaceholder() { return this.simple("HubLabelBuildingNamePlaceholder"); }
    public get HubLabelRememberCheckIn() { return this.simple("HubLabelRememberCheckIn"); }
    public get HubLabelxLocation() { return this.simple("HubLabelxLocation"); }
    public get HubLabelxLocationPlaceholder() { return this.simple("HubLabelxLocationPlaceholder"); }
    public get HubLabelyLocation() { return this.simple("HubLabelyLocation"); }
    public get HubLabelyLocationPlaceholder() { return this.simple("HubLabelyLocationPlaceholder"); }
    public get HubLabelResetPassword() { return this.simple("HubLabelResetPassword"); }
    public get HubMenuRoomsDesks() { return this.simple("HubMenuRoomsDesks"); }
    public get HubTabAwaitingApproval() { return this.simple("HubTabAwaitingApproval"); }
    public get HubTabToday() { return this.simple("HubTabToday"); }
    public get HubTabNextWorkingDay() { return this.simple("HubTabNextWorkingDay"); }
    public get HubTabOnSite() { return this.simple("HubTabOnSite"); }
    public get HubLabelApproved() { return this.simple("HubLabelApproved"); }
    public get HubLabelInActive() { return this.simple("HubLabelInActive"); }
    public get HubLabelFormat() { return this.simple("HubLabelFormat"); }
    public get HubLabelChecked() { return this.simple("HubLabelChecked"); }
    public get HubLabelUnchecked() { return this.simple("HubLabelUnchecked"); }
    public get HubLabelArrivalLocation() { return this.simple("HubLabelArrivalLocation"); }
    public get HubLabelBookable() { return this.simple("HubLabelBookable"); }
    public get HubLabelReservationRequired() { return this.simple("HubLabelReservationRequired"); }
    public get HubLabelMinBookingDurationminutes() { return this.simple("HubLabelMinBookingDurationminutes"); }
    public get HubLabelMaxBookingDurationminutes() { return this.simple("HubLabelMaxBookingDurationminutes"); }
    public get HubLabelSignsofLifeThresholdminutes() { return this.simple("HubLabelSignsofLifeThresholdminutes"); }
    public get HubLabelCleanThresholdminutes() { return this.simple("HubLabelCleanThresholdminutes"); }
    public get HubLabelOccupancySystem() { return this.simple("HubLabelOccupancySystem"); }
    public get HubLabelOccupancyCountMode() { return this.simple("HubLabelOccupancyCountMode"); }
    public get HubLabelareaId() { return this.simple("HubLabelareaId"); }
    public get HubLabelSensorId() { return this.simple("HubLabelSensorId"); }
    public get HubLabelSensor() { return this.simple("HubLabelSensor"); }
    public get HubMenuSensor() { return this.simple("HubMenuSensor"); }
    public get HubTabOpservSpacesOccupied() { return this.simple("HubTabOpservSpacesOccupied"); }
    public get HubLabelextBookingSystemLabel() { return this.simple("HubLabelextBookingSystemLabel"); }
    public get HubLabelBookingSystemSpace() { return this.simple("HubLabelBookingSystemSpace"); }
    public get HubLabelBookingDetails() { return this.simple("HubLabelBookingDetails"); }
    public get HubLabelextCateringSystem() { return this.simple("HubLabelextCateringSystem"); }
    public get HubLabelCateringSystemSpace() { return this.simple("HubLabelCateringSystemSpace"); }
    public get HubLabelGatewayMode() { return this.simple("HubLabelGatewayMode"); }
    public get HubLabelGateway() { return this.simple("HubLabelGateway"); }
    public get HubLabelBookingFor() { return this.simple("HubLabelBookingFor"); }
    public get HubLabelBookingOrMeetingName() { return this.simple("HubLabelBookingOrMeetingName"); }
    public get HubLabelLightControlMode() { return this.simple("HubLabelLightControlMode"); }
    public get HubLabelLightScenes() { return this.simple("HubLabelLightScenes"); }
    public get HubLabelAVSourceScenes() { return this.simple("HubLabelAVSourceScenes"); }
    public get HubLabelSpaceTypeLabel() { return this.simple("HubLabelSpaceTypeLabel"); }
    public get HubLabelOccupancyType() { return this.simple("HubLabelOccupancyType"); }
    public get HubLabelCapacityMaximumOccupancy() { return this.simple("HubLabelCapacityMaximumOccupancy"); }
    public get HubLabelmapURI() { return this.simple("HubLabelmapURI"); }
    public get HubLabelyposition() { return this.simple("HubLabelyposition"); }
    public get HubLabelxpositionLabel() { return this.simple("HubLabelxpositionLabel"); }
    public get HubLabelCustomInformation() { return this.simple("HubLabelCustomInformation"); }
    public get HubTabCustomFilter() { return this.simple("HubTabCustomFilter"); }
    public get HubLabelSpaceClass() { return this.simple("HubLabelSpaceClass"); }
    public get HubLabelworkTypeLabel() { return this.simple("HubLabelworkTypeLabel"); }
    public get HubLabelId() { return this.simple("HubLabelId"); }
    public get HubLabelOpservSpacesFiltered() { return this.simple("HubLabelOpservSpacesFiltered"); }
    public get HubLabelExternalSystems() { return this.simple("HubLabelExternalSystems"); }
    public get HubLabelerrorSomethingWentWrong() { return this.simple("HubLabelerrorSomethingWentWrong"); }
    public get HublabelErrorEmptyData() { return this.simple("HublabelErrorEmptyData"); }
    public get HubLabelConfirmCancelMessage() { return this.simple("HubLabelConfirmCancelMessage"); }
    public get HubLabelNative() { return this.simple("HubLabelNative"); }
    public get HubLabelAAD() { return this.simple("HubLabelAAD"); }
    public get HubLabeloktaLabel() { return this.simple("HubLabeloktaLabel"); }
    public get HubLabelDeleteMessage() { return this.simple("HubLabelDeleteMessage"); }
    public get HubLabelRoleDetailsSubMenu() { return this.simple("HubLabelRoleDetailsSubMenu"); }
    public get HubLabelOktaProperties() { return this.simple("HubLabelOktaProperties"); }
    public get HubLabelAzureADProperties() { return this.simple("HubLabelAzureADProperties"); }
    public get HubLabelNativeProperty() { return this.simple("HubLabelNativeProperty"); }
    public get HubLabelOKTAIdentity() { return this.simple("HubLabelOKTAIdentity"); }
    public get HubLabelZeroNotAcceptable() { return this.simple("HubLabelZeroNotAcceptable"); }
    public get HubLabelNewProvider() { return this.simple("HubLabelNewProvider"); }
    public get HubLabelOnecharacterAnyType() { return this.simple("HubLabelOnecharacterAnyType"); }
    public get HubLabelOnecharacterTwoDifferenttypes() { return this.simple("HubLabelOnecharacterTwoDifferenttypes"); }
    public get HubLabelOnecharacterThreeDifferentTypes() { return this.simple("HubLabelOnecharacterThreeDifferentTypes"); }
    public get HubLabelOnecharacterFOURDifferenttypes() { return this.simple("HubLabelOnecharacterFOURDifferenttypes"); }
    public get HubLabelLastSynchronisation() { return this.simple("HubLabelLastSynchronisation"); }
    public get HubLabelProvideLastSynchronisation() { return this.simple("HubLabelProvideLastSynchronisation"); }
    public get HubLabelProvideStatus() { return this.simple("HubLabelProvideStatus"); }
    public get HubLabelIsSyncNowLabel() { return this.simple("HubLabelIsSyncNowLabel"); }
    public get HubLabeShowError() { return this.simple("HubLabeShowError"); }
    public get HubLabelTokenErorr() { return this.simple("HubLabelTokenErorr"); }
    public get HubLabelChangePassword() { return this.simple("HubLabelChangePassword"); }
    public get HubLabelDiagnostics() { return this.simple("HubLabelDiagnostics"); }
    public get HubLabelOriginalPassword() { return this.simple("HubLabelOriginalPassword"); }
    public get HubLabelNewPassword() { return this.simple("HubLabelNewPassword"); }
    public get HubLabelReType() { return this.simple("HubLabelReType"); }
    public get HubLabelOk() { return this.simple("HubLabelOk"); }
    public get HubLabelNewDevice() { return this.simple("HubLabelNewDevice"); }
    public get HubLabelSlider() { return this.simple("HubLabelSlider"); }
    public get HubLabelTextIndicatorSlider() { return this.simple("HubLabelTextIndicatorSlider"); }
    public get HubLabelNumericIndicator() { return this.simple("HubLabelNumericIndicator"); }
    public get HubLabelTextIndicator() { return this.simple("HubLabelTextIndicator"); }
    public get HubLabelIncorrectEmailError() { return this.simple("HubLabelIncorrectEmailError"); }
    public get HubLabelEnterPasswordMessage() { return this.simple("HubLabelEnterPasswordMessage"); }
    public get HubLabelEnterEmailMessage() { return this.simple("HubLabelEnterEmailMessage"); }
    public get HubLabelProvideAVSourceScenes() { return this.simple("HubLabelProvideAVSourceScenes"); }
    public get HubLabelProvideLightScenes() { return this.simple("HubLabelProvideLightScenes"); }
    public get HubLabelProvideGatewayl() { return this.simple("HubLabelProvideGatewayl"); }
    public get HubLabelProvideCateringSystemSpace() { return this.simple("HubLabelProvideCateringSystemSpace"); }
    public get HubLabelProvideBookingSystemSpace() { return this.simple("HubLabelProvideBookingSystemSpace"); }
    public get HubLabelProvideSensorId() { return this.simple("HubLabelProvideSensorId"); }
    public get HubLabelProvideAreaId() { return this.simple("HubLabelProvideAreaId"); }
    public get HubLabelCleanThreshold() { return this.simple("HubLabelCleanThreshold"); }
    public get HubLabelSignsofLifeThreshold() { return this.simple("HubLabelSignsofLifeThreshold"); }
    public get HubLabelMaxBookingDuration() { return this.simple("HubLabelMaxBookingDuration"); }
    public get HubLabelMinBookingDuration() { return this.simple("HubLabelMinBookingDuration"); }
    public get HubLabelProvideSize() { return this.simple("HubLabelProvideSize"); }
    public get HubLabelProvideCapacity() { return this.simple("HubLabelProvideCapacity"); }
    public get HubLabelProvideMapURI() { return this.simple("HubLabelProvideMapURI"); }
    public get HubLabelProvideLevelNumber() { return this.simple("HubLabelProvideLevelNumber"); }
    public get HubLabelprovideYposition() { return this.simple("HubLabelprovideYposition"); }
    public get HubLabelProvideXposition() { return this.simple("HubLabelProvideXposition"); }
    public get HubLabelProvideCustomInformation() { return this.simple("HubLabelProvideCustomInformation"); }
    public get HubLabelProvideLatitude() { return this.simple("HubLabelProvideLatitude"); }
    public get HubLabelProvideLongitude() { return this.simple("HubLabelProvideLongitude"); }
    public get HubLabelProvideAddress() { return this.simple("HubLabelProvideAddress"); }
    public get HubLabelProvideCity() { return this.simple("HubLabelProvideCity"); }
    public get HubLabelProvideCountryName() { return this.simple("HubLabelProvideCountryName"); }
    public get HubLabelProvidevalue() { return this.simple("HubLabelProvidevalue"); }
    public get HubLabelProvideId() { return this.simple("HubLabelProvideId"); }
    public get HubLabelProvideCode() { return this.simple("HubLabelProvideCode"); }
    public get HubLabelEnvironmental() { return this.simple("HubLabelEnvironmental"); }
    public get HubLabelRoot() { return this.simple("HubLabelRoot"); }
    public get HubLabelRegion() { return this.simple("HubLabelRegion"); }
    public get HubLabelLand() { return this.simple("HubLabelLand"); }
    public get HubLabelBuilding() { return this.simple("HubLabelBuilding"); }
    public get HubLabellevel() { return this.simple("HubLabellevel"); }
    public get HubLabelZone() { return this.simple("HubLabelZone"); }
    public get HubLabelRoom() { return this.simple("HubLabelRoom"); }
    public get HubLabelDesk() { return this.simple("HubLabelDesk"); }
    public get HubLabelManual() { return this.simple("HubLabelManual"); }
    public get HubLabelAutomatic() { return this.simple("HubLabelAutomatic"); }
    public get HubLabelNotBookable() { return this.simple("HubLabelNotBookable"); }
    public get HubLabelReservable() { return this.simple("HubLabelReservable"); }
    public get HublabelAutoCheckInLabel() { return this.simple("HublabelAutoCheckInLabel"); }
    public get HublabelCheckInTime() { return this.simple("HublabelCheckInTime"); }
    public get HublabelCompletionTime() { return this.simple("HublabelCompletionTime"); }
    public get HublabelCancelletionTime() { return this.simple("HublabelCancelletionTime"); }
    public get HubLabelAlldayBookingsOnly() { return this.simple("HubLabelAlldayBookingsOnly"); }
    public get HubLabelNone() { return this.simple("HubLabelNone"); }
    public get HubLabelCogniPointRESTJSON() { return this.simple("HubLabelCogniPointRESTJSON"); }
    public get HubLabelCogniPointWebhook() { return this.simple("HubLabelCogniPointWebhook"); }
    public get HubLabelVergesenseRESTJSON() { return this.simple("HubLabelVergesenseRESTJSON"); }
    public get HubLabelVergesenseWebhook() { return this.simple("HubLabelVergesenseWebhook"); }
    public get HubLabelSyneticaMQTT() { return this.simple("HubLabelSyneticaMQTT"); }
    public get HubLabelgENESIS64WebhookLabel() { return this.simple("HubLabelgENESIS64WebhookLabel"); }
    public get HubLabelPresence() { return this.simple("HubLabelPresence"); }
    public get HubLabelTrafficLine() { return this.simple("HubLabelTrafficLine"); }
    public get HubLabelOffice365() { return this.simple("HubLabelOffice365"); }
    public get HubLabelCondeco() { return this.simple("HubLabelCondeco"); }
    public get HubLabelgENESIS64() { return this.simple("HubLabelgENESIS64"); }
    public get HubLabelValues() { return this.simple("HubLabelValues"); }
    public get HubLabelScenes() { return this.simple("HubLabelScenes"); }
    public get HubLabelVisitStart() { return this.simple("HubLabelVisitStart"); }
    public get HubLabelVisitEnd() { return this.simple("HubLabelVisitEnd"); }
    public get HubLabelVisitorEmail() { return this.simple("HubLabelVisitorEmail"); }
    public get HubLabelvisitor() { return this.simple("HubLabelvisitor"); }
    public get HubLabelCompany() { return this.simple("HubLabelCompany"); }
    public get HubLabelHostName() { return this.simple("HubLabelHostName"); }
    public get HubLabelFacilityManagementText() { return this.simple("HubLabelFacilityManagementText"); }
    public get HubLabelBookings() { return this.simple("HubLabelBookings"); }
    public get HubLabelTIMESPENT() { return this.simple("HubLabelTIMESPENT"); }
    public get HubMenuBookings() { return this.simple("HubMenuBookings"); }
    public get HubMenuCatering() { return this.simple("HubMenuCatering"); }
    public get HubMenuTasks() { return this.simple("HubMenuTasks"); }
    public get HubMenuVisitors() { return this.simple("HubMenuVisitors"); }
    public get HubLabelTaskTypeName() { return this.simple("HubLabelTaskTypeName"); }
    public get HubLabelProvideTaskTypeName() { return this.simple("HubLabelProvideTaskTypeName"); }
    public get HubLabelTaskTypeId() { return this.simple("HubLabelTaskTypeId"); }
    public get HubLabelProvideTaskTypeIdLabel() { return this.simple("HubLabelProvideTaskTypeIdLabel"); }
    public get HubLabelCategoryId() { return this.simple("HubLabelCategoryId"); }
    public get HubLabelProvideCategoryId() { return this.simple("HubLabelProvideCategoryId"); }
    public get HubLabelProvideSpaceTypeLabel() { return this.simple("HubLabelProvideSpaceTypeLabel"); }
    public get HubLabelResolveDuration() { return this.simple("HubLabelResolveDuration"); }
    public get HubLabelProvideResolveDuration() { return this.simple("HubLabelProvideResolveDuration"); }
    public get HubLabelPriorityLabel() { return this.simple("HubLabelPriorityLabel"); }
    public get HubLabelProvidePriority() { return this.simple("HubLabelProvidePriority"); }
    public get HubLabelPriorityResolveDuration() { return this.simple("HubLabelPriorityResolveDuration"); }
    public get HubLabelProvidePriorityResolveDuration() { return this.simple("HubLabelProvidePriorityResolveDuration"); }
    public get HubLabelDefaultCleanTask() { return this.simple("HubLabelDefaultCleanTask"); }
    public get HubLabelAddDetails() { return this.simple("HubLabelAddDetails"); }
    public get HubLabelEquipmentType() { return this.simple("HubLabelEquipmentType"); }
    public get HubTabOverview() { return this.simple("HubTabOverview"); }
    public get HubMenuOverview() { return this.simple("HubMenuOverview"); }
    public get HubButtonApprove() { return this.simple("HubButtonApprove"); }
    public get HubButtonDeny() { return this.simple("HubButtonDeny"); }
    public get HubButtonCheckIn() { return this.simple("HubButtonCheckIn"); }
    public get HubButtonCheckOut() { return this.simple("HubButtonCheckOut"); }
    public get HubTabAssigned() { return this.simple("HubTabAssigned"); }
    public get HubTabUnAssigned() { return this.simple("HubTabUnAssigned"); }
    public get HubLabelUnAssignedTask() { return this.simple("HubLabelUnAssignedTask"); }
    public get HubLabelOverDewTask() { return this.simple("HubLabelOverDewTask"); }
    public get HubTabResolved() { return this.simple("HubTabResolved"); }
    public get HubButtonResolveMyTaskButton() { return this.simple("HubButtonResolveMyTaskButton"); }
    public get HubLabelTask() { return this.simple("HubLabelTask"); }
    public get HubLabeltaskType() { return this.simple("HubLabeltaskType"); }
    public get HubLabelCategory() { return this.simple("HubLabelCategory"); }
    public get HubLabelLocation() { return this.simple("HubLabelLocation"); }
    public get HubLabelTimeRemaining() { return this.simple("HubLabelTimeRemaining"); }
    public get HubLabelresolutionTimeLabel() { return this.simple("HubLabelresolutionTimeLabel"); }
    public get HubLabelCreatedBy() { return this.simple("HubLabelCreatedBy"); }
    public get HubLabelResolver() { return this.simple("HubLabelResolver"); }
    public get HubLabelDetailslabel() { return this.simple("HubLabelDetailslabel"); }
    public get HubLabelVisitID() { return this.simple("HubLabelVisitID"); }
    public get HubLabelVisitStartLabelPlaceholder() { return this.simple("HubLabelVisitStartLabelPlaceholder"); }
    public get HubLabelVisitEndLabelPlaceholder() { return this.simple("HubLabelVisitEndLabelPlaceholder"); }
    public get HubLabelHostNamePlaceholderText() { return this.simple("HubLabelHostNamePlaceholderText"); }
    public get HubLabelBookingName() { return this.simple("HubLabelBookingName"); }
    public get HublabelBookingId() { return this.simple("HublabelBookingId"); }
    public get HublabelExternalId() { return this.simple("HublabelExternalId"); }
    public get HubLabelBookingStart() { return this.simple("HubLabelBookingStart"); }
    public get HubLabelBookingEnd() { return this.simple("HubLabelBookingEnd"); }
    public get HubLabelSpaceName() { return this.simple("HubLabelSpaceName"); }
    public get HubLabelArrivalLocationId() { return this.simple("HubLabelArrivalLocationId"); }
    public get HubLabelCancelled() { return this.simple("HubLabelCancelled"); }
    public get HubLabelDeniedLabel() { return this.simple("HubLabelDeniedLabel"); }
    public get HubLabelCheckedIn() { return this.simple("HubLabelCheckedIn"); }
    public get HubLabelCheckedOut() { return this.simple("HubLabelCheckedOut"); }
    public get HubLabelNoShow() { return this.simple("HubLabelNoShow"); }
    public get HubMenuNotificationSetup() { return this.simple("HubMenuNotificationSetup"); }
    public get HubLabelVisitsAwaitingApproval() { return this.simple("HubLabelVisitsAwaitingApproval"); }
    public get HubLabelVisitsScheduledforToday() { return this.simple("HubLabelVisitsScheduledforToday"); }
    public get HubLabelVisitsScheduledForNextWorkingDay() { return this.simple("HubLabelVisitsScheduledForNextWorkingDay"); }
    public get HubLabelOnSiteCheckedInVisits() { return this.simple("HubLabelOnSiteCheckedInVisits"); }
    public get HubLabelApprovedVisits() { return this.simple("HubLabelApprovedVisits"); }
    public get HubButtonCheckInCap() { return this.simple("HubButtonCheckInCap"); }
    public get HubButtonCheckOutCap() { return this.simple("HubButtonCheckOutCap"); }
    public get HubLabelWorkInProgressLabel() { return this.simple("HubLabelWorkInProgressLabel"); }
    public get HubLabelComplete() { return this.simple("HubLabelComplete"); }
    public get HubLabelInvalidEmailError() { return this.simple("HubLabelInvalidEmailError"); }
    public get HubLabelOneCharacterError() { return this.simple("HubLabelOneCharacterError"); }
    public get HubLabelCharacterLimit() { return this.simple("HubLabelCharacterLimit"); }
    public get HubLabelSelectBuildingError() { return this.simple("HubLabelSelectBuildingError"); }
    public get HubLabelsppSettingsSubMenuTitle() { return this.simple("HubLabelsppSettingsSubMenuTitle"); }
    public get HubLabelDataSecuritySubMenuTitle() { return this.simple("HubLabelDataSecuritySubMenuTitle"); }
    public get HubLabelOwner() { return this.simple("HubLabelOwner"); }
    public get HubLabelAttendees() { return this.simple("HubLabelAttendees"); }
    public get HubLabelAddAttendeeLabel() { return this.simple("HubLabelAddAttendeeLabel"); }
    public get HubLabelLocalTime() { return this.simple("HubLabelLocalTime"); }
    public get HubLabelEquipmentId() { return this.simple("HubLabelEquipmentId"); }
    public get HubLabelTasksOverview() { return this.simple("HubLabelTasksOverview"); }
    public get HubLabelAssignedTasks() { return this.simple("HubLabelAssignedTasks"); }
    public get HubButtonUnassignedTasks() { return this.simple("HubButtonUnassignedTasks"); }
    public get HubLabelResolvedTasks() { return this.simple("HubLabelResolvedTasks"); }
    public get HubLabeluserType() { return this.simple("HubLabeluserType"); }
    public get HubLabeluserLabel() { return this.simple("HubLabeluserLabel"); }
    public get HubLabelApplication() { return this.simple("HubLabelApplication"); }
    public get HubLabelApplicationKey() { return this.simple("HubLabelApplicationKey"); }
    public get HubLabelBookingsOverview() { return this.simple("HubLabelBookingsOverview"); }
    public get HubLabelBookingsForToday() { return this.simple("HubLabelBookingsForToday"); }
    public get HubLabelBookingsForNextWorkingDay() { return this.simple("HubLabelBookingsForNextWorkingDay"); }
    public get HubLabelBookingsfromPreviousWorkingDay() { return this.simple("HubLabelBookingsfromPreviousWorkingDay"); }
    public get HubLabelBookingsforNext7Days() { return this.simple("HubLabelBookingsforNext7Days"); }
    public get HubLabelBookingsforFilteredList() { return this.simple("HubLabelBookingsforFilteredList"); }
    public get HubLabelBookedFor() { return this.simple("HubLabelBookedFor"); }
    public get HubLabelBookedBy() { return this.simple("HubLabelBookedBy"); }
    public get HubLabelCompleted() { return this.simple("HubLabelCompleted"); }
    public get HubTabPreviousWorkingDay() { return this.simple("HubTabPreviousWorkingDay"); }
    public get HubLabelAHU() { return this.simple("HubLabelAHU"); }
    public get HubLabelCAV() { return this.simple("HubLabelCAV"); }
    public get HubLabelVAV() { return this.simple("HubLabelVAV"); }
    public get HubLabelSF() { return this.simple("HubLabelSF"); }
    public get HubLabelEF() { return this.simple("HubLabelEF"); }
    public get HubLabelTef() { return this.simple("HubLabelTef"); }
    public get HubLabelPumpSet() { return this.simple("HubLabelPumpSet"); }
    public get HubLabelPump() { return this.simple("HubLabelPump"); }
    public get HubLabelphe() { return this.simple("HubLabelphe"); }
    public get HubLabelThermalStore() { return this.simple("HubLabelThermalStore"); }
    public get HubLabelHeatPump() { return this.simple("HubLabelHeatPump"); }
    public get HubLabelBoiler() { return this.simple("HubLabelBoiler"); }
    public get HubLabelCalorifier() { return this.simple("HubLabelCalorifier"); }
    public get HubLabelChiller() { return this.simple("HubLabelChiller"); }
    public get HubLabelCoolingTower() { return this.simple("HubLabelCoolingTower"); }
    public get HubLabelpu() { return this.simple("HubLabelpu"); }
    public get HubLabelfcu() { return this.simple("HubLabelfcu"); }
    public get HubLabelAirCurtain() { return this.simple("HubLabelAirCurtain"); }
    public get HubLabeltrenchHeater() { return this.simple("HubLabeltrenchHeater"); }
    public get HubLabelGenerator() { return this.simple("HubLabelGenerator"); }
    public get HubLabelchp() { return this.simple("HubLabelchp"); }
    public get HubLabelups() { return this.simple("HubLabelups"); }
    public get HubLabelController() { return this.simple("HubLabelController"); }
    public get HubLabelElectricMeter() { return this.simple("HubLabelElectricMeter"); }
    public get HubLabelThermalMeter() { return this.simple("HubLabelThermalMeter"); }
    public get HubLabelGasMeter() { return this.simple("HubLabelGasMeter"); }
    public get HubLabelWaterMeter() { return this.simple("HubLabelWaterMeter"); }
    public get HubLabelFireExtinguisher() { return this.simple("HubLabelFireExtinguisher"); }
    public get HubLabelCallPoint() { return this.simple("HubLabelCallPoint"); }
    public get HubLabelSmokeDetector() { return this.simple("HubLabelSmokeDetector"); }
    public get HubLabelHeatDetector() { return this.simple("HubLabelHeatDetector"); }
    public get HubLabelVisualAndSounder() { return this.simple("HubLabelVisualAndSounder"); }
    public get HubLabelFireAlarmPanel() { return this.simple("HubLabelFireAlarmPanel"); }
    public get HubLabelOutdoorUnit() { return this.simple("HubLabelOutdoorUnit"); }
    public get HubLabelIndoorUnit() { return this.simple("HubLabelIndoorUnit"); }
    public get HubLabelPresentationAid() { return this.simple("HubLabelPresentationAid"); }
    public get HubLabelHearingAid() { return this.simple("HubLabelHearingAid"); }
    public get HubLabeltvAv() { return this.simple("HubLabeltvAv"); }
    public get HubLabelConfirmation() { return this.simple("HubLabelConfirmation"); }
    public get HubLabelDisplayScreen() { return this.simple("HubLabelDisplayScreen"); }
    public get HubLabelCoffeeMachine() { return this.simple("HubLabelCoffeeMachine"); }
    public get HubLabelUnableToLoginMessage() { return this.simple("HubLabelUnableToLoginMessage"); }
    public get HubLabelBookingStatus() { return this.simple("HubLabelBookingStatus"); }
    public get HubLabelCheckinStatus() { return this.simple("HubLabelCheckinStatus"); }
    public get HubLabelCheckOutStatus() { return this.simple("HubLabelCheckOutStatus"); }
    public get HubLabelRedirectionStatus() { return this.simple("HubLabelRedirectionStatus"); }
    public get HubLabelRedirectionSubtitle_part_1() { return this.simple("HubLabelRedirectionSubtitle_part_1"); }
    public get HubLabelIsDefault() { return this.simple("HubLabelIsDefault"); }
    public get HubLabelVisitApprovalStatus() { return this.simple("HubLabelVisitApprovalStatus"); }
    public get HubLabelCheckinCheckoutStatusLabel() { return this.simple("HubLabelCheckinCheckoutStatusLabel"); }
    public get HubLabelVisitorsOverview() { return this.simple("HubLabelVisitorsOverview"); }
    public get HubLabelVisitorLevel() { return this.simple("HubLabelVisitorLevel"); }
    public get HubButtonAssign() { return this.simple("HubButtonAssign"); }
    public get HubButtonUnAssign() { return this.simple("HubButtonUnAssign"); }
    public get HubButtonReallocate() { return this.simple("HubButtonReallocate"); }
    public get HubButtonSetInProgress() { return this.simple("HubButtonSetInProgress"); }
    public get HubButtonChangePriority() { return this.simple("HubButtonChangePriority"); }
    public get HubLabelDefault() { return this.simple("HubLabelDefault"); }
    public get HubLabelComment() { return this.simple("HubLabelComment"); }
    public get HubLabelNoRights() { return this.simple("HubLabelNoRights"); }
    public get HubLabelTaskStatus() { return this.simple("HubLabelTaskStatus"); }
    public get HubLabelPassFailRate() { return this.simple("HubLabelPassFailRate"); }
    public get HubLabelUserSelection() { return this.simple("HubLabelUserSelection"); }
    public get HubLabelAttendeeSelection() { return this.simple("HubLabelAttendeeSelection"); }
    public get HubLabelnameEmailAddress() { return this.simple("HubLabelnameEmailAddress"); }
    public get HubLabelSetPriority() { return this.simple("HubLabelSetPriority"); }
    public get HubLabelMessageBox() { return this.simple("HubLabelMessageBox"); }
    public get HubLabelPrivate() { return this.simple("HubLabelPrivate"); }
    public get HubLabelAutomaticCheckin() { return this.simple("HubLabelAutomaticCheckin"); }
    public get HubLabelStart() { return this.simple("HubLabelStart"); }
    public get HubLabelStartDateVisitFilter() { return this.simple("HubLabelStartDateVisitFilter"); }
    public get HubLabelEndDateVisitFilter() { return this.simple("HubLabelEndDateVisitFilter"); }
    public get HublabelHostVisitFilter() { return this.simple("HublabelHostVisitFilter"); }
    public get HubLabelEnd() { return this.simple("HubLabelEnd"); }
    public get HubLabelInvalidJSONMessage() { return this.simple("HubLabelInvalidJSONMessage"); }
    public get HubLabelTaskCategory() { return this.simple("HubLabelTaskCategory"); }
    public get HubLabelMACaddress() { return this.simple("HubLabelMACaddress"); }
    public get HubLabelMACaddressPlaceholder() { return this.simple("HubLabelMACaddressPlaceholder"); }
    public get HubLabelIPAddressLabel() { return this.simple("HubLabelIPAddressLabel"); }
    public get HubLabelIPAddressPlaceholder() { return this.simple("HubLabelIPAddressPlaceholder"); }
    public get HubTabDeviceConfiguration() { return this.simple("HubTabDeviceConfiguration"); }
    public get HubLabelEventType() { return this.simple("HubLabelEventType"); }
    public get HubLabelEmailNotification() { return this.simple("HubLabelEmailNotification"); }
    public get HubLabelPushNotification() { return this.simple("HubLabelPushNotification"); }
    public get HubLabelEventTypePlaceholder() { return this.simple("HubLabelEventTypePlaceholder"); }
    public get HubLabelTo() { return this.simple("HubLabelTo"); }
    public get HubLabelFrom() { return this.simple("HubLabelFrom"); }
    public get HubLabelToPlaceholder() { return this.simple("HubLabelToPlaceholder"); }
    public get HubLabelcc() { return this.simple("HubLabelcc"); }
    public get HubLabelccPlaceholder() { return this.simple("HubLabelccPlaceholder"); }
    public get HubLabelbccLabel() { return this.simple("HubLabelbccLabel"); }
    public get HubLabelbccPlaceholder() { return this.simple("HubLabelbccPlaceholder"); }
    public get HubLabelReplyTo() { return this.simple("HubLabelReplyTo"); }
    public get HubLabelReplyToPlaceholder() { return this.simple("HubLabelReplyToPlaceholder"); }
    public get HubLabelSubject() { return this.simple("HubLabelSubject"); }
    public get HubLabelSubjectPlaceholder() { return this.simple("HubLabelSubjectPlaceholder"); }
    public get HubLabelhtmlMessageLabel() { return this.simple("HubLabelhtmlMessageLabel"); }
    public get HubLabelhtmlMessagePlaceholder() { return this.simple("HubLabelhtmlMessagePlaceholder"); }
    public get HubLabelSendEmail() { return this.simple("HubLabelSendEmail"); }
    public get HubLabelMessage() { return this.simple("HubLabelMessage"); }
    public get HubLabelSendMobilePushMessage() { return this.simple("HubLabelSendMobilePushMessage"); }
    public get HubLabelTitle() { return this.simple("HubLabelTitle"); }
    public get HubLabelShortMessage() { return this.simple("HubLabelShortMessage"); }
    public get HubLabelTitlePlaceholder() { return this.simple("HubLabelTitlePlaceholder"); }
    public get HubLabelMessagePlaceholder() { return this.simple("HubLabelMessagePlaceholder"); }
    public get HubLabelarea() { return this.simple("HubLabelarea"); }
    public get HubLabelareaPlaceholder() { return this.simple("HubLabelareaPlaceholder"); }
    public get HubLabelAction() { return this.simple("HubLabelAction"); }
    public get HubLabelActionPlaceholder() { return this.simple("HubLabelActionPlaceholder"); }
    public get HubLabelBadge() { return this.simple("HubLabelBadge"); }
    public get HubLabelBadgePlaceholder() { return this.simple("HubLabelBadgePlaceholder"); }
    public get HubLabelActionURI() { return this.simple("HubLabelActionURI"); }
    public get HubLabelActionURIPlaceholder() { return this.simple("HubLabelActionURIPlaceholder"); }
    public get HubLabelSecurityProviderType() { return this.simple("HubLabelSecurityProviderType"); }
    public get HubLabelRule() { return this.simple("HubLabelRule"); }
    public get HubLabelRulePlaceholder() { return this.simple("HubLabelRulePlaceholder"); }
    public get HubTabNext7Days() { return this.simple("HubTabNext7Days"); }
    public get HubTabFutureVisitors() { return this.simple("HubTabFutureVisitors"); }
    public get HubLabelHostNamePlaceholder() { return this.simple("HubLabelHostNamePlaceholder"); }
    public get HubLabelHighPriority() { return this.simple("HubLabelHighPriority"); }
    public get HubLabelNormalPriority() { return this.simple("HubLabelNormalPriority"); }
    public get HubLabelLowPriority() { return this.simple("HubLabelLowPriority"); }
    public get HubMenuFileManagement() { return this.simple("HubMenuFileManagement"); }
    public get HubMenuRoamer() { return this.simple("HubMenuRoamer"); }
    public get HubLabelFilter() { return this.simple("HubLabelFilter"); }
    public get HubMenuUnity() { return this.simple("HubMenuUnity"); }
    public get HubMenuInspire() { return this.simple("HubMenuInspire"); }
    public get HubMenuCommon() { return this.simple("HubMenuCommon"); }
    public get HubLabelNoneSecurityIcon() { return this.simple("HubLabelNoneSecurityIcon"); }
    public get HubLabelBasicSecurityIcon() { return this.simple("HubLabelBasicSecurityIcon"); }
    public get HubLabelLocalSecurityIcon() { return this.simple("HubLabelLocalSecurityIcon"); }
    public get HubLabelDeepSecurityIcon() { return this.simple("HubLabelDeepSecurityIcon"); }
    public get HubLabelGlobalSecurityIcon() { return this.simple("HubLabelGlobalSecurityIcon"); }
    public get HubMenuOperationalServices() { return this.simple("HubMenuOperationalServices"); }
    public get HubMenuAdminPortal() { return this.simple("HubMenuAdminPortal"); }
    public get HubMenuoneLens360() { return this.simple("HubMenuoneLens360"); }
    public get HubMenuSelectaBuilding() { return this.simple("HubMenuSelectaBuilding"); }
    public get HubTabOpservDirectedCleaning() { return this.simple("HubTabOpservDirectedCleaning"); }
    public get HubMenuOpservDirectedCleaning() { return this.simple("HubMenuOpservDirectedCleaning"); }
    public get HubLabelSpaceId() { return this.simple("HubLabelSpaceId"); }
    public get HubLabellastOccupiedLabel() { return this.simple("HubLabellastOccupiedLabel"); }
    public get HubMenuOpservNotifications() { return this.simple("HubMenuOpservNotifications"); }
    public get HubMenumySchedule() { return this.simple("HubMenumySchedule"); }
    public get HubLabelMyBookings() { return this.simple("HubLabelMyBookings"); }
    public get HubMenuMyBookings() { return this.simple("HubMenuMyBookings"); }
    public get HubLabelMyVisitors() { return this.simple("HubLabelMyVisitors"); }
    public get HubMenuMyVisitors() { return this.simple("HubMenuMyVisitors"); }
    public get HubMenuMyBookingsForOthers() { return this.simple("HubMenuMyBookingsForOthers"); }
    public get HubLabelMyTask() { return this.simple("HubLabelMyTask"); }
    public get HubMenuMyTask() { return this.simple("HubMenuMyTask"); }
    public get HubMenuMyTaskstoResolve() { return this.simple("HubMenuMyTaskstoResolve"); }
    public get HubMenuFlex() { return this.simple("HubMenuFlex"); }
    public get HubLabelOtherBookings() { return this.simple("HubLabelOtherBookings"); }
    public get HubLabelMyBookingsTableDescription() { return this.simple("HubLabelMyBookingsTableDescription"); }
    public get HubTabRecentlyResolved() { return this.simple("HubTabRecentlyResolved"); }
    public get HubTabOverdue() { return this.simple("HubTabOverdue"); }
    public get HublabelSpaceLayout() { return this.simple("HublabelSpaceLayout"); }
    public get HubLabelSpaceArrangement() { return this.simple("HubLabelSpaceArrangement"); }
    public get HubLabelInProgress() { return this.simple("HubLabelInProgress"); }
    public get HubLabelOccupancyOverviewLabel() { return this.simple("HubLabelOccupancyOverviewLabel"); }
    public get HubLabelTodaysBookings() { return this.simple("HubLabelTodaysBookings"); }
    public get HubLabelCheckedInBookings() { return this.simple("HubLabelCheckedInBookings"); }
    public get HubLabelNoShowBookings() { return this.simple("HubLabelNoShowBookings"); }
    public get HubLabelTodaysVisitors() { return this.simple("HubLabelTodaysVisitors"); }
    public get HubLabelVisitorsInBuilding() { return this.simple("HubLabelVisitorsInBuilding"); }
    public get HubLabelVisitorsCheckedOut() { return this.simple("HubLabelVisitorsCheckedOut"); }
    public get HubLabelSpacesNotCheckedIn() { return this.simple("HubLabelSpacesNotCheckedIn"); }
    public get HubLabelSpacesWithNoBookings() { return this.simple("HubLabelSpacesWithNoBookings"); }
    public get HubLabelUnOccupiedSpaceswithBookings() { return this.simple("HubLabelUnOccupiedSpaceswithBookings"); }
    public get HubMenuSustainabilityAnalitics() { return this.simple("HubMenuSustainabilityAnalitics"); }
    public get HubMenuSpaceAnalytics() { return this.simple("HubMenuSpaceAnalytics"); }
    public get HubMenuUserPreference() { return this.simple("HubMenuUserPreference"); }
    public get HubButtonImport() { return this.simple("HubButtonImport"); }
    public get HublabelUploadFile() { return this.simple("HublabelUploadFile"); }
    public get HubLabelFile() { return this.simple("HubLabelFile"); }
    public get HubLabelAutoCancelledStatus() { return this.simple("HubLabelAutoCancelledStatus"); }
    public get HubLabelamended() { return this.simple("HubLabelamended"); }
    public get HubLabelNew() { return this.simple("HubLabelNew"); }
    public get HublabelEarlyCheckIn() { return this.simple("HublabelEarlyCheckIn"); }
    public get HublabelLateCheckIn() { return this.simple("HublabelLateCheckIn"); }
    public get HubButtonHistoricalData() { return this.simple("HubButtonHistoricalData"); }
    public get HubMenuAccountSettings() { return this.simple("HubMenuAccountSettings"); }
    public get HubMenuWorkplace() { return this.simple("HubMenuWorkplace"); }
    public get HubLabelMySearchLabel() { return this.simple("HubLabelMySearchLabel"); }
    public get HubLabelworkingDay() { return this.simple("HubLabelworkingDay"); }
    public get HubLabelSearchResults() { return this.simple("HubLabelSearchResults"); }
    public get HubTabPersonal() { return this.simple("HubTabPersonal"); }
    public get HubLabelVisitorNotifications() { return this.simple("HubLabelVisitorNotifications"); }
    public get HubLabelTaskNotifications() { return this.simple("HubLabelTaskNotifications"); }
    public get HubLabelBookingNotifications() { return this.simple("HubLabelBookingNotifications"); }
    public get HubLabelSelectBuilding() { return this.simple("HubLabelSelectBuilding"); }
    public get HubLabelworkspaces() { return this.simple("HubLabelworkspaces"); }
    public get HubMenuSearchaSpace() { return this.simple("HubMenuSearchaSpace"); }
    public get HubMenuSpaceAnalyticsmain() { return this.simple("HubMenuSpaceAnalyticsmain"); }
    public get HubLabelPacs() { return this.simple("HubLabelPacs"); }
    public get HubButtonPrintPass() { return this.simple("HubButtonPrintPass"); }
    public get HubButtonPrint() { return this.simple("HubButtonPrint"); }
    public get HubLabelOccupancyLabel() { return this.simple("HubLabelOccupancyLabel"); }
    public get HubLabelnetworkFailure() { return this.simple("HubLabelnetworkFailure"); }
    public get HubLabelPleaseTryAgain() { return this.simple("HubLabelPleaseTryAgain"); }
    public get HubLabelUnexpectedResponse() { return this.simple("HubLabelUnexpectedResponse"); }
    public get HubLabelSomethingWentWrong() { return this.simple("HubLabelSomethingWentWrong"); }
    public get HubLabelValidationErrors() { return this.simple("HubLabelValidationErrors"); }
    public get HubLabelPleaseSelectABuilding() { return this.simple("HubLabelPleaseSelectABuilding"); }
    public get HubLabelPleaseSelectAFloor() { return this.simple("HubLabelPleaseSelectAFloor"); }
    public get HubLabelStartDateMustBeBeforeTheEndDate() { return this.simple("HubLabelStartDateMustBeBeforeTheEndDate"); }
    public get HubLabelSaved() { return this.simple("HubLabelSaved"); }
    public get HubLabelPreferencesSaved() { return this.simple("HubLabelPreferencesSaved"); }
    public get HubLabelEnterYourPin() { return this.simple("HubLabelEnterYourPin"); }
    public get HubLabelPinLengthError() { return this.simple("HubLabelPinLengthError"); }
    public get HubLabelLoginFailed() { return this.simple("HubLabelLoginFailed"); }
    public get HubLabelLogInAgain() { return this.simple("HubLabelLogInAgain"); }
    public get HubLabelNoPermissionToLogIn() { return this.simple("HubLabelNoPermissionToLogIn"); }
    public get HubLabelLoginFailedPleaseTryAgain() { return this.simple("HubLabelLoginFailedPleaseTryAgain"); }
    public get HubLabelBookForSomeone() { return this.simple("HubLabelBookForSomeone"); }
    public get HubLabelEmployee() { return this.simple("HubLabelEmployee"); }
    public get HubLabelLayouts() { return this.simple("HubLabelLayouts"); }
    public get HubLabelLinkedSpaces() { return this.simple("HubLabelLinkedSpaces"); }
    public get HubLabelBookOnBehalfHeading() { return this.simple("HubLabelBookOnBehalfHeading"); }
    public get HubLabelChooseBookingSpaceFor() { return this.simple("HubLabelChooseBookingSpaceFor"); }
    public get HubLabelEnterNameOrEmailOfEmployee() { return this.simple("HubLabelEnterNameOrEmailOfEmployee"); }
    public get HubLabelEmployeeAutoCheckInRoamerAccess() { return this.simple("HubLabelEmployeeAutoCheckInRoamerAccess"); }
    public get HubLabelVisitorAutoCheckInRoamerAccess() { return this.simple("HubLabelVisitorAutoCheckInRoamerAccess"); }
    public get HubLabelNotMemberOfStaff() { return this.simple("HubLabelNotMemberOfStaff"); }
    public get HubLabelLayoutAndArrangement() { return this.simple("HubLabelLayoutAndArrangement"); }
    public get HubLabelSelectALayout() { return this.simple("HubLabelSelectALayout"); }
    public get HubLabelSeatingArrangement() { return this.simple("HubLabelSeatingArrangement"); }
    public get HubLabelConfirmYourBooking() { return this.simple("HubLabelConfirmYourBooking"); }
    public get HubButtonBack() { return this.simple("HubButtonBack"); }
    public get HubButtonCancelBooking() { return this.simple("HubButtonCancelBooking"); }
    public get HubLabelViewStatus() { return this.simple("HubLabelViewStatus"); }
    public get HubButtonUpdateBooking() { return this.simple("HubButtonUpdateBooking"); }
    public get HubButtonBacklabel() { return this.simple("HubButtonBacklabel"); }
    public get HubLabelEditYourBooking() { return this.simple("HubLabelEditYourBooking"); }
    public get HubLabelconfirmCancel() { return this.simple("HubLabelconfirmCancel"); }
    public get HubButtoncancelConfirmation() { return this.simple("HubButtoncancelConfirmation"); }
    public get HubLabelalreadyPresent() { return this.simple("HubLabelalreadyPresent"); }
    public get HubLabelInvalidEmailAddress() { return this.simple("HubLabelInvalidEmailAddress"); }
    public get HubLabelNoOnBehalfSelcted() { return this.simple("HubLabelNoOnBehalfSelcted"); }
    public get HubButtonVisitorAccess() { return this.simple("HubButtonVisitorAccess"); }
    public get HubLabelNameOrEmailLabel() { return this.simple("HubLabelNameOrEmailLabel"); }
    public get HubLabelFirstNamePlaceholderLabel() { return this.simple("HubLabelFirstNamePlaceholderLabel"); }
    public get HubLabelLastNamePlaceholder() { return this.simple("HubLabelLastNamePlaceholder"); }
    public get HubLabelCompanyNamePlaceholder() { return this.simple("HubLabelCompanyNamePlaceholder"); }
    public get HubButtonAddattendeebutton() { return this.simple("HubButtonAddattendeebutton"); }
    public get HubLabelVisitorAttendee() { return this.simple("HubLabelVisitorAttendee"); }
    public get HubButtonClearAttendees() { return this.simple("HubButtonClearAttendees"); }
    public get HubLabelemailPlaceholder() { return this.simple("HubLabelemailPlaceholder"); }
    public get HubLabelBooking() { return this.simple("HubLabelBooking"); }
    public get HubLabelAddDescription() { return this.simple("HubLabelAddDescription"); }
    public get HubLabelselectALinkedSpaces() { return this.simple("HubLabelselectALinkedSpaces"); }
    public get HubButtonEditsearchcriteria() { return this.simple("HubButtonEditsearchcriteria"); }
    public get HubLabelNeeditnow() { return this.simple("HubLabelNeeditnow"); }
    public get HubLabelAssoonaspossible() { return this.simple("HubLabelAssoonaspossible"); }
    public get HubLabelDateandTime() { return this.simple("HubLabelDateandTime"); }
    public get HubButtonUpdate() { return this.simple("HubButtonUpdate"); }
    public get HubLabelClearSelections() { return this.simple("HubLabelClearSelections"); }
    public get HubButtonBookSpace() { return this.simple("HubButtonBookSpace"); }
    public get HubLabelInProgressText() { return this.simple("HubLabelInProgressText"); }
    public get HubLabelAny() { return this.simple("HubLabelAny"); }
    public get HubLabelSelectanOption() { return this.simple("HubLabelSelectanOption"); }
    public get HubLabelCalendarIntegration() { return this.simple("HubLabelCalendarIntegration"); }
    public get HubLabelLocationTracking() { return this.simple("HubLabelLocationTracking"); }
    public get HubLabelPIN() { return this.simple("HubLabelPIN"); }
    public get HubLabelDataPrivacy() { return this.simple("HubLabelDataPrivacy"); }
    public get HubLabelManagePIN() { return this.simple("HubLabelManagePIN"); }
    public get HubLabelMyDefaultBuilding() { return this.simple("HubLabelMyDefaultBuilding"); }
    public get HubLabelMypreferredFloor() { return this.simple("HubLabelMypreferredFloor"); }
    public get HubLabelEndTime() { return this.simple("HubLabelEndTime"); }
    public get HubLabelStartTime() { return this.simple("HubLabelStartTime"); }
    public get HubLabeliconInTheMobileApp() { return this.simple("HubLabeliconInTheMobileApp"); }
    public get HubLabelViaPushNotification() { return this.simple("HubLabelViaPushNotification"); }
    public get HubLabelViaEmailNotification() { return this.simple("HubLabelViaEmailNotification"); }
    public get HubLabelBuildingSpecificPushNotifications() { return this.simple("HubLabelBuildingSpecificPushNotifications"); }
    public get HubLabelDataHasBeenSavedSuccessfully() { return this.simple("HubLabelDataHasBeenSavedSuccessfully"); }
    public get HubLabelSustainabilityAnalyticsFloors() { return this.simple("HubLabelSustainabilityAnalyticsFloors"); }
    public get HubLabelSizeText() { return this.simple("HubLabelSizeText"); }
    public get HubLabelTemperature() { return this.simple("HubLabelTemperature"); }
    public get HubLabelHumidity() { return this.simple("HubLabelHumidity"); }
    public get HubLabelSustainabilityAnalyticsSensors() { return this.simple("HubLabelSustainabilityAnalyticsSensors"); }
    public get HubLabelNoise() { return this.simple("HubLabelNoise"); }
    public get HubLabelLastUpdate() { return this.simple("HubLabelLastUpdate"); }
    public get HubLabelCo() { return this.simple("HubLabelCo"); }
    public get HubLabelCO2() { return this.simple("HubLabelCO2"); }
    public get HubLabelPM10() { return this.simple("HubLabelPM10"); }
    public get HubLabelPM25() { return this.simple("HubLabelPM25"); }
    public get HubLabelTVOC() { return this.simple("HubLabelTVOC"); }
    public get HubLabelDate() { return this.simple("HubLabelDate"); }
    public get HubLabelSustainabilityAnalyticsZones() { return this.simple("HubLabelSustainabilityAnalyticsZones"); }
    public get HubLabelSustainabilityAnalyticsOverview() { return this.simple("HubLabelSustainabilityAnalyticsOverview"); }
    public get HubLabelNoiseLevel() { return this.simple("HubLabelNoiseLevel"); }
    public get HubLabelAirQuality() { return this.simple("HubLabelAirQuality"); }
    public get HubLabelGood() { return this.simple("HubLabelGood"); }
    public get HubLabelClass() { return this.simple("HubLabelClass"); }
    public get HubLabelSubClass() { return this.simple("HubLabelSubClass"); }
    public get HubLabelExpiry() { return this.simple("HubLabelExpiry"); }
    public get HubLabelNeverApproved() { return this.simple("HubLabelNeverApproved"); }
    public get HubLabeSelectFile() { return this.simple("HubLabeSelectFile"); }
    public get HubLabelCheckinBooking() { return this.simple("HubLabelCheckinBooking"); }
    public get HubLabelBookingCheckInSuccessfully() { return this.simple("HubLabelBookingCheckInSuccessfully"); }
    public get HubLabelBookingCheckOutSuccessfully() { return this.simple("HubLabelBookingCheckOutSuccessfully"); }
    public get HubLabelFacilityManagement() { return this.simple("HubLabelFacilityManagement"); }
    public get HubLabelSetyourpreferences() { return this.simple("HubLabelSetyourpreferences"); }
    public get HubLabelSetUserPrefenceError() { return this.simple("HubLabelSetUserPrefenceError"); }
    public get HubLabelCheckOutBooking() { return this.simple("HubLabelCheckOutBooking"); }
    public get HubLabelNotCheckedIn() { return this.simple("HubLabelNotCheckedIn"); }
    public get HublabelEarlyCheckInText() { return this.simple("HublabelEarlyCheckInText"); }
    public get HubLabelEarlyFinish() { return this.simple("HubLabelEarlyFinish"); }
    public get HubLabelAutoComplete() { return this.simple("HubLabelAutoComplete"); }
    public get HubLabelAvailable() { return this.simple("HubLabelAvailable"); }
    public get HubLabelUnavailable() { return this.simple("HubLabelUnavailable"); }
    public get HubLabelAvailableTo() { return this.simple("HubLabelAvailableTo"); }
    public get HubLabelAvailableFrom() { return this.simple("HubLabelAvailableFrom"); }
    public get HubLabelPassed() { return this.simple("HubLabelPassed"); }
    public get HubLabelFailed() { return this.simple("HubLabelFailed"); }
    public get HubLabelFair() { return this.simple("HubLabelFair"); }
    public get HubLabelPoor() { return this.simple("HubLabelPoor"); }
    public get HubLabelNoData() { return this.simple("HubLabelNoData"); }
    public get HubLabelSuccess() { return this.simple("HubLabelSuccess"); }
    public get HubLabelError() { return this.simple("HubLabelError"); }
    public get HubLabelRequestNewPassword() { return this.simple("HubLabelRequestNewPassword"); }
    public get HubLabelSubmitRequest() { return this.simple("HubLabelSubmitRequest"); }
    public get HubLabelCateringDeletedSuccessfully() { return this.simple("HubLabelCateringDeletedSuccessfully"); }
    public get HubLabelNotificationsDeleted() { return this.simple("HubLabelNotificationsDeleted"); }
    public get HubLabelFileuploadedsuccessfully() { return this.simple("HubLabelFileuploadedsuccessfully"); }
    public get HubLabelvisitissuccessfullycancelled() { return this.simple("HubLabelvisitissuccessfullycancelled"); }
    public get HubLabelUsingTheListView() { return this.simple("HubLabelUsingTheListView"); }
    public get HubmapFailedToLoad() { return this.simple("HubmapFailedToLoad"); }
    public get HubLabelSelectAFloor() { return this.simple("HubLabelSelectAFloor"); }
    public get HubLabelmapView() { return this.simple("HubLabelmapView"); }
    public get HubLabelNoSpacesAvailable() { return this.simple("HubLabelNoSpacesAvailable"); }
    public get HubLabelbackToResults() { return this.simple("HubLabelbackToResults"); }
    public get HubLabelsearchCriteriaNoSpaces() { return this.simple("HubLabelsearchCriteriaNoSpaces"); }
    public get HubLabelSetUp() { return this.simple("HubLabelSetUp"); }
    public get HubLabelNavigateWorkspaces() { return this.simple("HubLabelNavigateWorkspaces"); }
    public get HubLabelDoThis() { return this.simple("HubLabelDoThis"); }
    public get HubLabelDoItLater() { return this.simple("HubLabelDoItLater"); }
    public get HubLabelPreferences() { return this.simple("HubLabelPreferences"); }
    public get HubLabelUserPreferencesMenu() { return this.simple("HubLabelUserPreferencesMenu"); }
    public get HubLabelBuildingPreference() { return this.simple("HubLabelBuildingPreference"); }
    public get HubLabelBuildingPreferenceParagraph() { return this.simple("HubLabelBuildingPreferenceParagraph"); }
    public get HubLabelBuildingText() { return this.simple("HubLabelBuildingText"); }
    public get HubLabelNotified() { return this.simple("HubLabelNotified"); }
    public get HubLabelNotifiedParagraph() { return this.simple("HubLabelNotifiedParagraph"); }
    public get HubLabelVisitorArrivals() { return this.simple("HubLabelVisitorArrivals"); }
    public get HubLabelBookingReminders() { return this.simple("HubLabelBookingReminders"); }
    public get HubLabelServiceRequest() { return this.simple("HubLabelServiceRequest"); }
    public get HubLabelDontWorry() { return this.simple("HubLabelDontWorry"); }
    public get HubLabelColleagueFinding() { return this.simple("HubLabelColleagueFinding"); }
    public get HubLabelColleagueFindingParagraph() { return this.simple("HubLabelColleagueFindingParagraph"); }
    public get HubLabelBookingData() { return this.simple("HubLabelBookingData"); }
    public get HubLabelYourfloorpreference() { return this.simple("HubLabelYourfloorpreference"); }
    public get HubLabelfloorpreferenceParagraph() { return this.simple("HubLabelfloorpreferenceParagraph"); }
    public get HubLabelFloortext() { return this.simple("HubLabelFloortext"); }
    public get HubLabelSetupaPIN() { return this.simple("HubLabelSetupaPIN"); }
    public get HubLabelSetupaPINPara() { return this.simple("HubLabelSetupaPINPara"); }
    public get HubLabel4DigitPIN() { return this.simple("HubLabel4DigitPIN"); }
    public get HubLabelPINMenu() { return this.simple("HubLabelPINMenu"); }
    public get HubLabelYourWorkingDay() { return this.simple("HubLabelYourWorkingDay"); }
    public get HubLabelWorkingHrs() { return this.simple("HubLabelWorkingHrs"); }
    public get HubLabelStarttext() { return this.simple("HubLabelStarttext"); }
    public get HubLabelEndtext() { return this.simple("HubLabelEndtext"); }
    public get HubLabelMenu() { return this.simple("HubLabelMenu"); }
    public get HubTitleTailorNotification() { return this.simple("HubTitleTailorNotification"); }
    public get HubSubTitleTailorNotification() { return this.simple("HubSubTitleTailorNotification"); }
    public get HubSubTitleSecondTailorNotification() { return this.simple("HubSubTitleSecondTailorNotification"); }
    public get HubLabelVisitorNotification() { return this.simple("HubLabelVisitorNotification"); }
    public get HubLabelTaskNotification() { return this.simple("HubLabelTaskNotification"); }
    public get HubLabelBookingNotification() { return this.simple("HubLabelBookingNotification"); }
    public get HubLabelBuildingSpecific() { return this.simple("HubLabelBuildingSpecific"); }
    public get HubLabelBellIconInRoamer() { return this.simple("HubLabelBellIconInRoamer"); }
    public get HubLabelPushNotifications() { return this.simple("HubLabelPushNotifications"); }
    public get HubLabelEmailNotifications() { return this.simple("HubLabelEmailNotifications"); }
    public get HubSubTitleBuildingNotification() { return this.simple("HubSubTitleBuildingNotification"); }
    public get HubSubtitleworkplace() { return this.simple("HubSubtitleworkplace"); }
    public get HubTitleWorkingDay() { return this.simple("HubTitleWorkingDay"); }
    public get HubSubTitleWorkingDay() { return this.simple("HubSubTitleWorkingDay"); }
    public get HubTitleSearchResult() { return this.simple("HubTitleSearchResult"); }
    public get HubSubTitleSearchResult() { return this.simple("HubSubTitleSearchResult"); }
    public get HubButtonList() { return this.simple("HubButtonList"); }
    public get HubButtonMap() { return this.simple("HubButtonMap"); }
    public get HubTitleCalendar() { return this.simple("HubTitleCalendar"); }
    public get HubSubTitleCalendar() { return this.simple("HubSubTitleCalendar"); }
    public get HubLabelSynchronise365() { return this.simple("HubLabelSynchronise365"); }
    public get HubTitleDataPrivacy() { return this.simple("HubTitleDataPrivacy"); }
    public get HubSubTitleDataPrivacy() { return this.simple("HubSubTitleDataPrivacy"); }
    public get HubTitleManagePin() { return this.simple("HubTitleManagePin"); }
    public get HubSubTitleManagePin() { return this.simple("HubSubTitleManagePin"); }
    public get HubSubTitleSecondManagePin() { return this.simple("HubSubTitleSecondManagePin"); }
    public get HubLabelYourPinWillNeedToBeA4DigitCode() { return this.simple("HubLabelYourPinWillNeedToBeA4DigitCode"); }
    public get HubButtonSetPin() { return this.simple("HubButtonSetPin"); }
    public get HubLabelSpaceoverview() { return this.simple("HubLabelSpaceoverview"); }
    public get HubTabResolved7days() { return this.simple("HubTabResolved7days"); }
    public get HubLabelAssigned() { return this.simple("HubLabelAssigned"); }
    public get HubLabelUnassigned() { return this.simple("HubLabelUnassigned"); }
    public get HubLabelResolved() { return this.simple("HubLabelResolved"); }
    public get HubLabelReAllocation() { return this.simple("HubLabelReAllocation"); }
    public get HubMenuMyNotifications() { return this.simple("HubMenuMyNotifications"); }
    public get HubLabelSpaceFeatures() { return this.simple("HubLabelSpaceFeatures"); }
    public get HubLabelNeedAudioVisual() { return this.simple("HubLabelNeedAudioVisual"); }
    public get HubLabelNeedPresentationAids() { return this.simple("HubLabelNeedPresentationAids"); }
    public get HubLabelNeedHearingAids() { return this.simple("HubLabelNeedHearingAids"); }
    public get HubLabelNeedCatering() { return this.simple("HubLabelNeedCatering"); }
    public get HubLabelNeedLinkedSpace() { return this.simple("HubLabelNeedLinkedSpace"); }
    public get HubLabelNeedLayouts() { return this.simple("HubLabelNeedLayouts"); }
    public get HubLabelHowManyPeople() { return this.simple("HubLabelHowManyPeople"); }
    public get HubLabelHistoricalData() { return this.simple("HubLabelHistoricalData"); }
    public get HublabelResults() { return this.simple("HublabelResults"); }
    public get HublabelSpaceAnalyticsOverview() { return this.simple("HublabelSpaceAnalyticsOverview"); }
    public get HublabelSpaceUtilization() { return this.simple("HublabelSpaceUtilization"); }
    public get HublabelKpitaget() { return this.simple("HublabelKpitaget"); }
    public get HublabelEqual() { return this.simple("HublabelEqual"); }
    public get HublabelCapacityUtilization() { return this.simple("HublabelCapacityUtilization"); }
    public get HublabelNoofSpaces() { return this.simple("HublabelNoofSpaces"); }
    public get HublabelChange() { return this.simple("HublabelChange"); }
    public get HublabelMaxOccupancy() { return this.simple("HublabelMaxOccupancy"); }
    public get HublabelAvailableCapacity() { return this.simple("HublabelAvailableCapacity"); }
    public get HublabelAvgOccupancy() { return this.simple("HublabelAvgOccupancy"); }
    public get HublabelClassType() { return this.simple("HublabelClassType"); }
    public get HublabelWorkSpaceLabel() { return this.simple("HublabelWorkSpaceLabel"); }
    public get HublabelBookableLabel() { return this.simple("HublabelBookableLabel"); }
    public get HublabelPeriodType() { return this.simple("HublabelPeriodType"); }
    public get HublabelPeriodStartDate() { return this.simple("HublabelPeriodStartDate"); }
    public get HublabelGroupType() { return this.simple("HublabelGroupType"); }
    public get HublabelGroupBy() { return this.simple("HublabelGroupBy"); }
    public get HublabelDay() { return this.simple("HublabelDay"); }
    public get HublabelWeek() { return this.simple("HublabelWeek"); }
    public get HublabelMonth() { return this.simple("HublabelMonth"); }
    public get HublabelYear() { return this.simple("HublabelYear"); }
    public get HublabelSupportSpaces() { return this.simple("HublabelSupportSpaces"); }
    public get HublabelAmenities() { return this.simple("HublabelAmenities"); }
    public get HublabelFloor() { return this.simple("HublabelFloor"); }
    public get HublabelWorkspaceType() { return this.simple("HublabelWorkspaceType"); }
    public get HubLabelAllSpaces() { return this.simple("HubLabelAllSpaces"); }
    public get HubLabelAllBookableSpaces() { return this.simple("HubLabelAllBookableSpaces"); }
    public get HubLabelAllReservableSpaces() { return this.simple("HubLabelAllReservableSpaces"); }
    public get HubLabelAllBookandReservableSpaces() { return this.simple("HubLabelAllBookandReservableSpaces"); }
    public get HublabelSimilartolast() { return this.simple("HublabelSimilartolast"); }
    public get HubLabelPrivacyPolicy() { return this.simple("HubLabelPrivacyPolicy"); }
    public get HubLabelCopyRight() { return this.simple("HubLabelCopyRight"); }
    public get HubMenuAnalytics() { return this.simple("HubMenuAnalytics"); }
    public get HubMenuSpaceDetails() { return this.simple("HubMenuSpaceDetails"); }
    public get HubMenuHeatMaps() { return this.simple("HubMenuHeatMaps"); }
    public get HubLabelNoNotification() { return this.simple("HubLabelNoNotification"); }
    public get HubLabelNewNotification() { return this.simple("HubLabelNewNotification"); }
    public get HubLabelAnnouncements() { return this.simple("HubLabelAnnouncements"); }
    public get HubLabelFindOut() { return this.simple("HubLabelFindOut"); }
    public get HubLabelYourNotifications() { return this.simple("HubLabelYourNotifications"); }
    public get HublabelSpaceAnalyticsHeatmaps() { return this.simple("HublabelSpaceAnalyticsHeatmaps"); }
    public get HublabelAddResources() { return this.simple("HublabelAddResources"); }
    public get HublabelBooked() { return this.simple("HublabelBooked"); }
    public get HublabelSummary() { return this.simple("HublabelSummary"); }
    public get HublabelMyBooking() { return this.simple("HublabelMyBooking"); }
    public get HubLabelAll() { return this.simple("HubLabelAll"); }
    public get HubLabelUnRead() { return this.simple("HubLabelUnRead"); }
    public get HubLabelYouDone() { return this.simple("HubLabelYouDone"); }
    public get HubLabelNoEquipment() { return this.simple("HubLabelNoEquipment"); }
    public get HubLabelNoEquipmentPara() { return this.simple("HubLabelNoEquipmentPara"); }
    public get HubLabelNoBookedNow() { return this.simple("HubLabelNoBookedNow"); }
    public get HubLabelNoBookedNowPara() { return this.simple("HubLabelNoBookedNowPara"); }
    public get HubLabelAllBooked() { return this.simple("HubLabelAllBooked"); }
    public get HubLabelAllBookedPra1() { return this.simple("HubLabelAllBookedPra1"); }
    public get HubLabelAllBookedPra2() { return this.simple("HubLabelAllBookedPra2"); }
    public get HubButtondoThisLater() { return this.simple("HubButtondoThisLater"); }
    public get HubLabelAvailabelconfirmation() { return this.simple("HubLabelAvailabelconfirmation"); }
    public get HubLabelHearingConfirmation() { return this.simple("HubLabelHearingConfirmation"); }
    public get HubLabelUtilisation() { return this.simple("HubLabelUtilisation"); }
    public get HublabelPeakUtilization() { return this.simple("HublabelPeakUtilization"); }
    public get HublabelViewAll() { return this.simple("HublabelViewAll"); }
    public get HublabelWork() { return this.simple("HublabelWork"); }
    public get HublabelSupport() { return this.simple("HublabelSupport"); }
    public get HublabelAmenity() { return this.simple("HublabelAmenity"); }
    public get HublabelSpaceClassification() { return this.simple("HublabelSpaceClassification"); }
    public get HublabelPeak() { return this.simple("HublabelPeak"); }
    public get HublabelOutOfService() { return this.simple("HublabelOutOfService"); }
    public get HublabelInUse() { return this.simple("HublabelInUse"); }
    public get HublabelCouldBeFree() { return this.simple("HublabelCouldBeFree"); }
    public get HubLabelSpaceMain() { return this.simple("HubLabelSpaceMain"); }
    public get HubLabelOccupancyKPI() { return this.simple("HubLabelOccupancyKPI"); }
    public get HubLabelWhereIsThisSapce() { return this.simple("HubLabelWhereIsThisSapce"); }
    public get HubLabelHowisthespaceused() { return this.simple("HubLabelHowisthespaceused"); }
    public get HublabelOnLast() { return this.simple("HublabelOnLast"); }
    public get HubLabelSimilar() { return this.simple("HubLabelSimilar"); }
    public get HubLabelUserPrefNotifications() { return this.simple("HubLabelUserPrefNotifications"); }
    public get HubLabelUserPrefWorkplace() { return this.simple("HubLabelUserPrefWorkplace"); }
    public get HubLabelUserPrefAccountsettings() { return this.simple("HubLabelUserPrefAccountsettings"); }
    public get HubLabelUtilization() { return this.simple("HubLabelUtilization"); }
    public get HubLabelAnonymous() { return this.simple("HubLabelAnonymous"); }
    public get HubNoBookingDataMessage() { return this.simple("HubNoBookingDataMessage"); }
    public get HubLabelViewBookingPoliciesTableDescription() { return this.simple("HubLabelViewBookingPoliciesTableDescription"); }
    public get HubMenuHome() { return this.simple("HubMenuHome"); }
    public get HubMenuSetup() { return this.simple("HubMenuSetup"); }
    public get HubMenuSecuritySetup() { return this.simple("HubMenuSecuritySetup"); }
    public get HubMenuManage() { return this.simple("HubMenuManage"); }
    public get HubMenuBookingPolicies() { return this.simple("HubMenuBookingPolicies"); }
    public get HubLabelPolicyInformation() { return this.simple("HubLabelPolicyInformation"); }
    public get HubLabelPolicyInformationText() { return this.simple("HubLabelPolicyInformationText"); }
    public get HubLabelPolicyName() { return this.simple("HubLabelPolicyName"); }
    public get HubLabelPolicyDescription() { return this.simple("HubLabelPolicyDescription"); }
    public get HubLabelPolicyId() { return this.simple("HubLabelPolicyId"); }
    public get HubLabelSlots() { return this.simple("HubLabelSlots"); }
    public get HubLabelSlotsInformationText() { return this.simple("HubLabelSlotsInformationText"); }
    public get HubLabelExcludedDates() { return this.simple("HubLabelExcludedDates"); }
    public get HubLabelExcludedDatesInformationText() { return this.simple("HubLabelExcludedDatesInformationText"); }
    public get HubLabelDurationLimits() { return this.simple("HubLabelDurationLimits"); }
    public get HubLabelDurationLimitsInformationText() { return this.simple("HubLabelDurationLimitsInformationText"); }
    public get HubLabelBehaviours() { return this.simple("HubLabelBehaviours"); }
    public get HubLabelBehavioursInformationText() { return this.simple("HubLabelBehavioursInformationText"); }
    public get HubLabelApprovals() { return this.simple("HubLabelApprovals"); }
    public get HubLabelApprovalsInformationText() { return this.simple("HubLabelApprovalsInformationText"); }
    public get HubLabelMinutes() { return this.simple("HubLabelMinutes"); }
    public get HubLabelFixedTimeLimits() { return this.simple("HubLabelFixedTimeLimits"); }
    public get HubLabelConfigurableLimits() { return this.simple("HubLabelConfigurableLimits"); }
    public get HubLabelMinimumDuration() { return this.simple("HubLabelMinimumDuration"); }
    public get HubLabelMaximumDuration() { return this.simple("HubLabelMaximumDuration"); }
    public get HubLabelRepeatingStartAndEndInterval() { return this.simple("HubLabelRepeatingStartAndEndInterval"); }
    public get HubLabelRepeatingEndInterval() { return this.simple("HubLabelRepeatingEndInterval"); }
    public get HubLabelSpecificEndInterval() { return this.simple("HubLabelSpecificEndInterval"); }
    public get HubLabelSpecificStartandEndInterval() { return this.simple("HubLabelSpecificStartandEndInterval"); }
    public get HubLabelRepeatingStartInterval() { return this.simple("HubLabelRepeatingStartInterval"); }
    public get HubLabelEvery() { return this.simple("HubLabelEvery"); }
    public get HubLabelAutoCheckInSubText() { return this.simple("HubLabelAutoCheckInSubText"); }
    public get HubLabelAutoCheckIn() { return this.simple("HubLabelAutoCheckIn"); }
    public get HubLabelEarlyCheckInThreshold() { return this.simple("HubLabelEarlyCheckInThreshold"); }
    public get HubLabelAutoCancelSubText() { return this.simple("HubLabelAutoCancelSubText"); }
    public get HubLabelAutoCancelBookings() { return this.simple("HubLabelAutoCancelBookings"); }
    public get HubLabelCancellationThreshold() { return this.simple("HubLabelCancellationThreshold"); }
    public get HubLabelBookingHorizonSubText() { return this.simple("HubLabelBookingHorizonSubText"); }
    public get HubLabelBookingHorizonMinimum() { return this.simple("HubLabelBookingHorizonMinimum"); }
    public get HubLabelBookingHorizonMaximum() { return this.simple("HubLabelBookingHorizonMaximum"); }
    public get HubLabelDays() { return this.simple("HubLabelDays"); }
    public get HubLabelApprovalRequired() { return this.simple("HubLabelApprovalRequired"); }
    public get HubLabelAutoRejectionAfter() { return this.simple("HubLabelAutoRejectionAfter"); }
    public get HubLabelMonday() { return this.simple("HubLabelMonday"); }
    public get HubLabelTuesday() { return this.simple("HubLabelTuesday"); }
    public get HubLabelWednesday() { return this.simple("HubLabelWednesday"); }
    public get HubLabelThursday() { return this.simple("HubLabelThursday"); }
    public get HubLabelFriday() { return this.simple("HubLabelFriday"); }
    public get HubLabelSaturday() { return this.simple("HubLabelSaturday"); }
    public get HubLabelSunday() { return this.simple("HubLabelSunday"); }
    public get HubLabelFromDate() { return this.simple("HubLabelFromDate"); }
    public get HubLabelToDate() { return this.simple("HubLabelToDate"); }
    public get HubLabelFromTime() { return this.simple("HubLabelFromTime"); }
    public get HubLabelToTime() { return this.simple("HubLabelToTime"); }
    public get HubLabelBookingPolicyNameError() { return this.simple("HubLabelBookingPolicyNameError"); }
    public get HubLabelBookingPolicyDescription() { return this.simple("HubLabelBookingPolicyDescription"); }
    public get HubLabelBookingPolicyOverlappingSlots() { return this.simple("HubLabelBookingPolicyOverlappingSlots"); }
    public get HubLabelBookingPolicyInvalidTimeSlotsError() { return this.simple("HubLabelBookingPolicyInvalidTimeSlotsError"); }
    public get HubLabelBookingPolicyMinDurationError() { return this.simple("HubLabelBookingPolicyMinDurationError"); }
    public get HubLabelBookingPolicyMaxDurationError() { return this.simple("HubLabelBookingPolicyMaxDurationError"); }
    public get HubLabelBookingPolicyEarlyCheckinError() { return this.simple("HubLabelBookingPolicyEarlyCheckinError"); }
    public get HubLabelBookingPolicyAutoCancelError() { return this.simple("HubLabelBookingPolicyAutoCancelError"); }
    public get HubLabelBookingPolicyAutoRejectionError() { return this.simple("HubLabelBookingPolicyAutoRejectionError"); }
    public get HubLabelBookingPolicyResolveErrorsMessage() { return this.simple("HubLabelBookingPolicyResolveErrorsMessage"); }
    public get HubMenuRolesDataSecurity() { return this.simple("HubMenuRolesDataSecurity"); }
    public get HubMenuSetupClient() { return this.simple("HubMenuSetupClient"); }
    public get HubMenuBookingsForOthers() { return this.simple("HubMenuBookingsForOthers"); }
    public get HubMenuTasksRaised() { return this.simple("HubMenuTasksRaised"); }
    public get HubMenuTasksToResolve() { return this.simple("HubMenuTasksToResolve"); }
    public get HubMenuSettings() { return this.simple("HubMenuSettings"); }
    public get HubMenuWorkplacePreferences() { return this.simple("HubMenuWorkplacePreferences"); }
    public get HubMenuOperations() { return this.simple("HubMenuOperations"); }
    public get HubMenuSustainability() { return this.simple("HubMenuSustainability"); }
    public get HubMenuSpace() { return this.simple("HubMenuSpace"); }
    public get HubLabelViewPoliciesTableColumnPolicyName() { return this.simple("HubLabelViewPoliciesTableColumnPolicyName"); }
    public get HubLabelViewPoliciesTableColumnDescription() { return this.simple("HubLabelViewPoliciesTableColumnDescription"); }
    public get HubLabelViewPoliciesTableColumnWorkspaces() { return this.simple("HubLabelViewPoliciesTableColumnWorkspaces"); }
    public get HubLabelAddButton() { return this.simple("HubLabelAddButton"); }
    public get HubLabelAssignSpacesButton() { return this.simple("HubLabelAssignSpacesButton"); }
    public get HubLabelAssignPolicy() { return this.simple("HubLabelAssignPolicy"); }
    public get HubLabelAssignPolicySubText() { return this.simple("HubLabelAssignPolicySubText"); }
    public get HubLabelAssignPolicyFilterText() { return this.simple("HubLabelAssignPolicyFilterText"); }
    public get HubLabelSearch() { return this.simple("HubLabelSearch"); }
    public get HubLabelWarning() { return this.simple("HubLabelWarning"); }
    public get SpecificTimesWarningMessage() { return this.simple("SpecificTimesWarningMessage"); }
    public get SpecificTimesWarningMessageFurtherInfo() { return this.simple("SpecificTimesWarningMessageFurtherInfo"); }
    public get HubLabelToday() { return this.simple("HubLabelToday"); }
    public get HubLabelCustom() { return this.simple("HubLabelCustom"); }
    public get HubLabelApprove() { return this.simple("HubLabelApprove"); }
    public get HubLabelReject() { return this.simple("HubLabelReject"); }
    public get HubMenuCateringOrders() { return this.simple("HubMenuCateringOrders"); }
    public get HubMenuCateringOrdersForToday() { return this.simple("HubMenuCateringOrdersForToday"); }
    public get HubMenuTodaysUpcomingOrders() { return this.simple("HubMenuTodaysUpcomingOrders"); }
    public get HubMenuTodaysCompleteOrders() { return this.simple("HubMenuTodaysCompleteOrders"); }
    public get HubMenuCateringItems() { return this.simple("HubMenuCateringItems"); }
    public get HubMenuCateringMenus() { return this.simple("HubMenuCateringMenus"); }
    public get HubLabelOrders() { return this.simple("HubLabelOrders"); }
    public get HubButtonResolve() { return this.simple("HubButtonResolve"); }
    public get HubButtonInProgress() { return this.simple("HubButtonInProgress"); }
    public get HubLabelServiceTime() { return this.simple("HubLabelServiceTime"); }
    public get HubLabelCleaningTime() { return this.simple("HubLabelCleaningTime"); }
    public get HubLabelTaskOwner() { return this.simple("HubLabelTaskOwner"); }
    public get HubLabelCateringCost() { return this.simple("HubLabelCateringCost"); }
    public get HubLabelHost() { return this.simple("HubLabelHost"); }
    public get HubLabelDeliverHost() { return this.simple("HubLabelDeliverHost"); }
    public get HubLabelDateTimeDelivery() { return this.simple("HubLabelDateTimeDelivery"); }
    public get HubLabelPendingApproval() { return this.simple("HubLabelPendingApproval"); }
    public get HubLabelNotApproved() { return this.simple("HubLabelNotApproved"); }
    public get HubLabelRequestApproved() { return this.simple("HubLabelRequestApproved"); }
    public get HubLabelRequestCancelled() { return this.simple("HubLabelRequestCancelled"); }
    public get HubLabelReadyForPrep() { return this.simple("HubLabelReadyForPrep"); }
    public get HubLabelPrepareForService() { return this.simple("HubLabelPrepareForService"); }
    public get HubLabelInPreparation() { return this.simple("HubLabelInPreparation"); }
    public get HubLabelReadyForDispatchInTime() { return this.simple("HubLabelReadyForDispatchInTime"); }
    public get HubLabelReadyForDispatchOutOfTime() { return this.simple("HubLabelReadyForDispatchOutOfTime"); }
    public get HubLabelCancelledCharged() { return this.simple("HubLabelCancelledCharged"); }
    public get HubLabelCancelledNoCharge() { return this.simple("HubLabelCancelledNoCharge"); }
    public get HubLabelDeliveryIssue() { return this.simple("HubLabelDeliveryIssue"); }
    public get HubLabelDelivered() { return this.simple("HubLabelDelivered"); }
    public get HubLabelClearUpScheduled() { return this.simple("HubLabelClearUpScheduled"); }
    public get HubLabelClearUpExcess() { return this.simple("HubLabelClearUpExcess"); }
    public get HubLabelCleared() { return this.simple("HubLabelCleared"); }
    public get HubLabelAtt() { return this.simple("HubLabelAtt"); }
    public get HubLabelNotes() { return this.simple("HubLabelNotes"); }
    public get HubLabelTaskId() { return this.simple("HubLabelTaskId"); }
    public get HubLabelTomorrow() { return this.simple("HubLabelTomorrow"); }
    public get HubLabelThisWeek() { return this.simple("HubLabelThisWeek"); }
    public get HubLabelNextWeek() { return this.simple("HubLabelNextWeek"); }
    public get HubLabelThisMonth() { return this.simple("HubLabelThisMonth"); }
    public get HubLabelNextMonth() { return this.simple("HubLabelNextMonth"); }
    public get HubLabelDeletePolicy() { return this.simple("HubLabelDeletePolicy"); }
    public get HubLabelDeletePolicyTextPt1() { return this.simple("HubLabelDeletePolicyTextPt1"); }
    public get HubLabelDeletePolicyTextPt2() { return this.simple("HubLabelDeletePolicyTextPt2"); }
    public get HubLabelDeletePolicyPluralisedTextPt1() { return this.simple("HubLabelDeletePolicyPluralisedTextPt1"); }
    public get HubLabelDeletePolicyTextPluralisedPt2() { return this.simple("HubLabelDeletePolicyTextPluralisedPt2"); }
    public get HubLabelPickPolicy() { return this.simple("HubLabelPickPolicy"); }
    public get HubLabelDeletePolicyFooterText() { return this.simple("HubLabelDeletePolicyFooterText"); }
    public get HubLabelConfirmDeletePolicy() { return this.simple("HubLabelConfirmDeletePolicy"); }
    public get HubLabelConfirmDeletePolicyText() { return this.simple("HubLabelConfirmDeletePolicyText"); }
    public get HubLabelAddYourEquipment() { return this.simple("HubLabelAddYourEquipment"); }
    public get HubLabelItemsSelected() { return this.simple("HubLabelItemsSelected"); }
    public get HubLabelEarlyCheckinThresholdText() { return this.simple("HubLabelEarlyCheckinThresholdText"); }
    public get HubLabelEndBehavioursInformationText() { return this.simple("HubLabelEndBehavioursInformationText"); }
    public get HubLabelBookingsApprovedSuccesfullyMsg() { return this.simple("HubLabelBookingsApprovedSuccesfullyMsg"); }
    public get HubLabelBookingsRejectedSuccesfullyMsg() { return this.simple("HubLabelBookingsRejectedSuccesfullyMsg"); }
    public get HubLabelItems() { return this.simple("HubLabelItems"); }
    public get HubLabelIBSSStandardCostCode() { return this.simple("HubLabelIBSSStandardCostCode"); }
    public get HubLabelClientCode() { return this.simple("HubLabelClientCode"); }
    public get HubLabelDepartmentCode() { return this.simple("HubLabelDepartmentCode"); }
    public get HubLabelCostCode() { return this.simple("HubLabelCostCode"); }
    public get HubLabelCostCodes() { return this.simple("HubLabelCostCodes"); }
    public get HubLabelCodeType() { return this.simple("HubLabelCodeType"); }
    public get HubMessageNarrowResults() { return this.simple("HubMessageNarrowResults"); }
    public get HubLabelCostCodeApportioning() { return this.simple("HubLabelCostCodeApportioning"); }
    public get HubMessageCostsAddUpTo100() { return this.simple("HubMessageCostsAddUpTo100"); }
    public get HubLabelUpdate() { return this.simple("HubLabelUpdate"); }
    public get HubLabelDueDate() { return this.simple("HubLabelDueDate"); }
    public get HubLabelEnable() { return this.simple("HubLabelEnable"); }
    public get HubLabelDisable() { return this.simple("HubLabelDisable"); }
    public get HubLabelTrue() { return this.simple("HubLabelTrue"); }
    public get HubLabelFalse() { return this.simple("HubLabelFalse"); }
    public get HubCateringFilterSubTitle() { return this.simple("HubCateringFilterSubTitle"); }
    public get HubLabelSet() { return this.simple("HubLabelSet"); }
    public get HubLabelFixedDurationLimits() { return this.simple("HubLabelFixedDurationLimits"); }
    public get HubLabelSpecificStartIntervals() { return this.simple("HubLabelSpecificStartIntervals"); }
    public get HubLabelSpecificEndIntervals() { return this.simple("HubLabelSpecificEndIntervals"); }
    public get HubLabelInclude() { return this.simple("HubLabelInclude"); }
    public get HubLabelApprovalRoles() { return this.simple("HubLabelApprovalRoles"); }
    public get HubLabelApprovalGroupError() { return this.simple("HubLabelApprovalGroupError"); }
    public get HubLabelModalConfirmTitle() { return this.simple("HubLabelModalConfirmTitle"); }
    public get HubLabelCancelAllMessage() { return this.simple("HubLabelCancelAllMessage"); }
    public get HubLabelRejectAllMessage() { return this.simple("HubLabelRejectAllMessage"); }
    public get HubLabelFlexSearchCriteriaNoSpaces() { return this.simple("HubLabelFlexSearchCriteriaNoSpaces"); }
    public get HubLabelSelectLinkedSpace() { return this.simple("HubLabelSelectLinkedSpace"); }
    public get HubLabelAvailabelCatering() { return this.simple("HubLabelAvailabelCatering"); }
    public get HubLabelCodes() { return this.simple("HubLabelCodes"); }
    public get HubLabelCateringTask() { return this.simple("HubLabelCateringTask"); }
    public get HubLabelCancelChanges() { return this.simple("HubLabelCancelChanges"); }
    public get HubLabelCateringDetailsErrorMsg() { return this.simple("HubLabelCateringDetailsErrorMsg"); }
    public get HubLabelCateringDetailsDescription() { return this.simple("HubLabelCateringDetailsDescription"); }
    public get HubLabelService() { return this.simple("HubLabelService"); }
    public get HubLabelCleanUp() { return this.simple("HubLabelCleanUp"); }
    public get HubLabelCutOffTime() { return this.simple("HubLabelCutOffTime"); }
    public get HubLabelAddNotes() { return this.simple("HubLabelAddNotes"); }
    public get HubLabelEditCateringTaskModalSubtext() { return this.simple("HubLabelEditCateringTaskModalSubtext"); }
    public get HubLabelOriginal() { return this.simple("HubLabelOriginal"); }
    public get HubLabelQty() { return this.simple("HubLabelQty"); }
    public get HubLabelSaveChanges() { return this.simple("HubLabelSaveChanges"); }
    public get HubLabelServiceTimeGreaterThanCleanUpTimeMsg() { return this.simple("HubLabelServiceTimeGreaterThanCleanUpTimeMsg"); }
    public get HubLabelItem() { return this.simple("HubLabelItem"); }
    public get HubLabelHours() { return this.simple("HubLabelHours"); }
    public get HubLabelConfirmOrder() { return this.simple("HubLabelConfirmOrder"); }
    public get HubLabelEditCatering() { return this.simple("HubLabelEditCatering"); }
    public get HubLabelCancelCatering() { return this.simple("HubLabelCancelCatering"); }
    public get HubLabelBackToBooking() { return this.simple("HubLabelBackToBooking"); }
    public get HubLabelSwitchToDarkMode() { return this.simple("HubLabelSwitchToDarkMode"); }
    public get HubLabelSwitchToLightMode() { return this.simple("HubLabelSwitchToLightMode"); }
    public get HubLabelViewProfile() { return this.simple("HubLabelViewProfile"); }
    public get HubLabelLogout() { return this.simple("HubLabelLogout"); }
    public get HubLabelWhatsNew() { return this.simple("HubLabelWhatsNew"); }
    public get HubMessageEndTimeMustBeAfterStartTime() { return this.simple("HubMessageEndTimeMustBeAfterStartTime"); }
    public get HubMessageStartDateMustBeSameAsEndDate() { return this.simple("HubMessageStartDateMustBeSameAsEndDate"); }
    public get HubMessageStartTimeMinutesMustBeDefinedInBuildingConfiguration() { return this.simple("HubMessageStartTimeMinutesMustBeDefinedInBuildingConfiguration"); }
    public get HubMessageEndTimeMinutesMustBeDefinedInBuildingConfiguration() { return this.simple("HubMessageEndTimeMinutesMustBeDefinedInBuildingConfiguration"); }
    public get HubMessageFromIsNotValid() { return this.simple("HubMessageFromIsNotValid"); }
    public get HubMessageToIsNotValid() { return this.simple("HubMessageToIsNotValid"); }
    public get HubLabelRecurringBookingPolicySubText() { return this.simple("HubLabelRecurringBookingPolicySubText"); }
    public get HubLabelRecurringBookingErrorText() { return this.simple("HubLabelRecurringBookingErrorText"); }
    public get HubLabelRecurringBookingHelperText() { return this.simple("HubLabelRecurringBookingHelperText"); }
    public get HubLabelAllowRecurringBookings() { return this.simple("HubLabelAllowRecurringBookings"); }
    public get HubLabelRecurringBookingThreshold() { return this.simple("HubLabelRecurringBookingThreshold"); }
    public get HubLabelContinueToLogin() { return this.simple("HubLabelContinueToLogin"); }
    public get HubLabelForgottenPassword() { return this.simple("HubLabelForgottenPassword"); }
    public get HubLabelEmail() { return this.simple("HubLabelEmail"); }
    public get HubLabelPassword() { return this.simple("HubLabelPassword"); }
    public get HubLabelLogin() { return this.simple("HubLabelLogin"); }
    public get HubLabelWelcomeToHub() { return this.simple("HubLabelWelcomeToHub"); }
    public get HubLabelYourPortal() { return this.simple("HubLabelYourPortal"); }
    public get HubLabelThreeProducts() { return this.simple("HubLabelThreeProducts"); }
    public get HubLabelRecurringBookings() { return this.simple("HubLabelRecurringBookings"); }
    public get HubLabelCriteria() { return this.simple("HubLabelCriteria"); }
    public get HubLabelCriteriaSubHeading() { return this.simple("HubLabelCriteriaSubHeading"); }
    public get HubLabelFloor() { return this.simple("HubLabelFloor"); }
    public get HubLabelSingleBooking() { return this.simple("HubLabelSingleBooking"); }
    public get HubLabelRecurringBooking() { return this.simple("HubLabelRecurringBooking"); }
    public get HubLabelRecurrencePattern() { return this.simple("HubLabelRecurrencePattern"); }
    public get HubLabelDaily() { return this.simple("HubLabelDaily"); }
    public get HubLabelWeekly() { return this.simple("HubLabelWeekly"); }
    public get HubLabelMonthly() { return this.simple("HubLabelMonthly"); }
    public get HubLabelEnds() { return this.simple("HubLabelEnds"); }
    public get HubLabelOn() { return this.simple("HubLabelOn"); }
    public get HubLabelAfter() { return this.simple("HubLabelAfter"); }
    public get HubLabelOccurrences() { return this.simple("HubLabelOccurrences"); }
    public get HubLabelRepeatEvery() { return this.simple("HubLabelRepeatEvery"); }
    public get HubLabelRecurringSpaceDays() { return this.simple("HubLabelRecurringSpaceDays"); }
    public get HubLabelEveryWeekday() { return this.simple("HubLabelEveryWeekday"); }
    public get HubLabelOfEvery() { return this.simple("HubLabelOfEvery"); }
    public get HubLabelMonths() { return this.simple("HubLabelMonths"); }
    public get HubLabelThe() { return this.simple("HubLabelThe"); }
    public get HubLabelOf() { return this.simple("HubLabelOf"); }
    public get HubLabelFirst() { return this.simple("HubLabelFirst"); }
    public get HubLabelSecond() { return this.simple("HubLabelSecond"); }
    public get HubLabelThird() { return this.simple("HubLabelThird"); }
    public get HubLabelLast() { return this.simple("HubLabelLast"); }
    public get HubLabelFeatures() { return this.simple("HubLabelFeatures"); }
    public get HubLabelLastModified() { return this.simple("HubLabelLastModified"); }
    public get HubLabelSource() { return this.simple("HubLabelSource"); }
    public get HubLabelSelectASpace() { return this.simple("HubLabelSelectASpace"); }
    public get HubLabelSelectASpaceSubheading() { return this.simple("HubLabelSelectASpaceSubheading"); }
    public get HubLabelNoSpaceOptions() { return this.simple("HubLabelNoSpaceOptions"); }
    public get HubLabelNoSpaceOptionsSubText() { return this.simple("HubLabelNoSpaceOptionsSubText"); }
    public get HubLabelAvailability() { return this.simple("HubLabelAvailability"); }
    public get HubLabelBestFit() { return this.simple("HubLabelBestFit"); }
    public get HubLabelMustBeAValidDateAndTime() { return this.simple("HubLabelMustBeAValidDateAndTime"); }
    public get HubLabelMinutesMustBe_AllowedMinutes_Etc() { return this.simple("HubLabelMinutesMustBe_AllowedMinutes_Etc"); }
    public get HubLabelMustNotBeInThePast() { return this.simple("HubLabelMustNotBeInThePast"); }
    public get HubLabelMustBeBeforeTheEndTime() { return this.simple("HubLabelMustBeBeforeTheEndTime"); }
    public get HubLabelMustBeOnTheSameDayAsTheEndTime() { return this.simple("HubLabelMustBeOnTheSameDayAsTheEndTime"); }
    public get HubLabelMustBeAfterTheStartTime() { return this.simple("HubLabelMustBeAfterTheStartTime"); }
    public get HubLabelMustBeOnTheSameDayAsTheStartTime() { return this.simple("HubLabelMustBeOnTheSameDayAsTheStartTime"); }
    public get HubLabelStartTimeMustBeAValidDateAndTime() { return this.simple("HubLabelStartTimeMustBeAValidDateAndTime"); }
    public get HubLabelStartTimesMinutesMustBe_AllowedMinutes_Etc() { return this.simple("HubLabelStartTimesMinutesMustBe_AllowedMinutes_Etc"); }
    public get HubLabelStartTimeMustNotBeInThePast() { return this.simple("HubLabelStartTimeMustNotBeInThePast"); }
    public get HubLabelStartTimeMustBeBeforeTheEndTime() { return this.simple("HubLabelStartTimeMustBeBeforeTheEndTime"); }
    public get HubLabelStartTimeMustBeOnTheSameDayAsTheEndTime() { return this.simple("HubLabelStartTimeMustBeOnTheSameDayAsTheEndTime"); }
    public get HubLabelEndTimeMustBeAValidDateAndTime() { return this.simple("HubLabelEndTimeMustBeAValidDateAndTime"); }
    public get HubLabelEndTimesMinutesMustBe_AllowedMinutes_Etc() { return this.simple("HubLabelEndTimesMinutesMustBe_AllowedMinutes_Etc"); }
    public get HubLabelEndTimeMustNotBeInThePast() { return this.simple("HubLabelEndTimeMustNotBeInThePast"); }
    public get HubLabelAddBookingDetail() { return this.simple("HubLabelAddBookingDetail"); }
    public get HubLabelBookingDescription() { return this.simple("HubLabelBookingDescription"); }
    public get HubLabelOnlineMeetingLink() { return this.simple("HubLabelOnlineMeetingLink"); }
    public get HubLabelRecurrenceDates() { return this.simple("HubLabelRecurrenceDates"); }
    public get HubLabelAlternative() { return this.simple("HubLabelAlternative"); }
    public get HubLabelPickAlternativeSpace() { return this.simple("HubLabelPickAlternativeSpace"); }
    public get HubLabelAlternativeSpaceText() { return this.simple("HubLabelAlternativeSpaceText"); }
    public get HubLabelUseSpaceSelected() { return this.simple("HubLabelUseSpaceSelected"); }
    public get HubLabelTime() { return this.simple("HubLabelTime"); }
    public get HubLabelSkipOccurrence() { return this.simple("HubLabelSkipOccurrence"); }
    public get HubLabelCreateBookings() { return this.simple("HubLabelCreateBookings"); }
    public get HubLabelCreatingRecurringBookingsText() { return this.simple("HubLabelCreatingRecurringBookingsText"); }
    public get HubLabelCreated() { return this.simple("HubLabelCreated"); }
    public get HubLabelClose() { return this.simple("HubLabelClose"); }
    public get HubLabelTimeZone() { return this.simple("HubLabelTimeZone"); }
    public get HubLabelRow() { return this.simple("HubLabelRow"); }
    public get HubLabelEntriesUploadedSuccessfully() { return this.simple("HubLabelEntriesUploadedSuccessfully"); }
    public get HubLabelIssuesOccured() { return this.simple("HubLabelIssuesOccured"); }
    public get HubLabelEnterYourCriteria() { return this.simple("HubLabelEnterYourCriteria"); }
    public get HubLabelPickYourCriteria() { return this.simple("HubLabelPickYourCriteria"); }
    public get HubLabelAssigningSpacesMessage() { return this.simple("HubLabelAssigningSpacesMessage"); }
    public get HubLabelBookingCheckedInSuccessfully() { return this.simple("HubLabelBookingCheckedInSuccessfully"); }
    public get HubLabelSilent() { return this.simple("HubLabelSilent"); }
    public get HubLabelQuiet() { return this.simple("HubLabelQuiet"); }
    public get HubLabelNormal() { return this.simple("HubLabelNormal"); }
    public get HubLabelCapacityReachedPt1() { return this.simple("HubLabelCapacityReachedPt1"); }
    public get HubLabelCapacityReachedPt2() { return this.simple("HubLabelCapacityReachedPt2"); }
    public get HubLabelAddNewVisitor() { return this.simple("HubLabelAddNewVisitor"); }
    public get HubLabelAddAdhocVisitorText() { return this.simple("HubLabelAddAdhocVisitorText"); }
    public get HubLabelAddVisitor() { return this.simple("HubLabelAddVisitor"); }
    public get HubLabelVisitorDetailsText() { return this.simple("HubLabelVisitorDetailsText"); }
    public get HubLabelFullName() { return this.simple("HubLabelFullName"); }
    public get HubLabelArrival() { return this.simple("HubLabelArrival"); }
    public get HubLabelDeparture() { return this.simple("HubLabelDeparture"); }
    public get HubLabelCategories() { return this.simple("HubLabelCategories"); }
    public get HubLabelsVisitorsToday() { return this.simple("HubLabelsVisitorsToday"); }
    public get HubLabelsUpcoming() { return this.simple("HubLabelsUpcoming"); }
    public get HubLabelsInBuilding() { return this.simple("HubLabelsInBuilding"); }
    public get HubLabelsCheckedOut() { return this.simple("HubLabelsCheckedOut"); }
    public get HubLabelSearchForAVisitorOrRef() { return this.simple("HubLabelSearchForAVisitorOrRef"); }
    public get HubLabelViewDetails() { return this.simple("HubLabelViewDetails"); }
    public get HubLabelExp() { return this.simple("HubLabelExp"); }
    public get HubLabelAwaitingArrival() { return this.simple("HubLabelAwaitingArrival"); }
    public get HubLabelNoUpcomingVisitors() { return this.simple("HubLabelNoUpcomingVisitors"); }
    public get HubLabelVisitorList() { return this.simple("HubLabelVisitorList"); }
    public get HubLabelVisitorTrends() { return this.simple("HubLabelVisitorTrends"); }
    public get HubLabelVisitorDashboard() { return this.simple("HubLabelVisitorDashboard"); }
    public get HubLabelOperationalServicesVisitors() { return this.simple("HubLabelOperationalServicesVisitors"); }
    public get HubLabelVisitorsAwaitingApproval() { return this.simple("HubLabelVisitorsAwaitingApproval"); }
    public get HubLabelVisitorName() { return this.simple("HubLabelVisitorName"); }
    public get HubLabelSuccessfullyCheckedInMessage() { return this.simple("HubLabelSuccessfullyCheckedInMessage"); }
    public get HubLabelSuccessfullyCheckedOutMessage() { return this.simple("HubLabelSuccessfullyCheckedOutMessage"); }
    public get HubLabelReturnToVisitorOverview() { return this.simple("HubLabelReturnToVisitorOverview"); }
    public get HubLabelJSONView() { return this.simple("HubLabelJSONView"); }
    public get HubLabelButtonJSONView() { return this.simple("HubLabelButtonJSONView"); }
    public get HubLabelJSONViewDescription() { return this.simple("HubLabelJSONViewDescription"); }
    public get HubLabelUserPermissionDescription() { return this.simple("HubLabelUserPermissionDescription"); }
    public get HubLabelUserPermissionCol1() { return this.simple("HubLabelUserPermissionCol1"); }
    public get HubLabelUserPermissionCol2() { return this.simple("HubLabelUserPermissionCol2"); }
    public get HubLabelUserPermissionDescriptionCol1() { return this.simple("HubLabelUserPermissionDescriptionCol1"); }
    public get HubLabelUserPermissionDescriptionCol2() { return this.simple("HubLabelUserPermissionDescriptionCol2"); }
    public get HubLabelBookingList() { return this.simple("HubLabelBookingList"); }
    public get HubLabelBookingTrends() { return this.simple("HubLabelBookingTrends"); }
    public get HubLabelAllBookings() { return this.simple("HubLabelAllBookings"); }
    public get HubLabelAddSelectImage() { return this.simple("HubLabelAddSelectImage"); }
    public get HubLabelImageSelection() { return this.simple("HubLabelImageSelection"); }
    public get HubLabelUpload() { return this.simple("HubLabelUpload"); }
    public get HubLabelSelectedImage() { return this.simple("HubLabelSelectedImage"); }
    public get HubLabelSupplierDetails() { return this.simple("HubLabelSupplierDetails"); }
    public get HubLabelSupplier() { return this.simple("HubLabelSupplier"); }
    public get HubLabelSupplierName() { return this.simple("HubLabelSupplierName"); }
    public get HubLabelSupplierDescription() { return this.simple("HubLabelSupplierDescription"); }
    public get HubLabelSupplierContact() { return this.simple("HubLabelSupplierContact"); }
    public get HubLabelSupplierTelephone() { return this.simple("HubLabelSupplierTelephone"); }
    public get HubLabelSupplierEmail() { return this.simple("HubLabelSupplierEmail"); }
    public get HubLabelGeneralLeadTime() { return this.simple("HubLabelGeneralLeadTime"); }
    public get HubLabelSupplierId() { return this.simple("HubLabelSupplierId"); }
    public get HubLabelImageNameExistsError() { return this.simple("HubLabelImageNameExistsError"); }
    public get HubLabelBookingOffset() { return this.simple("HubLabelBookingOffset"); }
    public get HubLabelOffsetTime() { return this.simple("HubLabelOffsetTime"); }
    public get HubLabelMap() { return this.simple("HubLabelMap"); }
    public get HubLabelShowLessSearchOptions() { return this.simple("HubLabelShowLessSearchOptions"); }
    public get HubLabelShowMoreSearchOptions() { return this.simple("HubLabelShowMoreSearchOptions"); }
    public get HubLabelCooler() { return this.simple("HubLabelCooler"); }
    public get HubLabelWarmer() { return this.simple("HubLabelWarmer"); }
    public get HubLabelAddOns() { return this.simple("HubLabelAddOns"); }
    public get HubLabelWhatDoYouWantToBook() { return this.simple("HubLabelWhatDoYouWantToBook"); }
    public get HubLabelAsSoonAsPossible() { return this.simple("HubLabelAsSoonAsPossible"); }
    public get HubLabelAsHowLongHours() { return this.simple("HubLabelAsHowLongHours"); }
    public get HubLabelEndOfWorkingDay() { return this.simple("HubLabelEndOfWorkingDay"); }
    public get HubLabelAddRecurrence() { return this.simple("HubLabelAddRecurrence"); }
    public get HubLabelSound() { return this.simple("HubLabelSound"); }
    public get HubLabelPleaseSelectOption() { return this.simple("HubLabelPleaseSelectOption"); }
    public get HubLabelScheduleView() { return this.simple("HubLabelScheduleView"); }
    public get HubLabelGridScheduleView() { return this.simple("HubLabelGridScheduleView"); }
    public get HubMessageScheduleView() { return this.simple("HubMessageScheduleView"); }
    public get HubButtonSpaceTypeFilter() { return this.simple("HubButtonSpaceTypeFilter"); }
    public get HubLabelFiltersApplied() { return this.simple("HubLabelFiltersApplied"); }
    public get HubMessageScheduleFilters() { return this.simple("HubMessageScheduleFilters"); }
    public get HubLabelHasCatering() { return this.simple("HubLabelHasCatering"); }
    public get HubLabelHasEquipment() { return this.simple("HubLabelHasEquipment"); }
    public get HubMessageScheduleDisclaimer() { return this.simple("HubMessageScheduleDisclaimer"); }
    public get HubMenuCateringOrderPolicy() { return this.simple("HubMenuCateringOrderPolicy"); }
    public get HubLabelMenuOrderPolicy() { return this.simple("HubLabelMenuOrderPolicy"); }
    public get HubLabelPreparationTime() { return this.simple("HubLabelPreparationTime"); }
    public get HubLabelOperatingHours() { return this.simple("HubLabelOperatingHours"); }
    public get HubLabelRecurrenceAvailability() { return this.simple("HubLabelRecurrenceAvailability"); }
    public get HubLabelSupplierNameTitle() { return this.simple("HubLabelSupplierNameTitle"); }
    public get HubLabelSuppliedItems() { return this.simple("HubLabelSuppliedItems"); }
    public get HubLabelMenuCateringSuppliers() { return this.simple("HubLabelMenuCateringSuppliers"); }
    public get HubLabelLeadTime() { return this.simple("HubLabelLeadTime"); }
    public get HubLabelCateringSupplierSubText() { return this.simple("HubLabelCateringSupplierSubText"); }
    public get HubLabelKitchenOperatingHoursFrom() { return this.simple("HubLabelKitchenOperatingHoursFrom"); }
    public get HubLabelKitchenOperatingHoursTo() { return this.simple("HubLabelKitchenOperatingHoursTo"); }
    public get HubLabelAdvancedNotice() { return this.simple("HubLabelAdvancedNotice"); }
    public get HubLabelTimeDesignation() { return this.simple("HubLabelTimeDesignation"); }
    public get HubLabelClassification() { return this.simple("HubLabelClassification"); }
    public get HubLabelPickYourCriteriaAdditionalInfo() { return this.simple("HubLabelPickYourCriteriaAdditionalInfo"); }
    public get HubLabelNoSpaceResults() { return this.simple("HubLabelNoSpaceResults"); }
    public get HubLabelNoSpaceResultsSubText() { return this.simple("HubLabelNoSpaceResultsSubText"); }
    public get HubLabelSearchInProgress() { return this.simple("HubLabelSearchInProgress"); }
    public get HubLabelSelectSpaceInfoMessage() { return this.simple("HubLabelSelectSpaceInfoMessage"); }
    public get HubLabelSelectSpaceAdditionalInfoMessage() { return this.simple("HubLabelSelectSpaceAdditionalInfoMessage"); }
    public get HubLabelWhatDoYouWantToSearch() { return this.simple("HubLabelWhatDoYouWantToSearch"); }
    public get HubLabelNutritionalInformation() { return this.simple("HubLabelNutritionalInformation"); }
    public get HubLabelAllergens() { return this.simple("HubLabelAllergens"); }
    public get HubLabelDietaryType() { return this.simple("HubLabelDietaryType"); }
    public get HubLabelRetailPrice() { return this.simple("HubLabelRetailPrice"); }
    public get HubLabelBeverage() { return this.simple("HubLabelBeverage"); }
    public get HubLabelFood() { return this.simple("HubLabelFood"); }
    public get HubLabelSnack() { return this.simple("HubLabelSnack"); }
    public get HubLabelAddRestrictions() { return this.simple("HubLabelAddRestrictions"); }
    public get HubLabelAddRestrictionsDescription() { return this.simple("HubLabelAddRestrictionsDescription"); }
    public get HubLabelAllergensDescription() { return this.simple("HubLabelAllergensDescription"); }
    public get HubLabelDietaryDescription() { return this.simple("HubLabelDietaryDescription"); }
    public get HubLabelFindAColleagueHeading() { return this.simple("HubLabelFindAColleagueHeading"); }
    public get HubLabelNameOrEmailEtc() { return this.simple("HubLabelNameOrEmailEtc"); }
    public get HubLabelEnterNameOrEmail() { return this.simple("HubLabelEnterNameOrEmail"); }
    public get HubMenuFindColleague() { return this.simple("HubMenuFindColleague"); }
    public get HubLabelView() { return this.simple("HubLabelView"); }
    public get HubLabelFindColleagueInfoMessage() { return this.simple("HubLabelFindColleagueInfoMessage"); }
    public get HubLabelBookingFilterText() { return this.simple("HubLabelBookingFilterText"); }
    public get HubLabelColleagueBookingInfoMessage() { return this.simple("HubLabelColleagueBookingInfoMessage"); }
    public get HubLabelColleagueLocation() { return this.simple("HubLabelColleagueLocation"); }
    public get HubLabelEmployeeDefaultCostCodesForBooking() { return this.simple("HubLabelEmployeeDefaultCostCodesForBooking"); }
    public get HubCateringPantryTimeline() { return this.simple("HubCateringPantryTimeline"); }
    public get dataCateringMenuItemImage_S() { return this.simple("dataCateringMenuItemImage_S"); }
    public get dataNotificationShortMessage_S() { return this.simple("dataNotificationShortMessage_S"); }
    public get dataCateringMenuItemShortMessage_S() { return this.simple("dataCateringMenuItemShortMessage_S"); }
    public get funcCateringMenuEditIncludeBeverages_S() { return this.simple("funcCateringMenuEditIncludeBeverages_S"); }
    public get funcCateringMenuEditIncludeBeverages_L() { return this.simple("funcCateringMenuEditIncludeBeverages_L"); }
    public get funcCateringMenuEditIncludeBeverages_D() { return this.simple("funcCateringMenuEditIncludeBeverages_D"); }
    public get funcCateringMenuEditIncludeSnacks_S() { return this.simple("funcCateringMenuEditIncludeSnacks_S"); }
    public get funcCateringMenuEditIncludeSnacks_L() { return this.simple("funcCateringMenuEditIncludeSnacks_L"); }
    public get funcCateringMenuEditIncludeSnacks_D() { return this.simple("funcCateringMenuEditIncludeSnacks_D"); }
    public get funcCateringMenuEditIncludeFood_S() { return this.simple("funcCateringMenuEditIncludeFood_S"); }
    public get funcCateringMenuEditIncludeFood_L() { return this.simple("funcCateringMenuEditIncludeFood_L"); }
    public get funcCateringMenuEditIncludeFood_D() { return this.simple("funcCateringMenuEditIncludeFood_D"); }
    public get funcCateringMenuEditNoneSelected_S() { return this.simple("funcCateringMenuEditNoneSelected_S"); }
    public get funcCateringMenuEditNoneSelected_L() { return this.simple("funcCateringMenuEditNoneSelected_L"); }
    public get funcCateringMenuEditNoneSelected_D() { return this.simple("funcCateringMenuEditNoneSelected_D"); }
    public get funcCateringMenuEditMenuItemsAndMore_S() { return this.simple("funcCateringMenuEditMenuItemsAndMore_S"); }
    public get funcCateringMenuEditMenuItemsAndMore_L() { return this.simple("funcCateringMenuEditMenuItemsAndMore_L"); }
    public get funcCateringMenuEditMenuItemsAndMore_D() { return this.simple("funcCateringMenuEditMenuItemsAndMore_D"); }
    public get funcCateringMenuEditActive_S() { return this.simple("funcCateringMenuEditActive_S"); }
    public get funcCateringMenuEditActive_L() { return this.simple("funcCateringMenuEditActive_L"); }
    public get funcCateringMenuEditActive_D() { return this.simple("funcCateringMenuEditActive_D"); }
    public get funcCateringMenuEditInactive_S() { return this.simple("funcCateringMenuEditInactive_S"); }
    public get funcCateringMenuEditInactive_L() { return this.simple("funcCateringMenuEditInactive_L"); }
    public get funcCateringMenuEditInactive_D() { return this.simple("funcCateringMenuEditInactive_D"); }
    public get funcCateringMenuEditPageTitle_S() { return this.simple("funcCateringMenuEditPageTitle_S"); }
    public get funcCateringMenuEditPageTitle_L() { return this.simple("funcCateringMenuEditPageTitle_L"); }
    public get funcCateringMenuEditPageTitle_D() { return this.simple("funcCateringMenuEditPageTitle_D"); }
    public get funcCateringMenuEditRequired_S() { return this.simple("funcCateringMenuEditRequired_S"); }
    public get funcCateringMenuEditRequired_L() { return this.simple("funcCateringMenuEditRequired_L"); }
    public get funcCateringMenuEditRequired_D() { return this.simple("funcCateringMenuEditRequired_D"); }
    public get funcCateringMenuEditMustBeAValidDateAndTime_S() { return this.simple("funcCateringMenuEditMustBeAValidDateAndTime_S"); }
    public get funcCateringMenuEditMustBeAValidDateAndTime_L() { return this.simple("funcCateringMenuEditMustBeAValidDateAndTime_L"); }
    public get funcCateringMenuEditMustBeAValidDateAndTime_D() { return this.simple("funcCateringMenuEditMustBeAValidDateAndTime_D"); }
    public get funcCateringMenuEditMustBeBeforeAvailableTo_S() { return this.simple("funcCateringMenuEditMustBeBeforeAvailableTo_S"); }
    public get funcCateringMenuEditMustBeBeforeAvailableTo_L() { return this.simple("funcCateringMenuEditMustBeBeforeAvailableTo_L"); }
    public get funcCateringMenuEditMustBeBeforeAvailableTo_D() { return this.simple("funcCateringMenuEditMustBeBeforeAvailableTo_D"); }
    public get funcCateringMenuEditMustBeAfterAvailableFrom_S() { return this.simple("funcCateringMenuEditMustBeAfterAvailableFrom_S"); }
    public get funcCateringMenuEditMustBeAfterAvailableFrom_L() { return this.simple("funcCateringMenuEditMustBeAfterAvailableFrom_L"); }
    public get funcCateringMenuEditMustBeAfterAvailableFrom_D() { return this.simple("funcCateringMenuEditMustBeAfterAvailableFrom_D"); }
    public get funcCateringMenuEditAvailableToRoles_S() { return this.simple("funcCateringMenuEditAvailableToRoles_S"); }
    public get funcCateringMenuEditAvailableToRoles_L() { return this.simple("funcCateringMenuEditAvailableToRoles_L"); }
    public get funcCateringMenuEditAvailableToRoles_D() { return this.simple("funcCateringMenuEditAvailableToRoles_D"); }
    public get funcCateringMenuEditDelete_S() { return this.simple("funcCateringMenuEditDelete_S"); }
    public get funcCateringMenuEditDelete_L() { return this.simple("funcCateringMenuEditDelete_L"); }
    public get funcCateringMenuEditDelete_D() { return this.simple("funcCateringMenuEditDelete_D"); }
    public get funcCateringMenuEditConfirmDelete_S() { return this.simple("funcCateringMenuEditConfirmDelete_S"); }
    public get funcCateringMenuEditConfirmDelete_L() { return this.simple("funcCateringMenuEditConfirmDelete_L"); }
    public get funcCateringMenuEditConfirmDelete_D() { return this.simple("funcCateringMenuEditConfirmDelete_D"); }
    public get funcCateringMenuEditCateringMenu_S() { return this.simple("funcCateringMenuEditCateringMenu_S"); }
    public get funcCateringMenuEditCateringMenu_L() { return this.simple("funcCateringMenuEditCateringMenu_L"); }
    public get funcCateringMenuEditCateringMenu_D() { return this.simple("funcCateringMenuEditCateringMenu_D"); }
    public get funcCateringMenuEditMenuId_S() { return this.simple("funcCateringMenuEditMenuId_S"); }
    public get funcCateringMenuEditMenuId_L() { return this.simple("funcCateringMenuEditMenuId_L"); }
    public get funcCateringMenuEditMenuId_D() { return this.simple("funcCateringMenuEditMenuId_D"); }
    public get funcCateringMenuEditName_S() { return this.simple("funcCateringMenuEditName_S"); }
    public get funcCateringMenuEditName_L() { return this.simple("funcCateringMenuEditName_L"); }
    public get funcCateringMenuEditName_D() { return this.simple("funcCateringMenuEditName_D"); }
    public get funcCateringMenuEditShortDescription_S() { return this.simple("funcCateringMenuEditShortDescription_S"); }
    public get funcCateringMenuEditShortDescription_L() { return this.simple("funcCateringMenuEditShortDescription_L"); }
    public get funcCateringMenuEditShortDescription_D() { return this.simple("funcCateringMenuEditShortDescription_D"); }
    public get funcCateringMenuEditAvailableFrom_S() { return this.simple("funcCateringMenuEditAvailableFrom_S"); }
    public get funcCateringMenuEditAvailableFrom_L() { return this.simple("funcCateringMenuEditAvailableFrom_L"); }
    public get funcCateringMenuEditAvailableFrom_D() { return this.simple("funcCateringMenuEditAvailableFrom_D"); }
    public get funcCateringMenuEditAvailableTo_S() { return this.simple("funcCateringMenuEditAvailableTo_S"); }
    public get funcCateringMenuEditAvailableTo_L() { return this.simple("funcCateringMenuEditAvailableTo_L"); }
    public get funcCateringMenuEditAvailableTo_D() { return this.simple("funcCateringMenuEditAvailableTo_D"); }
    public get funcCateringMenuEditImage_S() { return this.simple("funcCateringMenuEditImage_S"); }
    public get funcCateringMenuEditImage_L() { return this.simple("funcCateringMenuEditImage_L"); }
    public get funcCateringMenuEditImage_D() { return this.simple("funcCateringMenuEditImage_D"); }
    public get funcCateringMenuEditOptions_S() { return this.simple("funcCateringMenuEditOptions_S"); }
    public get funcCateringMenuEditOptions_L() { return this.simple("funcCateringMenuEditOptions_L"); }
    public get funcCateringMenuEditOptions_D() { return this.simple("funcCateringMenuEditOptions_D"); }
    public get funcCateringMenuEditSet_S() { return this.simple("funcCateringMenuEditSet_S"); }
    public get funcCateringMenuEditSet_L() { return this.simple("funcCateringMenuEditSet_L"); }
    public get funcCateringMenuEditSet_D() { return this.simple("funcCateringMenuEditSet_D"); }
    public get funcBookingCreateBookingOptions_S() { return this.simple("funcBookingCreateBookingOptions_S"); }
    public get funcBookingCreateBookingOptions_L() { return this.simple("funcBookingCreateBookingOptions_L"); }
    public get funcBookingCreateChooseLayout_D() { return this.simple("funcBookingCreateChooseLayout_D"); }
    public get dataBookingCreateSetup_S() { return this.simple("dataBookingCreateSetup_S"); }
    public get dataBookingCreateSetup_L() { return this.simple("dataBookingCreateSetup_L"); }
    public get dataBookingCreateReset_S() { return this.simple("dataBookingCreateReset_S"); }
    public get dataBookingCreateReset_L() { return this.simple("dataBookingCreateReset_L"); }
    public get funcBookingCreateCheckAvailability_S() { return this.simple("funcBookingCreateCheckAvailability_S"); }
    public get funcBookingCreateSetupReset_S() { return this.simple("funcBookingCreateSetupReset_S"); }
    public get funcBookingCreateSetupReset_L() { return this.simple("funcBookingCreateSetupReset_L"); }
    public get funcBookingCreateSetupError_D() { return this.simple("funcBookingCreateSetupError_D"); }
    public get funcBookingCreateSetupErrorSuggestion_D() { return this.simple("funcBookingCreateSetupErrorSuggestion_D"); }
    public get dataUserPrefWorkplaceDefBuilding_L() { return this.simple("dataUserPrefWorkplaceDefBuilding_L"); }
    public get dataUserPrefWorkplaceDefFloor_L() { return this.simple("dataUserPrefWorkplaceDefFloor_L"); }
    public get dataUserPrefWorkplaceDefZone_L() { return this.simple("dataUserPrefWorkplaceDefZone_L"); }
    public get funcRefreshBookings_S() { return this.simple("funcRefreshBookings_S"); }
    public get dataNotificationTitle_S() { return this.simple("dataNotificationTitle_S"); }
    public get dataNotificationClassification_S() { return this.simple("dataNotificationClassification_S"); }
    public get dataNotificationSubclassification_S() { return this.simple("dataNotificationSubclassification_S"); }
    public get dataNotificationPriority_S() { return this.simple("dataNotificationPriority_S"); }
    public get dataNotificationAvailableFrom_S() { return this.simple("dataNotificationAvailableFrom_S"); }
    public get dataNotificationExpiryDate_S() { return this.simple("dataNotificationExpiryDate_S"); }
    public get dataNotificationImage_S() { return this.simple("dataNotificationImage_S"); }
    public get dataNotificationCustomSubclassification_S() { return this.simple("dataNotificationCustomSubclassification_S"); }
    public get funcUserPrefAccountSetPIN_L() { return this.simple("funcUserPrefAccountSetPIN_L"); }
    public get funcUserPrefAccountConfirmPIN_L() { return this.simple("funcUserPrefAccountConfirmPIN_L"); }
    public get funcNotificationsListAll_S() { return this.simple("funcNotificationsListAll_S"); }
    public get funcNotificationsListUnread_S() { return this.simple("funcNotificationsListUnread_S"); }
    public get funcBookingScheduleSelectSpace_S() { return this.simple("funcBookingScheduleSelectSpace_S"); }
    public get funcBookingScheduleSelectSpace_L() { return this.simple("funcBookingScheduleSelectSpace_L"); }
    public get funcBookingScheduleSelectSpace_D() { return this.simple("funcBookingScheduleSelectSpace_D"); }
    public get funcCateringDietaryTermsDietary_S() { return this.simple("funcCateringDietaryTermsDietary_S"); }
    public get funcCateringDietaryTermsDeleteDietaryTerms_L() { return this.simple("funcCateringDietaryTermsDeleteDietaryTerms_L"); }
    public get funcCateringDietaryTermsDeleteDietaryTerms_D() { return this.simple("funcCateringDietaryTermsDeleteDietaryTerms_D"); }
    public get funcCateringDietaryTerms_S() { return this.simple("funcCateringDietaryTerms_S"); }
    public get funcOrphanedUsers_S() { return this.simple("funcOrphanedUsers_S"); }
    public get funcOrphanedUsers_M() { return this.simple("funcOrphanedUsers_M"); }
    public get funcOrphanedUsersFutureBookings_S() { return this.simple("funcOrphanedUsersFutureBookings_S"); }
    public get funcOrphanedUsersView_S() { return this.simple("funcOrphanedUsersView_S"); }
    public get funcOrphanedUsersDeletedIn_S() { return this.simple("funcOrphanedUsersDeletedIn_S"); }
    public get funcOrphanedUsersLastMonth_S() { return this.simple("funcOrphanedUsersLastMonth_S"); }
    public get funcOrphanedUsersRecords_Message() { return this.simple("funcOrphanedUsersRecords_Message"); }
    public get funcCateringDietaryTermsEdit_L() { return this.simple("funcCateringDietaryTermsEdit_L"); }
    public get funcCateringDietaryTermsAdd_L() { return this.simple("funcCateringDietaryTermsAdd_L"); }
    public get funcCateringDietaryTermsEdit_D() { return this.simple("funcCateringDietaryTermsEdit_D"); }
    public get funcCateringDietaryTermsAdd_D() { return this.simple("funcCateringDietaryTermsAdd_D"); }
    public get funcBookingScheduleCancelNo_S() { return this.simple("funcBookingScheduleCancelNo_S"); }
    public get funcBookingScheduleCancelNo_M() { return this.simple("funcBookingScheduleCancelNo_M"); }
    public get funcBookingScheduleCancelNo_L() { return this.simple("funcBookingScheduleCancelNo_L"); }
    public get funcBookingScheduleCancelYes_S() { return this.simple("funcBookingScheduleCancelYes_S"); }
    public get funcBookingScheduleCancelYes_M() { return this.simple("funcBookingScheduleCancelYes_M"); }
    public get funcBookingScheduleCancelYes_L() { return this.simple("funcBookingScheduleCancelYes_L"); }
    public get funcBookingScheduleCancelSubtitle_S() { return this.simple("funcBookingScheduleCancelSubtitle_S"); }
    public get funcBookingScheduleCancelSubtitle_M() { return this.simple("funcBookingScheduleCancelSubtitle_M"); }
    public get funcBookingScheduleCancelSubtitle_L() { return this.simple("funcBookingScheduleCancelSubtitle_L"); }
    public get funcAddVisitorMustBeAfterArrival_S() { return this.simple("funcAddVisitorMustBeAfterArrival_S"); }
    public get funcAddVisitorMustBeAfterArrival_M() { return this.simple("funcAddVisitorMustBeAfterArrival_M"); }
    public get funcAddVisitorMustBeAfterArrival_L() { return this.simple("funcAddVisitorMustBeAfterArrival_L"); }
    public get funcAddVisitorEditMustBeBeforeDeparture_S() { return this.simple("funcAddVisitorEditMustBeBeforeDeparture_S"); }
    public get funcAddVisitorEditMustBeBeforeDeparture_M() { return this.simple("funcAddVisitorEditMustBeBeforeDeparture_M"); }
    public get funcAddVisitorEditMustBeBeforeDeparture_L() { return this.simple("funcAddVisitorEditMustBeBeforeDeparture_L"); }
    public get funcOrphanedUsersNoBookingsFound_M() { return this.simple("funcOrphanedUsersNoBookingsFound_M"); }
    public get funcOrphanedUsersDeletedBookings_S() { return this.simple("funcOrphanedUsersDeletedBookings_S"); }
    public get funcOrphanedUsersDeleteInProgress_Message() { return this.simple("funcOrphanedUsersDeleteInProgress_Message"); }
    public get funcOrphanedUsersDeleted_S() { return this.simple("funcOrphanedUsersDeleted_S"); }
    public get funcOrphanedUsersDeleteError_Message() { return this.simple("funcOrphanedUsersDeleteError_Message"); }
    public get funcOrphanedUsersBookingsInSystem_M() { return this.simple("funcOrphanedUsersBookingsInSystem_M"); }
    public get funcRolesAndDataSecurityRoleDetailsTab_Message() { return this.simple("funcRolesAndDataSecurityRoleDetailsTab_Message"); }
    public get funcRolesAndDataSecurityAppSettingsTab_Message() { return this.simple("funcRolesAndDataSecurityAppSettingsTab_Message"); }
    public get funcRolesAndDataSecurityDataSecurityTab_Message() { return this.simple("funcRolesAndDataSecurityDataSecurityTab_Message"); }
    public get funcRolesAndDataSecurityUsersTab_Message() { return this.simple("funcRolesAndDataSecurityUsersTab_Message"); }
    public get funcOutOfServiceTitle_S() { return this.simple("funcOutOfServiceTitle_S"); }
    public get funcOutOfServiceDescription_S() { return this.simple("funcOutOfServiceDescription_S"); }
    public get funcBookingHomeSpace_S() { return this.simple("funcBookingHomeSpace_S"); }
    public get funcBookingHomeSpace_L() { return this.simple("funcBookingHomeSpace_L"); }
    public get funcBookingHomeSpaceStart_S() { return this.simple("funcBookingHomeSpaceStart_S"); }
    public get funcVisitorsHomeView_L() { return this.simple("funcVisitorsHomeView_L"); }
    public get funcVisitorsHomeView_S() { return this.simple("funcVisitorsHomeView_S"); }
    public get funcBookingHomeView_L() { return this.simple("funcBookingHomeView_L"); }
    public get funcBookingHomeView_S() { return this.simple("funcBookingHomeView_S"); }
    public get funcCateringOrdersHomeView_L() { return this.simple("funcCateringOrdersHomeView_L"); }
    public get funcCateringOrdersHomeView_S() { return this.simple("funcCateringOrdersHomeView_S"); }
    public get funcWelcomeToHomeLabel_S() { return this.simple("funcWelcomeToHomeLabel_S"); }
    public get funcBookingHomeYourView_L() { return this.simple("funcBookingHomeYourView_L"); }
    public get funcBookingHomeYourVisitors_L() { return this.simple("funcBookingHomeYourVisitors_L"); }
    public get funcBookingHomePreferences_S() { return this.simple("funcBookingHomePreferences_S"); }
    public get funcBookingHomePreferences_L() { return this.simple("funcBookingHomePreferences_L"); }
    public get funcBookingHomeViewPreferences_S() { return this.simple("funcBookingHomeViewPreferences_S"); }
    public get funcCreateBookingReturnHome_S() { return this.simple("funcCreateBookingReturnHome_S"); }
    public get funcBookingPoliciesExernalServices_S() { return this.simple("funcBookingPoliciesExernalServices_S"); }
    public get funcBookingPoliciesExernalServices_Message() { return this.simple("funcBookingPoliciesExernalServices_Message"); }
    public get funcBookingPoliciesCateringManager_S() { return this.simple("funcBookingPoliciesCateringManager_S"); }
    public get funcBookingPoliciesFloorManager_S() { return this.simple("funcBookingPoliciesFloorManager_S"); }
    public get HubLabelNameError_D() { return this.simple("HubLabelNameError_D"); }
    public get HubLabelclassificationError_D() { return this.simple("HubLabelclassificationError_D"); }
    public get HubLabelorderPolicyError_D() { return this.simple("HubLabelorderPolicyError_D"); }
    public get HubLabelSearchForAVisiorOrRef() { return this.simple("HubLabelSearchForAVisiorOrRef"); }
    public get HubLabelAdvanceBookingsAdditionalBookingInfo() { return this.simple("HubLabelAdvanceBookingsAdditionalBookingInfo"); }
    public get HubLabelConfirmBookingDetails() { return this.simple("HubLabelConfirmBookingDetails"); }
    public get funcCateringOrderNutritionalInformation_S() { return this.simple("funcCateringOrderNutritionalInformation_S"); }
    public get funcCateringOrderBeverages_S() { return this.simple("funcCateringOrderBeverages_S"); }
    public get funcCateringOrderSnacks_S() { return this.simple("funcCateringOrderSnacks_S"); }
    public get funcCateringOrderFoods_S() { return this.simple("funcCateringOrderFoods_S"); }
    public get funcCateringOrderSelectCatering_M() { return this.simple("funcCateringOrderSelectCatering_M"); }
    public get funcCateringOrderMenuAvailable_S() { return this.simple("funcCateringOrderMenuAvailable_S"); }
    public get funcCateringOrderAllergies_S() { return this.simple("funcCateringOrderAllergies_S"); }
    public get funcCateringOrderRestrictions_Message() { return this.simple("funcCateringOrderRestrictions_Message"); }
    public get funcCateringOrderRemoveFoods_M() { return this.simple("funcCateringOrderRemoveFoods_M"); }
    public get funcCateringOrderOnlyShowMarkedFoods_M() { return this.simple("funcCateringOrderOnlyShowMarkedFoods_M"); }
    public get funcCateringOrderApply_S() { return this.simple("funcCateringOrderApply_S"); }
    public get funcCateringOrderFilterBy_S() { return this.simple("funcCateringOrderFilterBy_S"); }
    public get funcCateringOrderAllergyInformation_S() { return this.simple("funcCateringOrderAllergyInformation_S"); }
    public get funcCateringOrderFilter_Message() { return this.simple("funcCateringOrderFilter_Message"); }
    public get HubLabelContains() { return this.simple("HubLabelContains"); }
    public get TooManySearchResults_L() { return this.simple("TooManySearchResults_L"); }
    public get funcSupplierDetailsEnter_L() { return this.simple("funcSupplierDetailsEnter_L"); }
    public get funcCateringOrderDecline_S() { return this.simple("funcCateringOrderDecline_S"); }
    public get funcCateringOrderStausUpdate_Message() { return this.simple("funcCateringOrderStausUpdate_Message"); }
    public get funcCateringOrderReadyForPrep_S() { return this.simple("funcCateringOrderReadyForPrep_S"); }
    public get funcCateringOrderPrepareForService_S() { return this.simple("funcCateringOrderPrepareForService_S"); }
    public get funcCateringOrderReadyInTime_S() { return this.simple("funcCateringOrderReadyInTime_S"); }
    public get funcCateringOrderReadyLate_S() { return this.simple("funcCateringOrderReadyLate_S"); }
    public get funcCateringOrderDelivered_S() { return this.simple("funcCateringOrderDelivered_S"); }
    public get funcCateringOrderDeliveryIssue_S() { return this.simple("funcCateringOrderDeliveryIssue_S"); }
    public get funcCateringOrderClearedUpInTime_S() { return this.simple("funcCateringOrderClearedUpInTime_S"); }
    public get funcCateringOrderClearedUpLate_S() { return this.simple("funcCateringOrderClearedUpLate_S"); }
    public get funcCateringOrderCleared_S() { return this.simple("funcCateringOrderCleared_S"); }
    public get funcCateringOrderCancelOrderCharge_S() { return this.simple("funcCateringOrderCancelOrderCharge_S"); }
    public get funcCateringOrderCancelOrderNoCharge_S() { return this.simple("funcCateringOrderCancelOrderNoCharge_S"); }
    public get funcCateringOrderNoMenuAvailable_S() { return this.simple("funcCateringOrderNoMenuAvailable_S"); }
    public get funcCateringOrderNoMenuAvailable_Message() { return this.simple("funcCateringOrderNoMenuAvailable_Message"); }
    public get funcPolicyModal_Subtitle() { return this.simple("funcPolicyModal_Subtitle"); }
    public get funcAccept() { return this.simple("funcAccept"); }
    public get funcCateringOrderNoItems_L() { return this.simple("funcCateringOrderNoItems_L"); }
    public get funcCateringOrderNoItems_Message() { return this.simple("funcCateringOrderNoItems_Message"); }
    public get funcCateringOrderNoItemsWithFilter_L() { return this.simple("funcCateringOrderNoItemsWithFilter_L"); }
    public get funcCateringOrderNoItemsWithFilter_Message() { return this.simple("funcCateringOrderNoItemsWithFilter_Message"); }
    public get funcAdvancedBooking_Short() { return this.simple("funcAdvancedBooking_Short"); }
    public get funcDuplicate() { return this.simple("funcDuplicate"); }
    public get funDuplicatePolicyTitle() { return this.simple("funDuplicatePolicyTitle"); }
    public get funDuplicatePolicyDescription() { return this.simple("funDuplicatePolicyDescription"); }
    public get funcScheduleViewCreateBooking_S() { return this.simple("funcScheduleViewCreateBooking_S") }
    public get funcScheduleViewUpdateBooking_S() { return this.simple("funcScheduleViewUpdateBooking_S") }
    public get funcSwapSpaceSwap_S() { return this.simple("funcSwapSpaceSwap_S") }
    public get funcSwapSpaceCurrentSpace_S() { return this.simple("funcSwapSpaceCurrentSpace_S") }
    public get funcSwapSpaceAlternativeSpaces_D() { return this.simple("funcSwapSpaceAlternativeSpaces_D") }
    public get funcSwapSpaceConfirmSwap_S() { return this.simple("funcSwapSpaceConfirmSwap_S") }
    public get funcSwapTimeSwapDateAndTime_S() { return this.simple("funcSwapTimeSwapDateAndTime_S") }
    public get funcSwapTimeOriginalDateMeeting_S() { return this.simple("funcSwapTimeOriginalDateMeeting_S") }
    public get funcSwapTimeCurrentDate_S() { return this.simple("funcSwapTimeCurrentDate_S") }
    public get funcSwapTimeSearchSpacesInstruction_D() { return this.simple("funcSwapTimeSearchSpacesInstruction_D") }
    public get funcSwapTimeSpacesResults_L() { return this.simple("funcSwapTimeSpacesResults_L") }
    public get funcSwapTimeMoveResourcesInstruction_D() { return this.simple("funcSwapTimeMoveResourcesInstruction_D") }
    public get funcSwapTimeMoveResources_L() { return this.simple("funcSwapTimeMoveResources_L") }
    public get funcMustBeTwoOrMore_S() { return this.simple("funcMustBeTwoOrMore_S"); }
    public get funcMustBeNumber_S() { return this.simple("funcMustBeNumber_S"); }
    public get funcScheduleViewResources_S() { return this.simple("funcScheduleViewResources_S"); }
    public get HubLabelPolicy() { return this.simple("HubLabelPolicy"); }
    public get HubLabelViewSpacesTableDescription() { return this.simple("HubLabelViewSpacesTableDescription"); }
    public get funcCateringOrderAllergens_D() { return this.simple("funcCateringOrderAllergens_D"); }
    public get HubBookingPolicyTitle() { return this.simple("HubBookingPolicyTitle"); }
    public get funcScheduleNoSpaces_L() { return this.simple("funcScheduleNoSpaces_L"); }
    public get funcScheduleNoSpaces_D() { return this.simple("funcScheduleNoSpaces_D"); }
    public get funcScheduleReturnToFilters_S() { return this.simple("funcScheduleReturnToFilters_S"); }
    public get funcScheduleResetFilters_S() { return this.simple("funcScheduleResetFilters_S"); }
    public get funcAdvancedBookingSeeSpaceDetail_L() { return this.simple("funcAdvancedBookingSeeSpaceDetail_L"); }
    public get funcAdvancedBookingPolicyDescription_L() { return this.simple("funcAdvancedBookingPolicyDescription_L"); }
    public get funcAdvancedBookingAdditionalInformation_S() { return this.simple("funcAdvancedBookingAdditionalInformation_S"); }
    public get HubLabelEnterCostCode() { return this.simple("HubLabelEnterCostCode"); }
    public get HubLabelAboutSpaceDetails() { return this.simple("HubLabelAboutSpaceDetails"); }
    public get funcSwapSpaceLinkedWarning_L() { return this.simple("funcSwapSpaceLinkedWarning_L"); }
    public get funcSwapSpaceLinkedWarning_D() { return this.simple("funcSwapSpaceLinkedWarning_D"); }
    public get funcDelegatePermissions_S() { return this.simple("funcDelegatePermissions_S"); }
    public get funcDelegateSummary_S() { return this.simple("funcDelegateSummary_S"); }
    public get funcDelegateSummary_D() { return this.simple("funcDelegateSummary_D"); }
    public get funcDelegateAccess_S() { return this.simple("funcDelegateAccess_S"); }
    public get funcDelegateAccess_D() { return this.simple("funcDelegateAccess_D"); }
    public get funcDelegateReceived_S() { return this.simple("funcDelegateReceived_S"); }
    public get funcAdminDelegateSummary_D() { return this.simple("funcAdminDelegateSummary_D"); }
    public get funcFlexDelegateSummary_D() { return this.simple("funcFlexDelegateSummary_D"); }
    public get funcDelegateYourAccountAccess_S() { return this.simple("funcDelegateYourAccountAccess_S"); }
    public get funcDelegateYourAccountAccess_D() { return this.simple("funcDelegateYourAccountAccess_D"); }
    public get funcSwitch_S() { return this.simple("funcSwitch_S"); }
    public get funcDelegator_S() { return this.simple("funcDelegator_S"); }
    public get funcDelegates_S() { return this.simple("funcDelegates_S"); }
    public get funcSearchDelegator_S() { return this.simple("funcSearchDelegator_S"); }
    public get funcSearchDelegator_L() { return this.simple("funcSearchDelegator_L"); }
    public get funcSearchDelegates_S() { return this.simple("funcSearchDelegates_S"); }
    public get funcSearchDelegates_L() { return this.simple("funcSearchDelegates_L"); }
    public get funcCurrentAccount_S() { return this.simple("funcCurrentAccount_S"); }
    public get funcNoDelegates_S() { return this.simple("funcNoDelegates_S"); }
    public get funcFailedToLoadDelegates_L() { return this.simple("funcFailedToLoadDelegates_L"); }
    public get funcMustSelectDelegator_L() { return this.simple("funcMustSelectDelegator_L"); }
    public get funcDelegateName_S() { return this.simple("funcDelegateName_S"); }
    public get funcDelegateEmail_S() { return this.simple("funcDelegateEmail_S"); }
    public get funcFailedToSaveDelegates_L() { return this.simple("funcFailedToSaveDelegates_L"); }
    public get funcDelegatesSaved_L() { return this.simple("funcDelegatesSaved_L"); }
    public get funcDelegatorCannotDelegateSelf_L() { return this.simple("funcDelegatorCannotDelegateSelf_L"); }
    public get funcSwitchDelegator_S() { return this.simple("funcSwitchDelegator_S"); }
    public get funcSwitchDelegator_D() { return this.simple("funcSwitchDelegator_D"); }
    public get funcBuildingAnnouncement_S() { return this.simple("funcBuildingAnnouncement_S"); }
    public get funcAnnouncementsCreate_L() { return this.simple("funcAnnouncementsCreate_L"); }
    public get funcAnnouncementsEdit_L() { return this.simple("funcAnnouncementsEdit_L"); }
    public get funcAnnouncementID_S() { return this.simple("funcAnnouncementID_S"); }
    public get funcAnnouncementBroadcast_Message() { return this.simple("funcAnnouncementBroadcast_Message"); }
    public get funcAnnouncementRoamerAndFlex() { return this.simple("funcAnnouncementRoamerAndFlex"); }
    public get funcAnnouncementSaveAsDraft_S() { return this.simple("funcAnnouncementSaveAsDraft_S"); }
    public get funcAnnouncementPublish_S() { return this.simple("funcAnnouncementPublish_S"); }
    public get funcAnnouncementEnterTitleShortDescription_L() { return this.simple("funcAnnouncementEnterTitleShortDescription_L"); }
    public get funcAnnouncementTitle_S() { return this.simple("funcAnnouncementTitle_S"); }
    public get funcAnnouncementCharacterLimit_S() { return this.simple("funcAnnouncementCharacterLimit_S"); }
    public get funcAnnouncementShortDescription_S() { return this.simple("funcAnnouncementShortDescription_S"); }
    public get funcAnnouncementEnter_L() { return this.simple("funcAnnouncementEnter_L"); }
    public get funcAnnouncementSelectHeaderImage_S() { return this.simple("funcAnnouncementSelectHeaderImage_S"); }
    public get funcAnnouncementsLabels_S() { return this.simple("funcAnnouncementsLabels_S"); }
    public get funcAnnouncementsLabels_Message() { return this.simple("funcAnnouncementsLabels_Message"); }
    public get funcAnnouncementsCustomLabel_S() { return this.simple("funcAnnouncementsCustomLabel_S"); }
    public get funcAnnouncementAddHighPriority_L() { return this.simple("funcAnnouncementAddHighPriority_L"); }
    public get funcAnnouncementsMessageSchedule_S() { return this.simple("funcAnnouncementsMessageSchedule_S"); }
    public get funcAnnouncementsActiveStart_S() { return this.simple("funcAnnouncementsActiveStart_S"); }
    public get funcAnnouncementStartError_Message() { return this.simple("funcAnnouncementStartError_Message"); }
    public get funcAnnouncementEndError_Message() { return this.simple("funcAnnouncementEndError_Message"); }
    public get funcAnnouncementsEndExpire_S() { return this.simple("funcAnnouncementsEndExpire_S"); }
    public get funcAnnouncementsEnterTitleScrollingMessage_L() { return this.simple("funcAnnouncementsEnterTitleScrollingMessage_L"); }
    public get funcAnnouncementsTitleInfoHeading_Message() { return this.simple("funcAnnouncementsTitleInfoHeading_Message"); }
    public get funcAnnouncementsScrollingMessage_L() { return this.simple("funcAnnouncementsScrollingMessage_L"); }
    public get funcScheduleAddNewBooking_S() { return this.simple("funcScheduleAddNewBooking_S") }
    public get funcAnouncementPriorityMedium_Short() { return this.simple("funcAnouncementPriorityMedium_Short") }
    public get funcAnnouncementsStatusExpired_Short() { return this.simple("funcAnnouncementsStatusExpired_Short") }
    public get funcAnnouncementsBroadcast_Short() { return this.simple("funcAnnouncementsBroadcast_Short") }
    public get funcAnnouncementsExpire_Short() { return this.simple("funcAnnouncementsExpire_Short") }
    public get funcAnnouncementsConfirmDelete_Message() { return this.simple("funcAnnouncementsConfirmDelete_Message") }
    public get funcAnnouncementsCreateDuplicate_Short() { return this.simple("funcAnnouncementsCreateDuplicate_Short") }
    public get funcAnnouncementsConfirmExpire_Message() { return this.simple("funcAnnouncementsConfirmExpire_Message") }
    public get funcAnnouncementsSheduledContent_Short() { return this.simple("funcAnnouncementsSheduledContent_Short") }
    public get funcAnnouncementsTableDescriptionPt1_Message() { return this.simple("funcAnnouncementsTableDescriptionPt1_Message") }
    public get funcAnnouncementsTableDescriptionPt2_Message() { return this.simple("funcAnnouncementsTableDescriptionPt2_Message") }
    public get funcAnnouncementRoamerAndFlex_Short() { return this.simple("funcAnnouncementRoamerAndFlex_Short") }
    public get HubLabelOutOfService() { return this.simple("HubLabelOutOfService"); }
    public get HubLabelOfficeShutDown() { return this.simple("HubLabelOfficeShutDown"); }
    public get HubLabelMaintenance() { return this.simple("HubLabelMaintenance"); }
    public get funcMenuSchedule_S() { return this.simple("funcMenuSchedule_S"); }
    public get HubLabelFavourites() { return this.simple("HubLabelFavourites"); }
    public get HubLabelDelegatedBy() { return this.simple("HubLabelDelegatedBy"); }
    public get funcMenuDefaultCostCodes_Short() { return this.simple("funcMenuDefaultCostCodes_Short"); }
    public get funcDefaultCostCodesBooking_Short() { return this.simple("funcDefaultCostCodesBooking_Short"); }
    public get funcDefaultBookingCostCodesHeader_Message() { return this.simple("funcDefaultBookingCostCodesHeader_Message"); }
    public get funcDefaultBookingCostCodesInfoPt1_Message() { return this.simple("funcDefaultBookingCostCodesInfoPt1_Message"); }
    public get funcDefaultBookingCostCodesInfoPt2_Message() { return this.simple("funcDefaultBookingCostCodesInfoPt2_Message"); }
    public get funcDefaultCostCodesSelect_Long() { return this.simple("funcDefaultCostCodesSelect_Long"); }
    public get funcDefaultCateringCostCodesHeader_Message() { return this.simple("funcDefaultCateringCostCodesHeader_Message"); }
    public get funcDefaultCateringCostCodesInfoPt1_Message() { return this.simple("funcDefaultCateringCostCodesInfoPt1_Message"); }
    public get funcDefaultCateringCostCodesInfoPt2_Message() { return this.simple("funcDefaultCateringCostCodesInfoPt2_Message"); }
    public get funcSpacesEnableSpaces_Short() { return this.simple("funcSpacesEnableSpaces_Short"); }
    public get funcSpacesDisableSpaces_Short() { return this.simple("funcSpacesDisableSpaces_Short"); }
    public get funcSpacesSelectedSpacesToUpdate_Message() { return this.simple("funcSpacesSelectedSpacesToUpdate_Message"); }
    public get funcSpacesEnabledSpacesInfo_Message() { return this.simple("funcSpacesEnabledSpacesInfo_Message"); }
    public get funcSpacesDisabledSpacesInfo_Message() { return this.simple("funcSpacesDisabledSpacesInfo_Message"); }
    public get funcSpacesDisabledSpacesInfoPt2_Message() { return this.simple("funcSpacesDisabledSpacesInfoPt2_Message"); }
    public get funcSpacesSetStatus_Short() { return this.simple("funcSpacesSetStatus_Short"); }
    public get funcSidebarAnnouncements_Short() { return this.simple("funcSidebarAnnouncements_Short"); }
    public get funcCateringOrderSelectedMenu_Short() { return this.simple("funcCateringOrderSelectedMenu_Short") }
    public get funcCateringOrderChangeMenu_Short() { return this.simple("funcCateringOrderChangeMenu_Short") }
    public get funcCateringOrderMenuChangeWarning_Message() { return this.simple("funcCateringOrderMenuChangeWarning_Message") }
    public get funcCateringOrdersPleaseNote_Short() { return this.simple("funcCateringOrdersPleaseNote_Short") }
    public get funcCateringOrderMenuChangeInfo_Message() { return this.simple("funcCateringOrderMenuChangeInfo_Message") }
    public get funcScheduleSpaceSchedule_S() { return this.simple("funcScheduleSpaceSchedule_S"); }
    public get HubLabelAvailableToSpaces() { return this.simple("HubLabelAvailableToSpaces") }
    public get HubLabelAvailableForSpaces() { return this.simple("HubLabelAvailableForSpaces") }
    public get funcCateringMenuEditMenuIdIsNull_S() { return this.simple("funcCateringMenuEditMenuIdIsNull_S") }
    public get HubLabelWork() { return this.simple("HubLabelWork") }
    public get HubLabelAmenity() { return this.simple("HubLabelAmenity") }
    public get HubLabelAncillary() { return this.simple("HubLabelAncillary") }
    public get HubLabelSpaceConfiguration_Message() { return this.simple("HubLabelSpaceConfiguration_Message") }
    public get HubLabelActiveSpace() { return this.simple("HubLabelActiveSpace") }
    public get HubLabelSpaceCustomInformation() { return this.simple("HubLabelSpaceCustomInformation") }
    public get HubLabelSpaceImage() { return this.simple("HubLabelSpaceImage") }
    public get HubLabelXCoordinates() { return this.simple("HubLabelXCoordinates") }
    public get HubLabelYCoordinates() { return this.simple("HubLabelYCoordinates") }
    public get HubLabelPropertiesDescription() { return this.simple("HubLabelPropertiesDescription") }
    public get HubLabelSpaceStatus() { return this.simple("HubLabelSpaceStatus") }
    public get HubLabelSignoff_Message() { return this.simple("HubLabelSignoff_Message") }
    public get HubLabelDigitalSignType() { return this.simple("HubLabelDigitalSignType") }
    public get HubLabelTemperatureRange() { return this.simple("HubLabelTemperatureRange") }
    public get HubLabelSoundRange() { return this.simple("HubLabelSoundRange") }
    public get HubLabelSaveUpdates() { return this.simple("HubLabelSaveUpdates") }
    public get funcAnnouncementsDuplicate_D() { return this.simple("funcAnnouncementsDuplicate_D") }
    public get funcAnnouncementSelected_S() { return this.simple("funcAnnouncementSelected_S") }
    public get funcAnnouncementDuplicateLocation_S() { return this.simple("funcAnnouncementDuplicateLocation_S") }
    public get funcAnnouncementEditDetails_S() { return this.simple("funcAnnouncementEditDetails_S") }
    public get HubLabelSpaceNameLabel() { return this.simple("HubLabelSpaceNameLabel") }
    public get HubLabelEnvironmentalZone() { return this.simple("HubLabelEnvironmentalZone") }
    public get funcViewScheduleLoadNextTen_S() { return this.simple("funcViewScheduleLoadNextTen_S") }
    public get funcCalendarJumpToToday_S() { return this.simple("funcCalendarJumpToToday_S") }
    public get HubLabelSortBy_S() { return this.simple("HubLabelSortBy_S") }
    public get HubLabelCreate() { return this.simple("HubLabelCreate") }
    public get HubButtonLoadMore() { return this.simple("HubButtonLoadMore") }
    public get HublabelAdminBookableOnly() { return this.simple("HublabelAdminBookableOnly") }
    public get HublabelManualDataCollection() { return this.simple("HublabelManualDataCollection") }
    public get HublabelAutomaticDataCollection() { return this.simple("HublabelAutomaticDataCollection") }
    public get HublabelAlternativeworkspace() { return this.simple("HublabelAlternativeworkspace") }
    public get funcSystemNotificationTableDescriptionPt1_Message() { return this.simple("funcSystemNotificationTableDescriptionPt1_Message") }
    public get funcSpacesSetStatuses_Short() { return this.simple("funcSpacesSetStatuses_Short") }
    public get funcCateringOrder_S() { return this.simple("funcCateringOrder_S") }
    public get funcStartPreperations_S() { return this.simple("funcStartPreperations_S") }
    public get funcReadyforDelivery_S() { return this.simple("funcReadyforDelivery_S") }
    public get funcCleanedUp_S() { return this.simple("funcCleanedUp_S") }
    public get funcOrderNumber_S() { return this.simple("funcOrderNumber_S") }
    public get funcDeliveryTo_S() { return this.simple("funcDeliveryTo_S") }
    public get funcDeliveryLocation_S() { return this.simple("funcDeliveryLocation_S") }
    public get funcDropoffTime_S() { return this.simple("funcDropoffTime_S") }
    public get funcOrderedItems_S() { return this.simple("funcOrderedItems_S") }
    public get funcEditOrder_S() { return this.simple("funcEditOrder_S") }
    public get funcInPrep_S() { return this.simple("funcInPrep_S") }
    public get funcInApproval_S() { return this.simple("funcInApproval_S") }
    public get funcNoUserSelected_S() { return this.simple("funcNoUserSelected_S"); }
    public get funcUnknown_S() { return this.simple("funcUnknown_S"); }
    public get HubMenuSelectBuildings() { return this.simple("HubMenuSelectBuildings"); }
    public get funcNotificationsEventCreatedAt_L() { return this.simple("funcNotificationsEventCreatedAt_L"); }
    public get funcManyBookingsCancelled_D() { return this.simple("funcManyBookingsCancelled_D"); }
    public get funcManyBookingsNotCancelled_D() { return this.simple("funcManyBookingsNotCancelled_D"); }
    public get funcOrphanedBookings_S() { return this.simple("funcOrphanedBookings_S"); }
    public get funcAddRecurrence_D() { return this.simple("funcAddRecurrence_D"); }
    public get funcLinkedSpaces_D() { return this.simple("funcLinkedSpaces_D"); }
    public get funcAddOns_D() { return this.simple("funcAddOns_D"); }
    public get funcAsSoonAsPossible_D() { return this.simple("funcAsSoonAsPossible_D"); }
    public get funcAdvancedBookingAvailability_S() { return this.simple("funcAdvancedBookingAvailability_S"); }
    public get funcAdvancedBookingAvailableDates_S() { return this.simple("funcAdvancedBookingAvailableDates_S"); }
    public get funcAdvancedBookingRecurring_S() { return this.simple("funcAdvancedBookingRecurring_S"); }
    public get funcAdvancedBookingMultiple_S() { return this.simple("funcAdvancedBookingMultiple_S"); }
    public get funcAdvancedBookingAddDateTimes_Message() { return this.simple("funcAdvancedBookingAddDateTimes_Message"); }
    public get funcAdvancedBookingDateSelectedError_Message() { return this.simple("funcAdvancedBookingDateSelectedError_Message"); }
    public get funcPolicyDescription_D() { return this.simple("funcPolicyDescription_D"); }
    public get funcConfigurableLimits_D() { return this.simple("funcConfigurableLimits_D"); }
    public get funcFixedTimeLimits_D() { return this.simple("funcFixedTimeLimits_D"); }
    public get funcBookingOffset_D() { return this.simple("funcBookingOffset_D"); }
    public get funcEarlyCheckInThreshold_D() { return this.simple("funcEarlyCheckInThreshold_D"); }
    public get funcCancellationThreshold_D() { return this.simple("funcCancellationThreshold_D"); }
    public get funcRecurringBookingsPolicy_D() { return this.simple("funcRecurringBookingsPolicy_D"); }
    public get funcApprovals_D() { return this.simple("funcApprovals_D"); }
    public get funcVisitors_S() { return this.simple("funcVisitors_S"); }
    public get funcCreateNewTag_S() { return this.simple("funcCreateNewTag_S"); }
    public get funcCreateYourTag_S() { return this.simple("funcCreateYourTag_S"); }
    public get funcCurrentTags_S() { return this.simple("funcCurrentTags_S"); }
    public get funcComplexTag_S() { return this.simple("funcComplexTag_S"); }
    public get funcBasicTag_S() { return this.simple("funcBasicTag_S"); }
    public get funcTagName_S() { return this.simple("funcTagName_S"); }
    public get funcAssociateToRecord_S() { return this.simple("funcAssociateToRecord_S"); }
    public get funcUploadAnIcon_S() { return this.simple("funcUploadAnIcon_S"); }
    public get funcUploadAnIconInfo_D() { return this.simple("funcUploadAnIconInfo_D"); }
    public get funcSelectYourOptions_S() { return this.simple("funcSelectYourOptions_S"); }
    public get funcSelectYourOptionsInfo_D() { return this.simple("funcSelectYourOptionsInfo_D"); }
    public get funcOption_S() { return this.simple("funcOption_S"); }
    public get funcAddAnotherOption_S() { return this.simple("funcAddAnotherOption_S"); }
    public get funcSaveTag_S() { return this.simple("funcSaveTag_S"); }
    public get funcCreateNewTagInfo_D() { return this.simple("funcCreateNewTagInfo_D"); }
    public get funcAdvancedBookingAddMultipleDateTimes_Message() { return this.simple("funcAdvancedBookingAddMultipleDateTimes_Message"); }
    public get funcAdvancedBookingsMeetingStart_S() { return this.simple("funcAdvancedBookingsMeetingStart_S"); }
    public get funcAdvancedBookingsMeetingEnd_S() { return this.simple("funcAdvancedBookingsMeetingEnd_S"); }
    public get funcBookingDashboard_S() { return this.simple("funcBookingDashboard_S"); }
    public get funcBookableSetting_S() { return this.simple("funcBookableSetting_S"); }
    public get HubMenuSystemFilters() { return this.simple("HubMenuSystemFilters"); }
    public get HubLabelViewFiltersTableFilterName() { return this.simple("HubLabelViewFiltersTableFilterName"); }
    public get HubLabelConfirmDeleteSystemFilter() { return this.simple("HubLabelConfirmDeleteSystemFilter"); }
    public get HubLabelConfirmDeleteSystemFilterText() { return this.simple("HubLabelConfirmDeleteSystemFilterText"); }
    public get HubLabelDuplicateSystemFilter() { return this.simple("HubLabelDuplicateSystemFilter"); }
    public get HubLabelDuplicateSystemFilterText() { return this.simple("HubLabelDuplicateSystemFilterText"); }
    public get HubLabelAddSystemFilter() { return this.simple("HubLabelAddSystemFilter"); }
    public get HubLabelAddSystemFilterText() { return this.simple("HubLabelAddSystemFilterText"); }
    public get HubLabelOwnerRoleLabel() { return this.simple("HubLabelOwnerRoleLabel"); }
    public get HubLabelFilterQueryText() { return this.simple("HubLabelFilterQueryText"); }
    public get HubLabelFilterQueryHelperText() { return this.simple("HubLabelFilterQueryHelperText"); }
    public get HubLabelFilterQueryHelperLink() { return this.simple("HubLabelFilterQueryHelperLink"); }
    public get HubLabelRecordTypeLabel() { return this.simple("HubLabelRecordTypeLabel"); }
    public get HubLabelManagedSpaceFilter() { return this.simple("HubLabelManagedSpaceFilter"); }
    public get HubLabelManagedSpaceFilterText() { return this.simple("HubLabelManagedSpaceFilterText"); }
    public get funcTagManagement_S() { return this.simple("funcTagManagement_S"); }
    public get funcTagDescription_S() { return this.simple("funcTagDescription_S"); }
    public get funcAdvancedBookingsRefineSearch_S() { return this.simple("funcAdvancedBookingsRefineSearch_S"); }
    public get funcAdvancedBookingsMultipleBookingsInfo_Message() { return this.simple("funcAdvancedBookingsMultipleBookingsInfo_Message"); }
    public get funcTags_S() { return this.simple("funcTags_S"); }
    public get HubLabelEditFilter() { return this.simple("HubLabelEditFilter"); }
    public get HubTitleDefaultFilter() { return this.simple("HubTitleDefaultFilter"); }
    public get HubSubTitleDefaultFilter() { return this.simple("HubSubTitleDefaultFilter"); }
    public get funcPantryViewHeading_S() { return this.simple("funcPantryViewHeading_S"); }
    public get funcEditBookingBookingCancelled_S() { return this.simple("funcEditBookingBookingCancelled_S"); }
    public get funcEditBookingChangesSaved_S() { return this.simple("funcEditBookingChangesSaved_S"); }
    public get funcEdisBookingResourcesUpdated_S() { return this.simple("funcEdisBookingResourcesUpdated_S"); }
    public get funcEquipmentPresentationAids_S() { return this.simple("funcEquipmentPresentationAids_S"); }
    public get funcMakeAnotherBooking_S() { return this.simple("funcMakeAnotherBooking_S"); }
    public get funcCateringAdded_S() { return this.simple("funcCateringAdded_S"); }
    public get funcViewBooking_S() { return this.simple("funcViewBooking_S"); }
    public get funcSimpleTags_S() { return this.simple("funcSimpleTags_S"); }
    public get funcViewTheTagsInfo_D() { return this.simple("funcViewTheTagsInfo_D"); }
    public get funcViewSeries_S() { return this.simple("funcViewSeries_S"); }
    public get funcSeriesBookingSelected_Message() { return this.simple("funcSeriesBookingSelected_Message"); }
    public get funcConfirmViewBookingSeries_Message() { return this.simple("funcConfirmViewBookingSeries_Message"); }
    public get funcSeriesBooking_S() { return this.simple("funcSeriesBooking_S"); }
    public get funcBookingOwner_S() { return this.simple("funcBookingOwner_S"); }
    public get funcSeriesCreationInProgressPt1_Message() { return this.simple("funcSeriesCreationInProgressPt1_Message"); }
    public get funcSeriesCreationInProgressPt2_Message() { return this.simple("funcSeriesCreationInProgressPt2_Message"); }
    public get funcSeriesCreated_Message() { return this.simple("funcSeriesCreated_Message"); }
    public get funcFailedSeriesBooking_Message() { return this.simple("funcFailedSeriesBooking_Message"); }
    public get funcSaveSeriesUpdates_L() { return this.simple("funcSaveSeriesUpdates_L"); }
    public get funcSeriesListInfo_Message() { return this.simple("funcSeriesListInfo_Message"); }
    public get funcScheduleBookingSummary_S() { return this.simple("funcScheduleBookingSummary_S") }
    public get funcScheduleBookingNotes_S() { return this.simple("funcScheduleBookingNotes_S") }
    public get HubSubTitleCustomFilter() { return this.simple("HubSubTitleCustomFilter") }
    public get HubLabelPredefinedFilter() { return this.simple("HubLabelPredefinedFilter") }
    public get funcYourBookingSummary_S() { return this.simple("funcYourBookingSummary_S") }
    public get funcSelectYourOptionsIconInfo_D() { return this.simple("funcSelectYourOptionsIconInfo_D") }
    public get funcEditTag_S() { return this.simple("funcEditTag_S") }
    public get funcSimpleTagToolTip_L() { return this.simple("funcSimpleTagToolTip_L") }
    public get funcComplexTagToolTip_L() { return this.simple("funcComplexTagToolTip_L") }
    public get HubLabelEditBooking() { return this.simple('HubLabelEditBooking') }
    public get funcHostDetails_S() { return this.simple("funcHostDetails_S") }
    public get funcTimings_S() { return this.simple("funcTimings_S") }
    public get funcAddNote_S() { return this.simple('funcAddNote_S') }
    public get funcEnterYourNote_L() { return this.simple('funcEnterYourNote_L') }
    public get funcAddNewNote_S() { return this.simple('funcAddNewNote_S') }
    public get funcVisitor_S() { return this.simple('funcVisitor_S') }
    public get funcCheckInHypenated_S() { return this.simple('funcCheckInHypenated_S') }
    public get funcCheckOutHypenated_S() { return this.simple('funcCheckOutHypenated_S') }
    public get funcCheckInHypenated_Capitalized_S() { return this.simple('funcCheckInHypenated_Capitalized_S') }
    public get funcBold_S() { return this.simple('funcBold_S') }
    public get funcItalic_S() { return this.simple('funcItalic_S') }
    public get funcHeading_S() { return this.simple('funcHeading_S') }
    public get funcOrderedList_S() { return this.simple('funcOrderedList_S') }
    public get funcUnorderedList_S() { return this.simple('funcUnorderedList_S') }
    public get funcBlockQuote_S() { return this.simple('funcBlockQuote_S') }
    public get funcCodeBlock_S() { return this.simple('funcCodeBlock_S') }
    public get funcLink_S() { return this.simple('funcLink_S') }
    public get funcHorizontalRule_S() { return this.simple('funcHorizontalRule_S') }
    public get funcClearFormatting_S() { return this.simple('funcClearFormatting_S') }
    public get funcTagConfirmDelete_Message() { return this.simple('funcTagConfirmDelete_Message') }
    public get funcDeleteTag_S() { return this.simple('funcDeleteTag_S') }
    public get funcTimeForCleanUp_S() { return this.simple('funcTimeForCleanUp_S') }
    public get funcSelectSpaceFilter_S() { return this.simple('funcSelectSpaceFilter_S') }
    public get funcOverViewBookings_D() { return this.simple('funcOverViewBookings_D') }
    public get funcVisitorNotes_S() { return this.simple('funcVisitorNotes_S') }
    public get funcVisitorFrontDeskNotes_S() { return this.simple('funcVisitorFrontDeskNotes_S') }
    public get funcAdvancedBookingsWhere_L() { return this.simple('funcAdvancedBookingsWhere_L') }
    public get funcAdvancedBookingsWhat_L() { return this.simple('funcAdvancedBookingsWhat_L') }
    public get funcAdvancedBookingsWhen_L() { return this.simple('funcAdvancedBookingsWhen_L') }
    public get funcAdvancedBookingsSingular_S() { return this.simple('funcAdvancedBookingsSingular_S') }
    public get funcNewOwner_S() { return this.simple('funcNewOwner_S') }
    public get funcChangeOwner_S() { return this.simple('funcChangeOwner_S') }
    public get funcOptionName_S() { return this.simple('funcOptionName_S') }
    public get funcTagDetails_S() { return this.simple('funcTagDetails_S') }
    public get funcMustBeValidUrl_L() { return this.simple('funcMustBeValidUrl_L') }
    public get funcSpaceInfoSpaceGuides_S() { return this.simple('funcSpaceInfoSpaceGuides_S') }
    public get funcSpaceInfoSpaceInformation_S() { return this.simple('funcSpaceInfoSpaceInformation_S') }
    public get funcNeedPresentationAids_D() { return this.simple('funcNeedPresentationAids_D') }
    public get funcNeedHearingAids_D() { return this.simple('funcNeedHearingAids_D') }
    public get funcNeedLinkedSpace_D() { return this.simple('funcNeedLinkedSpace_D') }
    public get funcNeedLayouts_D() { return this.simple('funcNeedLayouts_D') }
    public get funcDefaultSetupAndTeardown_S() { return this.simple('funcDefaultSetupAndTeardown_S') }
    public get funcDefaultSetupAndTeardownMessagePt1_Message() { return this.simple('funcDefaultSetupAndTeardownMessagePt1_Message') }
    public get funcDefaultSetupAndTeardownMessagePt2_Message() { return this.simple('funcDefaultSetupAndTeardownMessagePt2_Message') }
    public get funcEditBookingDateTime_S() { return this.simple('funcEditBookingDateTime_S') }
    public get funcEditBookingStartTimeEndTime_S() { return this.simple('funcEditBookingStartTimeEndTime_S') }
    public get funcSpaceAvailableMessage_S() { return this.simple('funcSpaceAvailableMessage_S') }
    public get funcRefreshNodesCache_S() { return this.simple('funcRefreshNodesCache_S') }
    public get HubLabelAddTag_S() { return this.simple('HubLabelAddTag_S') }
    public get funcMinCapacity_S() { return this.simple('funcMinCapacity_S') }
    public get funcHasCatering_S() { return this.simple('funcHasCatering_S') }
    public get funcHasEquipment_S() { return this.simple('funcHasEquipment_S') }
    public get funcAdjustFilters_S() { return this.simple('funcAdjustFilters_S') }
    public get funcEditBookingUseAlternativeSpace_L() { return this.simple('funcEditBookingUseAlternativeSpace_L') }
    public get funcMustBeMoreThanError_L() { return this.simple('funcMustBeMoreThanError_L') }
    public get funcType_S() { return this.simple('funcType_S') }
    public get funcBookingRelationshipType_S() { return this.simple('funcBookingRelationshipType_S') }
    public get funcRaiseATask_S() { return this.simple('funcRaiseATask_S') }
    public get HubLabelClearUpIssue() { return this.simple('HubLabelClearUpIssue') }
    public get dataBookingMain_S() { return this.simple('dataBookingMain_S') }
    public get funcCatering_S() { return this.simple('funcCatering_S') }
    public get funcHasAudioVisualEquipment_S() { return this.simple('funcHasAudioVisualEquipment_S') }
    public get funcHasAudioVisualEquipment_L() { return this.simple('funcHasAudioVisualEquipment_L') }
    public get funcHasPresentationAids_S() { return this.simple('funcHasPresentationAids_S') }
    public get funcHasPresentationAids_L() { return this.simple('funcHasPresentationAids_L') }
    public get funcHasHearingAids_S() { return this.simple('funcHasHearingAids_S') }
    public get funcHasHearingAids_L() { return this.simple('funcHasHearingAids_L') }
    public get funcVisitorRecordPast_D() { return this.simple('funcVisitorRecordPast_D') }
    public get funcAdvancedBookingWhereTooltip_Message() { return this.simple('funcAdvancedBookingWhereTooltip_Message') }
    public get funcBookingSaveTags_S() { return this.simple('funcBookingSaveTags_S') }
    public get funcSpacesSignsOfLifeThresholdInfo_Message() { return this.simple('funcSpacesSignsOfLifeThresholdInfo_Message') }
    public get HubLabelNotStarted_S() { return this.simple('HubLabelNotStarted_S') }
    public get funcBookingsSummary_S() { return this.simple('funcBookingsSummary_S') }
    public get funcSearchForAColleague_S() { return this.simple('funcSearchForAColleague_S') }
    public get funcSearchForAColleague_Message() { return this.simple('funcSearchForAColleague_Message') }
    public get funcWeekToDate_S() { return this.simple('funcWeekToDate_S') }
    public get funcMonthToDate_S() { return this.simple('funcMonthToDate_S') }
    public get funcQuarterToDate_S() { return this.simple('funcQuarterToDate_S') }
    public get funcYearToDate_S() { return this.simple('funcYearToDate_S') }
    public get funcLastWeek_S() { return this.simple('funcLastWeek_S') }
    public get funcLastMonth_S() { return this.simple('funcLastMonth_S') }
    public get funcLastQuarter_S() { return this.simple('funcLastQuarter_S') }
    public get funcVisitorData_S() { return this.simple('funcVisitorData_S') }
    public get funcDateRange_S() { return this.simple('funcDateRange_S') }
    public get funcKpiDataForPeriod_S() { return this.simple('funcKpiDataForPeriod_S') }
    public get funcWeekdayTrendForPeriod_S() { return this.simple('funcWeekdayTrendForPeriod_S') }
    public get funcVisitorBreakdownByDay_S() { return this.simple('funcVisitorBreakdownByDay_S') }
    public get funcVisitorBreakdownByArrivalTimeVsDepartureTime_S() { return this.simple('funcVisitorBreakdownByArrivalTimeVsDepartureTime_S') }
    public get funcVisitAnalyticsForPeriod_S() { return this.simple('funcVisitAnalyticsForPeriod_S') }
    public get funcAvgDailyVisitorsPlanned_S() { return this.simple('funcAvgDailyVisitorsPlanned_S') }
    public get funcAvgDailyVisitorsApproved_S() { return this.simple('funcAvgDailyVisitorsApproved_S') }
    public get funcAvgDailyVisitorsAttended_S() { return this.simple('funcAvgDailyVisitorsAttended_S') }
    public get funcHours_S() { return this.simple('funcHours_S') }
    public get funcMeetingInvite_S() { return this.simple('funcMeetingInvite_S') }
    public get funcAdHocVisitor_S() { return this.simple('funcAdHocVisitor_S') }
    public get funcPlannedVisits_S() { return this.simple('funcPlannedVisits_S') }
    public get funcApprovedVisits_S() { return this.simple('funcApprovedVisits_S') }
    public get funcCancelledVisits_S() { return this.simple('funcCancelledVisits_S') }
    public get funcAttended_S() { return this.simple('funcAttended_S') }
    public get funcDailyExpectedVisits_S() { return this.simple('funcDailyExpectedVisits_S') }
    public get funcDailyVisits_S() { return this.simple('funcDailyVisits_S') }
    public get funcTotalVisitorsCreated_S() { return this.simple('funcTotalVisitorsCreated_S') }
    public get funcTotalVisitorsAttended_S() { return this.simple('funcTotalVisitorsAttended_S') }
    public get funcBookingsOperationalSlots_S() { return this.simple('funcBookingsOperationalSlots_S') }
    public get funcEditCateringOrderGrandTotal_S() { return this.simple('funcEditCateringOrderGrandTotal_S') }
    public get funcEditCateringOrder_S() { return this.simple('funcEditCateringOrder_S') }
    public get funcEditCateringOrderAdd_L() { return this.simple('funcEditCateringOrderAdd_L') }
    public get funcEditCateringOrderUnsavedChanges_Message() { return this.simple('funcEditCateringOrderUnsavedChanges_Message') }
    public get funcEditCateringOrderUnsavedChanges_S() { return this.simple('funcEditCateringOrderUnsavedChanges_S') }
    public get funcEditCateringOrderOrderTotal_S() { return this.simple('funcEditCateringOrderOrderTotal_S') }
    public get funcEditCateringOrderConfirmChanges_S() { return this.simple('funcEditCateringOrderConfirmChanges_S') }
    public get funcEditCateringOrderAddNewOrder_S() { return this.simple('funcEditCateringOrderAddNewOrder_S') }
    public get funcEditCateringOrderNewOrder_S() { return this.simple('funcEditCateringOrderNewOrder_S') }
    public get funcNoResultsFound_S() { return this.simple('funcNoResultsFound_S') }
    public get funcResultsFound_S() { return this.simple('funcResultsFound_S') }
    public get funcPieChart_S() { return this.simple('funcPieChart_S') }
    public get funcBarChart_S() { return this.simple('funcBarChart_S') }
    public get funcNumberOfVisits_S() { return this.simple('funcNumberOfVisits_S') }
    public get funcMeetingDuration_S() { return this.simple('funcMeetingDuration_S') }
    public get funcEditBookingContinueEditing_S() { return this.simple('funcEditBookingContinueEditing_S') }
    public get funcCharacters_S() { return this.simple('funcCharacters_S') }
    public get funcNotificationDuration_S() { return this.simple('funcNotificationDuration_S') }
    public get funcTimeOfDay_S() { return this.simple('funcTimeOfDay_S') }
    public get funcNotificationReminders_S() { return this.simple('funcNotificationReminders_S') }
    public get funcVisitReminder_S() { return this.simple('funcVisitReminder_S') }
    public get funcBookingReminder_S() { return this.simple('funcBookingReminder_S') }
    public get funcIncompleteBooking_S() { return this.simple('funcIncompleteBooking_S') }
    public get funcBuiltInRule_S() { return this.simple('funcBuiltInRule_S') }
    public get funcAdvancedBookingsWho_L() { return this.simple('funcAdvancedBookingsWho_L') }
    public get funcAdvancedBookingsSpace_L() { return this.simple('funcAdvancedBookingsSpace_L') }
    public get HubLabelAddVisitorDetails() { return this.simple('HubLabelAddVisitorDetails') }
    public get HubLabelSetBookingAutoCheckIn() { return this.simple('HubLabelSetBookingAutoCheckIn') }
    public get funcBookingsNoSpaces_D() { return this.simple('funcBookingsNoSpaces_D') }
    public get funcBookingsUpdateFilter_S() { return this.simple('funcBookingsUpdateFilter_S') }
    public get funcBookingsSelectAnotherFilter_S() { return this.simple('funcBookingsSelectAnotherFilter_S') }
    public get funcBookingsCreateFilter_L() { return this.simple('funcBookingsCreateFilter_L') }
    public get funcBookingsCreateFilter_S() { return this.simple('funcBookingsCreateFilter_S') }
    public get funcBookingsManageFilters_S() { return this.simple('funcBookingsManageFilters_S') }
    public get funcBookingAttendance_S() { return this.simple('funcBookingAttendance_S') }
    public get funcSubHeaderNotification_D() { return this.simple('funcSubHeaderNotification_D') }
    public get funcInAppNotifications_S() { return this.simple('funcInAppNotifications_S') }
    public get funcPushNotificationInfo_D() { return this.simple('funcPushNotificationInfo_D') }
    public get funcBookingsYesProceed_S() { return this.simple('funcBookingsYesProceed_S') }
    public get funcFlexBookingTimeChangePreCutOff_D() { return this.simple('funcFlexBookingTimeChangePreCutOff_D') }
    public get funcFlexBookingTimeChangePostCutOff_D() { return this.simple('funcFlexBookingTimeChangePostCutOff_D') }
    public get HubLabelBookingsForPrimaries() { return this.simple('HubLabelBookingsForPrimaries') }
    public get funcFlexBookingDateChangePreCutOff_D() { return this.simple('funcFlexBookingDateChangePreCutOff_D') }
    public get funcFlexBookingDateChangePostCutOff_D() { return this.simple('funcFlexBookingDateChangePostCutOff_D') }
    public get funcBookingAttendanceToolTip_L() { return this.simple('funcBookingAttendanceToolTip_L') }
    public get funcBypassingPolicies_Message() { return this.simple('funcBypassingPolicies_Message') }
    public get funcCurrentSpaceBookings_S() { return this.simple('funcCurrentSpaceBookings_S') }
    public get funcOpenScheduleForSpace_S() { return this.simple('funcOpenScheduleForSpace_S') }
    public get funcToTimeForNextBooking_D() { return this.simple('funcToTimeForNextBooking_D') }
    public get funcSpaceInfoReadSpaceGuide_S() { return this.simple('funcSpaceInfoReadSpaceGuide_S') }
    public get HubLabelSpaceInfoDescriptionNotAvailable() { return this.simple('HubLabelSpaceInfoDescriptionNotAvailable') }
    public get funcOnelensBookingTimeChangePreCutOff_D() { return this.simple('funcOnelensBookingTimeChangePreCutOff_D') }
    public get funcOnelensBookingTimeChangePostCutOff_D() { return this.simple('funcOnelensBookingTimeChangePostCutOff_D')}
    public get funcOnelensBookingDateChangePreCutOff_D() { return this.simple('funcOnelensBookingDateChangePreCutOff_D')}
    public get funcOnelensBookingDateChangePostCutOff_D() { return this.simple('funcOnelensBookingDateChangePostCutOff_D')}
    public get funcOnelensBookingSpaceChangePreCutOff_D() { return this.simple('funcOnelensBookingSpaceChangePreCutOff_D')}
    public get funcOnelensBookingSpaceChangePostCutOff_D() { return this.simple('funcOnelensBookingSpaceChangePostCutOff_D')}
    public get funcCateringOrderItemInUnavailableMenu_D() { return this.simple('funcCateringOrderItemInUnavailableMenu_D')}
}
