import { Component } from "react";
import { RouteComponentProps, RouterProps, withRouter } from "react-router-dom";
import { appContext } from "../../../AppContext";
import AlertModal, { IOptions } from "../../Dialogs/AlertDialog/AlertModal";
import Header, { IProps as IHeaderProps } from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Messages, { IMessage } from "../Messages/Messages";

class Layout extends Component<IProps, IState>
{
    private get appContext() { return appContext(); }
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }

    constructor(props: IProps)
    {
        super(props);

        this.appContext.router =
        {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
            staticContext: this.props.staticContext,
        };

        this.state =
        {
            isPrivate: props.isPrivate,
            showAlert: false,
            alertTitle: "",
            alertMessage: "",
            messages: [],
        };

    }

    public componentDidMount(): void
    {
        this.appState.autoMap(this, i => ({ messages: i.messages }));
        this.alert.changed = (show, title, message, options) => this.alertChanged(show, title, message, options);
    }

    public componentWillUnmount(): void
    {
        this.alert.changed = undefined;
    }

    private alertChanged(show: boolean, title: string, message: string, options?: IOptions): Promise<void>
    {
        return new Promise((resolve, reject) =>
        {
            this.setState({
                showAlert: show,
                alertTitle: title,
                alertMessage: message,
                alertOptions: options,
            }, () => resolve());
        });
    }

    private hideAlert(): void
    {
        this.alert.hide();
    }

    public render(): JSX.Element
    {
        return (
            <>
                <AlertModal
                    show={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    options={this.state.alertOptions}
                    onClose={() => this.hideAlert()}
                />
                {this.state.isPrivate &&
                    <div className="page-container" style={{ overflow: "hidden", display: "flex", backgroundColor: "var(--ui-background)" }}>
                        <Sidebar {...this.props} />
                        <main>
                            <div className="rightPanel">
                                <header>
                                    <Header {...this.props as unknown as IHeaderProps} />
                                </header>
                                <Messages messages={this.state.messages} />
                                {this.props.children}
                            </div>
                        </main>
                    </div>
                }
                {!this.state.isPrivate && this.props.children}
            </>);
    }
}

export default withRouter(Layout);

interface IProps extends RouteComponentProps, RouterProps
{
    isPrivate: boolean;
}

interface IState
{
    isPrivate: boolean;
    showAlert: boolean;
    alertTitle: string;
    alertMessage: string;
    alertOptions?: IOptions;
    messages: IMessage[];
}
