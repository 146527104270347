
import { getBuildingNameUsingBuildingId } from "../../../../Common/Helper";
import { appContext } from "../../../../AppContext";
import { RouteComponentProps } from "react-router";
import { PagedResponse } from "../../../../Providers.Api/Models";
import { DateTime } from "luxon";
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material'
import IbssButton from '../../../../Components/Buttons/Button/IbssButton'
import EditVisitorDetails from './EditVisitorDetails';
import EditVisitorStatus from './EditVisitorStatus';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import AssociatedDetails from './AssociatedDetails';
import { DateHelper } from '../../../../Common/DateHelper';
import { VisitDetail } from './DataModels';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import Notes, { INote } from "../../../../Components/Lists/Notes/Notes";
import { Diff, IDiffResult } from "../../../../Common/Diff";
import { Note } from "../../../OneLens/Visits/ViewDashboard/DataModels";
import { EventType } from "../../../../Providers.Api/EventTypes";
import { ISpace } from "../../../../Providers.Api/Spaces/UpdateV2SpaceByIdEndpoint";
import { ApiConstants } from "../../../../Providers.Api/ApiConstants";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import EditHostDetails from "./EditHostDetails";
import "../../../../styles/css/dashboard.scss";
import "../../../../styles/css/header.scss";
import "../../../../App.css";

class GetVisitorService
{
  public async execute(nodeId: number, visitId: string, hasV2Rights: boolean): Promise<VisitDetail>
  {
    if (hasV2Rights)
    {
      return await appContext().ibssApiClientV2.v2.byNodeid.visits.byVisitid.get<VisitDetail>({
        nodeId,
        visitid: visitId,
      });
    } else
    {
      return await appContext().ibssApiClientV1.v1.byNodeid.visits.byVisitid.get<VisitDetail>({
        nodeId,
        visitid: visitId,
      });
    }
  }
}


class EditVisit extends IbssPage<IProps, IState> 
{
  private get labels() { return appContext().labels; }
  private get alert() { return appContext().alert; }
  private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Visits.V2"); }
  private get hasUpdateRights() { return appContext().localStorageProvider.hasRight("DATAMODEL.Visits.Update"); }
  private getVisitorDetail = new GetVisitorService();
  private buildingId: number;
  private visitId: string;


  constructor(props: IProps)
  {
    super(props);
    this.state =
    {
      isLoading: false,
      visitorDetail:
      {
        visitorFirstName: "",
        visitorLastName: "",
        company: "",
        visitorEmail: "",
        pacsId: "",
      },
      hostDetail:
      {
        hostName: "",
        hostEmail: "",
      },
      associatedDetails:
      {
        arrivalLocation: "",
        arrivalLocationId: "",
        bookingName: "",
        bookingId: "",
        bookingStart: DateTime.now(),
        bookingEnd: DateTime.now(),
      },
      visitorStatus:
      {
        isApproved: 0,
        isCancelled: 0,
        isDenied: 0,
        isCheckedIn: 0,
        isCheckedOut: 0,
        isNoShow: 0
      },
      visitId: "",
      status: "",
      showDateTimeError: false,
      visitorStartDate: DateTime.now(),
      visitorEndDate: DateTime.now(),
      approveDenyButtonDisabled: true,
      checkInButtonDisabled: true,
      checkOutButtonDisabled: true,
      showDenyModal: false,
      denyComment: "",
      originalNotes: [],
      notes: [],
      arrivalLocations: [],
      showDeleteConfirmationModal: false,
    }
    this.buildingId = parseInt(this.props.match.params.buildingid);
    this.visitId = this.props.match.params.visit_Id;
  }

  private get notesDiff(): IDiffResult<INote>
  {
    const { originalNotes, notes } = this.state;
    const diff = new Diff(originalNotes, notes, i => i.id, (a, b) => a.value == b.value);
    const result = diff.execute();
    return result;
  }

  public async getVisitorDetails(): Promise<void>
  {
    this.setState({
      isLoading: true,
    })

    const visit = await this.getVisitorDetail.execute(this.buildingId, this.visitId, this.hasV2Rights);

    const enableApproved = DateTime.fromISO(visit.Visit_Start_Date).toUtcByNode(this.buildingId).toISODate() === DateTime.local().startOf('day').toISODate();

    this.setState({
      visitorDetail: {
        visitorFirstName: visit.Visitor_First_Name,
        visitorLastName: visit.Visitor_Last_Name,
        company: visit.Visitor_Company,
        visitorEmail: visit.Visitor_Email,
        pacsId: visit.Visit_Pacs_Id,
      },
      hostDetail: {
        hostName: visit.Visit_Host_Name,
        hostEmail: visit.Visit_Host_Email,
      },
      associatedDetails: {
        arrivalLocation: visit.Space_Name,
        arrivalLocationId: visit.Space_Id,
        bookingName: visit.Booking_Name,
        bookingId: visit.Booking_Id,
        bookingStart: DateHelper.fromIsoByNode(visit.Booking_Start, this.buildingId),
        bookingEnd: DateHelper.fromIsoByNode(visit.Booking_End, this.buildingId),
      },
      visitorStatus: {
        isApproved: visit.Visit_IsApproved,
        isCancelled: visit.Visit_IsCancelled,
        isDenied: visit.Visit_IsDenied,
        isCheckedIn: visit.Visit_IsCheckedIn,
        isCheckedOut: visit.Visit_IsCheckedOut,
        isNoShow: visit.Visit_IsNoShow
      },
      visitId: visit.Visit_Id,
      status: visit.Visit_Status,
      visitorStartDate: DateHelper.fromIsoByNode(visit.Visit_Start_Date, this.buildingId),
      visitorEndDate: DateHelper.fromIsoByNode(visit.Visit_End_Date, this.buildingId),
      isLoading: false,
      approveDenyButtonDisabled: visit.Visit_Status == "Awaiting Approval" ? false : true,
      checkInButtonDisabled: visit.Visit_Status == "Approved" && enableApproved ? false : true,
      checkOutButtonDisabled: visit.Visit_Status == "Checked In" ? false : true,
    });
  }

  private async getNotes(): Promise<void>
  {
    const notesData = await appContext().ibssApiClientV2.v2.byNodeid.notes.get<PagedResponse<Note[]>>({
      nodeId: this.buildingId,
      filter: `EventType_Id eq ${EventType.visits.id} and Event_Id eq '${this.visitId}' and IsDeleted eq 0`,
      select: Note,
      top: ApiConstants.largeODataTop,
    });

    const notes: INote[] = notesData.value.map(i => ({
      isNew: false,
      id: i.Note_Id,
      value: i.Note,
      author: i.CreatedBy,
      time: i.CreatedAt,
    }));

    this.setState({
      originalNotes: JSON.parse(JSON.stringify(notes)),
      notes: notes,
    })
  }

  private async saveNotes(): Promise<void>
  {
    const diff = this.notesDiff;
    for (let note of diff.createdItems)
    {
      try
      {
        appContext().ibssApiClientV2.v2.byNodeid.notes.post({
          nodeId: this.buildingId,
          body: {
            Node_Id: this.buildingId,
            EventType_Id: EventType.visits.id,
            EventType_Name: EventType.visits.name,
            Event_Id: this.visitId,
            Note: note.value,
            IsDeleted: 0
          },
        });
      }
      catch { }
    }

    for (let note of diff.updatedItemsAfter)
    {
      try
      {
        appContext().ibssApiClientV2.v2.byNodeid.notes.byNoteid.put({
          nodeId: this.buildingId,
          noteid: note.id,
          body: {
            Node_Id: this.buildingId,
            Note_Id: note.id,
            EventType_Id: EventType.visits.id,
            EventType_Name: EventType.visits.name,
            Event_Id: this.visitId,
            Note: note.value,
            IsDeleted: 0,
          },
        });
      }
      catch { }
    }

    for (let note of diff.deletedItems)
    {
      try
      {
        appContext().ibssApiClientV2.v2.byNodeid.notes.byNoteid.cancel.patch({
          nodeId: this.buildingId,
          noteid: note.id,
          body: {
            IsDeleted: 1
          },
        });
      }
      catch { }
    }
  }

  private async getLocationDetails(): Promise<void>
  {
    try 
    {
      const arrivalLocation: IFilterOptions[] = [];
      const spaces = await appContext().ibssApiClientV2.v2.byNodeid.spaces.get<PagedResponse<ISpace[]>>({ nodeId: this.buildingId, filter: "Space_Arrival_Loc eq 1" });
      spaces.value.forEach((item) =>
      {
        arrivalLocation.push({
          label: item.Space_Name,
          value: item.Space_Id,
        })
      })

      this.setState({ arrivalLocations: arrivalLocation });
      const selectedLocation = arrivalLocation.find((item) => item.value === this.state.associatedDetails.arrivalLocationId);
      if (selectedLocation)
      {
        this.setState({
          associatedDetails:
          {
            ...this.state.associatedDetails,
            arrivalLocation: selectedLocation.label,
            arrivalLocationId: selectedLocation.value,
          }
        })
      }
    }
    catch (error)
    {
    }
  }

  public componentDidMount(): void
  {
    const buildingName = getBuildingNameUsingBuildingId(this.buildingId);
    this.pageTitle = this.labels.HubMenuMyVisitors + ' - ' + buildingName;
    this.getVisitorDetails();
    this.getLocationDetails();
    this.getNotes();
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    const { target: { name, value } } = event;
    this.setState((prevState) => ({
      visitorDetail: {
        ...prevState.visitorDetail,
        [name]: value,
      },
    }));
  }


  private firstNameChanged(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    this.handleInputChange(event);
  }

  private lastNameChanged(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    this.handleInputChange(event);
  }

  private emailChanged(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    this.handleInputChange(event);
  }

  private companyChanged(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    this.handleInputChange(event);
  }

  private pacsChanged(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    this.handleInputChange(event);
  }

  private hostNameChanged(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    this.setState((prevState) => ({
      hostDetail: {
        ...prevState.hostDetail,
        hostName: event.target.value,
      },
    }));
  }

  private async handleSave(): Promise<void>
  {
    const diff = this.notesDiff;
    try 
    {
      this.setState({
        isLoading: true
      })
      const currentDateTime = DateTime.now().toUTC();
      const visitStartDateTime = this.state.visitorStartDate.setZoneByNode(this.buildingId).toUTC();

      if (visitStartDateTime > currentDateTime) 
      {
        const payload =
        {
          Visitor_First_Name: this.state.visitorDetail.visitorFirstName,
          Visitor_Last_Name: this.state.visitorDetail.visitorLastName,
          Visitor_Email: this.state.visitorDetail.visitorEmail,
          Visitor_Company: this.state.visitorDetail.company,
          Visit_Host_Name: this.state.hostDetail.hostName,
          Visit_Host_Email: this.state.hostDetail.hostEmail,
          Visit_Start_Date: visitStartDateTime.toISO(),
          Visit_End_Date: this.state.visitorEndDate.setZoneByNode(this.buildingId).toUTC().toISO(),
          Visit_Approval_Comments: "",
          Visitor_Dietary_Pref: 0,
          Visit_Pacs_Id: this.state.visitorDetail.pacsId,
          Visit_Save_Info: 0,
          Space_Name: this.state.associatedDetails.arrivalLocation,
          Space_Id: this.state.associatedDetails.arrivalLocationId,
          Disable_Ext_Update: false
        }
        this.saveNotes();
        const data = await appContext().ibssApiClientV2.v2.byNodeid.visits.byVisitid.put({ nodeId: this.buildingId, visitid: this.visitId, body: payload });
        if (data)
        {
          this.setState({
            isLoading: false,
          });
          this.props.history.goBack();
        }
      } 
      else if(diff.createdItems.length > 0 || diff.updatedItemsAfter.length > 0 || diff.deletedItems.length > 0)
      {
        this.saveNotes();
        this.alert.show(this.labels.HubLabelWarning, this.labels.funcVisitorRecordPast_D);
      }
      else 
      {
        this.props.history.goBack();
      }
    } 
    catch (error) 
    {
      this.saveNotes();
    }
    finally
    {
      this.setState({
        isLoading: false,
      });
    }
  }

  private async handleDelete(): Promise<void>
  {
    try 
    {
      const data: string = await appContext().ibssApiClientV2.v2.byNodeid.visits.byVisitid.delete({ nodeId: this.buildingId, visitid: this.visitId })
      this.alert.show("", data);
    }
    catch (error) 
    {
    }
    this.props.history.goBack();
  }

  private async approvedClicked(): Promise<void>
  {
    this.setState({
      isLoading: true,
    })
    await appContext().ibssApiClientV2.v2.byNodeid.visits.approve.post({
      nodeId: this.buildingId,
      body:
        [{
          Visit_Id: this.visitId,
          Visit_Approve_Comment: ''
        }]
    })
    this.getVisitorDetails();
    this.setState({
      isLoading: false,
    })
  }

  private async denyVisitorClicked(): Promise<void>
  {
    this.setState({
      isLoading: true,
    })
    await appContext().ibssApiClientV2.v2.byNodeid.visits.deny.post({
      nodeId: this.buildingId, body:
        [{
          Visit_Id: this.visitId,
          Visit_Denial_Comment: this.state.denyComment
        }]
    })
    this.getVisitorDetails();
    this.setState({ showDenyModal: false, isLoading: false })
  }

  private async checkInVisitorClicked(): Promise<void>
  {
    this.setState({
      isLoading: true,
    })
    await appContext().ibssApiClientV2.v2.byNodeid.visits.checkin.post({
      nodeId: this.buildingId, body:
        [
          {
            Visit_Id: this.visitId,
          }
        ]
    })
    this.getVisitorDetails();
    this.setState({
      isLoading: false,
    })
  }

  private async checkOutVisitorClicked(): Promise<void>
  {
    this.setState({
      isLoading: true,
    })
    await appContext().ibssApiClientV2.v2.byNodeid.visits.checkout.post({
      nodeId: this.buildingId, body:
        [
          {
            Visit_Id: this.visitId,
          }
        ]
    })
    this.getVisitorDetails();
    this.setState({
      isLoading: false,
    })
  }

  private startDateTimeChanged(value: DateTime): void
  {
    if (value > this.state.visitorEndDate)
    {
      this.setState({ showDateTimeError: true });
    }
    else
    {
      this.setState({ showDateTimeError: false });
    }
    this.setState({ visitorStartDate: value });
  }

  private endDateTimeChanged(value: DateTime): void
  {
    if (value < this.state.visitorStartDate)
    {
      this.setState({ showDateTimeError: true });
    }
    else
    {
      this.setState({ showDateTimeError: false });
    }
    this.setState({ visitorEndDate: value });
  }

  private visitorLocationChanged(event: SelectChangeEvent): void
  {
    const selectedPolicy = this.state.arrivalLocations.find((item) => item.value === event.target.value);
    if (selectedPolicy)
    {
      this.setState
        ({
          associatedDetails:
          {
            ...this.state.associatedDetails,
            arrivalLocation: selectedPolicy.label,
            arrivalLocationId: selectedPolicy.value,
          }
        })
    }
  }

  render() 
  {

    const submitActive = this.state.visitorDetail.visitorFirstName === "" || this.state.visitorDetail.visitorLastName === "" || this.state.visitorDetail.company === "" || this.state.visitorDetail.visitorEmail === "" || this.state.hostDetail.hostName === ""

    return (
      <div className="page-height-exct-header">
        <div className="rightPanel-main-content">
          <h2>{this.labels.HubLabelvisitor} : {this.state.visitorDetail.visitorFirstName} {this.state.visitorDetail.visitorLastName}</h2>
          {this.state.isLoading && <Spinner />}
          <IbssDialog
            aria-modal="true"
            aria-label="deny modal"
            onClose={() => this.setState({ showDenyModal: !this.state.showDenyModal })}
            open={this.state.showDenyModal}
            header={this.labels.HubButtonDeny}
            fullWidth
            dialogContent={
              <>
                <div className="col-12">
                  <label>{this.labels.HubLabelComment}</label>
                  <input className="input-box border model-textbox" type="text" name="denyComment" value={this.state.denyComment} onChange={(e) => this.setState({ denyComment: e.target.value })} />
                </div>
              </>
            }
            footer={
              <>
                <IbssButtonRedo
                  color="secondary"
                  variant="outlined"
                  className="mr-2"
                  onClick={() => this.setState({ showDenyModal: !this.state.showDenyModal })}>
                  {this.labels.HubButtonCancel}
                </IbssButtonRedo>
                <IbssButtonRedo
                  color="primary"
                  variant="contained"
                  size="medium"
                  disabled={!this.state.denyComment}
                  onClick={() => this.denyVisitorClicked()}>
                  {this.labels.HubLabelOk}
                </IbssButtonRedo>
              </>
            }
          />
          <ConfirmModal
            show={this.state.showDeleteConfirmationModal}
            modalHeading={this.labels.HubLabelDeleteCol}
            handleModal={() => this.setState({ showDeleteConfirmationModal: false })}
            modalMessage={this.labels.HubLabelDeleteMessage}
            okButton={() => this.handleDelete()}
          />
          <Grid container>
            <Grid xs={6} pr={2}>
              <Box className="tablePanel">
                <h3>{this.labels.HubLabelVisitorDetails}</h3>
                <EditVisitorDetails
                  visitorDetails={this.state.visitorDetail}
                  onFirstNameChanged={(event) => this.firstNameChanged(event)}
                  onLastNameChanged={(event) => this.lastNameChanged(event)}
                  onCompanyChanged={(event) => this.companyChanged(event)}
                  onEmailChanged={(event) => this.emailChanged(event)}
                  onPacsChanged={(event) => this.pacsChanged(event)}
                />
                <Box>
                  <h3>{this.labels.funcVisitorNotes_S}</h3>
                  <Notes
                    buildingId={this.buildingId}
                    notes={this.state.notes}
                    onChange={notes => this.setState({ notes: notes })}
                  />
                </Box>
                <Typography color="textSecondary" sx={{ mt: 2 }}>
                  {this.labels.funcVisitorFrontDeskNotes_S}
                </Typography>
                <hr />
                <EditHostDetails
                  hostDetail={this.state.hostDetail}
                  onHostNameChanged={(event) => this.hostNameChanged(event)}
                  visitorStartDate={this.state.visitorStartDate}
                  startDateTimeChanged={(event) => this.startDateTimeChanged(event)}
                  visitorEndDate={this.state.visitorEndDate}
                  endDateTimeChanged={(event) => this.endDateTimeChanged(event)}
                  showDateTimeError={this.state.showDateTimeError}
                  onVisitorArrivalLocations={(event) => this.visitorLocationChanged(event)}
                  visitorArrivalLocationId={this.state.associatedDetails.arrivalLocationId}
                  visitorArrivalLocations={this.state.arrivalLocations}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box className="tablePanel">
                <EditVisitorStatus
                  visitId={this.state.visitId}
                  status={this.state.status}
                  visitorStatus={this.state.visitorStatus}
                />
                <Box display={'flex'} justifyContent={'space-between'} mx={2}>
                  <IbssButton color="secondary" variant="contained" size="medium" disabled={this.state.approveDenyButtonDisabled && this.hasUpdateRights} onClick={() => this.approvedClicked()}>
                    {this.labels.HubLabelApprove}
                  </IbssButton>
                  <IbssButton color="secondary" variant="contained" size="medium" disabled={this.state.approveDenyButtonDisabled && this.hasUpdateRights} onClick={() => this.setState({ showDenyModal: !this.state.showDenyModal })}>
                    {this.labels.HubButtonDeny}
                  </IbssButton>
                  <IbssButton color="secondary" variant="contained" size="medium" disabled={this.state.checkInButtonDisabled && this.hasUpdateRights} onClick={() => this.checkInVisitorClicked()}>
                    {this.labels.HubButtonCheckIn}
                  </IbssButton>
                  <IbssButton color="secondary" variant="contained" size="medium" disabled={this.state.checkOutButtonDisabled && this.hasUpdateRights} onClick={() => this.checkOutVisitorClicked()}>
                    {this.labels.HubButtonCheckOut}
                  </IbssButton>
                </Box>
                {this.state.associatedDetails.bookingId &&
                  <AssociatedDetails
                    visitorStatus={this.state.visitorStatus}
                    associatedDetails={this.state.associatedDetails}
                  />
                }
              </Box>
            </Grid>
          </Grid>
          <Box className="rightPanel-main-button-group">
            <Box>
              <IbssButton color='error' disabled={!this.hasUpdateRights} variant="contained" size="medium" onClick={() => this.setState({ showDeleteConfirmationModal: true })}>
                {this.labels.funcCateringMenuEditDelete_S}
              </IbssButton>
            </Box>
            <Box>
              <IbssButton  color="secondary" className="mr-2" variant="contained" size="medium" onClick={() => this.props.history.goBack()}>
                {this.labels.HubButtonCancel}
              </IbssButton>
              <IbssButton
                disabled={submitActive && !this.hasUpdateRights}
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => this.handleSave()}
              >
                {this.labels.HubButtonSave}
              </IbssButton>
            </Box>
          </Box>
        </div>
      </div>
    )
  }
}

export default EditVisit;

export interface IQueryParams
{
  buildingid: string;
  visit_Id: string;
}

export interface IProps extends RouteComponentProps<IQueryParams>
{
}


export interface IVisitorDetail
{
  visitorFirstName: string;
  visitorLastName: string;
  company: string;
  visitorEmail: string;
  pacsId: string;
}

export interface IAssociatedDetail
{
  arrivalLocation: string;
  arrivalLocationId: string;
  bookingName: string;
  bookingId: string;
  bookingStart: DateTime;
  bookingEnd: DateTime;
}

export interface IVisitorStatus
{
  isApproved: number;
  isCancelled: number;
  isDenied: number;
  isCheckedIn: number;
  isCheckedOut: number;
  isNoShow: number;
}

export interface IHostDetail
{
  hostName: string;
  hostEmail: string;
}

export interface IFilterOptions
{
  label: string;
  value: string;
}

interface IState
{
  isLoading: boolean;
  visitorDetail: IVisitorDetail;
  hostDetail: IHostDetail;
  visitorStatus: IVisitorStatus;
  visitId: string;
  status: string;
  associatedDetails: IAssociatedDetail;
  visitorStartDate: DateTime;
  visitorEndDate: DateTime;
  showDateTimeError: boolean;
  showDenyModal: boolean;
  denyComment: string,
  approveDenyButtonDisabled: boolean;
  checkInButtonDisabled: boolean;
  checkOutButtonDisabled: boolean;
  originalNotes: INote[];
  notes: INote[];
  arrivalLocations: IFilterOptions[];
  showDeleteConfirmationModal: boolean;
}